import React from "react";
import {FormattedMessage} from "react-intl";
import "./styles/HomeCustomSearch.scss";
import {Link} from "react-router-dom";

const HomeCustomSearch = (props) => (
    <div className="home-custom-search">
      <h1 className="home-custom-search__title text-weight-light">
        <FormattedMessage
            id="HomeCustomSearch.title"
            description="Title for the home's custom search box"
            defaultMessage="Product Library"/>
      </h1>
      <hr className="home-custom-search__separator"/>
      <h4 className="home-custom-search__subtitle text-weight-normal">
        <FormattedMessage
            id="HomeCustomSearch.subtitle"
            description="Subtitle for the home's custom search box"
            defaultMessage="Custom your search"/>
      </h4>
      <p className="home-custom-search__text text-weight-light">
        <FormattedMessage
            id="HomeCustomSearch.text"
            description="Text below the subtitle at home's custom search box"
            defaultMessage="Explore all geographies products and filter them according to your needs."/>
      </p>
      <Link
          to="/products"
          className="home-custom-search__button btn btn-primary">
        <FormattedMessage
            id="HomeCustomSearch.buttonText"
            description="Text for the call to action at home's custom search box"
            defaultMessage="Access now!"/>
      </Link>
    </div>
);

export default HomeCustomSearch;