import {applyMiddleware, combineReducers, createStore} from "redux";
import {reducer as formReducer} from 'redux-form'
import {headerReducer} from "components/Header/reducer";
import {productsPageReducer} from "scenes/ProductsPage/reducer";
import {authReducer} from "scenes/Auth/reducer/index";
import proquinalHistory from "services/browser-history";
import {connectRouter} from 'connected-react-router'
import { routerMiddleware } from 'connected-react-router'
import {localesReducer} from "intl/reducer";
import {footerReducer} from "components/Footer/reducer";

const combinedReducers = combineReducers({
  form: formReducer,
  router: connectRouter(proquinalHistory),
  headerReducer,
  productsPageReducer,
  authReducer,
  localesReducer,
  footerReducer
});

export const reduxStore = createStore(
    combinedReducers,
    applyMiddleware(
        routerMiddleware(proquinalHistory)
    )
);