import {LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_TOKEN_EXPIRATION_KEY} from "services/auth/contants/index";

export function getToken() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
}

export function setToken(token) {
  return localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
}

export function removeToken() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
}

export function getTokenExpirationDate() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_EXPIRATION_KEY);
}

export function setTokenExpirationDate(token) {
  return localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRATION_KEY, token);
}

export function removeTokenExpirationDate() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRATION_KEY);
}

/**
 * Returns true if there is a Token in the local storage.
 * DO NOT USE THIS METHOD TO DETERMINE IF A USER IS AUTHENTICATED INSIDE A COMPONENT, USE isAuthenticated FROM authReducer INSTEAD.
 * @returns {boolean} isAuthenticated
 */
export function isAuthenticated() {
  return getToken() !== null;
}
