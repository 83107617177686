import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {reduxForm} from "redux-form";
import {renderInput} from "utils/form/renderers";
import {required} from "utils/form/validators";
import {Field} from "redux-form";

export const ResetPasswordForm = ({isLoading, handleSubmit, validationErrors}) => (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label
            htmlFor="password"
            className="sr-only">
          <FormattedMessage
              id="ResetPasswordForm.passwordLabel"
              description="Label for password input at reset password form"
              defaultMessage="Password"/>
        </label>
        <FormattedMessage
            id="ResetPasswordForm.passwordPlaceholder"
            description="Placeholder for password input at reset password form"
            defaultMessage="New password">
          {
            placeholder => (
                <Field
                    name="password"
                    id="password"
                    component={renderInput}
                    type="password"
                    placeholder={placeholder}
                    validate={[required]}
                    className="form-control"/>
            )
          }
        </FormattedMessage>
      </div>
      <div className="form-group">
        <label
            htmlFor="passwordConfirmation"
            className="sr-only">
          <FormattedMessage
              id="ResetPasswordForm.passwordConfirmationLabel"
              description="Label for password confirmation input at reset password form"
              defaultMessage="Password confirmation"/>
        </label>
        <FormattedMessage
            id="ResetPasswordForm.passwordConfirmationPlaceholder"
            description="Placeholder for password confirmation input at reset password form"
            defaultMessage="Confirm your password">
          {
            placeholder => (
                <Field
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    component={renderInput}
                    type="password"
                    placeholder={placeholder}
                    validate={[required]}
                    className="form-control"/>
            )
          }
        </FormattedMessage>
      </div>
      {
        validationErrors.map((error, index) => (
            <p key={`formError${index}`}>
              <strong>
                {error}
              </strong>
            </p>
        ))
      }
      <button
          type="submit"
          className="btn btn-outline-primary btn-lg"
          disabled={isLoading}>
        {
          !isLoading ?
              <FormattedMessage
                  id="ResetPasswordForm.submitButton"
                  description="Submit button label at reset password form"
                  defaultMessage="Submit"/>
              :
              <i className="fa fa-spinner fa-spin"/>
        }
      </button>
    </form>

);

ResetPasswordForm.defaultProps = {
  validationErrors: []
};

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  validationErrors: PropTypes.array,
  initialValues: PropTypes.object
};

const ResetPasswordReduxForm = reduxForm({
  form: 'ResetPasswordForm'
})(ResetPasswordForm);


export default ResetPasswordReduxForm;