import React from "react";
import spradlingLogoLandscape from "./img/spradling-logo-landscape.svg";
import spradlingLogoPortrait from "./img/spradling-logo-portrait.svg";
import {Link} from "react-router-dom";
import "./styles/HeaderLogo.scss";

const HeaderLogo = (props) => (
    <Link
        className="header-logo"
        to="/">
      <img
          src={spradlingLogoLandscape}
          alt="Spradling"
          className="header-logo__image header-logo__image--landscape"/>
      <img
          src={spradlingLogoPortrait}
          alt="Spradling"
          className="header-logo__image header-logo__image--portrait"/>
    </Link>
);

export default HeaderLogo;