import gql from "graphql-tag";

export const flameRetardancyTestsQuery = gql`
  query FlameRetardancyTestsQuery {
      flameRetardancyTests {
          edges {
              node {
                  id
                  name
              }
          }
      }
  }
`;

export const gaugeRangeLimitsQuery = gql`
  query GaugeRangeLimitsQuery {
      gaugeRangeLimits
  }
`;

export const widthRangeLimitsQuery = gql`
  query WidthRangeLimitsQuery {
      generalWidthLimits
  }
`;

export const weightRangeLimitsQuery = gql`
  query WeightRangeLimitsQuery {
      generalWeightLimits
  }
`;
