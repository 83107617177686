import gql from "graphql-tag";
import singlePatternFragment from "utils/fragments/SinglePatternFragment";

export const multipleSimplePatternsQuery = gql`
    query MultipleSimplePatternsQuery($ids: [ID]) {
        patterns(id: $ids) {
            edges {
                node {
                    ...SinglePatternFragment
                    imageThumbSmall
                    products {
                        totalCount
                        edges {
                            node {
                                id
                                code
                                color
                                image
                            }
                        }
                    }
                }
            }
        }
    }
    ${singlePatternFragment}
`;
export default multipleSimplePatternsQuery;