import React from "react";
import dummyImage from './img/dummy-image.jpg';

const DummyCard = (props) => (
    <div className="compare-card">
      <div className="compare-card__image">
        <div
            className="product-card-image"
            style={{
              backgroundImage: `url("${dummyImage}")`
            }}/>
      </div>
      <div className="compare-card__name">
        &nbsp;
      </div>
    </div>
);

export default DummyCard;