import * as types from "../actions/types";

export const initialState = {
  filtersBarIsOpen: false,
  compareBoxItems: []
};

export const productsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILTERS_BAR_OPEN:
      return {
        ...state,
        filtersBarIsOpen: true
      };
    case types.FILTERS_BAR_CLOSE:
      return {
        ...state,
        filtersBarIsOpen: false
      };
    default:
      return state;
  }
};