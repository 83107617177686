import gql from "graphql-tag";

const registerSpecSheetDownloadMutation = gql`
    mutation RegisterSpecSheetDownload($input: RegisterSpecSheetDownloadInput!) {
        registerSpecSheetDownload(input: $input) {
            registered
        }
    }

`;

export default registerSpecSheetDownloadMutation;