import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import "./styles/FiltersBarButton.scss";

const FiltersBarButton = ({closeMode, className, openFiltersBar, closeFiltersBar}) => (
    <button
        className={`btn btn-outline-secondary filters-bar-button ${className}`}
        onClick={closeMode ? closeFiltersBar : openFiltersBar}>
      {
        closeMode
            ?
            (
                <React.Fragment>
                  <FormattedMessage
                      id="FiltersBarButton.goBackButtonText"
                      description="Go back label to filter button in Product Filters on Mobile view"
                      defaultMessage="Go back"/>
                  &nbsp;
                  <i className="sp-arrow-right"/>
                </React.Fragment>
            )
            :
            (
                <React.Fragment>
                  <i className="sp-arrow-left"/>
                  &nbsp;
                  <FormattedMessage
                      id="FiltersBarButton.filterButtonText"
                      description="Label to filter button in Product list on Mobile view"
                      defaultMessage="Filter"/>
                </React.Fragment>
            )
      }
    </button>
);

FiltersBarButton.propTypes = {
  closeMode: PropTypes.bool,
  className: PropTypes.string
};

FiltersBarButton.defaultProps = {
  closeMode: false,
  className: ""
};

export default FiltersBarButton;