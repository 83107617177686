import * as types from './types';

export const openSearchBox = () => ({
  type: types.SEARCH_BOX_OPEN
});

export const closeSearchBox = () => ({
  type: types.SEARCH_BOX_CLOSE
});

export const openMainMenu = () => ({
  type: types.MAIN_MENU_OPEN
});

export const closeMainMenu = () => ({
  type: types.MAIN_MENU_CLOSE
});