import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/FiltersBox.scss";

class FiltersBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  /**
   * Handles click on the header to toggle visibility of the body
   * @param {Object} event
   */
  handleHeaderClick(event) {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const {title, children} = this.props;
    const {isOpen} = this.state;

    return (
        <div className={classNames({
          "filters-box": true,
          "filters-box--open": isOpen
        })}>
          <div
              className="filters-box__header"
              onClick={this.handleHeaderClick}>
            <h4>
              {title}
              {
                isOpen ?
                    <i className="sp-minus"/>
                    :
                    <i className="sp-plus"/>
              }
            </h4>
          </div>
          <div className={classNames({
            "filters-box__body": true,
            "filters-box__body--visible": isOpen
          })}>
            <div className="filters-box-content">
              {children}
            </div>
          </div>
        </div>
    )
  }
}

FiltersBox.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default FiltersBox