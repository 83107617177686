import {connect} from "react-redux";
import TermsModal from "../index";
import {closeTermsModal} from "components/Footer/actions";

const mapStateToProps = (state) => {
  return {
    footerReducer: state.footerReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeTermsModal: () => dispatch(closeTermsModal())
  };
};

const TermsModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsModal);

export default TermsModalContainer;