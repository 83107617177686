import { connect } from 'react-redux';
import HeaderSearchButton from "../index";
import {closeSearchBox, openSearchBox} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSearchBox: () => dispatch(openSearchBox()),
    closeSearchBox: () => dispatch(closeSearchBox()),
  };
};

const HeaderSearchButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderSearchButton);

export default HeaderSearchButtonContainer;