import {connect} from "react-redux";
import HeaderBurgerButton from "../index";
import {closeMainMenu, openMainMenu} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openMainMenu: () => dispatch(openMainMenu()),
    closeMainMenu: () => dispatch(closeMainMenu())
  };
};

const HeaderBurgerButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderBurgerButton);

export default HeaderBurgerButtonContainer;