import React from "react";
import {FormattedMessage} from "react-intl";

const FAQ = (props) => (
    <div>
      <h1>
        <FormattedMessage
            id="FAQ.title"
            description="Title of the FAQ page"
            defaultMessage="Frequently Asked Questions"/>
      </h1>
    </div>
);

export default FAQ;