import React from "react";
import { FormattedMessage } from "react-intl";
import "./styles/HomeLatestReleases.scss";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import { HOME_LATEST_RELEASE_AMOUNT } from "scenes/Home/components/HomeLatestReleases/constants";
import proquinalHistory from "services/browser-history";
import simplePatternsQuery from "scenes/Home/components/HomeLatestReleases/query";

const HomeLatestReleases = (props) => {
  /**
   * Handle product selector to redirect to product details page
   * @param event
   */
  const handleMobileSelectorChange = (event) => {
    proquinalHistory.push({
      pathname: `/products/${event.target.value}`,
    });
  };

  return (
    <div className="home-latest-releases">
      <h2 className="home-latest-releases__title">
        <FormattedMessage
          id="HomeLatestReleases.title"
          description="Title for the home's latest releases section"
          defaultMessage="Discover our latest releases!"
        />
      </h2>
      <hr className="home-latest-releases__separator" />
      <p className="home-latest-releases__text font-weight-light gray">
        <FormattedMessage
          id="HomeLatestReleases.text"
          description="Text below the title at home's latest releases section"
          defaultMessage="Find technical details for our new products here. It could be an opportunity to reach new business and new clients"
        />
      </p>
      <Query
        query={simplePatternsQuery}
        variables={{
          first: HOME_LATEST_RELEASE_AMOUNT,
          orderBy: "-date",
          isVisibleLibrary: true,
        }}
      >
        {({ loading, error, data }) => {
          if (loading || error) return null;

          const { patterns } = data;
          return (
            <React.Fragment>
              <div className="home-latest-releases__mobile-selector">
                <form>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={handleMobileSelectorChange}
                    >
                      <FormattedMessage
                        id="HomeLatestReleases.selectPlaceholder"
                        description="Default value for mobile select at home's latest releases section"
                        defaultMessage="Select"
                      >
                        {(defaultValue) => <option>{defaultValue}</option>}
                      </FormattedMessage>
                      {patterns.edges.map((pattern) => (
                        <option
                          key={`lastReleaseOption${pattern.node.id}`}
                          value={pattern.node.slug}
                        >
                          {pattern.node.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
              <div className="home-latest-releases__desktop-list">
                <ul className="latest-releases-list row no-gutters">
                  {patterns.edges.map((pattern) => (
                    <li
                      key={`lastRelease${pattern.node.id}`}
                      className="latest-releases-product col-sm-4"
                    >
                      <Link
                        to={`/products/${pattern.node.slug}`}
                        className="text-truncate"
                      >
                        {pattern.node.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </React.Fragment>
          );
        }}
      </Query>
    </div>
  );
};

export default HomeLatestReleases;
