import {connect} from "react-redux";
import LoginReduxForm from "../index";
import {openTermsModal} from "components/Footer/actions";

const mapStateToProps = (state) => {
  return {
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openTermsModal: () => dispatch(openTermsModal())
  };
};

const LoginReduxFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginReduxForm);

export default LoginReduxFormContainer;