import gql from "graphql-tag";
import singlePatternFragment from "utils/fragments/SinglePatternFragment";
import singlePatternGeneralFragment from "utils/fragments/SinglePatternGeneralFragment";
import singlePatternBackingFragment from "utils/fragments/SinglePatternBackingFragment";
import singlePatternOtherAttributesFragment from "utils/fragments/SinglePatternOtherAttributesFragment";
import singlePatternProductCategorizationFragment from "utils/fragments/SinglePatternProductCategorizationFragment";

export const multiplePatternsQuery = gql`
  query MultiplePatternsQuery($ids: [ID]) {
    patterns(id: $ids) {
      edges {
        node {
          ...SinglePatternFragment
          imageThumbSmall
          general {
            ...SinglePatternGeneralFragment
          }
          backing {
            ...SinglePatternBackingFragment
          }
          otherattributes {
            ...SinglePatternOtherAttributesFragment
          }
          productcategorization {
            ...SinglePatternProductCategorizationFragment
            type
            appearance
            business
          }
          successFlameRetardancyTests
        }
      }
    }
  }
  ${singlePatternFragment}
  ${singlePatternGeneralFragment}
  ${singlePatternBackingFragment}
  ${singlePatternOtherAttributesFragment}
  ${singlePatternProductCategorizationFragment}
`;

export const multiplePatternsSuccessPdfFlameRetardancyTestsQuery = gql`
  query multiplePatternsSuccessPdfFlameRetardancyTestsQuery($ids: [String]) {
    comparisons(ids: $ids)
  }
`;
