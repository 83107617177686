import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

const PatternMarkets = ({sectors}) => {

  const markets = sectors.map(sector => ({
    name: sector.node.market.name,
    sector: sector.node.name
  })).reduce((r, a) => {
    r[a.name] = r[a.name] || [];
    r[a.name].push(a);
    return r;
  }, Object.create(null));

  return (
      <div className="product-markets p-3">
        <h2 className="h5 text-family-base">
          <FormattedMessage
              id="PatternMarkets.title"
              description="Title of sector list in Product details page"
              defaultMessage="Sectors"/>
        </h2>
        {
          Object.keys(markets).map((key, index) => (
              <div
                  key={index}
                  className="product-markets__market-name">
                {key}
                <hr/>
                <ul className="product-markets__sector-lists">
                  {
                    markets[key].map((market, index) => (
                        <li key={index}>
                          {market.sector}
                        </li>
                    ))
                  }
                </ul>
              </div>
          ))
        }
      </div>
  )
};

PatternMarkets.propTypes = {
  sectors: PropTypes.array.isRequired
};

export default PatternMarkets;