export const UNIT_SYSTEMS = [
  {
    "value": 0,
    "display": 'International units (SI)'
  },
  {
    "value": 1,
    "display": 'Anglo-Saxon units'
  }
];
