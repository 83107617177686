/**
 * This file contains a well defined object of intl messages.
 * The objective of this one is to replace translations.js (in this same folder)
 */
import {defineMessages} from "react-intl";

const intlMessages = defineMessages({
  weight: {
    id: "ProductSpecAccordionBody.weight",
    description: "'weight' word in single product spec accordion",
    defaultMessage: "Weight"
  },
  width: {
    id: "ProductSpecAccordionBody.width",
    description: "'width' word in single product spec accordion",
    defaultMessage: "Width"
  },
  gauge: {
    id: "ProductSpecAccordionBody.gauge",
    description: "'gauge' word in single product spec accordion",
    defaultMessage: "Gauge"
  },
  embossing: {
    id: "ProductSpecAccordionBody.embossing",
    description: "'embossing' word in single product spec accordion",
    defaultMessage: "Embossing"
  },
  topcoat: {
    id: "ProductSpecAccordionBody.topcoat",
    description: "'topcoat' word in single product spec accordion",
    defaultMessage: "Topcoat"
  },
  pinholes: {
    id: "ProductSpecAccordionBody.pinholes",
    description: "'pinholes' word in single product spec accordion",
    defaultMessage: "Pinholes / Square inch"
  },
  rollLength: {
    id: "ProductSpecAccordionBody.rollLength",
    description: "'rollLength' word in single product spec accordion",
    defaultMessage: "Roll Length"
  },
  constructionType: {
    id: "ProductSpecAccordionBody.constructionType",
    description: "'constructionType' word in single product spec accordion",
    defaultMessage: "Construction Type"
  },
  printType: {
    id: "ProductSpecAccordionBody.printType",
    description: "'printType' word in single product spec accordion",
    defaultMessage: "Print Type"
  },
  gloss: {
    id: "ProductSpecAccordionBody.gloss",
    description: "'gloss' word in single product spec accordion",
    defaultMessage: "Luster"
  },
  piecesNumber: {
    id: "ProductSpecAccordionBody.piecesNumber",
    description: "'piecesNumber' word in single product spec accordion",
    defaultMessage: "Number of pieces"
  },
  minPieceNumber: {
    id: "ProductSpecAccordionBody.minPieceNumber",
    description: "'minPieceNumber' word in single product spec accordion",
    defaultMessage: "Min. number of pieces"
  },
  package: {
    id: "ProductSpecAccordionBody.package",
    description: "'package' word in single product spec accordion",
    defaultMessage: "Package"
  },
  storage: {
    id: "ProductSpecAccordionBody.storage",
    description: "'storage' word in single product spec accordion",
    defaultMessage: "Storage"
  },
  storageRollPosition: {
    id: "ProductSpecAccordionBody.storageRollPosition",
    description: "'storageRollPosition' word in single product spec accordion",
    defaultMessage: "Storage roll position"
  },
  polymerCompositionPercentage: {
    id: "ProductSpecAccordionBody.polymerCompositionPercentage",
    description: "'polymerCompositionPercentage' word in single product spec accordion",
    defaultMessage: "Polymer composition %"
  },
  colorEvaluation: {
    id: "ProductSpecAccordionBody.colorEvaluation",
    description: "'colorEvaluation' word in single product spec accordion",
    defaultMessage: "Color %"
  },
  glossEvaluation: {
    id: "ProductSpecAccordionBody.glossEvaluation",
    description: "'glossEvaluation' word in single product spec accordion",
    defaultMessage: "Gloss %"
  },
  backingCompositionPercentage: {
    id: "ProductSpecAccordionBody.backingCompositionPercentage",
    description: "'backingCompositionPercentage' word in single product spec accordion",
    defaultMessage: "Backing composition %"
  },
  price: {
    id: "ProductSpecAccordionBody.price",
    description: "'price' word in single product spec accordion",
    defaultMessage: "Price"
  },
  unitPrice: {
    id: "ProductSpecAccordionBody.unitPrice",
    description: "'unitPrice' word in single product spec accordion",
    defaultMessage: "Unit price"
  },
  type: {
    id: "ProductSpecAccordionBody.type",
    description: "'type' word in single product spec accordion",
    defaultMessage: "Type"
  },
  description: {
    id: "ProductSpecAccordionBody.description",
    description: "'description' word in single product spec accordion",
    defaultMessage: "Description"
  },
  antiStatic: {
    id: "ProductSpecAccordionBody.antiStatic",
    description: "'Anti-Static' word in single product spec accordion",
    defaultMessage: "Anti-Static"
  },
  antiStaticConductive: {
    id: "ProductSpecAccordionBody.antiStaticConductive",
    description: "'Anti-Static - Conductive' word in single product spec accordion",
    defaultMessage: "Anti-Static - Conductive"
  },
  antiBacterial: {
    id: "ProductSpecAccordionBody.antiBacterial",
    description: "'Anti-Bacterial' word in single product spec accordion",
    defaultMessage: "Anti-Bacterial"
  },
  bacterialResistance: {
    id: "ProductSpecAccordionBody.bacterialResistance",
    description: "'Bacterial Resistant' word in single product spec accordion",
    defaultMessage: "Bacterial Resistant"
  },
  mildewResistance: {
    id: "ProductSpecAccordionBody.mildewResistance",
    description: "'Mildew Resistant' word in single product spec accordion",
    defaultMessage: "Mildew Resistant"
  },
  mildewResistanceMethod: {
    id: "ProductSpecAccordionBody.mildewResistanceMethod",
    description: "'Mildew Resistant Method' word in single product spec accordion",
    defaultMessage: "Mildew Resistant Method"
  },
  pinkStainResistance: {
    id: "ProductSpecAccordionBody.pinkStainResistance",
    description: "'Pink Stain Resistant' word in single product spec accordion",
    defaultMessage: "Pink Stain Resistant"
  },
  cytotoxicity: {
    id: "ProductSpecAccordionBody.cytotoxicity",
    description: "'Cytotoxicity' word in single product spec accordion",
    defaultMessage: "Cytotoxicity"
  },
  irritationAndSkinSensitization: {
    id: "ProductSpecAccordionBody.irritationAndSkinSensitization",
    description: "'Irritation and skin Sensitization' word in single product spec accordion",
    defaultMessage: "Irritation and skin Sensitization"
  },
  cleanability: {
    id: "ProductSpecAccordionBody.cleanability",
    description: "'Cleanability' word in single product spec accordion",
    defaultMessage: "Cleanability"
  },
  cleanibilityMethod: {
    id: "ProductSpecAccordionBody.cleanibilityMethod",
    description: "'Cleanability Method' word in single product spec accordion",
    defaultMessage: "Cleanability Method"
  },
  hydrocarbonResistance: {
    id: "ProductSpecAccordionBody.hydrocarbonResistance",
    description: "'Hydrocarbon Resistant' word in single product spec accordion",
    defaultMessage: "Hydrocarbon Resistant"
  },
  hydrocarbonResistanceMethod: {
    id: "ProductSpecAccordionBody.hydrocarbonResistanceMethod",
    description: "'Hydrocarbon Resistant Method' word in single product spec accordion",
    defaultMessage: "Hydrocarbon Resistant Method"
  },
  oilResistance: {
    id: "ProductSpecAccordionBody.oilResistance",
    description: "'Oil Resistant' word in single product spec accordion",
    defaultMessage: "Oil Resistant"
  },
  sulfideStaining: {
    id: "ProductSpecAccordionBody.sulfideStaining",
    description: "'Sulfide Stain Resistant' word in single product spec accordion",
    defaultMessage: "Sulfide Stain Resistant"
  },
  heavyMetals: {
    id: "ProductSpecAccordionBody.heavyMetals",
    description: "'No Heavy Metals' word in single product spec accordion",
    defaultMessage: "No Heavy Metals"
  },
  phthalateFreeProduct: {
    id: "ProductSpecAccordionBody.phthalateFreeProduct",
    description: "'Phthalate Free Product' word in single product spec accordion",
    defaultMessage: "Phthalate Free Product"
  },
  hydrolisis: {
    id: "ProductSpecAccordionBody.hydrolisis",
    description: "'Hydrolisis  Resistance' word in single product spec accordion",
    defaultMessage: "Hydrolisis  Resistance"
  },
  hydrolisisMethod: {
    id: "ProductSpecAccordionBody.hydrolisisMethod",
    description: "'Hydrolisis Resistance Method' word in single product spec accordion",
    defaultMessage: "Hydrolisis Resistance Method"
  },
  heatBuildup: {
    id: "ProductSpecAccordionBody.heatBuildup",
    description: "'Heat Buildup' word in single product spec accordion",
    defaultMessage: "Heat Buildup"
  },
  heatBuildupMethod: {
    id: "ProductSpecAccordionBody.heatBuildupMethod",
    description: "'Heat Buildup Method' word in single product spec accordion",
    defaultMessage: "Heat Buildup Method"
  },
  accousticalAttenuation: {
    id: "ProductSpecAccordionBody.accousticalAttenuation",
    description: "'Accoustical Attenuation' word in single product spec accordion",
    defaultMessage: "Accoustical Attenuation"
  },
  accousticalAttenuationMethod: {
    id: "ProductSpecAccordionBody.accousticalAttenuationMethod",
    description: "'Accoustical Attenuation Method' word in single product spec accordion",
    defaultMessage: "Accoustical Attenuation Method"
  },
  soundAbsorption: {
    id: "ProductSpecAccordionBody.soundAbsorption",
    description: "'Sound Absorption' word in single product spec accordion",
    defaultMessage: "Sound Absorption"
  },
  soundAbsorptionMethod: {
    id: "ProductSpecAccordionBody.soundAbsorptionMethod",
    description: "'Sound Absorption Method' word in single product spec accordion",
    defaultMessage: "Sound Absorption Method"
  },
  foamLamination: {
    id: "ProductSpecAccordionBody.foamLamination",
    description: "'Sound Foam Lamination' word in single product spec accordion",
    defaultMessage: "Sound Foam Lamination"
  },
  thermoformed: {
    id: "ProductSpecAccordionBody.thermoformed",
    description: "'Thermoformed' word in single product spec accordion",
    defaultMessage: "Thermoformed"
  },
  heatSealable: {
    id: "ProductSpecAccordionBody.heatSealable",
    description: "'Heat Sealable' word in single product spec accordion",
    defaultMessage: "Heat Sealable"
  },
  electricSealable: {
    id: "ProductSpecAccordionBody.electricSealable",
    description: "'Electric Sealable' word in single product spec accordion",
    defaultMessage: "Electric Sealable"
  },
  impermeable: {
    id: "ProductSpecAccordionBody.impermeable",
    description: "'Impermeable' word in single product spec accordion",
    defaultMessage: "Impermeable"
  },
  ruleMed: {
    id: "ProductSpecAccordionBody.ruleMed",
    description: "'ruleMed' word in single product spec accordion",
    defaultMessage: "MED"
  },
  ruleUkca: {
    id: "ProductSpecAccordionBody.ruleUkca",
    description: "'ruleUkca' word in single product spec accordion",
    defaultMessage: "UKCA"
  },
  temperatureReductionTreatment: {
    id: "ProductSpecAccordionBody.temperatureReductionTreatment",
    description: "'Temperature Reduction Treatment' word in single product spec accordion",
    defaultMessage: "Temperature Reduction Treatment"
  },
  denimStainResistance: {
    id: "ProductSpecAccordionBody.denimStainResistance",
    description: "'Denim Stain Resistance' word in single product spec accordion",
    defaultMessage: "Denim Stain Resistance"
  },
  stainResistanceInHealthcareEnvironment: {
    id: "ProductSpecAccordionBody.stainResistanceInHealthcareEnvironment",
    description: "'Stain Resistance In Healthcare Environment' word in single product spec accordion",
    defaultMessage: "Stain Resistance In Healthcare Environment"
  },
  business: {
    id: "ProductSpecAccordionBody.business",
    description: "'Business' word in single product spec accordion",
    defaultMessage: "Business"
  },
  marketSegment: {
    id: "ProductSpecAccordionBody.markets",
    description: "'Markets' word in single product spec accordion",
    defaultMessage: "Markets"
  },
  application: {
    id: "ProductSpecAccordionBody.application",
    description: "'Application' word in single product spec accordion",
    defaultMessage: "Application"
  },
  niche: {
    id: "ProductSpecAccordionBody.niche",
    description: "'Niche' word in single product spec accordion",
    defaultMessage: "Niche"
  },
  appearance: {
    id: "ProductSpecAccordionBody.appearance",
    description: "'Appearance' word in single product spec accordion",
    defaultMessage: "Appearance"
  },
  warranty: {
    id: "ProductSpecAccordionBody.warranty",
    description: "'Warranty' word in single product spec accordion",
    defaultMessage: "Warranty"
  },
  polymer: {
    id: "ProductSpecAccordionBody.polymer",
    description: "'Polymer' word in single product spec accordion",
    defaultMessage: "Polymer"
  },
  status: {
    id: "ProductSpecAccordionBody.status",
    description: "'Status' word in single product spec accordion",
    defaultMessage: "Status"
  },
  definition: {
    id: "ProductSpecAccordionBody.definition",
    description: "'Definition' word in single product spec accordion",
    defaultMessage: "Definition"
  },
  outdoor: {
    id: "ProductSpecAccordionBody.outdoor",
    description: "'Outdoor' word in single product spec accordion",
    defaultMessage: "Outdoor"
  }
});

export default intlMessages;
