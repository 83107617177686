import {defineMessages} from "react-intl";

export const EMBOSSING_3518TH_Q = '3518TH-Q';
export const EMBOSSING_3518TH_Q_MESSAGE = defineMessages({
  [EMBOSSING_3518TH_Q]: {
    id: "Embossing.3518th-q",
    description: "Embossing value named 3518TH-Q",
    defaultMessage: "3518th-q"
  }
});
export const EMBOSSING_74 = '74';
export const EMBOSSING_74_MESSAGE = defineMessages({
  [EMBOSSING_74]: {
    id: "Embossing.74",
    description: "Embossing value named 74",
    defaultMessage: "74"
  }
});
export const EMBOSSING_A6 = 'A6';
export const EMBOSSING_A6_MESSAGE = defineMessages({
  [EMBOSSING_A6]: {
    id: "Embossing.a6",
    description: "Embossing value named A6",
    defaultMessage: "a6"
  }
});
export const EMBOSSING_ABERDEEN = 'ABERDEEN';
export const EMBOSSING_ABERDEEN_MESSAGE = defineMessages({
  [EMBOSSING_ABERDEEN]: {
    id: "Embossing.aberdeen",
    description: "Embossing value named ABERDEEN",
    defaultMessage: "aberdeen"
  }
});
export const EMBOSSING_ALCE = 'ALCE';
export const EMBOSSING_ALCE_MESSAGE = defineMessages({
  [EMBOSSING_ALCE]: {
    id: "Embossing.alce",
    description: "Embossing value named ALCE",
    defaultMessage: "alce"
  }
});
export const EMBOSSING_ALFAJOR_2 = 'ALFAJOR #2';
export const EMBOSSING_ALFAJOR_2_MESSAGE = defineMessages({
  [EMBOSSING_ALFAJOR_2]: {
    id: "Embossing.alfajor_2",
    description: "Embossing value named ALFAJOR #2",
    defaultMessage: "alfajor #2"
  }
});
export const EMBOSSING_ALFAJOR_2_100CM = 'ALFAJOR #2-100CM';
export const EMBOSSING_ALFAJOR_2_100CM_MESSAGE = defineMessages({
  [EMBOSSING_ALFAJOR_2_100CM]: {
    id: "Embossing.alfajor_2-100cm",
    description: "Embossing value named ALFAJOR #2-100CM",
    defaultMessage: "alfajor #2-100cm"
  }
});
export const EMBOSSING_ALFAJOR_3 = 'ALFAJOR #3';
export const EMBOSSING_ALFAJOR_3_MESSAGE = defineMessages({
  [EMBOSSING_ALFAJOR_3]: {
    id: "Embossing.alfajor_3",
    description: "Embossing value named ALFAJOR #3",
    defaultMessage: "alfajor #3"
  }
});
export const EMBOSSING_ALFAJOR_3_MATE = 'ALFAJOR #3 MATE';
export const EMBOSSING_ALFAJOR_3_MATE_MESSAGE = defineMessages({
  [EMBOSSING_ALFAJOR_3_MATE]: {
    id: "Embossing.alfajor_3_mate",
    description: "Embossing value named ALFAJOR #3 MATE",
    defaultMessage: "alfajor #3 mate"
  }
});
export const EMBOSSING_ALICATES = 'ALICATES';
export const EMBOSSING_ALICATES_MESSAGE = defineMessages({
  [EMBOSSING_ALICATES]: {
    id: "Embossing.alicates",
    description: "Embossing value named ALICATES",
    defaultMessage: "alicates"
  }
});
export const EMBOSSING_ANGELITOS = 'ANGELITOS';
export const EMBOSSING_ANGELITOS_MESSAGE = defineMessages({
  [EMBOSSING_ANGELITOS]: {
    id: "Embossing.angelitos",
    description: "Embossing value named ANGELITOS",
    defaultMessage: "angelitos"
  }
});
export const EMBOSSING_ANTILOPE = 'ANTILOPE';
export const EMBOSSING_ANTILOPE_MESSAGE = defineMessages({
  [EMBOSSING_ANTILOPE]: {
    id: "Embossing.antilope",
    description: "Embossing value named ANTILOPE",
    defaultMessage: "antilope"
  }
});
export const EMBOSSING_ARBUSHALONE = 'ARBUSHALONE';
export const EMBOSSING_ARBUSHALONE_MESSAGE = defineMessages({
  [EMBOSSING_ARBUSHALONE]: {
    id: "Embossing.arbushalone",
    description: "Embossing value named ARBUSHALONE",
    defaultMessage: "arbushalone"
  }
});
export const EMBOSSING_ARENA = 'ARENA';
export const EMBOSSING_ARENA_MESSAGE = defineMessages({
  [EMBOSSING_ARENA]: {
    id: "Embossing.arena",
    description: "Embossing value named ARENA",
    defaultMessage: "arena"
  }
});
export const EMBOSSING_ARENA_MATE_360_BEMA = 'ARENA MATE-360 BEMA';
export const EMBOSSING_ARENA_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_ARENA_MATE_360_BEMA]: {
    id: "Embossing.arena_mate-360_bema",
    description: "Embossing value named ARENA MATE-360 BEMA",
    defaultMessage: "arena mate-360 bema"
  }
});
export const EMBOSSING_ARIZONA = 'ARIZONA';
export const EMBOSSING_ARIZONA_MESSAGE = defineMessages({
  [EMBOSSING_ARIZONA]: {
    id: "Embossing.arizona",
    description: "Embossing value named ARIZONA",
    defaultMessage: "arizona"
  }
});
export const EMBOSSING_ARIZONA_MATE = 'ARIZONA MATE';
export const EMBOSSING_ARIZONA_MATE_MESSAGE = defineMessages({
  [EMBOSSING_ARIZONA_MATE]: {
    id: "Embossing.arizonaMate",
    description: "Embossing value named ARIZONA MATE",
    defaultMessage: "arizona mate"
  }
});
export const EMBOSSING_ARIZONA_180 = 'ARIZONA-180';
export const EMBOSSING_ARIZONA_180_MESSAGE = defineMessages({
  [EMBOSSING_ARIZONA_180]: {
    id: "Embossing.arizona-180",
    description: "Embossing value named ARIZONA-180",
    defaultMessage: "arizona-180"
  }
});
export const EMBOSSING_ARO = 'ARO';
export const EMBOSSING_ARO_MESSAGE = defineMessages({
  [EMBOSSING_ARO]: {
    id: "Embossing.aro",
    description: "Embossing value named ARO",
    defaultMessage: "aro"
  }
});
export const EMBOSSING_AVANTE = 'AVANTE';
export const EMBOSSING_AVANTE_MESSAGE = defineMessages({
  [EMBOSSING_AVANTE]: {
    id: "Embossing.avante",
    description: "Embossing value named AVANTE",
    defaultMessage: "avante"
  }
});
export const EMBOSSING_AVANTGARDE = 'AVANTGARDE';
export const EMBOSSING_AVANTGARDE_MESSAGE = defineMessages({
  [EMBOSSING_AVANTGARDE]: {
    id: "Embossing.avantgarde",
    description: "Embossing value named AVANTGARDE",
    defaultMessage: "avantgarde"
  }
});
export const EMBOSSING_AZTECA = 'AZTECA';
export const EMBOSSING_AZTECA_MESSAGE = defineMessages({
  [EMBOSSING_AZTECA]: {
    id: "Embossing.azteca",
    description: "Embossing value named AZTECA",
    defaultMessage: "azteca"
  }
});
export const EMBOSSING_AZTECA_MATE_360 = 'AZTECA MATE-360';
export const EMBOSSING_AZTECA_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_AZTECA_MATE_360]: {
    id: "Embossing.aztecaMate-360",
    description: "Embossing value named AZTECA MATE-360",
    defaultMessage: "azteca mate-360"
  }
});
export const EMBOSSING_AZTECA_180 = 'AZTECA-180';
export const EMBOSSING_AZTECA_180_MESSAGE = defineMessages({
  [EMBOSSING_AZTECA_180]: {
    id: "Embossing.azteca-180",
    description: "Embossing value named AZTECA-180",
    defaultMessage: "azteca-180"
  }
});
export const EMBOSSING_BADANA = 'BADANA';
export const EMBOSSING_BADANA_MESSAGE = defineMessages({
  [EMBOSSING_BADANA]: {
    id: "Embossing.badana",
    description: "Embossing value named BADANA",
    defaultMessage: "badana"
  }
});
export const EMBOSSING_BALON = 'BALON';
export const EMBOSSING_BALON_MESSAGE = defineMessages({
  [EMBOSSING_BALON]: {
    id: "Embossing.balon",
    description: "Embossing value named BALON",
    defaultMessage: "balon"
  }
});
export const EMBOSSING_BALON_BRILLANTE = 'BALON BRILLANTE';
export const EMBOSSING_BALON_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_BALON_BRILLANTE]: {
    id: "Embossing.balonBrillante",
    description: "Embossing value named BALON BRILLANTE",
    defaultMessage: "balon brillante"
  }
});
export const EMBOSSING_BECERRO = 'BECERRO';
export const EMBOSSING_BECERRO_MESSAGE = defineMessages({
  [EMBOSSING_BECERRO]: {
    id: "Embossing.becerro",
    description: "Embossing value named BECERRO",
    defaultMessage: "becerro"
  }
});
export const EMBOSSING_BEETLE = 'BEETLE';
export const EMBOSSING_BEETLE_MESSAGE = defineMessages({
  [EMBOSSING_BEETLE]: {
    id: "Embossing.beetle",
    description: "Embossing value named BEETLE",
    defaultMessage: "beetle"
  }
});
export const EMBOSSING_BENGALA = 'BENGALA';
export const EMBOSSING_BENGALA_MESSAGE = defineMessages({
  [EMBOSSING_BENGALA]: {
    id: "Embossing.bengala",
    description: "Embossing value named BENGALA",
    defaultMessage: "bengala"
  }
});
export const EMBOSSING_BENGALA_MATE = 'BENGALA MATE';
export const EMBOSSING_BENGALA_MATE_MESSAGE = defineMessages({
  [EMBOSSING_BENGALA_MATE]: {
    id: "Embossing.bengalaMate",
    description: "Embossing value named BENGALA MATE",
    defaultMessage: "bengala mate"
  }
});
export const EMBOSSING_BISONTE = 'BISONTE';
export const EMBOSSING_BISONTE_MESSAGE = defineMessages({
  [EMBOSSING_BISONTE]: {
    id: "Embossing.bisonte",
    description: "Embossing value named BISONTE",
    defaultMessage: "bisonte"
  }
});
export const EMBOSSING_BISONTE_GAMSIL = 'BISONTE GAMSIL';
export const EMBOSSING_BISONTE_GAMSIL_MESSAGE = defineMessages({
  [EMBOSSING_BISONTE_GAMSIL]: {
    id: "Embossing.bisonteGamsil",
    description: "Embossing value named BISONTE GAMSIL",
    defaultMessage: "bisonte gamsil"
  }
});
export const EMBOSSING_BISONTE_MATE = 'BISONTE MATE';
export const EMBOSSING_BISONTE_MATE_MESSAGE = defineMessages({
  [EMBOSSING_BISONTE_MATE]: {
    id: "Embossing.bisonteMate",
    description: "Embossing value named BISONTE MATE",
    defaultMessage: "bisonte mate"
  }
});
export const EMBOSSING_BKN = 'BKN';
export const EMBOSSING_BKN_MESSAGE = defineMessages({
  [EMBOSSING_BKN]: {
    id: "Embossing.bkn",
    description: "Embossing value named BKN",
    defaultMessage: "bkn"
  }
});
export const EMBOSSING_BKN_GAM_MET = 'BKN GAM-MET.';
export const EMBOSSING_BKN_GAM_MET_MESSAGE = defineMessages({
  [EMBOSSING_BKN_GAM_MET]: {
    id: "Embossing.bknGam-met.",
    description: "Embossing value named BKN GAM-MET.",
    defaultMessage: "bkn gam-met."
  }
});
export const EMBOSSING_BKN_WUMAG = 'BKN WUMAG';
export const EMBOSSING_BKN_WUMAG_MESSAGE = defineMessages({
  [EMBOSSING_BKN_WUMAG]: {
    id: "Embossing.bknWumag",
    description: "Embossing value named BKN WUMAG",
    defaultMessage: "bkn wumag"
  }
});
export const EMBOSSING_BOUQUET = 'BOUQUET';
export const EMBOSSING_BOUQUET_MESSAGE = defineMessages({
  [EMBOSSING_BOUQUET]: {
    id: "Embossing.bouquet",
    description: "Embossing value named BOUQUET",
    defaultMessage: "bouquet"
  }
});
export const EMBOSSING_BRILLANTE = 'BRILLANTE';
export const EMBOSSING_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE]: {
    id: "Embossing.brillante",
    description: "Embossing value named BRILLANTE",
    defaultMessage: "brillante"
  }
});
export const EMBOSSING_BRILLANTE_ALFAJOR_2_MATE_360_BEMA = 'BRILLANTE ALFAJOR #2 MATE-360 BEMA';
export const EMBOSSING_BRILLANTE_ALFAJOR_2_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_ALFAJOR_2_MATE_360_BEMA]: {
    id: "Embossing.brillanteAlfajor_2_mate-360_bema",
    description: "Embossing value named BRILLANTE ALFAJOR #2 MATE-360 BEMA",
    defaultMessage: "brillante alfajor #2 mate-360 bema"
  }
});
export const EMBOSSING_BRILLANTE_ALFAJOR_3_MATE = 'BRILLANTE ALFAJOR #3 MATE';
export const EMBOSSING_BRILLANTE_ALFAJOR_3_MATE_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_ALFAJOR_3_MATE]: {
    id: "Embossing.brillante alfajor #3 mate",
    description: "Embossing value named BRILLANTE ALFAJOR #3 MATE",
    defaultMessage: "brillante alfajor #3 mate"
  }
});
export const EMBOSSING_BRILLANTE_ESMERILADO = 'BRILLANTE ESMERILADO';
export const EMBOSSING_BRILLANTE_ESMERILADO_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_ESMERILADO]: {
    id: "Embossing.brillanteEsmerilado",
    description: "Embossing value named BRILLANTE ESMERILADO",
    defaultMessage: "brillante esmerilado"
  }
});
export const EMBOSSING_BRILLANTE_ESTRELLAS_DURATEX = 'BRILLANTE ESTRELLAS DURATEX';
export const EMBOSSING_BRILLANTE_ESTRELLAS_DURATEX_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_ESTRELLAS_DURATEX]: {
    id: "Embossing.brillanteEstrellasDuratex",
    description: "Embossing value named BRILLANTE ESTRELLAS DURATEX",
    defaultMessage: "brillante estrellas duratex"
  }
});
export const EMBOSSING_BRILLANTE_LABERINTOS = 'BRILLANTE LABERINTOS';
export const EMBOSSING_BRILLANTE_LABERINTOS_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_LABERINTOS]: {
    id: "Embossing.brillanteLaberintos",
    description: "Embossing value named BRILLANTE LABERINTOS",
    defaultMessage: "brillante laberintos"
  }
});
export const EMBOSSING_BRILLANTE_MATE_360_BEMA = 'BRILLANTE MATE-360 BEMA';
export const EMBOSSING_BRILLANTE_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_MATE_360_BEMA]: {
    id: "Embossing.brillanteMate-360Bema",
    description: "Embossing value named BRILLANTE MATE-360 BEMA",
    defaultMessage: "brillante mate-360 bema"
  }
});
export const EMBOSSING_BRILLANTE_MATE_360_BEMA_SULTAN = 'BRILLANTE MATE-360 BEMA SULTAN';
export const EMBOSSING_BRILLANTE_MATE_360_BEMA_SULTAN_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_MATE_360_BEMA_SULTAN]: {
    id: "Embossing.brillanteMate-360BemaSultan",
    description: "Embossing value named BRILLANTE MATE-360 BEMA SULTAN",
    defaultMessage: "brillante mate-360 bema sultan"
  }
});
export const EMBOSSING_BRILLANTE_MAYA = 'BRILLANTE MAYA';
export const EMBOSSING_BRILLANTE_MAYA_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_MAYA]: {
    id: "Embossing.brillanteMaya",
    description: "Embossing value named BRILLANTE MAYA",
    defaultMessage: "brillante maya"
  }
});
export const EMBOSSING_BRILLANTE_MOQUETA = 'BRILLANTE MOQUETA';
export const EMBOSSING_BRILLANTE_MOQUETA_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_MOQUETA]: {
    id: "Embossing.brillanteMoqueta",
    description: "Embossing value named BRILLANTE MOQUETA",
    defaultMessage: "brillante moqueta"
  }
});
export const EMBOSSING_BRILLANTE_PIEL = 'BRILLANTE PIEL';
export const EMBOSSING_BRILLANTE_PIEL_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_PIEL]: {
    id: "Embossing.brillantePiel",
    description: "Embossing value named BRILLANTE PIEL",
    defaultMessage: "brillante piel"
  }
});
export const EMBOSSING_BRILLANTE_PINT_SB = 'BRILLANTE PINT SB';
export const EMBOSSING_BRILLANTE_PINT_SB_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_PINT_SB]: {
    id: "Embossing.brillantePintSb",
    description: "Embossing value named BRILLANTE PINT SB",
    defaultMessage: "brillante pint sb"
  }
});
export const EMBOSSING_BRILLANTE_PITAGORAS_CONSUL_MATE = 'BRILLANTE PITAGORAS CONSUL MATE';
export const EMBOSSING_BRILLANTE_PITAGORAS_CONSUL_MATE_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_PITAGORAS_CONSUL_MATE]: {
    id: "Embossing.brillantePitagorasConsulMate",
    description: "Embossing value named BRILLANTE PITAGORAS CONSUL MATE",
    defaultMessage: "brillante pitagoras consul mate"
  }
});
export const EMBOSSING_BRILLANTE_SATIN = 'BRILLANTE SATIN';
export const EMBOSSING_BRILLANTE_SATIN_MESSAGE = defineMessages({
  [EMBOSSING_BRILLANTE_SATIN]: {
    id: "Embossing.brillanteSatin",
    description: "Embossing value named BRILLANTE SATIN",
    defaultMessage: "brillante satin"
  }
});
export const EMBOSSING_CAIMAN = 'CAIMAN';
export const EMBOSSING_CAIMAN_MESSAGE = defineMessages({
  [EMBOSSING_CAIMAN]: {
    id: "Embossing.caiman",
    description: "Embossing value named CAIMAN",
    defaultMessage: "caiman"
  }
});
export const EMBOSSING_CALF = 'CALF';
export const EMBOSSING_CALF_MESSAGE = defineMessages({
  [EMBOSSING_CALF]: {
    id: "Embossing.calf",
    description: "Embossing value named CALF",
    defaultMessage: "calf"
  }
});
export const EMBOSSING_CALF_HORN = 'CALF-HORN';
export const EMBOSSING_CALF_HORN_MESSAGE = defineMessages({
  [EMBOSSING_CALF_HORN]: {
    id: "Embossing.calf-horn",
    description: "Embossing value named CALF-HORN",
    defaultMessage: "calf-horn"
  }
});
export const EMBOSSING_CALIMA = 'CALIMA';
export const EMBOSSING_CALIMA_MESSAGE = defineMessages({
  [EMBOSSING_CALIMA]: {
    id: "Embossing.calima",
    description: "Embossing value named CALIMA",
    defaultMessage: "calima"
  }
});
export const EMBOSSING_CAMMO = 'CAMMO';
export const EMBOSSING_CAMMO_MESSAGE = defineMessages({
  [EMBOSSING_CAMMO]: {
    id: "Embossing.cammo",
    description: "Embossing value named CAMMO",
    defaultMessage: "cammo"
  }
});
export const EMBOSSING_CANASTA = 'CANASTA';
export const EMBOSSING_CANASTA_MESSAGE = defineMessages({
  [EMBOSSING_CANASTA]: {
    id: "Embossing.canasta",
    description: "Embossing value named CANASTA",
    defaultMessage: "canasta"
  }
});
export const EMBOSSING_CANGURO = 'CANGURO';
export const EMBOSSING_CANGURO_MESSAGE = defineMessages({
  [EMBOSSING_CANGURO]: {
    id: "Embossing.canguro",
    description: "Embossing value named CANGURO",
    defaultMessage: "canguro"
  }
});
export const EMBOSSING_CANGURO_LONETA_50Q = 'CANGURO LONETA 50Q';
export const EMBOSSING_CANGURO_LONETA_50Q_MESSAGE = defineMessages({
  [EMBOSSING_CANGURO_LONETA_50Q]: {
    id: "Embossing.canguroLoneta50q",
    description: "Embossing value named CANGURO LONETA 50Q",
    defaultMessage: "canguro loneta 50q"
  }
});
export const EMBOSSING_CANVAS = 'CANVAS';
export const EMBOSSING_CANVAS_MESSAGE = defineMessages({
  [EMBOSSING_CANVAS]: {
    id: "Embossing.canvas",
    description: "Embossing value named CANVAS",
    defaultMessage: "canvas"
  }
});
export const EMBOSSING_CANVAS_MATE_360_BEMA = 'CANVAS MATE-360 BEMA';
export const EMBOSSING_CANVAS_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_CANVAS_MATE_360_BEMA]: {
    id: "Embossing.canvasMate-360Bema",
    description: "Embossing value named CANVAS MATE-360 BEMA",
    defaultMessage: "canvas mate-360 bema"
  }
});
export const EMBOSSING_CANVAS_PINT_SB = 'CANVAS PINT SB';
export const EMBOSSING_CANVAS_PINT_SB_MESSAGE = defineMessages({
  [EMBOSSING_CANVAS_PINT_SB]: {
    id: "Embossing.canvasPintSb",
    description: "Embossing value named CANVAS PINT SB",
    defaultMessage: "canvas pint sb"
  }
});
export const EMBOSSING_CAP = 'CAP';
export const EMBOSSING_CAP_MESSAGE = defineMessages({
  [EMBOSSING_CAP]: {
    id: "Embossing.cap",
    description: "Embossing value named CAP",
    defaultMessage: "cap"
  }
});
export const EMBOSSING_CAP_LIJA_A100 = 'CAP LIJA A100';
export const EMBOSSING_CAP_LIJA_A100_MESSAGE = defineMessages({
  [EMBOSSING_CAP_LIJA_A100]: {
    id: "Embossing.capLijaA100",
    description: "Embossing value named CAP LIJA A100",
    defaultMessage: "cap lija a100"
  }
});
export const EMBOSSING_CAPRI = 'CAPRI';
export const EMBOSSING_CAPRI_MESSAGE = defineMessages({
  [EMBOSSING_CAPRI]: {
    id: "Embossing.capri",
    description: "Embossing value named CAPRI",
    defaultMessage: "capri"
  }
});
export const EMBOSSING_CAPRI_BRILLANTE = 'CAPRI BRILLANTE';
export const EMBOSSING_CAPRI_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_CAPRI_BRILLANTE]: {
    id: "Embossing.capriBrillante",
    description: "Embossing value named CAPRI BRILLANTE",
    defaultMessage: "capri brillante"
  }
});
export const EMBOSSING_CAPRI_LONETA_50Q = 'CAPRI LONETA 50Q';
export const EMBOSSING_CAPRI_LONETA_50Q_MESSAGE = defineMessages({
  [EMBOSSING_CAPRI_LONETA_50Q]: {
    id: "Embossing.capriLoneta50q",
    description: "Embossing value named CAPRI LONETA 50Q",
    defaultMessage: "capri loneta 50q"
  }
});
export const EMBOSSING_CAPRICE = 'CAPRICE';
export const EMBOSSING_CAPRICE_MESSAGE = defineMessages({
  [EMBOSSING_CAPRICE]: {
    id: "Embossing.caprice",
    description: "Embossing value named CAPRICE",
    defaultMessage: "caprice"
  }
});
export const EMBOSSING_CARACOL_BRILLANTE = 'CARACOL BRILLANTE';
export const EMBOSSING_CARACOL_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_CARACOL_BRILLANTE]: {
    id: "Embossing.caracolBrillante",
    description: "Embossing value named CARACOL BRILLANTE",
    defaultMessage: "caracol brillante"
  }
});
export const EMBOSSING_CARBON_FIBER = 'CARBON FIBER';
export const EMBOSSING_CARBON_FIBER_MESSAGE = defineMessages({
  [EMBOSSING_CARBON_FIBER]: {
    id: "Embossing.carbonFiber",
    description: "Embossing value named CARBON FIBER",
    defaultMessage: "carbon fiber"
  }
});
export const EMBOSSING_CARIBE = 'CARIBE';
export const EMBOSSING_CARIBE_MESSAGE = defineMessages({
  [EMBOSSING_CARIBE]: {
    id: "Embossing.caribe",
    description: "Embossing value named CARIBE",
    defaultMessage: "caribe"
  }
});
export const EMBOSSING_CARIBU = 'CARIBU';
export const EMBOSSING_CARIBU_MESSAGE = defineMessages({
  [EMBOSSING_CARIBU]: {
    id: "Embossing.caribu",
    description: "Embossing value named CARIBU",
    defaultMessage: "caribu"
  }
});
export const EMBOSSING_CEBRA = 'CEBRA';
export const EMBOSSING_CEBRA_MESSAGE = defineMessages({
  [EMBOSSING_CEBRA]: {
    id: "Embossing.cebra",
    description: "Embossing value named CEBRA",
    defaultMessage: "cebra"
  }
});
export const EMBOSSING_CEBU = 'CEBU';
export const EMBOSSING_CEBU_MESSAGE = defineMessages({
  [EMBOSSING_CEBU]: {
    id: "Embossing.cebu",
    description: "Embossing value named CEBU",
    defaultMessage: "cebu"
  }
});
export const EMBOSSING_CENERY = 'CENERY';
export const EMBOSSING_CENERY_MESSAGE = defineMessages({
  [EMBOSSING_CENERY]: {
    id: "Embossing.cenery",
    description: "Embossing value named CENERY",
    defaultMessage: "cenery"
  }
});
export const EMBOSSING_CERVATO_SIL = 'CERVATO-SIL';
export const EMBOSSING_CERVATO_SIL_MESSAGE = defineMessages({
  [EMBOSSING_CERVATO_SIL]: {
    id: "Embossing.cervato-sil",
    description: "Embossing value named CERVATO-SIL",
    defaultMessage: "cervato-sil"
  }
});
export const EMBOSSING_CHACAL = 'CHACAL';
export const EMBOSSING_CHACAL_MESSAGE = defineMessages({
  [EMBOSSING_CHACAL]: {
    id: "Embossing.chacal",
    description: "Embossing value named CHACAL",
    defaultMessage: "chacal"
  }
});
export const EMBOSSING_CHAN_TUNG = 'CHAN TUNG';
export const EMBOSSING_CHAN_TUNG_MESSAGE = defineMessages({
  [EMBOSSING_CHAN_TUNG]: {
    id: "Embossing.chanTung",
    description: "Embossing value named CHAN TUNG",
    defaultMessage: "chan tung"
  }
});
export const EMBOSSING_CHECKER = 'CHECKER';
export const EMBOSSING_CHECKER_MESSAGE = defineMessages({
  [EMBOSSING_CHECKER]: {
    id: "Embossing.checker",
    description: "Embossing value named CHECKER",
    defaultMessage: "checker"
  }
});
export const EMBOSSING_CHIGUIRO = 'CHIGUIRO';
export const EMBOSSING_CHIGUIRO_MESSAGE = defineMessages({
  [EMBOSSING_CHIGUIRO]: {
    id: "Embossing.chiguiro",
    description: "Embossing value named CHIGUIRO",
    defaultMessage: "chiguiro"
  }
});
export const EMBOSSING_CHN = 'CHN';
export const EMBOSSING_CHN_MESSAGE = defineMessages({
  [EMBOSSING_CHN]: {
    id: "Embossing.chn",
    description: "Embossing value named CHN",
    defaultMessage: "chn"
  }
});
export const EMBOSSING_CHROMATA = 'CHROMATA';
export const EMBOSSING_CHROMATA_MESSAGE = defineMessages({
  [EMBOSSING_CHROMATA]: {
    id: "Embossing.chromata",
    description: "Embossing value named CHROMATA",
    defaultMessage: "chromata"
  }
});
export const EMBOSSING_CIERVO = 'CIERVO';
export const EMBOSSING_CIERVO_MESSAGE = defineMessages({
  [EMBOSSING_CIERVO]: {
    id: "Embossing.ciervo",
    description: "Embossing value named CIERVO",
    defaultMessage: "ciervo"
  }
});
export const EMBOSSING_CIRCULOS = 'CIRCULOS';
export const EMBOSSING_CIRCULOS_MESSAGE = defineMessages({
  [EMBOSSING_CIRCULOS]: {
    id: "Embossing.circulos",
    description: "Embossing value named CIRCULOS",
    defaultMessage: "circulos"
  }
});
export const EMBOSSING_CIRCULOS_MATE = 'CIRCULOS MATE';
export const EMBOSSING_CIRCULOS_MATE_MESSAGE = defineMessages({
  [EMBOSSING_CIRCULOS_MATE]: {
    id: "Embossing.circulosMate",
    description: "Embossing value named CIRCULOS MATE",
    defaultMessage: "circulos mate"
  }
});
export const EMBOSSING_COBRA = 'COBRA';
export const EMBOSSING_COBRA_MESSAGE = defineMessages({
  [EMBOSSING_COBRA]: {
    id: "Embossing.cobra",
    description: "Embossing value named COBRA",
    defaultMessage: "cobra"
  }
});
export const EMBOSSING_COLOMBO = 'COLOMBO';
export const EMBOSSING_COLOMBO_MESSAGE = defineMessages({
  [EMBOSSING_COLOMBO]: {
    id: "Embossing.colombo",
    description: "Embossing value named COLOMBO",
    defaultMessage: "colombo"
  }
});
export const EMBOSSING_COLONIA = 'COLONIA';
export const EMBOSSING_COLONIA_MESSAGE = defineMessages({
  [EMBOSSING_COLONIA]: {
    id: "Embossing.colonia",
    description: "Embossing value named COLONIA",
    defaultMessage: "colonia"
  }
});
export const EMBOSSING_COLONIA_BRILLANTE = 'COLONIA BRILLANTE';
export const EMBOSSING_COLONIA_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_COLONIA_BRILLANTE]: {
    id: "Embossing.coloniaBrillante",
    description: "Embossing value named COLONIA BRILLANTE",
    defaultMessage: "colonia brillante"
  }
});
export const EMBOSSING_COLONIA_EPINGLE = 'COLONIA EPINGLE';
export const EMBOSSING_COLONIA_EPINGLE_MESSAGE = defineMessages({
  [EMBOSSING_COLONIA_EPINGLE]: {
    id: "Embossing.coloniaEpingle",
    description: "Embossing value named COLONIA EPINGLE",
    defaultMessage: "colonia epingle"
  }
});
export const EMBOSSING_COLONIA_MATE_360_BEMA = 'COLONIA MATE-360 BEMA';
export const EMBOSSING_COLONIA_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_COLONIA_MATE_360_BEMA]: {
    id: "Embossing.coloniaMate-360Bema",
    description: "Embossing value named COLONIA MATE-360 BEMA",
    defaultMessage: "colonia mate-360 bema"
  }
});
export const EMBOSSING_COLONIA_PINT_SB = 'COLONIA PINT SB';
export const EMBOSSING_COLONIA_PINT_SB_MESSAGE = defineMessages({
  [EMBOSSING_COLONIA_PINT_SB]: {
    id: "Embossing.coloniaPintSb",
    description: "Embossing value named COLONIA PINT SB",
    defaultMessage: "colonia pint sb"
  }
});
export const EMBOSSING_COLONIA_PUMA = 'COLONIA PUMA';
export const EMBOSSING_COLONIA_PUMA_MESSAGE = defineMessages({
  [EMBOSSING_COLONIA_PUMA]: {
    id: "Embossing.coloniaPuma",
    description: "Embossing value named COLONIA PUMA",
    defaultMessage: "colonia puma"
  }
});
export const EMBOSSING_COLONIA_QUETZAL = 'COLONIA QUETZAL';
export const EMBOSSING_COLONIA_QUETZAL_MESSAGE = defineMessages({
  [EMBOSSING_COLONIA_QUETZAL]: {
    id: "Embossing.coloniaQuetzal",
    description: "Embossing value named COLONIA QUETZAL",
    defaultMessage: "colonia quetzal"
  }
});
export const EMBOSSING_CONFETY = 'CONFETY';
export const EMBOSSING_CONFETY_MESSAGE = defineMessages({
  [EMBOSSING_CONFETY]: {
    id: "Embossing.confety",
    description: "Embossing value named CONFETY",
    defaultMessage: "confety"
  }
});
export const EMBOSSING_CONSTELACION = 'CONSTELACION';
export const EMBOSSING_CONSTELACION_MESSAGE = defineMessages({
  [EMBOSSING_CONSTELACION]: {
    id: "Embossing.constelacion",
    description: "Embossing value named CONSTELACION",
    defaultMessage: "constelacion"
  }
});
export const EMBOSSING_CONSUL = 'CONSUL';
export const EMBOSSING_CONSUL_MESSAGE = defineMessages({
  [EMBOSSING_CONSUL]: {
    id: "Embossing.consul",
    description: "Embossing value named CONSUL",
    defaultMessage: "consul"
  }
});
export const EMBOSSING_CONSUL_ESTRELLAS = 'CONSUL ESTRELLAS';
export const EMBOSSING_CONSUL_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_CONSUL_ESTRELLAS]: {
    id: "Embossing.consulEstrellas",
    description: "Embossing value named CONSUL ESTRELLAS",
    defaultMessage: "consul estrellas"
  }
});
export const EMBOSSING_CONSUL_MATE = 'CONSUL MATE';
export const EMBOSSING_CONSUL_MATE_MESSAGE = defineMessages({
  [EMBOSSING_CONSUL_MATE]: {
    id: "Embossing.consulMate",
    description: "Embossing value named CONSUL MATE",
    defaultMessage: "consul mate"
  }
});
export const EMBOSSING_CONSUL_MATE_360_BEMA = 'CONSUL MATE-360 BEMA';
export const EMBOSSING_CONSUL_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_CONSUL_MATE_360_BEMA]: {
    id: "Embossing.consulMate-360Bema",
    description: "Embossing value named CONSUL MATE-360 BEMA",
    defaultMessage: "consul mate-360 bema"
  }
});
export const EMBOSSING_CONTINENTAL = 'CONTINENTAL';
export const EMBOSSING_CONTINENTAL_MESSAGE = defineMessages({
  [EMBOSSING_CONTINENTAL]: {
    id: "Embossing.continental",
    description: "Embossing value named CONTINENTAL",
    defaultMessage: "continental"
  }
});
export const EMBOSSING_CORCHO = 'CORCHO';
export const EMBOSSING_CORCHO_MESSAGE = defineMessages({
  [EMBOSSING_CORCHO]: {
    id: "Embossing.corcho",
    description: "Embossing value named CORCHO",
    defaultMessage: "corcho"
  }
});
export const EMBOSSING_CORDERO = 'CORDERO';
export const EMBOSSING_CORDERO_MESSAGE = defineMessages({
  [EMBOSSING_CORDERO]: {
    id: "Embossing.cordero",
    description: "Embossing value named CORDERO",
    defaultMessage: "cordero"
  }
});
export const EMBOSSING_CORDOBA = 'CORDOBA';
export const EMBOSSING_CORDOBA_MESSAGE = defineMessages({
  [EMBOSSING_CORDOBA]: {
    id: "Embossing.cordoba",
    description: "Embossing value named CORDOBA",
    defaultMessage: "cordoba"
  }
});
export const EMBOSSING_CORTEZA = 'CORTEZA';
export const EMBOSSING_CORTEZA_MESSAGE = defineMessages({
  [EMBOSSING_CORTEZA]: {
    id: "Embossing.corteza",
    description: "Embossing value named CORTEZA",
    defaultMessage: "corteza"
  }
});
export const EMBOSSING_CORY = 'CORY';
export const EMBOSSING_CORY_MESSAGE = defineMessages({
  [EMBOSSING_CORY]: {
    id: "Embossing.cory",
    description: "Embossing value named CORY",
    defaultMessage: "cory"
  }
});
export const EMBOSSING_CORZO = 'CORZO';
export const EMBOSSING_CORZO_MESSAGE = defineMessages({
  [EMBOSSING_CORZO]: {
    id: "Embossing.corzo",
    description: "Embossing value named CORZO",
    defaultMessage: "corzo"
  }
});
export const EMBOSSING_COSMOS = 'COSMOS';
export const EMBOSSING_COSMOS_MESSAGE = defineMessages({
  [EMBOSSING_COSMOS]: {
    id: "Embossing.cosmos",
    description: "Embossing value named COSMOS",
    defaultMessage: "cosmos"
  }
});
export const EMBOSSING_COSMOS_ESTRELLAS = 'COSMOS ESTRELLAS';
export const EMBOSSING_COSMOS_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_COSMOS_ESTRELLAS]: {
    id: "Embossing.cosmosEstrellas",
    description: "Embossing value named COSMOS ESTRELLAS",
    defaultMessage: "cosmos estrellas"
  }
});
export const EMBOSSING_COSTURADO = 'COSTURADO';
export const EMBOSSING_COSTURADO_MESSAGE = defineMessages({
  [EMBOSSING_COSTURADO]: {
    id: "Embossing.costurado",
    description: "Embossing value named COSTURADO",
    defaultMessage: "costurado"
  }
});
export const EMBOSSING_CROSTERY = 'CROSTERY';
export const EMBOSSING_CROSTERY_MESSAGE = defineMessages({
  [EMBOSSING_CROSTERY]: {
    id: "Embossing.crostery",
    description: "Embossing value named CROSTERY",
    defaultMessage: "crostery"
  }
});
export const EMBOSSING_CROYDON = 'CROYDON';
export const EMBOSSING_CROYDON_MESSAGE = defineMessages({
  [EMBOSSING_CROYDON]: {
    id: "Embossing.croydon",
    description: "Embossing value named CROYDON",
    defaultMessage: "croydon"
  }
});
export const EMBOSSING_CROYDON_CHROMATA = 'CROYDON CHROMATA';
export const EMBOSSING_CROYDON_CHROMATA_MESSAGE = defineMessages({
  [EMBOSSING_CROYDON_CHROMATA]: {
    id: "Embossing.croydonChromata",
    description: "Embossing value named CROYDON CHROMATA",
    defaultMessage: "croydon chromata"
  }
});
export const EMBOSSING_CROYDON_GAMUZA_2003_2 = 'CROYDON GAMUZA 2003#2';
export const EMBOSSING_CROYDON_GAMUZA_2003_2_MESSAGE = defineMessages({
  [EMBOSSING_CROYDON_GAMUZA_2003_2]: {
    id: "Embossing.croydonGamuza2003_2",
    description: "Embossing value named CROYDON GAMUZA 2003#2",
    defaultMessage: "croydon gamuza 2003#2"
  }
});
export const EMBOSSING_CUADRICULA_G = 'CUADRICULA (G)';
export const EMBOSSING_CUADRICULA_G_MESSAGE = defineMessages({
  [EMBOSSING_CUADRICULA_G]: {
    id: "Embossing.cuadricula",
    description: "Embossing value named CUADRICULA (G)",
    defaultMessage: "cuadricula (g)"
  }
});
export const EMBOSSING_CUADRICULA_G_BRILLANTE = 'CUADRICULA (G) BRILLANTE';
export const EMBOSSING_CUADRICULA_G_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_CUADRICULA_G_BRILLANTE]: {
    id: "Embossing.cuadriculaBrillante",
    description: "Embossing value named CUADRICULA (G) BRILLANTE",
    defaultMessage: "cuadricula (g) brillante"
  }
});
export const EMBOSSING_CUADROS_G = 'CUADROS (G)';
export const EMBOSSING_CUADROS_G_MESSAGE = defineMessages({
  [EMBOSSING_CUADROS_G]: {
    id: "Embossing.cuadros",
    description: "Embossing value named CUADROS (G)",
    defaultMessage: "cuadros (g)"
  }
});
export const EMBOSSING_CUADROS_G_MATE = 'CUADROS (G) MATE';
export const EMBOSSING_CUADROS_G_MATE_MESSAGE = defineMessages({
  [EMBOSSING_CUADROS_G_MATE]: {
    id: "Embossing.cuadrosMate",
    description: "Embossing value named CUADROS (G) MATE",
    defaultMessage: "cuadros (g) mate"
  }
});
export const EMBOSSING_CUERO_DE_VACA = 'CUERO DE VACA';
export const EMBOSSING_CUERO_DE_VACA_MESSAGE = defineMessages({
  [EMBOSSING_CUERO_DE_VACA]: {
    id: "Embossing.cueroDeVaca",
    description: "Embossing value named CUERO DE VACA",
    defaultMessage: "cuero de vaca"
  }
});
export const EMBOSSING_DANTA = 'DANTA';
export const EMBOSSING_DANTA_MESSAGE = defineMessages({
  [EMBOSSING_DANTA]: {
    id: "Embossing.danta",
    description: "Embossing value named DANTA",
    defaultMessage: "danta"
  }
});
export const EMBOSSING_DANTA_1 = 'DANTA 1';
export const EMBOSSING_DANTA_1_MESSAGE = defineMessages({
  [EMBOSSING_DANTA_1]: {
    id: "Embossing.danta1",
    description: "Embossing value named DANTA 1",
    defaultMessage: "danta 1"
  }
});
export const EMBOSSING_DANTA_GAM_MET = 'DANTA GAM-MET.';
export const EMBOSSING_DANTA_GAM_MET_MESSAGE = defineMessages({
  [EMBOSSING_DANTA_GAM_MET]: {
    id: "Embossing.dantaGam-met.",
    description: "Embossing value named DANTA GAM-MET.",
    defaultMessage: "danta gam-met."
  }
});
export const EMBOSSING_DELFIN = 'DELFIN';
export const EMBOSSING_DELFIN_MESSAGE = defineMessages({
  [EMBOSSING_DELFIN]: {
    id: "Embossing.delfin",
    description: "Embossing value named DELFIN",
    defaultMessage: "delfin"
  }
});
export const EMBOSSING_DELFIN_MATE = 'DELFIN MATE';
export const EMBOSSING_DELFIN_MATE_MESSAGE = defineMessages({
  [EMBOSSING_DELFIN_MATE]: {
    id: "Embossing.delfinMate",
    description: "Embossing value named DELFIN MATE",
    defaultMessage: "delfin mate"
  }
});
export const EMBOSSING_DELFIN_MATE_360 = 'DELFIN MATE-360';
export const EMBOSSING_DELFIN_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_DELFIN_MATE_360]: {
    id: "Embossing.delfinMate-360",
    description: "Embossing value named DELFIN MATE-360",
    defaultMessage: "delfin mate-360"
  }
});
export const EMBOSSING_DEMON = 'DEMON';
export const EMBOSSING_DEMON_MESSAGE = defineMessages({
  [EMBOSSING_DEMON]: {
    id: "Embossing.demon",
    description: "Embossing value named DEMON",
    defaultMessage: "demon"
  }
});
export const EMBOSSING_D_GRAIN = 'D-GRAIN';
export const EMBOSSING_D_GRAIN_MESSAGE = defineMessages({
  [EMBOSSING_D_GRAIN]: {
    id: "Embossing.d-grain",
    description: "Embossing value named D-GRAIN",
    defaultMessage: "d-grain"
  }
});
export const EMBOSSING_DIABLO = 'DIABLO';
export const EMBOSSING_DIABLO_MESSAGE = defineMessages({
  [EMBOSSING_DIABLO]: {
    id: "Embossing.diablo",
    description: "Embossing value named DIABLO",
    defaultMessage: "diablo"
  }
});
export const EMBOSSING_DIAMANTE = 'DIAMANTE';
export const EMBOSSING_DIAMANTE_MESSAGE = defineMessages({
  [EMBOSSING_DIAMANTE]: {
    id: "Embossing.diamante",
    description: "Embossing value named DIAMANTE",
    defaultMessage: "diamante"
  }
});
export const EMBOSSING_DIVA = 'DIVA';
export const EMBOSSING_DIVA_MESSAGE = defineMessages({
  [EMBOSSING_DIVA]: {
    id: "Embossing.diva",
    description: "Embossing value named DIVA",
    defaultMessage: "diva"
  }
});
export const EMBOSSING_DRAPE = 'DRAPE';
export const EMBOSSING_DRAPE_MESSAGE = defineMessages({
  [EMBOSSING_DRAPE]: {
    id: "Embossing.drape",
    description: "Embossing value named DRAPE",
    defaultMessage: "drape"
  }
});
export const EMBOSSING_DRILLING = 'DRILLING';
export const EMBOSSING_DRILLING_MESSAGE = defineMessages({
  [EMBOSSING_DRILLING]: {
    id: "Embossing.drilling",
    description: "Embossing value named DRILLING",
    defaultMessage: "drilling"
  }
});
export const EMBOSSING_DURATEX = 'DURATEX';
export const EMBOSSING_DURATEX_MESSAGE = defineMessages({
  [EMBOSSING_DURATEX]: {
    id: "Embossing.duratex",
    description: "Embossing value named DURATEX",
    defaultMessage: "duratex"
  }
});
export const EMBOSSING_DURAZNO = 'DURAZNO';
export const EMBOSSING_DURAZNO_MESSAGE = defineMessages({
  [EMBOSSING_DURAZNO]: {
    id: "Embossing.durazno",
    description: "Embossing value named DURAZNO",
    defaultMessage: "durazno"
  }
});
export const EMBOSSING_DURAZNO_MATE_360_BEMA = 'DURAZNO MATE-360 BEMA';
export const EMBOSSING_DURAZNO_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_DURAZNO_MATE_360_BEMA]: {
    id: "Embossing.duraznoMate-360Bema",
    description: "Embossing value named DURAZNO MATE-360 BEMA",
    defaultMessage: "durazno mate-360 bema"
  }
});
export const EMBOSSING_DURAZNO_PINT_SB = 'DURAZNO PINT SB';
export const EMBOSSING_DURAZNO_PINT_SB_MESSAGE = defineMessages({
  [EMBOSSING_DURAZNO_PINT_SB]: {
    id: "Embossing.duraznoPintSb",
    description: "Embossing value named DURAZNO PINT SB",
    defaultMessage: "durazno pint sb"
  }
});
export const EMBOSSING_DURAZNO_PINT_SB_MATE = 'DURAZNO PINT SB MATE';
export const EMBOSSING_DURAZNO_PINT_SB_MATE_MESSAGE = defineMessages({
  [EMBOSSING_DURAZNO_PINT_SB_MATE]: {
    id: "Embossing.duraznoPintSbMate",
    description: "Embossing value named DURAZNO PINT SB MATE",
    defaultMessage: "durazno pint sb mate"
  }
});
export const EMBOSSING_DURAZNO_SATIN = 'DURAZNO SATIN';
export const EMBOSSING_DURAZNO_SATIN_MESSAGE = defineMessages({
  [EMBOSSING_DURAZNO_SATIN]: {
    id: "Embossing.duraznoSatin",
    description: "Embossing value named DURAZNO SATIN",
    defaultMessage: "durazno satin"
  }
});
export const EMBOSSING_EGIPCIO = 'EGIPCIO';
export const EMBOSSING_EGIPCIO_MESSAGE = defineMessages({
  [EMBOSSING_EGIPCIO]: {
    id: "Embossing.egipcio",
    description: "Embossing value named EGIPCIO",
    defaultMessage: "egipcio"
  }
});
export const EMBOSSING_ELEFANTE = 'ELEFANTE';
export const EMBOSSING_ELEFANTE_MESSAGE = defineMessages({
  [EMBOSSING_ELEFANTE]: {
    id: "Embossing.elefante",
    description: "Embossing value named ELEFANTE",
    defaultMessage: "elefante"
  }
});
export const EMBOSSING_ELITE = 'ELITE';
export const EMBOSSING_ELITE_MESSAGE = defineMessages({
  [EMBOSSING_ELITE]: {
    id: "Embossing.elite",
    description: "Embossing value named ELITE",
    defaultMessage: "elite"
  }
});
export const EMBOSSING_EPINGLE = 'EPINGLE';
export const EMBOSSING_EPINGLE_MESSAGE = defineMessages({
  [EMBOSSING_EPINGLE]: {
    id: "Embossing.epingle",
    description: "Embossing value named EPINGLE",
    defaultMessage: "epingle"
  }
});
export const EMBOSSING_EPINGLE_2 = 'EPINGLE #2';
export const EMBOSSING_EPINGLE_2_MESSAGE = defineMessages({
  [EMBOSSING_EPINGLE_2]: {
    id: "Embossing.epingle_2",
    description: "Embossing value named EPINGLE #2",
    defaultMessage: "epingle #2"
  }
});
export const EMBOSSING_EPINGLE_2_GAMUZA_2007_1 = 'EPINGLE #2 GAMUZA 2007 #1';
export const EMBOSSING_EPINGLE_2_GAMUZA_2007_1_MESSAGE = defineMessages({
  [EMBOSSING_EPINGLE_2_GAMUZA_2007_1]: {
    id: "Embossing.epingle_2Gamuza2007_1",
    description: "Embossing value named EPINGLE #2 GAMUZA 2007 #1",
    defaultMessage: "epingle #2 gamuza 2007 #1"
  }
});
export const EMBOSSING_EPINGLE_2_MATE_360_BEMA = 'EPINGLE #2 MATE-360 BEMA';
export const EMBOSSING_EPINGLE_2_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_EPINGLE_2_MATE_360_BEMA]: {
    id: "Embossing.epingle_2Mate360Bema",
    description: "Embossing value named EPINGLE #2 MATE-360 BEMA",
    defaultMessage: "epingle #2 mate-360 bema"
  }
});
export const EMBOSSING_EPINGLE_MATE = 'EPINGLE MATE';
export const EMBOSSING_EPINGLE_MATE_MESSAGE = defineMessages({
  [EMBOSSING_EPINGLE_MATE]: {
    id: "Embossing.epingleMate",
    description: "Embossing value named EPINGLE MATE",
    defaultMessage: "epingle mate"
  }
});
export const EMBOSSING_ESMERILADO = 'ESMERILADO';
export const EMBOSSING_ESMERILADO_MESSAGE = defineMessages({
  [EMBOSSING_ESMERILADO]: {
    id: "Embossing.esmerilado",
    description: "Embossing value named ESMERILADO",
    defaultMessage: "esmerilado"
  }
});
export const EMBOSSING_ESPINA_DE_PESCADO = 'ESPINA DE PESCADO';
export const EMBOSSING_ESPINA_DE_PESCADO_MESSAGE = defineMessages({
  [EMBOSSING_ESPINA_DE_PESCADO]: {
    id: "Embossing.espinaDePescado",
    description: "Embossing value named ESPINA DE PESCADO",
    defaultMessage: "espina de pescado"
  }
});
export const EMBOSSING_ESTERILLA_G = 'ESTERILLA (G)';
export const EMBOSSING_ESTERILLA_G_MESSAGE = defineMessages({
  [EMBOSSING_ESTERILLA_G]: {
    id: "Embossing.esterilla",
    description: "Embossing value named ESTERILLA (G)",
    defaultMessage: "esterilla (g)"
  }
});
export const EMBOSSING_ESTRELLAS = 'ESTRELLAS';
export const EMBOSSING_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_ESTRELLAS]: {
    id: "Embossing.estrellas",
    description: "Embossing value named ESTRELLAS",
    defaultMessage: "estrellas"
  }
});
export const EMBOSSING_ESTRELLAS_DURATEX = 'ESTRELLAS DURATEX';
export const EMBOSSING_ESTRELLAS_DURATEX_MESSAGE = defineMessages({
  [EMBOSSING_ESTRELLAS_DURATEX]: {
    id: "Embossing.estrellasDuratex",
    description: "Embossing value named ESTRELLAS DURATEX",
    defaultMessage: "estrellas duratex"
  }
});
export const EMBOSSING_ESTRELLAS_ESMERILADO = 'ESTRELLAS ESMERILADO';
export const EMBOSSING_ESTRELLAS_ESMERILADO_MESSAGE = defineMessages({
  [EMBOSSING_ESTRELLAS_ESMERILADO]: {
    id: "Embossing.estrellasEsmerilado",
    description: "Embossing value named ESTRELLAS ESMERILADO",
    defaultMessage: "estrellas esmerilado"
  }
});
export const EMBOSSING_ESTRELLAS_PINT_SB_CARB = 'ESTRELLAS PINT SB CARB.';
export const EMBOSSING_ESTRELLAS_PINT_SB_CARB_MESSAGE = defineMessages({
  [EMBOSSING_ESTRELLAS_PINT_SB_CARB]: {
    id: "Embossing.estrellasPintSbCarb.",
    description: "Embossing value named ESTRELLAS PINT SB CARB.",
    defaultMessage: "estrellas pint sb carb."
  }
});
export const EMBOSSING_EUROPA = 'EUROPA';
export const EMBOSSING_EUROPA_MESSAGE = defineMessages({
  [EMBOSSING_EUROPA]: {
    id: "Embossing.europa",
    description: "Embossing value named EUROPA",
    defaultMessage: "europa"
  }
});
export const EMBOSSING_EUROPA_RAYAS = 'EUROPA - RAYAS';
export const EMBOSSING_EUROPA_RAYAS_MESSAGE = defineMessages({
  [EMBOSSING_EUROPA_RAYAS]: {
    id: "Embossing.europaRayas",
    description: "Embossing value named EUROPA - RAYAS",
    defaultMessage: "europa - rayas"
  }
});
export const EMBOSSING_FAILLE = 'FAILLE';
export const EMBOSSING_FAILLE_MESSAGE = defineMessages({
  [EMBOSSING_FAILLE]: {
    id: "Embossing.faille",
    description: "Embossing value named FAILLE",
    defaultMessage: "faille"
  }
});
export const EMBOSSING_FARGO = 'FARGO';
export const EMBOSSING_FARGO_MESSAGE = defineMessages({
  [EMBOSSING_FARGO]: {
    id: "Embossing.fargo",
    description: "Embossing value named FARGO",
    defaultMessage: "fargo"
  }
});
export const EMBOSSING_FIBRA_CARBONO = 'FIBRA CARBONO';
export const EMBOSSING_FIBRA_CARBONO_MESSAGE = defineMessages({
  [EMBOSSING_FIBRA_CARBONO]: {
    id: "Embossing.fibraCarbono",
    description: "Embossing value named FIBRA CARBONO",
    defaultMessage: "fibra carbono"
  }
});
export const EMBOSSING_FILIGREE = 'FILIGREE';
export const EMBOSSING_FILIGREE_MESSAGE = defineMessages({
  [EMBOSSING_FILIGREE]: {
    id: "Embossing.filigree",
    description: "Embossing value named FILIGREE",
    defaultMessage: "filigree"
  }
});
export const EMBOSSING_FLAMENCO = 'FLAMENCO';
export const EMBOSSING_FLAMENCO_MESSAGE = defineMessages({
  [EMBOSSING_FLAMENCO]: {
    id: "Embossing.flamenco",
    description: "Embossing value named FLAMENCO",
    defaultMessage: "flamenco"
  }
});
export const EMBOSSING_FLAMENCO_180 = 'FLAMENCO-180';
export const EMBOSSING_FLAMENCO_180_MESSAGE = defineMessages({
  [EMBOSSING_FLAMENCO_180]: {
    id: "Embossing.flamenco180",
    description: "Embossing value named FLAMENCO-180",
    defaultMessage: "flamenco-180"
  }
});
export const EMBOSSING_FLANNEL_1_G = 'FLANNEL #1(G)';
export const EMBOSSING_FLANNEL_1_G_MESSAGE = defineMessages({
  [EMBOSSING_FLANNEL_1_G]: {
    id: "Embossing.flannel1",
    description: "Embossing value named FLANNEL #1(G)",
    defaultMessage: "flannel #1(g)"
  }
});
export const EMBOSSING_FLANNEL_2_G = 'FLANNEL #2(G)';
export const EMBOSSING_FLANNEL_2_G_MESSAGE = defineMessages({
  [EMBOSSING_FLANNEL_2_G]: {
    id: "Embossing.flannel2",
    description: "Embossing value named FLANNEL #2(G)",
    defaultMessage: "flannel #2(g)"
  }
});
export const EMBOSSING_FLANNEL_2_G_MATE_360_BEMA = 'FLANNEL #2(G) MATE-360 BEMA';
export const EMBOSSING_FLANNEL_2_G_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_FLANNEL_2_G_MATE_360_BEMA]: {
    id: "Embossing.flannel_2_Mate360Bema",
    description: "Embossing value named FLANNEL #2(G) MATE-360 BEMA",
    defaultMessage: "flannel #2(g) mate-360 bema"
  }
});
export const EMBOSSING_FLORIDIAN = 'FLORIDIAN';
export const EMBOSSING_FLORIDIAN_MESSAGE = defineMessages({
  [EMBOSSING_FLORIDIAN]: {
    id: "Embossing.floridian",
    description: "Embossing value named FLORIDIAN",
    defaultMessage: "floridian"
  }
});
export const EMBOSSING_FRANCESA = 'FRANCESA';
export const EMBOSSING_FRANCESA_MESSAGE = defineMessages({
  [EMBOSSING_FRANCESA]: {
    id: "Embossing.francesa",
    description: "Embossing value named FRANCESA",
    defaultMessage: "francesa"
  }
});
export const EMBOSSING_GACELA = 'GACELA';
export const EMBOSSING_GACELA_MESSAGE = defineMessages({
  [EMBOSSING_GACELA]: {
    id: "Embossing.gacela",
    description: "Embossing value named GACELA",
    defaultMessage: "gacela"
  }
});
export const EMBOSSING_GACELA_MATE_360 = 'GACELA MATE-360';
export const EMBOSSING_GACELA_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_GACELA_MATE_360]: {
    id: "Embossing.gacelaMate360",
    description: "Embossing value named GACELA MATE-360",
    defaultMessage: "gacela mate-360"
  }
});
export const EMBOSSING_GACELA_MATE_360_BEMA = 'GACELA MATE-360 BEMA';
export const EMBOSSING_GACELA_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_GACELA_MATE_360_BEMA]: {
    id: "Embossing.gacelaMate360Bema",
    description: "Embossing value named GACELA MATE-360 BEMA",
    defaultMessage: "gacela mate-360 bema"
  }
});
export const EMBOSSING_GALAXIA = 'GALAXIA';
export const EMBOSSING_GALAXIA_MESSAGE = defineMessages({
  [EMBOSSING_GALAXIA]: {
    id: "Embossing.galaxia",
    description: "Embossing value named GALAXIA",
    defaultMessage: "galaxia"
  }
});
export const EMBOSSING_GAM_MET_MATE = 'GAM-MET. MATE';
export const EMBOSSING_GAM_MET_MATE_MESSAGE = defineMessages({
  [EMBOSSING_GAM_MET_MATE]: {
    id: "Embossing.gammetMate",
    description: "Embossing value named GAM-MET. MATE",
    defaultMessage: "gam-met. mate"
  }
});
export const EMBOSSING_GAM_MET_PINT_SB = 'GAM-MET. PINT SB';
export const EMBOSSING_GAM_MET_PINT_SB_MESSAGE = defineMessages({
  [EMBOSSING_GAM_MET_PINT_SB]: {
    id: "Embossing.gammetPintSb",
    description: "Embossing value named GAM-MET. PINT SB",
    defaultMessage: "gam-met. pint sb"
  }
});
export const EMBOSSING_GAM_MET_VENADO = 'GAM-MET. VENADO';
export const EMBOSSING_GAM_MET_VENADO_MESSAGE = defineMessages({
  [EMBOSSING_GAM_MET_VENADO]: {
    id: "Embossing.gammetVenado",
    description: "Embossing value named GAM-MET. VENADO",
    defaultMessage: "gam-met. venado"
  }
});
export const EMBOSSING_GAMSIL = 'GAMSIL';
export const EMBOSSING_GAMSIL_MESSAGE = defineMessages({
  [EMBOSSING_GAMSIL]: {
    id: "Embossing.gamsil",
    description: "Embossing value named GAMSIL",
    defaultMessage: "gamsil"
  }
});
export const EMBOSSING_GAMSIL_159_2_MATE = 'GAMSIL 159#2 MATE';
export const EMBOSSING_GAMSIL_159_2_MATE_MESSAGE = defineMessages({
  [EMBOSSING_GAMSIL_159_2_MATE]: {
    id: "Embossing.gamsil159_2Mate",
    description: "Embossing value named GAMSIL 159#2 MATE",
    defaultMessage: "gamsil 159#2 mate"
  }
});
export const EMBOSSING_GAMSIL4 = 'GAMSIL4';
export const EMBOSSING_GAMSIL4_MESSAGE = defineMessages({
  [EMBOSSING_GAMSIL4]: {
    id: "Embossing.gamsil4",
    description: "Embossing value named GAMSIL4",
    defaultMessage: "gamsil4"
  }
});
export const EMBOSSING_GAMUZA_2003_2 = 'GAMUZA 2003#2';
export const EMBOSSING_GAMUZA_2003_2_MESSAGE = defineMessages({
  [EMBOSSING_GAMUZA_2003_2]: {
    id: "Embossing.gamuza2003_2",
    description: "Embossing value named GAMUZA 2003#2",
    defaultMessage: "gamuza 2003#2"
  }
});
export const EMBOSSING_GAMUZA_2003_2_MATE = 'GAMUZA 2003#2 MATE';
export const EMBOSSING_GAMUZA_2003_2_MATE_MESSAGE = defineMessages({
  [EMBOSSING_GAMUZA_2003_2_MATE]: {
    id: "Embossing.gamuza2003_2Mate",
    description: "Embossing value named GAMUZA 2003#2 MATE",
    defaultMessage: "gamuza 2003#2 mate"
  }
});
export const EMBOSSING_GAMUZA_2003_2_MATE_360_BEMA = 'GAMUZA 2003#2 MATE-360 BEMA';
export const EMBOSSING_GAMUZA_2003_2_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_GAMUZA_2003_2_MATE_360_BEMA]: {
    id: "Embossing.gamuza2003_2Mate360Bema",
    description: "Embossing value named GAMUZA 2003#2 MATE-360 BEMA",
    defaultMessage: "gamuza 2003#2 mate-360 bema"
  }
});
export const EMBOSSING_GAMUZA_2004_1 = 'GAMUZA 2004 #1';
export const EMBOSSING_GAMUZA_2004_1_MESSAGE = defineMessages({
  [EMBOSSING_GAMUZA_2004_1]: {
    id: "Embossing.gamuza2004_1",
    description: "Embossing value named GAMUZA 2004 #1",
    defaultMessage: "gamuza 2004 #1"
  }
});
export const EMBOSSING_GAMUZA_2007_1 = 'GAMUZA 2007 #1';
export const EMBOSSING_GAMUZA_2007_1_MESSAGE = defineMessages({
  [EMBOSSING_GAMUZA_2007_1]: {
    id: "Embossing.gamuza2007_1",
    description: "Embossing value named GAMUZA 2007 #1",
    defaultMessage: "gamuza 2007 #1"
  }
});
export const EMBOSSING_GAMUZA_2007_1_CROYDON = 'GAMUZA 2007 #1 CROYDON';
export const EMBOSSING_GAMUZA_2007_1_CROYDON_MESSAGE = defineMessages({
  [EMBOSSING_GAMUZA_2007_1_CROYDON]: {
    id: "Embossing.gamuza2007_1Croydon",
    description: "Embossing value named GAMUZA 2007 #1 CROYDON",
    defaultMessage: "gamuza 2007 #1 croydon"
  }
});
export const EMBOSSING_GARDENIA = 'GARDENIA';
export const EMBOSSING_GARDENIA_MESSAGE = defineMessages({
  [EMBOSSING_GARDENIA]: {
    id: "Embossing.gardenia",
    description: "Embossing value named GARDENIA",
    defaultMessage: "gardenia"
  }
});
export const EMBOSSING_GAVIAL = 'GAVIAL';
export const EMBOSSING_GAVIAL_MESSAGE = defineMessages({
  [EMBOSSING_GAVIAL]: {
    id: "Embossing.gavial",
    description: "Embossing value named GAVIAL",
    defaultMessage: "gavial"
  }
});
export const EMBOSSING_GEOMETRIC = 'GEOMETRIC';
export const EMBOSSING_GEOMETRIC_MESSAGE = defineMessages({
  [EMBOSSING_GEOMETRIC]: {
    id: "Embossing.geometric",
    description: "Embossing value named GEOMETRIC",
    defaultMessage: "geometric"
  }
});
export const EMBOSSING_GOAT = 'GOAT';
export const EMBOSSING_GOAT_MESSAGE = defineMessages({
  [EMBOSSING_GOAT]: {
    id: "Embossing.goat",
    description: "Embossing value named GOAT",
    defaultMessage: "goat"
  }
});
export const EMBOSSING_GOLD_COAST = 'GOLD COAST';
export const EMBOSSING_GOLD_COAST_MESSAGE = defineMessages({
  [EMBOSSING_GOLD_COAST]: {
    id: "Embossing.goldCoast",
    description: "Embossing value named GOLD COAST",
    defaultMessage: "gold coast"
  }
});
export const EMBOSSING_GOLETA = 'GOLETA';
export const EMBOSSING_GOLETA_MESSAGE = defineMessages({
  [EMBOSSING_GOLETA]: {
    id: "Embossing.goleta",
    description: "Embossing value named GOLETA",
    defaultMessage: "goleta"
  }
});
export const EMBOSSING_GRANE = 'GRANE';
export const EMBOSSING_GRANE_MESSAGE = defineMessages({
  [EMBOSSING_GRANE]: {
    id: "Embossing.grane",
    description: "Embossing value named GRANE",
    defaultMessage: "grane"
  }
});
export const EMBOSSING_GRANE_2 = 'GRANE #2';
export const EMBOSSING_GRANE_2_MESSAGE = defineMessages({
  [EMBOSSING_GRANE_2]: {
    id: "Embossing.grane_2",
    description: "Embossing value named GRANE #2",
    defaultMessage: "grane #2"
  }
});
export const EMBOSSING_GRANE_ESTRELLAS = 'GRANE ESTRELLAS';
export const EMBOSSING_GRANE_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_GRANE_ESTRELLAS]: {
    id: "Embossing.graneEstrellas",
    description: "Embossing value named GRANE ESTRELLAS",
    defaultMessage: "grane estrellas"
  }
});
export const EMBOSSING_GRANE_MATE = 'GRANE MATE';
export const EMBOSSING_GRANE_MATE_MESSAGE = defineMessages({
  [EMBOSSING_GRANE_MATE]: {
    id: "Embossing.graneMate",
    description: "Embossing value named GRANE MATE",
    defaultMessage: "grane mate"
  }
});
export const EMBOSSING_GRANE_MATE_360_BEMA = 'GRANE MATE-360 BEMA';
export const EMBOSSING_GRANE_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_GRANE_MATE_360_BEMA]: {
    id: "Embossing.graneMate360Bema",
    description: "Embossing value named GRANE MATE-360 BEMA",
    defaultMessage: "grane mate-360 bema"
  }
});
export const EMBOSSING_GRAVILLA_SIL = 'GRAVILLA-SIL';
export const EMBOSSING_GRAVILLA_SIL_MESSAGE = defineMessages({
  [EMBOSSING_GRAVILLA_SIL]: {
    id: "Embossing.gravillaSil",
    description: "Embossing value named GRAVILLA-SIL",
    defaultMessage: "gravilla-sil"
  }
});
export const EMBOSSING_GRAVILLA_SIL_MATE_360_BEMA = 'GRAVILLA-SIL MATE-360 BEMA';
export const EMBOSSING_GRAVILLA_SIL_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_GRAVILLA_SIL_MATE_360_BEMA]: {
    id: "Embossing.gravillaSilMate360Bema",
    description: "Embossing value named GRAVILLA-SIL MATE-360 BEMA",
    defaultMessage: "gravilla-sil mate-360 bema"
  }
});
export const EMBOSSING_GRID = 'GRID';
export const EMBOSSING_GRID_MESSAGE = defineMessages({
  [EMBOSSING_GRID]: {
    id: "Embossing.grid",
    description: "Embossing value named GRID",
    defaultMessage: "grid"
  }
});
export const EMBOSSING_GV_M2 = 'GV-M2';
export const EMBOSSING_GV_M2_MESSAGE = defineMessages({
  [EMBOSSING_GV_M2]: {
    id: "Embossing.gvm2",
    description: "Embossing value named GV-M2",
    defaultMessage: "gv-m2"
  }
});
export const EMBOSSING_GV_REN = 'GV-REN';
export const EMBOSSING_GV_REN_MESSAGE = defineMessages({
  [EMBOSSING_GV_REN]: {
    id: "Embossing.gvren",
    description: "Embossing value named GV-REN",
    defaultMessage: "gv-ren"
  }
});
export const EMBOSSING_GV_REN_MATE_360 = 'GV-REN MATE-360';
export const EMBOSSING_GV_REN_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_GV_REN_MATE_360]: {
    id: "Embossing.gvrenMate360",
    description: "Embossing value named GV-REN MATE-360",
    defaultMessage: "gv-ren mate-360"
  }
});
export const EMBOSSING_HALCON = 'HALCON';
export const EMBOSSING_HALCON_MESSAGE = defineMessages({
  [EMBOSSING_HALCON]: {
    id: "Embossing.halcon",
    description: "Embossing value named HALCON",
    defaultMessage: "halcon"
  }
});
export const EMBOSSING_HAMMERED_P_2887 = 'HAMMERED P 2887';
export const EMBOSSING_HAMMERED_P_2887_MESSAGE = defineMessages({
  [EMBOSSING_HAMMERED_P_2887]: {
    id: "Embossing.hammeredP2887",
    description: "Embossing value named HAMMERED P 2887",
    defaultMessage: "hammered p 2887"
  }
});
export const EMBOSSING_HATCH_RICE_PLAIN = 'HATCH RICE PLAIN';
export const EMBOSSING_HATCH_RICE_PLAIN_MESSAGE = defineMessages({
  [EMBOSSING_HATCH_RICE_PLAIN]: {
    id: "Embossing.hatchRicePlain",
    description: "Embossing value named HATCH RICE PLAIN",
    defaultMessage: "hatch rice plain"
  }
});
export const EMBOSSING_HATCHBALIBALL = 'HATCHBALIBALL';
export const EMBOSSING_HATCHBALIBALL_MESSAGE = defineMessages({
  [EMBOSSING_HATCHBALIBALL]: {
    id: "Embossing.hatchbaliball",
    description: "Embossing value named HATCHBALIBALL",
    defaultMessage: "hatchbaliball"
  }
});
export const EMBOSSING_HATCHBETSY = 'HATCHBETSY';
export const EMBOSSING_HATCHBETSY_MESSAGE = defineMessages({
  [EMBOSSING_HATCHBETSY]: {
    id: "Embossing.hatchbetsy",
    description: "Embossing value named HATCHBETSY",
    defaultMessage: "hatchbetsy"
  }
});
export const EMBOSSING_HATCHEAK = 'HATCHEAK';
export const EMBOSSING_HATCHEAK_MESSAGE = defineMessages({
  [EMBOSSING_HATCHEAK]: {
    id: "Embossing.hatcheak",
    description: "Embossing value named HATCHEAK",
    defaultMessage: "hatcheak"
  }
});
export const EMBOSSING_HATCHEMLEAF = 'HATCHEMLEAF';
export const EMBOSSING_HATCHEMLEAF_MESSAGE = defineMessages({
  [EMBOSSING_HATCHEMLEAF]: {
    id: "Embossing.hatchemleaf",
    description: "Embossing value named HATCHEMLEAF",
    defaultMessage: "hatchemleaf"
  }
});
export const EMBOSSING_HATCHFOSLEAF = 'HATCHFOSLEAF';
export const EMBOSSING_HATCHFOSLEAF_MESSAGE = defineMessages({
  [EMBOSSING_HATCHFOSLEAF]: {
    id: "Embossing.hatchfosleaf",
    description: "Embossing value named HATCHFOSLEAF",
    defaultMessage: "hatchfosleaf"
  }
});
export const EMBOSSING_HAWAY_BAMBU = 'HAWAY-BAMBU';
export const EMBOSSING_HAWAY_BAMBU_MESSAGE = defineMessages({
  [EMBOSSING_HAWAY_BAMBU]: {
    id: "Embossing.hawaybambu",
    description: "Embossing value named HAWAY-BAMBU",
    defaultMessage: "haway-bambu"
  }
});
export const EMBOSSING_HEXAGONOS = 'HEXAGONOS';
export const EMBOSSING_HEXAGONOS_MESSAGE = defineMessages({
  [EMBOSSING_HEXAGONOS]: {
    id: "Embossing.hexagonos",
    description: "Embossing value named HEXAGONOS",
    defaultMessage: "hexagonos"
  }
});
export const EMBOSSING_HEXX = 'HEXX';
export const EMBOSSING_HEXX_MESSAGE = defineMessages({
  [EMBOSSING_HEXX]: {
    id: "Embossing.hexx",
    description: "Embossing value named HEXX",
    defaultMessage: "hexx"
  }
});
export const EMBOSSING_HINDU = 'HINDU';
export const EMBOSSING_HINDU_MESSAGE = defineMessages({
  [EMBOSSING_HINDU]: {
    id: "Embossing.hindu",
    description: "Embossing value named HINDU",
    defaultMessage: "hindu"
  }
});
export const EMBOSSING_HITCH = 'HITCH';
export const EMBOSSING_HITCH_MESSAGE = defineMessages({
  [EMBOSSING_HITCH]: {
    id: "Embossing.hitch",
    description: "Embossing value named HITCH",
    defaultMessage: "hitch"
  }
});
export const EMBOSSING_H_NEEDLE = 'H-NEEDLE';
export const EMBOSSING_H_NEEDLE_MESSAGE = defineMessages({
  [EMBOSSING_H_NEEDLE]: {
    id: "Embossing.hneedle",
    description: "Embossing value named H-NEEDLE",
    defaultMessage: "h-needle"
  }
});
export const EMBOSSING_IGUANA_G = 'IGUANA (G)';
export const EMBOSSING_IGUANA_G_MESSAGE = defineMessages({
  [EMBOSSING_IGUANA_G]: {
    id: "Embossing.iguana",
    description: "Embossing value named IGUANA (G)",
    defaultMessage: "iguana (g)"
  }
});
export const EMBOSSING_IMPALA = 'IMPALA';
export const EMBOSSING_IMPALA_MESSAGE = defineMessages({
  [EMBOSSING_IMPALA]: {
    id: "Embossing.impala",
    description: "Embossing value named IMPALA",
    defaultMessage: "impala"
  }
});
export const EMBOSSING_IMPALA_180 = 'IMPALA-180';
export const EMBOSSING_IMPALA_180_MESSAGE = defineMessages({
  [EMBOSSING_IMPALA_180]: {
    id: "Embossing.impala180",
    description: "Embossing value named IMPALA-180",
    defaultMessage: "impala-180"
  }
});
export const EMBOSSING_INCA = 'INCA';
export const EMBOSSING_INCA_MESSAGE = defineMessages({
  [EMBOSSING_INCA]: {
    id: "Embossing.inca",
    description: "Embossing value named INCA",
    defaultMessage: "inca"
  }
});
export const EMBOSSING_INCA_MATE = 'INCA MATE';
export const EMBOSSING_INCA_MATE_MESSAGE = defineMessages({
  [EMBOSSING_INCA_MATE]: {
    id: "Embossing.incaMate",
    description: "Embossing value named INCA MATE",
    defaultMessage: "inca mate"
  }
});
export const EMBOSSING_INCA_MATE_360_BEMA = 'INCA MATE-360 BEMA';
export const EMBOSSING_INCA_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_INCA_MATE_360_BEMA]: {
    id: "Embossing.incaMate360Bema",
    description: "Embossing value named INCA MATE-360 BEMA",
    defaultMessage: "inca mate-360 bema"
  }
});
export const EMBOSSING_INFUSION = 'INFUSION';
export const EMBOSSING_INFUSION_MESSAGE = defineMessages({
  [EMBOSSING_INFUSION]: {
    id: "Embossing.infusion",
    description: "Embossing value named INFUSION",
    defaultMessage: "infusion"
  }
});
export const EMBOSSING_IRREGULAR_GRID = 'IRREGULAR GRID';
export const EMBOSSING_IRREGULAR_GRID_MESSAGE = defineMessages({
  [EMBOSSING_IRREGULAR_GRID]: {
    id: "Embossing.irregularGrid",
    description: "Embossing value named IRREGULAR GRID",
    defaultMessage: "irregular grid"
  }
});
export const EMBOSSING_IZMIR = 'IZMIR';
export const EMBOSSING_IZMIR_MESSAGE = defineMessages({
  [EMBOSSING_IZMIR]: {
    id: "Embossing.izmir",
    description: "Embossing value named IZMIR",
    defaultMessage: "izmir"
  }
});
export const EMBOSSING_JACKSON = 'JACKSON';
export const EMBOSSING_JACKSON_MESSAGE = defineMessages({
  [EMBOSSING_JACKSON]: {
    id: "Embossing.jackson",
    description: "Embossing value named JACKSON",
    defaultMessage: "jackson"
  }
});
export const EMBOSSING_JUMBO = 'JUMBO';
export const EMBOSSING_JUMBO_MESSAGE = defineMessages({
  [EMBOSSING_JUMBO]: {
    id: "Embossing.jumbo",
    description: "Embossing value named JUMBO",
    defaultMessage: "jumbo"
  }
});
export const EMBOSSING_KOALA = 'KOALA';
export const EMBOSSING_KOALA_MESSAGE = defineMessages({
  [EMBOSSING_KOALA]: {
    id: "Embossing.koala",
    description: "Embossing value named KOALA",
    defaultMessage: "koala"
  }
});
export const EMBOSSING_LABERINTOS = 'LABERINTOS';
export const EMBOSSING_LABERINTOS_MESSAGE = defineMessages({
  [EMBOSSING_LABERINTOS]: {
    id: "Embossing.laberintos",
    description: "Embossing value named LABERINTOS",
    defaultMessage: "laberintos"
  }
});
export const EMBOSSING_LAYOUT = 'LAYOUT';
export const EMBOSSING_LAYOUT_MESSAGE = defineMessages({
  [EMBOSSING_LAYOUT]: {
    id: "Embossing.layout",
    description: "Embossing value named LAYOUT",
    defaultMessage: "layout"
  }
});
export const EMBOSSING_LEVANT = 'LEVANT';
export const EMBOSSING_LEVANT_MESSAGE = defineMessages({
  [EMBOSSING_LEVANT]: {
    id: "Embossing.levant",
    description: "Embossing value named LEVANT",
    defaultMessage: "levant"
  }
});
export const EMBOSSING_LIENZO = 'LIENZO';
export const EMBOSSING_LIENZO_MESSAGE = defineMessages({
  [EMBOSSING_LIENZO]: {
    id: "Embossing.lienzo",
    description: "Embossing value named LIENZO",
    defaultMessage: "lienzo"
  }
});
export const EMBOSSING_LIENZO_DURAZNO = 'LIENZO DURAZNO';
export const EMBOSSING_LIENZO_DURAZNO_MESSAGE = defineMessages({
  [EMBOSSING_LIENZO_DURAZNO]: {
    id: "Embossing.lienzoDurazno",
    description: "Embossing value named LIENZO DURAZNO",
    defaultMessage: "lienzo durazno"
  }
});
export const EMBOSSING_LIENZO_MATE = 'LIENZO MATE';
export const EMBOSSING_LIENZO_MATE_MESSAGE = defineMessages({
  [EMBOSSING_LIENZO_MATE]: {
    id: "Embossing.lienzoMate",
    description: "Embossing value named LIENZO MATE",
    defaultMessage: "lienzo mate"
  }
});
export const EMBOSSING_LIENZO_MATE_360_BEMA = 'LIENZO MATE-360 BEMA';
export const EMBOSSING_LIENZO_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_LIENZO_MATE_360_BEMA]: {
    id: "Embossing.lienzoMate360Bema",
    description: "Embossing value named LIENZO MATE-360 BEMA",
    defaultMessage: "lienzo mate-360 bema"
  }
});
export const EMBOSSING_LIJA_A_60 = 'LIJA A 60';
export const EMBOSSING_LIJA_A_60_MESSAGE = defineMessages({
  [EMBOSSING_LIJA_A_60]: {
    id: "Embossing.lijaA60",
    description: "Embossing value named LIJA A 60",
    defaultMessage: "lija a 60"
  }
});
export const EMBOSSING_LIJA_A100 = 'LIJA A100';
export const EMBOSSING_LIJA_A100_MESSAGE = defineMessages({
  [EMBOSSING_LIJA_A100]: {
    id: "Embossing.lijaA100",
    description: "Embossing value named LIJA A100",
    defaultMessage: "lija a100"
  }
});
export const EMBOSSING_LINEN = 'LINEN';
export const EMBOSSING_LINEN_MESSAGE = defineMessages({
  [EMBOSSING_LINEN]: {
    id: "Embossing.linen",
    description: "Embossing value named LINEN",
    defaultMessage: "linen"
  }
});
export const EMBOSSING_LISBOA = 'LISBOA';
export const EMBOSSING_LISBOA_MESSAGE = defineMessages({
  [EMBOSSING_LISBOA]: {
    id: "Embossing.lisboa",
    description: "Embossing value named LISBOA",
    defaultMessage: "lisboa"
  }
});
export const EMBOSSING_LISBOA_MATE = 'LISBOA MATE';
export const EMBOSSING_LISBOA_MATE_MESSAGE = defineMessages({
  [EMBOSSING_LISBOA_MATE]: {
    id: "Embossing.lisboaMate",
    description: "Embossing value named LISBOA MATE",
    defaultMessage: "lisboa mate"
  }
});
export const EMBOSSING_L_LOGANA = 'L-LOGANA';
export const EMBOSSING_L_LOGANA_MESSAGE = defineMessages({
  [EMBOSSING_L_LOGANA]: {
    id: "Embossing.lLogana",
    description: "Embossing value named L-LOGANA",
    defaultMessage: "l-logana"
  }
});
export const EMBOSSING_LONA_FILM_SIL = 'LONA FILM-SIL';
export const EMBOSSING_LONA_FILM_SIL_MESSAGE = defineMessages({
  [EMBOSSING_LONA_FILM_SIL]: {
    id: "Embossing.lonaFilmsil",
    description: "Embossing value named LONA FILM-SIL",
    defaultMessage: "lona film-sil"
  }
});
export const EMBOSSING_LONETA_50Q = 'LONETA 50Q';
export const EMBOSSING_LONETA_50Q_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q]: {
    id: "Embossing.loneta50q",
    description: "Embossing value named LONETA 50Q",
    defaultMessage: "loneta 50q"
  }
});
export const EMBOSSING_LONETA_50Q_2 = 'LONETA 50Q #2';
export const EMBOSSING_LONETA_50Q_2_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_2]: {
    id: "Embossing.loneta50q_2",
    description: "Embossing value named LONETA 50Q #2",
    defaultMessage: "loneta 50q #2"
  }
});
export const EMBOSSING_LONETA_50Q_ALFAJOR_2 = 'LONETA 50Q ALFAJOR #2';
export const EMBOSSING_LONETA_50Q_ALFAJOR_2_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_ALFAJOR_2]: {
    id: "Embossing.loneta50qAlfajor_2",
    description: "Embossing value named LONETA 50Q ALFAJOR #2",
    defaultMessage: "loneta 50q alfajor #2"
  }
});
export const EMBOSSING_LONETA_50Q_ALFAJOR_3 = 'LONETA 50Q ALFAJOR #3';
export const EMBOSSING_LONETA_50Q_ALFAJOR_3_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_ALFAJOR_3]: {
    id: "Embossing.loneta50qalfajor_3",
    description: "Embossing value named LONETA 50Q ALFAJOR #3",
    defaultMessage: "loneta 50q alfajor #3"
  }
});
export const EMBOSSING_LONETA_50Q_BOOMERANG_BRILLANTE = 'LONETA 50Q BOOMERANG BRILLANTE';
export const EMBOSSING_LONETA_50Q_BOOMERANG_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BOOMERANG_BRILLANTE]: {
    id: "Embossing.loneta50qBoomerangBrillante",
    description: "Embossing value named LONETA 50Q BOOMERANG BRILLANTE",
    defaultMessage: "loneta 50q boomerang brillante"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE = 'LONETA 50Q BRILLANTE';
export const EMBOSSING_LONETA_50Q_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE]: {
    id: "Embossing.loneta50qBrillante",
    description: "Embossing value named LONETA 50Q BRILLANTE",
    defaultMessage: "loneta 50q brillante"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_2 = 'LONETA 50Q BRILLANTE ALFAJOR #2';
export const EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_2_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_2]: {
    id: "Embossing.loneta50qBrillanteAlfajor_2",
    description: "Embossing value named LONETA 50Q BRILLANTE ALFAJOR #2",
    defaultMessage: "loneta 50q brillante alfajor #2"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3 = 'LONETA 50Q BRILLANTE ALFAJOR #3';
export const EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3]: {
    id: "Embossing.loneta50qBrillanteAlfajor_3",
    description: "Embossing value named LONETA 50Q BRILLANTE ALFAJOR #3",
    defaultMessage: "loneta 50q brillante alfajor #3"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3_MATE = 'LONETA 50Q BRILLANTE ALFAJOR #3 MATE';
export const EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3_MATE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3_MATE]: {
    id: "Embossing.loneta50qBrillanteAlfajor_3Mate",
    description: "Embossing value named LONETA 50Q BRILLANTE ALFAJOR #3 MATE",
    defaultMessage: "loneta 50q brillante alfajor #3 mate"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_CHECKER = 'LONETA 50Q BRILLANTE CHECKER';
export const EMBOSSING_LONETA_50Q_BRILLANTE_CHECKER_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_CHECKER]: {
    id: "Embossing.loneta50qBrillanteChecker",
    description: "Embossing value named LONETA 50Q BRILLANTE CHECKER",
    defaultMessage: "loneta 50q brillante checker"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS = 'LONETA 50Q BRILLANTE CIRCULOS';
export const EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS]: {
    id: "Embossing.loneta50qBrillanteCirculos",
    description: "Embossing value named LONETA 50Q BRILLANTE CIRCULOS",
    defaultMessage: "loneta 50q brillante circulos"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS_MATE = 'LONETA 50Q BRILLANTE CIRCULOS MATE';
export const EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS_MATE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS_MATE]: {
    id: "Embossing.loneta50qBrillanteCirculosMate",
    description: "Embossing value named LONETA 50Q BRILLANTE CIRCULOS MATE",
    defaultMessage: "loneta 50q brillante circulos mate"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_CUADROS_G = 'LONETA 50Q BRILLANTE CUADROS (G)';
export const EMBOSSING_LONETA_50Q_BRILLANTE_CUADROS_G_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_CUADROS_G]: {
    id: "Embossing.loneta50qBrillanteCuadros",
    description: "Embossing value named LONETA 50Q BRILLANTE CUADROS (G)",
    defaultMessage: "loneta 50q brillante cuadros (g)"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_EUROPA_RAYAS = 'LONETA 50Q BRILLANTE EUROPA - RAYAS';
export const EMBOSSING_LONETA_50Q_BRILLANTE_EUROPA_RAYAS_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_EUROPA_RAYAS]: {
    id: "Embossing.loneta50qBrillanteEuropaRayas",
    description: "Embossing value named LONETA 50Q BRILLANTE EUROPA - RAYAS",
    defaultMessage: "loneta 50q brillante europa - rayas"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_MATE_CAPSULA = 'LONETA 50Q BRILLANTE MATE CAPSULA';
export const EMBOSSING_LONETA_50Q_BRILLANTE_MATE_CAPSULA_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_MATE_CAPSULA]: {
    id: "Embossing.loneta50qBrillanteMateCapsula",
    description: "Embossing value named LONETA 50Q BRILLANTE MATE CAPSULA",
    defaultMessage: "loneta 50q brillante mate capsula"
  }
});
export const EMBOSSING_LONETA_50Q_BRILLANTE_PITAGORAS = 'LONETA 50Q BRILLANTE PITAGORAS';
export const EMBOSSING_LONETA_50Q_BRILLANTE_PITAGORAS_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_BRILLANTE_PITAGORAS]: {
    id: "Embossing.loneta50qBrillantePitagoras",
    description: "Embossing value named LONETA 50Q BRILLANTE PITAGORAS",
    defaultMessage: "loneta 50q brillante pitagoras"
  }
});
export const EMBOSSING_LONETA_50Q_CIRCULOS = 'LONETA 50Q CIRCULOS';
export const EMBOSSING_LONETA_50Q_CIRCULOS_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_CIRCULOS]: {
    id: "Embossing.loneta50qCirculos",
    description: "Embossing value named LONETA 50Q CIRCULOS",
    defaultMessage: "loneta 50q circulos"
  }
});
export const EMBOSSING_LONETA_50Q_CIRCULOS_DEGRADE_BRILLANTE = 'LONETA 50Q CIRCULOS DEGRADE BRILLANTE';
export const EMBOSSING_LONETA_50Q_CIRCULOS_DEGRADE_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_CIRCULOS_DEGRADE_BRILLANTE]: {
    id: "Embossing.loneta50qCirculosDegradeBrillante",
    description: "Embossing value named LONETA 50Q CIRCULOS DEGRADE BRILLANTE",
    defaultMessage: "loneta 50q circulos degrade brillante"
  }
});
export const EMBOSSING_LONETA_50Q_DIABLO_MATE_360_BEMA = 'LONETA 50Q DIABLO MATE-360 BEMA';
export const EMBOSSING_LONETA_50Q_DIABLO_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_DIABLO_MATE_360_BEMA]: {
    id: "Embossing.loneta50qDiabloMate_360Bema",
    description: "Embossing value named LONETA 50Q DIABLO MATE-360 BEMA",
    defaultMessage: "loneta 50q diablo mate-360 bema"
  }
});
export const EMBOSSING_LONETA_50Q_DURAZNO = 'LONETA 50Q DURAZNO';
export const EMBOSSING_LONETA_50Q_DURAZNO_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_DURAZNO]: {
    id: "Embossing.loneta50qDurazno",
    description: "Embossing value named LONETA 50Q DURAZNO",
    defaultMessage: "loneta 50q durazno"
  }
});
export const EMBOSSING_LONETA_50Q_DURAZNO_BRILLANTE_LIJA_A100 = 'LONETA 50Q DURAZNO BRILLANTE LIJA A100';
export const EMBOSSING_LONETA_50Q_DURAZNO_BRILLANTE_LIJA_A100_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_DURAZNO_BRILLANTE_LIJA_A100]: {
    id: "Embossing.loneta50qDuraznoBrillanteLijaA100",
    description: "Embossing value named LONETA 50Q DURAZNO BRILLANTE LIJA A100",
    defaultMessage: "loneta 50q durazno brillante lija a100"
  }
});
export const EMBOSSING_LONETA_50Q_EUROPA = 'LONETA 50Q EUROPA';
export const EMBOSSING_LONETA_50Q_EUROPA_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_EUROPA]: {
    id: "Embossing.loneta50qEuropa",
    description: "Embossing value named LONETA 50Q EUROPA",
    defaultMessage: "loneta 50q europa"
  }
});
export const EMBOSSING_LONETA_50Q_EUROPA_BRILLANTE = 'LONETA 50Q EUROPA BRILLANTE';
export const EMBOSSING_LONETA_50Q_EUROPA_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_EUROPA_BRILLANTE]: {
    id: "Embossing.loneta50qEuropaBrillante",
    description: "Embossing value named LONETA 50Q EUROPA BRILLANTE",
    defaultMessage: "loneta 50q europa brillante"
  }
});
export const EMBOSSING_LONETA_50Q_EUROPA_MOLETTE_18L_BRILLANTE = 'LONETA 50Q EUROPA MOLETTE-18L BRILLANTE';
export const EMBOSSING_LONETA_50Q_EUROPA_MOLETTE_18L_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_EUROPA_MOLETTE_18L_BRILLANTE]: {
    id: "Embossing.loneta50qEuropaMolette_18lBrillante",
    description: "Embossing value named LONETA 50Q EUROPA MOLETTE-18L BRILLANTE",
    defaultMessage: "loneta 50q europa molette-18l brillante"
  }
});
export const EMBOSSING_LONETA_50Q_LEVANT = 'LONETA 50Q LEVANT';
export const EMBOSSING_LONETA_50Q_LEVANT_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_LEVANT]: {
    id: "Embossing.loneta50qLevant",
    description: "Embossing value named LONETA 50Q LEVANT",
    defaultMessage: "loneta 50q levant"
  }
});
export const EMBOSSING_LONETA_50Q_MATE = 'LONETA 50Q MATE';
export const EMBOSSING_LONETA_50Q_MATE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_MATE]: {
    id: "Embossing.loneta50qMate",
    description: "Embossing value named LONETA 50Q MATE",
    defaultMessage: "loneta 50q mate"
  }
});
export const EMBOSSING_LONETA_50Q_MATE_360_BEMA = 'LONETA 50Q MATE-360 BEMA';
export const EMBOSSING_LONETA_50Q_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_MATE_360_BEMA]: {
    id: "Embossing.loneta50qMate_360Bema",
    description: "Embossing value named LONETA 50Q MATE-360 BEMA",
    defaultMessage: "loneta 50q mate-360 bema"
  }
});
export const EMBOSSING_LONETA_50Q_PRISMA_BRILLANTE = 'LONETA 50Q PRISMA BRILLANTE';
export const EMBOSSING_LONETA_50Q_PRISMA_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_PRISMA_BRILLANTE]: {
    id: "Embossing.loneta50qPrismaBrillante",
    description: "Embossing value named LONETA 50Q PRISMA BRILLANTE",
    defaultMessage: "loneta 50q prisma brillante"
  }
});
export const EMBOSSING_LONETA_50Q_ROMBOS = 'LONETA 50Q ROMBOS';
export const EMBOSSING_LONETA_50Q_ROMBOS_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_ROMBOS]: {
    id: "Embossing.loneta50qRombos",
    description: "Embossing value named LONETA 50Q ROMBOS",
    defaultMessage: "loneta 50q rombos"
  }
});
export const EMBOSSING_LONETA_50Q_STATIC_FLOOR = 'LONETA 50Q STATIC FLOOR';
export const EMBOSSING_LONETA_50Q_STATIC_FLOOR_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_STATIC_FLOOR]: {
    id: "Embossing.loneta50qStaticFloor",
    description: "Embossing value named LONETA 50Q STATIC FLOOR",
    defaultMessage: "loneta 50q static floor"
  }
});
export const EMBOSSING_LONETA_50Q_STATIC_FLOOR_BRILLANTE = 'LONETA 50Q STATIC FLOOR BRILLANTE';
export const EMBOSSING_LONETA_50Q_STATIC_FLOOR_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_STATIC_FLOOR_BRILLANTE]: {
    id: "Embossing.loneta50qStaticFloorBrillante",
    description: "Embossing value named LONETA 50Q STATIC FLOOR BRILLANTE",
    defaultMessage: "loneta 50q static floor brillante"
  }
});
export const EMBOSSING_LONETA_50Q_SULTAN = 'LONETA 50Q SULTAN';
export const EMBOSSING_LONETA_50Q_SULTAN_MESSAGE = defineMessages({
  [EMBOSSING_LONETA_50Q_SULTAN]: {
    id: "Embossing.loneta50qSultan",
    description: "Embossing value named LONETA 50Q SULTAN",
    defaultMessage: "loneta 50q sultan"
  }
});
export const EMBOSSING_LOON_SIL = 'LOON-SIL';
export const EMBOSSING_LOON_SIL_MESSAGE = defineMessages({
  [EMBOSSING_LOON_SIL]: {
    id: "Embossing.loonsil",
    description: "Embossing value named LOON-SIL",
    defaultMessage: "loon-sil"
  }
});
export const EMBOSSING_LSR_2 = 'LSR-2';
export const EMBOSSING_LSR_2_MESSAGE = defineMessages({
  [EMBOSSING_LSR_2]: {
    id: "Embossing.lsr2",
    description: "Embossing value named LSR-2",
    defaultMessage: "lsr-2"
  }
});
export const EMBOSSING_M_10 = 'M 10';
export const EMBOSSING_M_10_MESSAGE = defineMessages({
  [EMBOSSING_M_10]: {
    id: "Embossing.m10",
    description: "Embossing value named M 10",
    defaultMessage: "m 10"
  }
});
export const EMBOSSING_M_11 = 'M 11';
export const EMBOSSING_M_11_MESSAGE = defineMessages({
  [EMBOSSING_M_11]: {
    id: "Embossing.m11",
    description: "Embossing value named M 11",
    defaultMessage: "m 11"
  }
});
export const EMBOSSING_M_13 = 'M 13';
export const EMBOSSING_M_13_MESSAGE = defineMessages({
  [EMBOSSING_M_13]: {
    id: "Embossing.m13",
    description: "Embossing value named M 13",
    defaultMessage: "m 13"
  }
});
export const EMBOSSING_M_14 = 'M 14';
export const EMBOSSING_M_14_MESSAGE = defineMessages({
  [EMBOSSING_M_14]: {
    id: "Embossing.m14",
    description: "Embossing value named M 14",
    defaultMessage: "m 14"
  }
});
export const EMBOSSING_M_15 = 'M 15';
export const EMBOSSING_M_15_MESSAGE = defineMessages({
  [EMBOSSING_M_15]: {
    id: "Embossing.m15",
    description: "Embossing value named M 15",
    defaultMessage: "m 15"
  }
});
export const EMBOSSING_M_16 = 'M 16';
export const EMBOSSING_M_16_MESSAGE = defineMessages({
  [EMBOSSING_M_16]: {
    id: "Embossing.m16",
    description: "Embossing value named M 16",
    defaultMessage: "m 16"
  }
});
export const EMBOSSING_M_17 = 'M 17';
export const EMBOSSING_M_17_MESSAGE = defineMessages({
  [EMBOSSING_M_17]: {
    id: "Embossing.m17",
    description: "Embossing value named M 17",
    defaultMessage: "m 17"
  }
});
export const EMBOSSING_M_18 = 'M 18';
export const EMBOSSING_M_18_MESSAGE = defineMessages({
  [EMBOSSING_M_18]: {
    id: "Embossing.m18",
    description: "Embossing value named M 18",
    defaultMessage: "m 18"
  }
});
export const EMBOSSING_M_2 = 'M 2';
export const EMBOSSING_M_2_MESSAGE = defineMessages({
  [EMBOSSING_M_2]: {
    id: "Embossing.m2",
    description: "Embossing value named M 2",
    defaultMessage: "m 2"
  }
});
export const EMBOSSING_M_2_CROYDON = 'M 2 CROYDON';
export const EMBOSSING_M_2_CROYDON_MESSAGE = defineMessages({
  [EMBOSSING_M_2_CROYDON]: {
    id: "Embossing.m2Croydon",
    description: "Embossing value named M 2 CROYDON",
    defaultMessage: "m 2 croydon"
  }
});
export const EMBOSSING_M_2_LIJA_A100 = 'M 2 LIJA A100';
export const EMBOSSING_M_2_LIJA_A100_MESSAGE = defineMessages({
  [EMBOSSING_M_2_LIJA_A100]: {
    id: "Embossing.m2LijaA100",
    description: "Embossing value named M 2 LIJA A100",
    defaultMessage: "m 2 lija a100"
  }
});
export const EMBOSSING_M_20 = 'M 20';
export const EMBOSSING_M_20_MESSAGE = defineMessages({
  [EMBOSSING_M_20]: {
    id: "Embossing.m20",
    description: "Embossing value named M 20",
    defaultMessage: "m 20"
  }
});
export const EMBOSSING_M_21 = 'M 21';
export const EMBOSSING_M_21_MESSAGE = defineMessages({
  [EMBOSSING_M_21]: {
    id: "Embossing.m21",
    description: "Embossing value named M 21",
    defaultMessage: "m 21"
  }
});
export const EMBOSSING_M_22 = 'M 22';
export const EMBOSSING_M_22_MESSAGE = defineMessages({
  [EMBOSSING_M_22]: {
    id: "Embossing.m22",
    description: "Embossing value named M 22",
    defaultMessage: "m 22"
  }
});
export const EMBOSSING_M_23 = 'M 23';
export const EMBOSSING_M_23_MESSAGE = defineMessages({
  [EMBOSSING_M_23]: {
    id: "Embossing.m23",
    description: "Embossing value named M 23",
    defaultMessage: "m 23"
  }
});
export const EMBOSSING_M_25 = 'M 25';
export const EMBOSSING_M_25_MESSAGE = defineMessages({
  [EMBOSSING_M_25]: {
    id: "Embossing.m25",
    description: "Embossing value named M 25",
    defaultMessage: "m 25"
  }
});
export const EMBOSSING_M_26 = 'M 26';
export const EMBOSSING_M_26_MESSAGE = defineMessages({
  [EMBOSSING_M_26]: {
    id: "Embossing.m26",
    description: "Embossing value named M 26",
    defaultMessage: "m 26"
  }
});
export const EMBOSSING_M_28 = 'M 28';
export const EMBOSSING_M_28_MESSAGE = defineMessages({
  [EMBOSSING_M_28]: {
    id: "Embossing.m28",
    description: "Embossing value named M 28",
    defaultMessage: "m 28"
  }
});
export const EMBOSSING_M_3 = 'M 3';
export const EMBOSSING_M_3_MESSAGE = defineMessages({
  [EMBOSSING_M_3]: {
    id: "Embossing.m3",
    description: "Embossing value named M 3",
    defaultMessage: "m 3"
  }
});
export const EMBOSSING_M_30 = 'M 30';
export const EMBOSSING_M_30_MESSAGE = defineMessages({
  [EMBOSSING_M_30]: {
    id: "Embossing.m30",
    description: "Embossing value named M 30",
    defaultMessage: "m 30"
  }
});
export const EMBOSSING_M_38 = 'M 38';
export const EMBOSSING_M_38_MESSAGE = defineMessages({
  [EMBOSSING_M_38]: {
    id: "Embossing.m38",
    description: "Embossing value named M 38",
    defaultMessage: "m 38"
  }
});
export const EMBOSSING_M_4 = 'M 4';
export const EMBOSSING_M_4_MESSAGE = defineMessages({
  [EMBOSSING_M_4]: {
    id: "Embossing.m4",
    description: "Embossing value named M 4",
    defaultMessage: "m 4"
  }
});
export const EMBOSSING_M_40 = 'M 40';
export const EMBOSSING_M_40_MESSAGE = defineMessages({
  [EMBOSSING_M_40]: {
    id: "Embossing.m40",
    description: "Embossing value named M 40",
    defaultMessage: "m 40"
  }
});
export const EMBOSSING_M_41 = 'M 41';
export const EMBOSSING_M_41_MESSAGE = defineMessages({
  [EMBOSSING_M_41]: {
    id: "Embossing.m41",
    description: "Embossing value named M 41",
    defaultMessage: "m 41"
  }
});
export const EMBOSSING_M_42 = 'M 42';
export const EMBOSSING_M_42_MESSAGE = defineMessages({
  [EMBOSSING_M_42]: {
    id: "Embossing.m42",
    description: "Embossing value named M 42",
    defaultMessage: "m 42"
  }
});
export const EMBOSSING_M_43 = 'M 43';
export const EMBOSSING_M_43_MESSAGE = defineMessages({
  [EMBOSSING_M_43]: {
    id: "Embossing.m43",
    description: "Embossing value named M 43",
    defaultMessage: "m 43"
  }
});
export const EMBOSSING_M_44 = 'M 44';
export const EMBOSSING_M_44_MESSAGE = defineMessages({
  [EMBOSSING_M_44]: {
    id: "Embossing.m44",
    description: "Embossing value named M 44",
    defaultMessage: "m 44"
  }
});
export const EMBOSSING_M_45 = 'M 45';
export const EMBOSSING_M_45_MESSAGE = defineMessages({
  [EMBOSSING_M_45]: {
    id: "Embossing.m45",
    description: "Embossing value named M 45",
    defaultMessage: "m 45"
  }
});
export const EMBOSSING_M_46 = 'M 46';
export const EMBOSSING_M_46_MESSAGE = defineMessages({
  [EMBOSSING_M_46]: {
    id: "Embossing.m46",
    description: "Embossing value named M 46",
    defaultMessage: "m 46"
  }
});
export const EMBOSSING_M_48 = 'M 48';
export const EMBOSSING_M_48_MESSAGE = defineMessages({
  [EMBOSSING_M_48]: {
    id: "Embossing.m48",
    description: "Embossing value named M 48",
    defaultMessage: "m 48"
  }
});
export const EMBOSSING_M_5 = 'M 5';
export const EMBOSSING_M_5_MESSAGE = defineMessages({
  [EMBOSSING_M_5]: {
    id: "Embossing.m5",
    description: "Embossing value named M 5",
    defaultMessage: "m 5"
  }
});
export const EMBOSSING_M_50 = 'M 50';
export const EMBOSSING_M_50_MESSAGE = defineMessages({
  [EMBOSSING_M_50]: {
    id: "Embossing.m50",
    description: "Embossing value named M 50",
    defaultMessage: "m 50"
  }
});
export const EMBOSSING_M_53 = 'M 53';
export const EMBOSSING_M_53_MESSAGE = defineMessages({
  [EMBOSSING_M_53]: {
    id: "Embossing.m53",
    description: "Embossing value named M 53",
    defaultMessage: "m 53"
  }
});
export const EMBOSSING_M_54 = 'M 54';
export const EMBOSSING_M_54_MESSAGE = defineMessages({
  [EMBOSSING_M_54]: {
    id: "Embossing.m54",
    description: "Embossing value named M 54",
    defaultMessage: "m 54"
  }
});
export const EMBOSSING_M_55 = 'M 55';
export const EMBOSSING_M_55_MESSAGE = defineMessages({
  [EMBOSSING_M_55]: {
    id: "Embossing.m55",
    description: "Embossing value named M 55",
    defaultMessage: "m 55"
  }
});
export const EMBOSSING_M_56 = 'M 56';
export const EMBOSSING_M_56_MESSAGE = defineMessages({
  [EMBOSSING_M_56]: {
    id: "Embossing.m56",
    description: "Embossing value named M 56",
    defaultMessage: "m 56"
  }
});
export const EMBOSSING_M_6 = 'M 6';
export const EMBOSSING_M_6_MESSAGE = defineMessages({
  [EMBOSSING_M_6]: {
    id: "Embossing.m6",
    description: "Embossing value named M 6",
    defaultMessage: "m 6"
  }
});
export const EMBOSSING_M_7 = 'M 7';
export const EMBOSSING_M_7_MESSAGE = defineMessages({
  [EMBOSSING_M_7]: {
    id: "Embossing.m7",
    description: "Embossing value named M 7",
    defaultMessage: "m 7"
  }
});
export const EMBOSSING_M_8 = 'M 8';
export const EMBOSSING_M_8_MESSAGE = defineMessages({
  [EMBOSSING_M_8]: {
    id: "Embossing.m8",
    description: "Embossing value named M 8",
    defaultMessage: "m 8"
  }
});
export const EMBOSSING_M_9 = 'M 9';
export const EMBOSSING_M_9_MESSAGE = defineMessages({
  [EMBOSSING_M_9]: {
    id: "Embossing.m9",
    description: "Embossing value named M 9",
    defaultMessage: "m 9"
  }
});
export const EMBOSSING_M114_FP = 'M114 FP';
export const EMBOSSING_M114_FP_MESSAGE = defineMessages({
  [EMBOSSING_M114_FP]: {
    id: "Embossing.m114Fp",
    description: "Embossing value named M114 FP",
    defaultMessage: "m114 fp"
  }
});
export const EMBOSSING_M_51 = 'M-51';
export const EMBOSSING_M_51_MESSAGE = defineMessages({
  [EMBOSSING_M_51]: {
    id: "Embossing.m51",
    description: "Embossing value named M-51",
    defaultMessage: "m-51"
  }
});
export const EMBOSSING_M_52 = 'M-52';
export const EMBOSSING_M_52_MESSAGE = defineMessages({
  [EMBOSSING_M_52]: {
    id: "Embossing.m52",
    description: "Embossing value named M-52",
    defaultMessage: "m-52"
  }
});
export const EMBOSSING_M_59 = 'M-59';
export const EMBOSSING_M_59_MESSAGE = defineMessages({
  [EMBOSSING_M_59]: {
    id: "Embossing.m59",
    description: "Embossing value named M-59",
    defaultMessage: "m-59"
  }
});
export const EMBOSSING_M_60 = 'M-60';
export const EMBOSSING_M_60_MESSAGE = defineMessages({
  [EMBOSSING_M_60]: {
    id: "Embossing.m60",
    description: "Embossing value named M-60",
    defaultMessage: "m-60"
  }
});
export const EMBOSSING_M87_ELH = 'M87 ELH';
export const EMBOSSING_M87_ELH_MESSAGE = defineMessages({
  [EMBOSSING_M87_ELH]: {
    id: "Embossing.m87Elh",
    description: "Embossing value named M87 ELH",
    defaultMessage: "m87 elh"
  }
});
export const EMBOSSING_M92_REN = 'M92 REN';
export const EMBOSSING_M92_REN_MESSAGE = defineMessages({
  [EMBOSSING_M92_REN]: {
    id: "Embossing.m92Ren",
    description: "Embossing value named M92 REN",
    defaultMessage: "m92 ren"
  }
});
export const EMBOSSING_MACARENA = 'MACARENA';
export const EMBOSSING_MACARENA_MESSAGE = defineMessages({
  [EMBOSSING_MACARENA]: {
    id: "Embossing.macarena",
    description: "Embossing value named MACARENA",
    defaultMessage: "macarena"
  }
});
export const EMBOSSING_MADRAS = 'MADRAS';
export const EMBOSSING_MADRAS_MESSAGE = defineMessages({
  [EMBOSSING_MADRAS]: {
    id: "Embossing.madras",
    description: "Embossing value named MADRAS",
    defaultMessage: "madras"
  }
});
export const EMBOSSING_MADRAS_MATE_360 = 'MADRAS MATE-360';
export const EMBOSSING_MADRAS_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_MADRAS_MATE_360]: {
    id: "Embossing.madrasMate_360",
    description: "Embossing value named MADRAS MATE-360",
    defaultMessage: "madras mate-360"
  }
});
export const EMBOSSING_MADRID = 'MADRID';
export const EMBOSSING_MADRID_MESSAGE = defineMessages({
  [EMBOSSING_MADRID]: {
    id: "Embossing.madrid",
    description: "Embossing value named MADRID",
    defaultMessage: "madrid"
  }
});
export const EMBOSSING_MADRID_II = 'MADRID II';
export const EMBOSSING_MADRID_II_MESSAGE = defineMessages({
  [EMBOSSING_MADRID_II]: {
    id: "Embossing.madridII",
    description: "Embossing value named MADRID II",
    defaultMessage: "madrid ii"
  }
});
export const EMBOSSING_MADRID_MATE_360_BEMA = 'MADRID MATE-360 BEMA';
export const EMBOSSING_MADRID_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_MADRID_MATE_360_BEMA]: {
    id: "Embossing.madridMate_360Bema",
    description: "Embossing value named MADRID MATE-360 BEMA",
    defaultMessage: "madrid mate-360 bema"
  }
});
export const EMBOSSING_MADRID_VIEJO = 'MADRID VIEJO';
export const EMBOSSING_MADRID_VIEJO_MESSAGE = defineMessages({
  [EMBOSSING_MADRID_VIEJO]: {
    id: "Embossing.madridViejo",
    description: "Embossing value named MADRID VIEJO",
    defaultMessage: "madrid viejo"
  }
});
export const EMBOSSING_MANGO = 'MANGO';
export const EMBOSSING_MANGO_MESSAGE = defineMessages({
  [EMBOSSING_MANGO]: {
    id: "Embossing.mango",
    description: "Embossing value named MANGO",
    defaultMessage: "mango"
  }
});
export const EMBOSSING_MARILYN = 'MARILYN';
export const EMBOSSING_MARILYN_MESSAGE = defineMessages({
  [EMBOSSING_MARILYN]: {
    id: "Embossing.marilyn",
    description: "Embossing value named MARILYN",
    defaultMessage: "marilyn"
  }
});
export const EMBOSSING_MARILYN_ROMBOS = 'MARILYN ROMBOS';
export const EMBOSSING_MARILYN_ROMBOS_MESSAGE = defineMessages({
  [EMBOSSING_MARILYN_ROMBOS]: {
    id: "Embossing.marilynRombos",
    description: "Embossing value named MARILYN ROMBOS",
    defaultMessage: "marilyn rombos"
  }
});
export const EMBOSSING_MARROCO = 'MARROCO';
export const EMBOSSING_MARROCO_MESSAGE = defineMessages({
  [EMBOSSING_MARROCO]: {
    id: "Embossing.marroco",
    description: "Embossing value named MARROCO",
    defaultMessage: "marroco"
  }
});
export const EMBOSSING_MARROCO_GAMUZA_2003_2 = 'MARROCO GAMUZA 2003#2';
export const EMBOSSING_MARROCO_GAMUZA_2003_2_MESSAGE = defineMessages({
  [EMBOSSING_MARROCO_GAMUZA_2003_2]: {
    id: "Embossing.marrocoGamuza2003#2",
    description: "Embossing value named MARROCO GAMUZA 2003#2",
    defaultMessage: "marroco gamuza 2003#2"
  }
});
export const EMBOSSING_MARROCO_LIJA_A100 = 'MARROCO LIJA A100';
export const EMBOSSING_MARROCO_LIJA_A100_MESSAGE = defineMessages({
  [EMBOSSING_MARROCO_LIJA_A100]: {
    id: "Embossing.marrocoLijaA100",
    description: "Embossing value named MARROCO LIJA A100",
    defaultMessage: "marroco lija a100"
  }
});
export const EMBOSSING_MATE = 'MATE';
export const EMBOSSING_MATE_MESSAGE = defineMessages({
  [EMBOSSING_MATE]: {
    id: "Embossing.mate",
    description: "Embossing value named MATE",
    defaultMessage: "mate"
  }
});
export const EMBOSSING_MATE_CAPSULA = 'MATE CAPSULA';
export const EMBOSSING_MATE_CAPSULA_MESSAGE = defineMessages({
  [EMBOSSING_MATE_CAPSULA]: {
    id: "Embossing.mateCapsula",
    description: "Embossing value named MATE CAPSULA",
    defaultMessage: "mate capsula"
  }
});
export const EMBOSSING_MATE_SAMBL_GF_MATE_250 = 'MATE SAMBL. GF MATE-250';
export const EMBOSSING_MATE_SAMBL_GF_MATE_250_MESSAGE = defineMessages({
  [EMBOSSING_MATE_SAMBL_GF_MATE_250]: {
    id: "Embossing.mateSamblGfMate_250",
    description: "Embossing value named MATE SAMBL. GF MATE-250",
    defaultMessage: "mate sambl. gf mate-250"
  }
});
export const EMBOSSING_MATE_250 = 'MATE-250';
export const EMBOSSING_MATE_250_MESSAGE = defineMessages({
  [EMBOSSING_MATE_250]: {
    id: "Embossing.mate250",
    description: "Embossing value named MATE-250",
    defaultMessage: "mate-250"
  }
});
export const EMBOSSING_MATE_360 = 'MATE-360';
export const EMBOSSING_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360]: {
    id: "Embossing.mate360",
    description: "Embossing value named MATE-360",
    defaultMessage: "mate-360"
  }
});
export const EMBOSSING_MATE_360_BEMA = 'MATE-360 BEMA';
export const EMBOSSING_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA]: {
    id: "Embossing.mate360Bema",
    description: "Embossing value named MATE-360 BEMA",
    defaultMessage: "mate-360 bema"
  }
});
export const EMBOSSING_MATE_360_BEMA_DELFIN = 'MATE-360 BEMA DELFIN';
export const EMBOSSING_MATE_360_BEMA_DELFIN_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_DELFIN]: {
    id: "Embossing.mate360BemaDelfin",
    description: "Embossing value named MATE-360 BEMA DELFIN",
    defaultMessage: "mate-360 bema delfin"
  }
});
export const EMBOSSING_MATE_360_BEMA_ESTRELLAS = 'MATE-360 BEMA ESTRELLAS';
export const EMBOSSING_MATE_360_BEMA_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_ESTRELLAS]: {
    id: "Embossing.mate360BemaEstrellas",
    description: "Embossing value named MATE-360 BEMA ESTRELLAS",
    defaultMessage: "mate-360 bema estrellas"
  }
});
export const EMBOSSING_MATE_360_BEMA_ESTRELLAS_DURATEX = 'MATE-360 BEMA ESTRELLAS DURATEX';
export const EMBOSSING_MATE_360_BEMA_ESTRELLAS_DURATEX_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_ESTRELLAS_DURATEX]: {
    id: "Embossing.mate_360BemaEstrellasDuratex",
    description: "Embossing value named MATE-360 BEMA ESTRELLAS DURATEX",
    defaultMessage: "mate-360 bema estrellas duratex"
  }
});
export const EMBOSSING_MATE_360_BEMA_GOAT = 'MATE-360 BEMA GOAT';
export const EMBOSSING_MATE_360_BEMA_GOAT_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_GOAT]: {
    id: "Embossing.mate_360BemaGoat",
    description: "Embossing value named MATE-360 BEMA GOAT",
    defaultMessage: "mate-360 bema goat"
  }
});
export const EMBOSSING_MATE_360_BEMA_IMPALA = 'MATE-360 BEMA IMPALA';
export const EMBOSSING_MATE_360_BEMA_IMPALA_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_IMPALA]: {
    id: "Embossing.mate360BemaImpala",
    description: "Embossing value named MATE-360 BEMA IMPALA",
    defaultMessage: "mate-360 bema impala"
  }
});
export const EMBOSSING_MATE_360_BEMA_LEVANT = 'MATE-360 BEMA LEVANT';
export const EMBOSSING_MATE_360_BEMA_LEVANT_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_LEVANT]: {
    id: "Embossing.mate360BemaLevant",
    description: "Embossing value named MATE-360 BEMA LEVANT",
    defaultMessage: "mate-360 bema levant"
  }
});
export const EMBOSSING_MATE_360_BEMA_LISBOA = 'MATE-360 BEMA LISBOA';
export const EMBOSSING_MATE_360_BEMA_LISBOA_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_LISBOA]: {
    id: "Embossing.mate360BemaLisboa",
    description: "Embossing value named MATE-360 BEMA LISBOA",
    defaultMessage: "mate-360 bema lisboa"
  }
});
export const EMBOSSING_MATE_360_BEMA_MATE_360 = 'MATE-360 BEMA MATE-360';
export const EMBOSSING_MATE_360_BEMA_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_MATE_360]: {
    id: "Embossing.mate360BemaMate360",
    description: "Embossing value named MATE-360 BEMA MATE-360",
    defaultMessage: "mate-360 bema mate-360"
  }
});
export const EMBOSSING_MATE_360_BEMA_QUETZAL_LINE = 'MATE-360 BEMA QUETZAL LINE';
export const EMBOSSING_MATE_360_BEMA_QUETZAL_LINE_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_QUETZAL_LINE]: {
    id: "Embossing.mate360BemaQuetzalLine",
    description: "Embossing value named MATE-360 BEMA QUETZAL LINE",
    defaultMessage: "mate-360 bema quetzal line"
  }
});
export const EMBOSSING_MATE_360_BEMA_SULTAN = 'MATE-360 BEMA SULTAN';
export const EMBOSSING_MATE_360_BEMA_SULTAN_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_SULTAN]: {
    id: "Embossing.mate360bEmasUltan",
    description: "Embossing value named MATE-360 BEMA SULTAN",
    defaultMessage: "mate-360 bema sultan"
  }
});
export const EMBOSSING_MATE_360_BEMA_TENTACION_G = 'MATE-360 BEMA TENTACION (G)';
export const EMBOSSING_MATE_360_BEMA_TENTACION_G_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_TENTACION_G]: {
    id: "Embossing.mate360BemaTentacion",
    description: "Embossing value named MATE-360 BEMA TENTACION (G)",
    defaultMessage: "mate-360 bema tentacion (g)"
  }
});
export const EMBOSSING_MATE_360_BEMA_WARD = 'MATE-360 BEMA WARD';
export const EMBOSSING_MATE_360_BEMA_WARD_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_WARD]: {
    id: "Embossing.mate360BemaWard",
    description: "Embossing value named MATE-360 BEMA WARD",
    defaultMessage: "mate-360 bema ward"
  }
});
export const EMBOSSING_MATE_360_BEMA_WUMAG = 'MATE-360 BEMA WUMAG';
export const EMBOSSING_MATE_360_BEMA_WUMAG_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_BEMA_WUMAG]: {
    id: "Embossing.mate360BemaWumag",
    description: "Embossing value named MATE-360 BEMA WUMAG",
    defaultMessage: "mate-360 bema wumag"
  }
});
export const EMBOSSING_MATE_360_IMPALA = 'MATE-360 IMPALA';
export const EMBOSSING_MATE_360_IMPALA_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_IMPALA]: {
    id: "Embossing.mate360Impala",
    description: "Embossing value named MATE-360 IMPALA",
    defaultMessage: "mate-360 impala"
  }
});
export const EMBOSSING_MATE_360_LEVANT = 'MATE-360 LEVANT';
export const EMBOSSING_MATE_360_LEVANT_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_LEVANT]: {
    id: "Embossing.mate360Levant",
    description: "Embossing value named MATE-360 LEVANT",
    defaultMessage: "mate-360 levant"
  }
});
export const EMBOSSING_MATE_360_MOQUETA = 'MATE-360 MOQUETA';
export const EMBOSSING_MATE_360_MOQUETA_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_MOQUETA]: {
    id: "Embossing.mate360Moqueta",
    description: "Embossing value named MATE-360 MOQUETA",
    defaultMessage: "mate-360 moqueta"
  }
});
export const EMBOSSING_MATE_360_PINT_SB_CARB = 'MATE-360 PINT SB CARB.';
export const EMBOSSING_MATE_360_PINT_SB_CARB_MESSAGE = defineMessages({
  [EMBOSSING_MATE_360_PINT_SB_CARB]: {
    id: "Embossing.mate360PintSbCarb.",
    description: "Embossing value named MATE-360 PINT SB CARB.",
    defaultMessage: "mate-360 pint sb carb."
  }
});
export const EMBOSSING_MAYA = 'MAYA';
export const EMBOSSING_MAYA_MESSAGE = defineMessages({
  [EMBOSSING_MAYA]: {
    id: "Embossing.maya",
    description: "Embossing value named MAYA",
    defaultMessage: "maya"
  }
});
export const EMBOSSING_MAYA_MATE_360_BEMA = 'MAYA MATE-360 BEMA';
export const EMBOSSING_MAYA_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_MAYA_MATE_360_BEMA]: {
    id: "Embossing.mayaMate360Bema",
    description: "Embossing value named MAYA MATE-360 BEMA",
    defaultMessage: "maya mate-360 bema"
  }
});
export const EMBOSSING_MICHAEL = 'MICHAEL';
export const EMBOSSING_MICHAEL_MESSAGE = defineMessages({
  [EMBOSSING_MICHAEL]: {
    id: "Embossing.michael",
    description: "Embossing value named MICHAEL",
    defaultMessage: "michael"
  }
});
export const EMBOSSING_MICHAEL_ESTRELLAS = 'MICHAEL ESTRELLAS';
export const EMBOSSING_MICHAEL_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_MICHAEL_ESTRELLAS]: {
    id: "Embossing.michaelEstrellas",
    description: "Embossing value named MICHAEL ESTRELLAS",
    defaultMessage: "michael estrellas"
  }
});
export const EMBOSSING_MILLED_PEBBLE = 'MILLED PEBBLE';
export const EMBOSSING_MILLED_PEBBLE_MESSAGE = defineMessages({
  [EMBOSSING_MILLED_PEBBLE]: {
    id: "Embossing.milledPebble",
    description: "Embossing value named MILLED PEBBLE",
    defaultMessage: "milled pebble"
  }
});
export const EMBOSSING_MIMBRE = 'MIMBRE';
export const EMBOSSING_MIMBRE_MESSAGE = defineMessages({
  [EMBOSSING_MIMBRE]: {
    id: "Embossing.mimbre",
    description: "Embossing value named MIMBRE",
    defaultMessage: "mimbre"
  }
});
export const EMBOSSING_MINICHECK = 'MINICHECK';
export const EMBOSSING_MINICHECK_MESSAGE = defineMessages({
  [EMBOSSING_MINICHECK]: {
    id: "Embossing.minicheck",
    description: "Embossing value named MINICHECK",
    defaultMessage: "minicheck"
  }
});
export const EMBOSSING_MINICHECK_BRILLANTE = 'MINICHECK BRILLANTE';
export const EMBOSSING_MINICHECK_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_MINICHECK_BRILLANTE]: {
    id: "Embossing.minicheckBrillante",
    description: "Embossing value named MINICHECK BRILLANTE",
    defaultMessage: "minicheck brillante"
  }
});
export const EMBOSSING_MINK = 'MINK';
export const EMBOSSING_MINK_MESSAGE = defineMessages({
  [EMBOSSING_MINK]: {
    id: "Embossing.mink",
    description: "Embossing value named MINK",
    defaultMessage: "mink"
  }
});
export const EMBOSSING_MOCASIN = 'MOCASIN';
export const EMBOSSING_MOCASIN_MESSAGE = defineMessages({
  [EMBOSSING_MOCASIN]: {
    id: "Embossing.mocasin",
    description: "Embossing value named MOCASIN",
    defaultMessage: "mocasin"
  }
});
export const EMBOSSING_MOCASIN_IMPALA = 'MOCASIN IMPALA';
export const EMBOSSING_MOCASIN_IMPALA_MESSAGE = defineMessages({
  [EMBOSSING_MOCASIN_IMPALA]: {
    id: "Embossing.mocasinImpala",
    description: "Embossing value named MOCASIN IMPALA",
    defaultMessage: "mocasin impala"
  }
});
export const EMBOSSING_MOJAVE = 'MOJAVE';
export const EMBOSSING_MOJAVE_MESSAGE = defineMessages({
  [EMBOSSING_MOJAVE]: {
    id: "Embossing.mojave",
    description: "Embossing value named MOJAVE",
    defaultMessage: "mojave"
  }
});
export const EMBOSSING_MOLETTE = 'MOLETTE';
export const EMBOSSING_MOLETTE_MESSAGE = defineMessages({
  [EMBOSSING_MOLETTE]: {
    id: "Embossing.molette",
    description: "Embossing value named MOLETTE",
    defaultMessage: "molette"
  }
});
export const EMBOSSING_MONACO = 'MONACO';
export const EMBOSSING_MONACO_MESSAGE = defineMessages({
  [EMBOSSING_MONACO]: {
    id: "Embossing.monaco",
    description: "Embossing value named MONACO",
    defaultMessage: "monaco"
  }
});
export const EMBOSSING_MONTANA = 'MONTANA';
export const EMBOSSING_MONTANA_MESSAGE = defineMessages({
  [EMBOSSING_MONTANA]: {
    id: "Embossing.montana",
    description: "Embossing value named MONTANA",
    defaultMessage: "montana"
  }
});
export const EMBOSSING_MONTANA_180 = 'MONTANA-180';
export const EMBOSSING_MONTANA_180_MESSAGE = defineMessages({
  [EMBOSSING_MONTANA_180]: {
    id: "Embossing.montana180",
    description: "Embossing value named MONTANA-180",
    defaultMessage: "montana-180"
  }
});
export const EMBOSSING_MONTEREY = 'MONTEREY';
export const EMBOSSING_MONTEREY_MESSAGE = defineMessages({
  [EMBOSSING_MONTEREY]: {
    id: "Embossing.monterey",
    description: "Embossing value named MONTEREY",
    defaultMessage: "monterey"
  }
});
export const EMBOSSING_MONTICELLO = 'MONTICELLO';
export const EMBOSSING_MONTICELLO_MESSAGE = defineMessages({
  [EMBOSSING_MONTICELLO]: {
    id: "Embossing.monticello",
    description: "Embossing value named MONTICELLO",
    defaultMessage: "monticello"
  }
});
export const EMBOSSING_MOQUETA = 'MOQUETA';
export const EMBOSSING_MOQUETA_MESSAGE = defineMessages({
  [EMBOSSING_MOQUETA]: {
    id: "Embossing.moqueta",
    description: "Embossing value named MOQUETA",
    defaultMessage: "moqueta"
  }
});
export const EMBOSSING_MOSAICO = 'MOSAICO';
export const EMBOSSING_MOSAICO_MESSAGE = defineMessages({
  [EMBOSSING_MOSAICO]: {
    id: "Embossing.mosaico",
    description: "Embossing value named MOSAICO",
    defaultMessage: "mosaico"
  }
});
export const EMBOSSING_NAPOLES = 'NAPOLES';
export const EMBOSSING_NAPOLES_MESSAGE = defineMessages({
  [EMBOSSING_NAPOLES]: {
    id: "Embossing.napoles",
    description: "Embossing value named NAPOLES",
    defaultMessage: "napoles"
  }
});
export const EMBOSSING_NEPTUNO = 'NEPTUNO';
export const EMBOSSING_NEPTUNO_MESSAGE = defineMessages({
  [EMBOSSING_NEPTUNO]: {
    id: "Embossing.neptuno",
    description: "Embossing value named NEPTUNO",
    defaultMessage: "neptuno"
  }
});
export const EMBOSSING_NEW_MITCH = 'NEW MITCH';
export const EMBOSSING_NEW_MITCH_MESSAGE = defineMessages({
  [EMBOSSING_NEW_MITCH]: {
    id: "Embossing.newMitch",
    description: "Embossing value named NEW MITCH",
    defaultMessage: "new mitch"
  }
});
export const EMBOSSING_NO_APLICA = 'NO APLICA';
export const EMBOSSING_NO_APLICA_MESSAGE = defineMessages({
  [EMBOSSING_NO_APLICA]: {
    id: "Embossing.noAplica",
    description: "Embossing value named NO APLICA",
    defaultMessage: "no aplica"
  }
});
export const EMBOSSING_NUTRIA = 'NUTRIA';
export const EMBOSSING_NUTRIA_MESSAGE = defineMessages({
  [EMBOSSING_NUTRIA]: {
    id: "Embossing.nutria",
    description: "Embossing value named NUTRIA",
    defaultMessage: "nutria"
  }
});
export const EMBOSSING_OCAPI = 'OCAPI';
export const EMBOSSING_OCAPI_MESSAGE = defineMessages({
  [EMBOSSING_OCAPI]: {
    id: "Embossing.ocapi",
    description: "Embossing value named OCAPI",
    defaultMessage: "ocapi"
  }
});
export const EMBOSSING_OLD_WARD = 'OLD WARD';
export const EMBOSSING_OLD_WARD_MESSAGE = defineMessages({
  [EMBOSSING_OLD_WARD]: {
    id: "Embossing.oldWard",
    description: "Embossing value named OLD WARD",
    defaultMessage: "old ward"
  }
});
export const EMBOSSING_PAMPERO = 'PAMPERO';
export const EMBOSSING_PAMPERO_MESSAGE = defineMessages({
  [EMBOSSING_PAMPERO]: {
    id: "Embossing.pampero",
    description: "Embossing value named PAMPERO",
    defaultMessage: "pampero"
  }
});
export const EMBOSSING_PAMPERO_G = 'PAMPERO (G)';
export const EMBOSSING_PAMPERO_G_MESSAGE = defineMessages({
  [EMBOSSING_PAMPERO_G]: {
    id: "Embossing.pamperoG",
    description: "Embossing value named PAMPERO (G)",
    defaultMessage: "pampero (g)"
  }
});
export const EMBOSSING_PAMPERO_G_MATE = 'PAMPERO (G) MATE';
export const EMBOSSING_PAMPERO_G_MATE_MESSAGE = defineMessages({
  [EMBOSSING_PAMPERO_G_MATE]: {
    id: "Embossing.pamperoGMate",
    description: "Embossing value named PAMPERO (G) MATE",
    defaultMessage: "pampero (g) mate"
  }
});
export const EMBOSSING_PANA = 'PANA';
export const EMBOSSING_PANA_MESSAGE = defineMessages({
  [EMBOSSING_PANA]: {
    id: "Embossing.pana",
    description: "Embossing value named PANA",
    defaultMessage: "pana"
  }
});
export const EMBOSSING_PANAMA = 'PANAMA';
export const EMBOSSING_PANAMA_MESSAGE = defineMessages({
  [EMBOSSING_PANAMA]: {
    id: "Embossing.panama",
    description: "Embossing value named PANAMA",
    defaultMessage: "panama"
  }
});
export const EMBOSSING_PANNA_SILK = 'PANNA SILK';
export const EMBOSSING_PANNA_SILK_MESSAGE = defineMessages({
  [EMBOSSING_PANNA_SILK]: {
    id: "Embossing.pannaSilk",
    description: "Embossing value named PANNA SILK",
    defaultMessage: "panna silk"
  }
});
export const EMBOSSING_PANORAMA = 'PANORAMA';
export const EMBOSSING_PANORAMA_MESSAGE = defineMessages({
  [EMBOSSING_PANORAMA]: {
    id: "Embossing.panorama",
    description: "Embossing value named PANORAMA",
    defaultMessage: "panorama"
  }
});
export const EMBOSSING_PAPIRO = 'PAPIRO';
export const EMBOSSING_PAPIRO_MESSAGE = defineMessages({
  [EMBOSSING_PAPIRO]: {
    id: "Embossing.papiro",
    description: "Embossing value named PAPIRO",
    defaultMessage: "papiro"
  }
});
export const EMBOSSING_PEBBLE_GRAIN = 'PEBBLE GRAIN';
export const EMBOSSING_PEBBLE_GRAIN_MESSAGE = defineMessages({
  [EMBOSSING_PEBBLE_GRAIN]: {
    id: "Embossing.pebbleGrain",
    description: "Embossing value named PEBBLE GRAIN",
    defaultMessage: "pebble grain"
  }
});
export const EMBOSSING_PECARI = 'PECARI';
export const EMBOSSING_PECARI_MESSAGE = defineMessages({
  [EMBOSSING_PECARI]: {
    id: "Embossing.pecari",
    description: "Embossing value named PECARI",
    defaultMessage: "pecari"
  }
});
export const EMBOSSING_PECARI_M_7 = 'PECARI M 7';
export const EMBOSSING_PECARI_M_7_MESSAGE = defineMessages({
  [EMBOSSING_PECARI_M_7]: {
    id: "Embossing.pecariM7",
    description: "Embossing value named PECARI M 7",
    defaultMessage: "pecari m 7"
  }
});
export const EMBOSSING_PECARI_MATE_360_BEMA = 'PECARI MATE-360 BEMA';
export const EMBOSSING_PECARI_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_PECARI_MATE_360_BEMA]: {
    id: "Embossing.pecariMate360Bema",
    description: "Embossing value named PECARI MATE-360 BEMA",
    defaultMessage: "pecari mate-360 bema"
  }
});
export const EMBOSSING_PECARI_180 = 'PECARI-180';
export const EMBOSSING_PECARI_180_MESSAGE = defineMessages({
  [EMBOSSING_PECARI_180]: {
    id: "Embossing.pecari180",
    description: "Embossing value named PECARI-180",
    defaultMessage: "pecari-180"
  }
});
export const EMBOSSING_PIEL = 'PIEL';
export const EMBOSSING_PIEL_MESSAGE = defineMessages({
  [EMBOSSING_PIEL]: {
    id: "Embossing.piel",
    description: "Embossing value named PIEL",
    defaultMessage: "piel"
  }
});
export const EMBOSSING_PIEL_DE_SAPA = 'PIEL DE SAPA';
export const EMBOSSING_PIEL_DE_SAPA_MESSAGE = defineMessages({
  [EMBOSSING_PIEL_DE_SAPA]: {
    id: "Embossing.pielDeSapa",
    description: "Embossing value named PIEL DE SAPA",
    defaultMessage: "piel de sapa"
  }
});
export const EMBOSSING_PINT_SB = 'PINT SB';
export const EMBOSSING_PINT_SB_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB]: {
    id: "Embossing.pintSb",
    description: "Embossing value named PINT SB",
    defaultMessage: "pint sb"
  }
});
export const EMBOSSING_PINT_SB_CARB = 'PINT SB CARB.';
export const EMBOSSING_PINT_SB_CARB_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB_CARB]: {
    id: "Embossing.pintSbCarb.",
    description: "Embossing value named PINT SB CARB.",
    defaultMessage: "pint sb carb."
  }
});
export const EMBOSSING_PINT_SB_ESP = 'PINT SB ESP.';
export const EMBOSSING_PINT_SB_ESP_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB_ESP]: {
    id: "Embossing.pintSbEsp.",
    description: "Embossing value named PINT SB ESP.",
    defaultMessage: "pint sb esp."
  }
});
export const EMBOSSING_PINT_SB_ESP_ESTRELLAS = 'PINT SB ESP. ESTRELLAS';
export const EMBOSSING_PINT_SB_ESP_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB_ESP_ESTRELLAS]: {
    id: "Embossing.pintSbEspEstrellas",
    description: "Embossing value named PINT SB ESP. ESTRELLAS",
    defaultMessage: "pint sb esp. estrellas"
  }
});
export const EMBOSSING_PINT_SB_ESP_MATE = 'PINT SB ESP. MATE';
export const EMBOSSING_PINT_SB_ESP_MATE_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB_ESP_MATE]: {
    id: "Embossing.pintSbEspMate",
    description: "Embossing value named PINT SB ESP. MATE",
    defaultMessage: "pint sb esp. mate"
  }
});
export const EMBOSSING_PINT_SB_ESP_MATE_360 = 'PINT SB ESP. MATE-360';
export const EMBOSSING_PINT_SB_ESP_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB_ESP_MATE_360]: {
    id: "Embossing.pintSbEspMate_360",
    description: "Embossing value named PINT SB ESP. MATE-360",
    defaultMessage: "pint sb esp. mate-360"
  }
});
export const EMBOSSING_PINT_SB_ESP_MATE_360_BEMA = 'PINT SB ESP. MATE-360 BEMA';
export const EMBOSSING_PINT_SB_ESP_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB_ESP_MATE_360_BEMA]: {
    id: "Embossing.pintSbEspMate_360Bema",
    description: "Embossing value named PINT SB ESP. MATE-360 BEMA",
    defaultMessage: "pint sb esp. mate-360 bema"
  }
});
export const EMBOSSING_PINT_SB_ESTRELLAS = 'PINT SB ESTRELLAS';
export const EMBOSSING_PINT_SB_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB_ESTRELLAS]: {
    id: "Embossing.pintSbEstrellas",
    description: "Embossing value named PINT SB ESTRELLAS",
    defaultMessage: "pint sb estrellas"
  }
});
export const EMBOSSING_PINT_SB_MATE_360 = 'PINT SB MATE-360';
export const EMBOSSING_PINT_SB_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB_MATE_360]: {
    id: "Embossing.pintSbMate360",
    description: "Embossing value named PINT SB MATE-360",
    defaultMessage: "pint sb mate-360"
  }
});
export const EMBOSSING_PINT_SB_MATE_360_BEMA = 'PINT SB MATE-360 BEMA';
export const EMBOSSING_PINT_SB_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_PINT_SB_MATE_360_BEMA]: {
    id: "Embossing.pintSbMate360Bema",
    description: "Embossing value named PINT SB MATE-360 BEMA",
    defaultMessage: "pint sb mate-360 bema"
  }
});
export const EMBOSSING_PIQUE = 'PIQUE';
export const EMBOSSING_PIQUE_MESSAGE = defineMessages({
  [EMBOSSING_PIQUE]: {
    id: "Embossing.pique",
    description: "Embossing value named PIQUE",
    defaultMessage: "pique"
  }
});
export const EMBOSSING_PIQUE_MATE_360_BEMA = 'PIQUE MATE-360 BEMA';
export const EMBOSSING_PIQUE_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_PIQUE_MATE_360_BEMA]: {
    id: "Embossing.piqueMate360Bema",
    description: "Embossing value named PIQUE MATE-360 BEMA",
    defaultMessage: "pique mate-360 bema"
  }
});
export const EMBOSSING_PITAGORAS = 'PITAGORAS';
export const EMBOSSING_PITAGORAS_MESSAGE = defineMessages({
  [EMBOSSING_PITAGORAS]: {
    id: "Embossing.pitagoras",
    description: "Embossing value named PITAGORAS",
    defaultMessage: "pitagoras"
  }
});
export const EMBOSSING_PORSCHE = 'PORSCHE';
export const EMBOSSING_PORSCHE_MESSAGE = defineMessages({
  [EMBOSSING_PORSCHE]: {
    id: "Embossing.porsche",
    description: "Embossing value named PORSCHE",
    defaultMessage: "porsche"
  }
});
export const EMBOSSING_PREMIER = 'PREMIER';
export const EMBOSSING_PREMIER_MESSAGE = defineMessages({
  [EMBOSSING_PREMIER]: {
    id: "Embossing.premier",
    description: "Embossing value named PREMIER",
    defaultMessage: "premier"
  }
});
export const EMBOSSING_PREMIER_CUERO_DE_VACA_MATE_360_BEMA = 'PREMIER CUERO DE VACA MATE-360 BEMA';
export const EMBOSSING_PREMIER_CUERO_DE_VACA_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_PREMIER_CUERO_DE_VACA_MATE_360_BEMA]: {
    id: "Embossing.premierCueroDeVacaMate360Bema",
    description: "Embossing value named PREMIER CUERO DE VACA MATE-360 BEMA",
    defaultMessage: "premier cuero de vaca mate-360 bema"
  }
});
export const EMBOSSING_PREMIER_LIJA_A100 = 'PREMIER LIJA A100';
export const EMBOSSING_PREMIER_LIJA_A100_MESSAGE = defineMessages({
  [EMBOSSING_PREMIER_LIJA_A100]: {
    id: "Embossing.premierLijaA100",
    description: "Embossing value named PREMIER LIJA A100",
    defaultMessage: "premier lija a100"
  }
});
export const EMBOSSING_PRISMA = 'PRISMA';
export const EMBOSSING_PRISMA_MESSAGE = defineMessages({
  [EMBOSSING_PRISMA]: {
    id: "Embossing.prisma",
    description: "Embossing value named PRISMA",
    defaultMessage: "prisma"
  }
});
export const EMBOSSING_PRISMA_MATE = 'PRISMA MATE';
export const EMBOSSING_PRISMA_MATE_MESSAGE = defineMessages({
  [EMBOSSING_PRISMA_MATE]: {
    id: "Embossing.prismaMate",
    description: "Embossing value named PRISMA MATE",
    defaultMessage: "prisma mate"
  }
});
export const EMBOSSING_PUMA = 'PUMA';
export const EMBOSSING_PUMA_MESSAGE = defineMessages({
  [EMBOSSING_PUMA]: {
    id: "Embossing.puma",
    description: "Embossing value named PUMA",
    defaultMessage: "puma"
  }
});
export const EMBOSSING_PUMA_CIERVO = 'PUMA CIERVO';
export const EMBOSSING_PUMA_CIERVO_MESSAGE = defineMessages({
  [EMBOSSING_PUMA_CIERVO]: {
    id: "Embossing.pumaCiervo",
    description: "Embossing value named PUMA CIERVO",
    defaultMessage: "puma ciervo"
  }
});
export const EMBOSSING_PUMA_GAMUZA_2003_2 = 'PUMA GAMUZA 2003#2';
export const EMBOSSING_PUMA_GAMUZA_2003_2_MESSAGE = defineMessages({
  [EMBOSSING_PUMA_GAMUZA_2003_2]: {
    id: "Embossing.pumaGamuza2003_2",
    description: "Embossing value named PUMA GAMUZA 2003#2",
    defaultMessage: "puma gamuza 2003#2"
  }
});
export const EMBOSSING_QUETZAL = 'QUETZAL';
export const EMBOSSING_QUETZAL_MESSAGE = defineMessages({
  [EMBOSSING_QUETZAL]: {
    id: "Embossing.quetzal",
    description: "Embossing value named QUETZAL",
    defaultMessage: "quetzal"
  }
});
export const EMBOSSING_QUETZAL_GAM_MET = 'QUETZAL GAM-MET.';
export const EMBOSSING_QUETZAL_GAM_MET_MESSAGE = defineMessages({
  [EMBOSSING_QUETZAL_GAM_MET]: {
    id: "Embossing.quetzalGamMet",
    description: "Embossing value named QUETZAL GAM-MET.",
    defaultMessage: "quetzal gam-met."
  }
});
export const EMBOSSING_QUETZAL_II = 'QUETZAL II';
export const EMBOSSING_QUETZAL_II_MESSAGE = defineMessages({
  [EMBOSSING_QUETZAL_II]: {
    id: "Embossing.quetzalII",
    description: "Embossing value named QUETZAL II",
    defaultMessage: "quetzal II"
  }
});
export const EMBOSSING_QUETZAL_LINE = 'QUETZAL LINE';
export const EMBOSSING_QUETZAL_LINE_MESSAGE = defineMessages({
  [EMBOSSING_QUETZAL_LINE]: {
    id: "Embossing.quetzalLine",
    description: "Embossing value named QUETZAL LINE",
    defaultMessage: "quetzal line"
  }
});
export const EMBOSSING_QUETZAL_MATE = 'QUETZAL MATE';
export const EMBOSSING_QUETZAL_MATE_MESSAGE = defineMessages({
  [EMBOSSING_QUETZAL_MATE]: {
    id: "Embossing.quetzalMate",
    description: "Embossing value named QUETZAL MATE",
    defaultMessage: "quetzal mate"
  }
});
export const EMBOSSING_QUETZAL_MATE_360_BEMA = 'QUETZAL MATE-360 BEMA';
export const EMBOSSING_QUETZAL_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_QUETZAL_MATE_360_BEMA]: {
    id: "Embossing.quetzalMate360Bema",
    description: "Embossing value named QUETZAL MATE-360 BEMA",
    defaultMessage: "quetzal mate-360 bema"
  }
});
export const EMBOSSING_RAYO = 'RAYO';
export const EMBOSSING_RAYO_MESSAGE = defineMessages({
  [EMBOSSING_RAYO]: {
    id: "Embossing.rayo",
    description: "Embossing value named RAYO",
    defaultMessage: "rayo"
  }
});
export const EMBOSSING_REBECO_SIL = 'REBECO-SIL';
export const EMBOSSING_REBECO_SIL_MESSAGE = defineMessages({
  [EMBOSSING_REBECO_SIL]: {
    id: "Embossing.rebeco_sil",
    description: "Embossing value named REBECO-SIL",
    defaultMessage: "rebeco-sil"
  }
});
export const EMBOSSING_RECANAL = 'RECANAL';
export const EMBOSSING_RECANAL_MESSAGE = defineMessages({
  [EMBOSSING_RECANAL]: {
    id: "Embossing.recanal",
    description: "Embossing value named RECANAL",
    defaultMessage: "recanal"
  }
});
export const EMBOSSING_RED = 'RED';
export const EMBOSSING_RED_MESSAGE = defineMessages({
  [EMBOSSING_RED]: {
    id: "Embossing.red",
    description: "Embossing value named RED",
    defaultMessage: "red"
  }
});
export const EMBOSSING_RENO = 'RENO';
export const EMBOSSING_RENO_MESSAGE = defineMessages({
  [EMBOSSING_RENO]: {
    id: "Embossing.reno",
    description: "Embossing value named RENO",
    defaultMessage: "reno"
  }
});
export const EMBOSSING_RENO_MATE_360_BEMA = 'RENO MATE-360 BEMA';
export const EMBOSSING_RENO_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_RENO_MATE_360_BEMA]: {
    id: "Embossing.renoMate360Bema",
    description: "Embossing value named RENO MATE-360 BEMA",
    defaultMessage: "reno mate-360 bema"
  }
});
export const EMBOSSING_RENO_TETRA = 'RENO TETRA';
export const EMBOSSING_RENO_TETRA_MESSAGE = defineMessages({
  [EMBOSSING_RENO_TETRA]: {
    id: "Embossing.renoTetra",
    description: "Embossing value named RENO TETRA",
    defaultMessage: "reno tetra"
  }
});
export const EMBOSSING_RICE_PLAIN_LINEN = 'RICE PLAIN LINEN';
export const EMBOSSING_RICE_PLAIN_LINEN_MESSAGE = defineMessages({
  [EMBOSSING_RICE_PLAIN_LINEN]: {
    id: "Embossing.ricePlainLinen",
    description: "Embossing value named RICE PLAIN LINEN",
    defaultMessage: "rice plain linen"
  }
});
export const EMBOSSING_RINO = 'RINO';
export const EMBOSSING_RINO_MESSAGE = defineMessages({
  [EMBOSSING_RINO]: {
    id: "Embossing.rino",
    description: "Embossing value named RINO",
    defaultMessage: "rino"
  }
});
export const EMBOSSING_ROLAND = 'ROLAND';
export const EMBOSSING_ROLAND_MESSAGE = defineMessages({
  [EMBOSSING_ROLAND]: {
    id: "Embossing.roland",
    description: "Embossing value named ROLAND",
    defaultMessage: "roland"
  }
});
export const EMBOSSING_ROMBO = 'ROMBO';
export const EMBOSSING_ROMBO_MESSAGE = defineMessages({
  [EMBOSSING_ROMBO]: {
    id: "Embossing.rombo",
    description: "Embossing value named ROMBO",
    defaultMessage: "rombo"
  }
});
export const EMBOSSING_ROMBO_MATE_360_BEMA = 'ROMBO MATE-360 BEMA';
export const EMBOSSING_ROMBO_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_ROMBO_MATE_360_BEMA]: {
    id: "Embossing.romboMate360Bema",
    description: "Embossing value named ROMBO MATE-360 BEMA",
    defaultMessage: "rombo mate-360 bema"
  }
});
export const EMBOSSING_ROMBOS = 'ROMBOS';
export const EMBOSSING_ROMBOS_MESSAGE = defineMessages({
  [EMBOSSING_ROMBOS]: {
    id: "Embossing.rombos",
    description: "Embossing value named ROMBOS",
    defaultMessage: "rombos"
  }
});
export const EMBOSSING_ROMBOTEX = 'ROMBOTEX';
export const EMBOSSING_ROMBOTEX_MESSAGE = defineMessages({
  [EMBOSSING_ROMBOTEX]: {
    id: "Embossing.rombotex",
    description: "Embossing value named ROMBOTEX",
    defaultMessage: "rombotex"
  }
});
export const EMBOSSING_ROSAS = 'ROSAS';
export const EMBOSSING_ROSAS_MESSAGE = defineMessages({
  [EMBOSSING_ROSAS]: {
    id: "Embossing.rosas",
    description: "Embossing value named ROSAS",
    defaultMessage: "rosas"
  }
});
export const EMBOSSING_ROSSETE = 'ROSSETE';
export const EMBOSSING_ROSSETE_MESSAGE = defineMessages({
  [EMBOSSING_ROSSETE]: {
    id: "Embossing.rossete",
    description: "Embossing value named ROSSETE",
    defaultMessage: "rossete"
  }
});
export const EMBOSSING_RR = 'RR';
export const EMBOSSING_RR_MESSAGE = defineMessages({
  [EMBOSSING_RR]: {
    id: "Embossing.rr",
    description: "Embossing value named RR",
    defaultMessage: "rr"
  }
});
export const EMBOSSING_RR_MATE_360_BEMA = 'RR MATE-360 BEMA';
export const EMBOSSING_RR_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_RR_MATE_360_BEMA]: {
    id: "Embossing.rrMate360Bema",
    description: "Embossing value named RR MATE-360 BEMA",
    defaultMessage: "rr mate-360 bema"
  }
});
export const EMBOSSING_RUFFINO = 'RUFFINO';
export const EMBOSSING_RUFFINO_MESSAGE = defineMessages({
  [EMBOSSING_RUFFINO]: {
    id: "Embossing.ruffino",
    description: "Embossing value named RUFFINO",
    defaultMessage: "ruffino"
  }
});
export const EMBOSSING_RUSTIC = 'RUSTIC';
export const EMBOSSING_RUSTIC_MESSAGE = defineMessages({
  [EMBOSSING_RUSTIC]: {
    id: "Embossing.rustic",
    description: "Embossing value named RUSTIC",
    defaultMessage: "rustic"
  }
});
export const EMBOSSING_RY = 'RY';
export const EMBOSSING_RY_MESSAGE = defineMessages({
  [EMBOSSING_RY]: {
    id: "Embossing.ry",
    description: "Embossing value named RY",
    defaultMessage: "ry"
  }
});
export const EMBOSSING_RY_LIJA_A100 = 'RY LIJA A100';
export const EMBOSSING_RY_LIJA_A100_MESSAGE = defineMessages({
  [EMBOSSING_RY_LIJA_A100]: {
    id: "Embossing.ryLijaA100",
    description: "Embossing value named RY LIJA A100",
    defaultMessage: "ry lija a100"
  }
});
export const EMBOSSING_RY_MATE_360_BEMA = 'RY MATE-360 BEMA';
export const EMBOSSING_RY_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_RY_MATE_360_BEMA]: {
    id: "Embossing.ryMate360Bema",
    description: "Embossing value named RY MATE-360 BEMA",
    defaultMessage: "ry mate-360 bema"
  }
});
export const EMBOSSING_S_1_G = 'S-1 (G)';
export const EMBOSSING_S_1_G_MESSAGE = defineMessages({
  [EMBOSSING_S_1_G]: {
    id: "Embossing.S1G",
    description: "Embossing value named S-1 (G)",
    defaultMessage: "s-1 (g)"
  }
});
export const EMBOSSING_SAFARI = 'SAFARI';
export const EMBOSSING_SAFARI_MESSAGE = defineMessages({
  [EMBOSSING_SAFARI]: {
    id: "Embossing.safari",
    description: "Embossing value named SAFARI",
    defaultMessage: "safari"
  }
});
export const EMBOSSING_SATIN = 'SATIN';
export const EMBOSSING_SATIN_MESSAGE = defineMessages({
  [EMBOSSING_SATIN]: {
    id: "Embossing.satin",
    description: "Embossing value named SATIN",
    defaultMessage: "satin"
  }
});
export const EMBOSSING_SATIN_BRILLANTE = 'SATIN BRILLANTE';
export const EMBOSSING_SATIN_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_SATIN_BRILLANTE]: {
    id: "Embossing.satinBrillante",
    description: "Embossing value named SATIN BRILLANTE",
    defaultMessage: "satin brillante"
  }
});
export const EMBOSSING_SATIN_CUADROS_G_MATE = 'SATIN CUADROS (G) MATE';
export const EMBOSSING_SATIN_CUADROS_G_MATE_MESSAGE = defineMessages({
  [EMBOSSING_SATIN_CUADROS_G_MATE]: {
    id: "Embossing.satinCuadrosGMate",
    description: "Embossing value named SATIN CUADROS (G) MATE",
    defaultMessage: "satin cuadros (g) mate"
  }
});
export const EMBOSSING_SATIN_II = 'SATIN II';
export const EMBOSSING_SATIN_II_MESSAGE = defineMessages({
  [EMBOSSING_SATIN_II]: {
    id: "Embossing.satinII",
    description: "Embossing value named SATIN II",
    defaultMessage: "satin II"
  }
});
export const EMBOSSING_SATIN_II_MATE_360 = 'SATIN II MATE-360';
export const EMBOSSING_SATIN_II_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_SATIN_II_MATE_360]: {
    id: "Embossing.satinIImate360",
    description: "Embossing value named SATIN II MATE-360",
    defaultMessage: "satin ii mate-360"
  }
});
export const EMBOSSING_SATIN_II_MATE_360_BEMA = 'SATIN II MATE-360 BEMA';
export const EMBOSSING_SATIN_II_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_SATIN_II_MATE_360_BEMA]: {
    id: "Embossing.satinIImate360bema",
    description: "Embossing value named SATIN II MATE-360 BEMA",
    defaultMessage: "satin ii mate-360 bema"
  }
});
export const EMBOSSING_SATIN_II_PINT_SB = 'SATIN II PINT SB';
export const EMBOSSING_SATIN_II_PINT_SB_MESSAGE = defineMessages({
  [EMBOSSING_SATIN_II_PINT_SB]: {
    id: "Embossing.satinIIPintSb",
    description: "Embossing value named SATIN II PINT SB",
    defaultMessage: "satin ii pint sb"
  }
});
export const EMBOSSING_SATIN_MATE = 'SATIN MATE';
export const EMBOSSING_SATIN_MATE_MESSAGE = defineMessages({
  [EMBOSSING_SATIN_MATE]: {
    id: "Embossing.satinMate",
    description: "Embossing value named SATIN MATE",
    defaultMessage: "satin mate"
  }
});
export const EMBOSSING_SATIN_MATE_360_BEMA = 'SATIN MATE-360 BEMA';
export const EMBOSSING_SATIN_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_SATIN_MATE_360_BEMA]: {
    id: "Embossing.satinMate360Bema",
    description: "Embossing value named SATIN MATE-360 BEMA",
    defaultMessage: "satin mate-360 bema"
  }
});
export const EMBOSSING_SATIN_SATIN_II_MATE = 'SATIN SATIN II MATE';
export const EMBOSSING_SATIN_SATIN_II_MATE_MESSAGE = defineMessages({
  [EMBOSSING_SATIN_SATIN_II_MATE]: {
    id: "Embossing.satinSatinIIMate",
    description: "Embossing value named SATIN SATIN II MATE",
    defaultMessage: "satin satin ii mate"
  }
});
export const EMBOSSING_SAURIO = 'SAURIO';
export const EMBOSSING_SAURIO_MESSAGE = defineMessages({
  [EMBOSSING_SAURIO]: {
    id: "Embossing.saurio",
    description: "Embossing value named SAURIO",
    defaultMessage: "saurio"
  }
});
export const EMBOSSING_SCRIBBLE = 'SCRIBBLE';
export const EMBOSSING_SCRIBBLE_MESSAGE = defineMessages({
  [EMBOSSING_SCRIBBLE]: {
    id: "Embossing.scribble",
    description: "Embossing value named SCRIBBLE",
    defaultMessage: "scribble"
  }
});
export const EMBOSSING_SEDA_FILM_SIL = 'SEDA FILM-SIL';
export const EMBOSSING_SEDA_FILM_SIL_MESSAGE = defineMessages({
  [EMBOSSING_SEDA_FILM_SIL]: {
    id: "Embossing.sedaFilmSil",
    description: "Embossing value named SEDA FILM-SIL",
    defaultMessage: "seda film-sil"
  }
});
export const EMBOSSING_SEDAMET = 'SEDAMET';
export const EMBOSSING_SEDAMET_MESSAGE = defineMessages({
  [EMBOSSING_SEDAMET]: {
    id: "Embossing.sedamet",
    description: "Embossing value named SEDAMET",
    defaultMessage: "sedamet"
  }
});
export const EMBOSSING_SEQUINS = 'SEQUINS';
export const EMBOSSING_SEQUINS_MESSAGE = defineMessages({
  [EMBOSSING_SEQUINS]: {
    id: "Embossing.sequins",
    description: "Embossing value named SEQUINS",
    defaultMessage: "sequins"
  }
});
export const EMBOSSING_SEVILLA = 'SEVILLA';
export const EMBOSSING_SEVILLA_MESSAGE = defineMessages({
  [EMBOSSING_SEVILLA]: {
    id: "Embossing.sevilla",
    description: "Embossing value named SEVILLA",
    defaultMessage: "sevilla"
  }
});
export const EMBOSSING_SIL_429 = 'SIL-429';
export const EMBOSSING_SIL_429_MESSAGE = defineMessages({
  [EMBOSSING_SIL_429]: {
    id: "Embossing.sil429",
    description: "Embossing value named SIL-429",
    defaultMessage: "sil-429"
  }
});
export const EMBOSSING_SILVER = 'SILVER';
export const EMBOSSING_SILVER_MESSAGE = defineMessages({
  [EMBOSSING_SILVER]: {
    id: "Embossing.silver",
    description: "Embossing value named SILVER",
    defaultMessage: "silver"
  }
});
export const EMBOSSING_SINU = 'SINU';
export const EMBOSSING_SINU_MESSAGE = defineMessages({
  [EMBOSSING_SINU]: {
    id: "Embossing.sinu",
    description: "Embossing value named SINU",
    defaultMessage: "sinu"
  }
});
export const EMBOSSING_SINU_MATE_360_BEMA = 'SINU MATE-360 BEMA';
export const EMBOSSING_SINU_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_SINU_MATE_360_BEMA]: {
    id: "Embossing.sinuMate360Mema",
    description: "Embossing value named SINU MATE-360 BEMA",
    defaultMessage: "sinu mate-360 bema"
  }
});
export const EMBOSSING_SKYLINE = 'SKYLINE';
export const EMBOSSING_SKYLINE_MESSAGE = defineMessages({
  [EMBOSSING_SKYLINE]: {
    id: "Embossing.skyline",
    description: "Embossing value named SKYLINE",
    defaultMessage: "skyline"
  }
});
export const EMBOSSING_SN_361 = 'SN-361';
export const EMBOSSING_SN_361_MESSAGE = defineMessages({
  [EMBOSSING_SN_361]: {
    id: "Embossing.sn361",
    description: "Embossing value named SN-361",
    defaultMessage: "sn-361"
  }
});
export const EMBOSSING_SOFT_TORINO = 'SOFT-TORINO';
export const EMBOSSING_SOFT_TORINO_MESSAGE = defineMessages({
  [EMBOSSING_SOFT_TORINO]: {
    id: "Embossing.softTorino",
    description: "Embossing value named SOFT-TORINO",
    defaultMessage: "soft-torino"
  }
});
export const EMBOSSING_SOHO = 'SOHO';
export const EMBOSSING_SOHO_MESSAGE = defineMessages({
  [EMBOSSING_SOHO]: {
    id: "Embossing.soho",
    description: "Embossing value named SOHO",
    defaultMessage: "soho"
  }
});
export const EMBOSSING_SORRENTO = 'SORRENTO';
export const EMBOSSING_SORRENTO_MESSAGE = defineMessages({
  [EMBOSSING_SORRENTO]: {
    id: "Embossing.sorrento",
    description: "Embossing value named SORRENTO",
    defaultMessage: "sorrento"
  }
});
export const EMBOSSING_SORRENTO_ESTRELLAS = 'SORRENTO ESTRELLAS';
export const EMBOSSING_SORRENTO_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_SORRENTO_ESTRELLAS]: {
    id: "Embossing.sorrentoEstrellas",
    description: "Embossing value named SORRENTO ESTRELLAS",
    defaultMessage: "sorrento estrellas"
  }
});
export const EMBOSSING_SORRENTO_MATE_360_BEMA_ESTRELLAS = 'SORRENTO MATE-360 BEMA ESTRELLAS';
export const EMBOSSING_SORRENTO_MATE_360_BEMA_ESTRELLAS_MESSAGE = defineMessages({
  [EMBOSSING_SORRENTO_MATE_360_BEMA_ESTRELLAS]: {
    id: "Embossing.sorrentoMate360BemaEstrellas",
    description: "Embossing value named SORRENTO MATE-360 BEMA ESTRELLAS",
    defaultMessage: "sorrento mate-360 bema estrellas"
  }
});
export const EMBOSSING_STITCH = 'STITCH';
export const EMBOSSING_STITCH_MESSAGE = defineMessages({
  [EMBOSSING_STITCH]: {
    id: "Embossing.stitch",
    description: "Embossing value named STITCH",
    defaultMessage: "stitch"
  }
});
export const EMBOSSING_SULTAN = 'SULTAN';
export const EMBOSSING_SULTAN_MESSAGE = defineMessages({
  [EMBOSSING_SULTAN]: {
    id: "Embossing.sultan",
    description: "Embossing value named SULTAN",
    defaultMessage: "sultan"
  }
});
export const EMBOSSING_SURIKEN = 'SURIKEN';
export const EMBOSSING_SURIKEN_MESSAGE = defineMessages({
  [EMBOSSING_SURIKEN]: {
    id: "Embossing.suriken",
    description: "Embossing value named SURIKEN",
    defaultMessage: "suriken"
  }
});
export const EMBOSSING_SUTTON = 'SUTTON';
export const EMBOSSING_SUTTON_MESSAGE = defineMessages({
  [EMBOSSING_SUTTON]: {
    id: "Embossing.sutton",
    description: "Embossing value named SUTTON",
    defaultMessage: "sutton"
  }
});
export const EMBOSSING_TAIRONA = 'TAIRONA';
export const EMBOSSING_TAIRONA_MESSAGE = defineMessages({
  [EMBOSSING_TAIRONA]: {
    id: "Embossing.tairona",
    description: "Embossing value named TAIRONA",
    defaultMessage: "tairona"
  }
});
export const EMBOSSING_TAPIN = 'TAPIN';
export const EMBOSSING_TAPIN_MESSAGE = defineMessages({
  [EMBOSSING_TAPIN]: {
    id: "Embossing.tapin",
    description: "Embossing value named TAPIN",
    defaultMessage: "tapin"
  }
});
export const EMBOSSING_TAURO = 'TAURO';
export const EMBOSSING_TAURO_MESSAGE = defineMessages({
  [EMBOSSING_TAURO]: {
    id: "Embossing.tauro",
    description: "Embossing value named TAURO",
    defaultMessage: "tauro"
  }
});
export const EMBOSSING_TED_LAPIDUS = 'TED LAPIDUS';
export const EMBOSSING_TED_LAPIDUS_MESSAGE = defineMessages({
  [EMBOSSING_TED_LAPIDUS]: {
    id: "Embossing.tedLapidus",
    description: "Embossing value named TED LAPIDUS",
    defaultMessage: "ted lapidus"
  }
});
export const EMBOSSING_TEJANO = 'TEJANO';
export const EMBOSSING_TEJANO_MESSAGE = defineMessages({
  [EMBOSSING_TEJANO]: {
    id: "Embossing.tejano",
    description: "Embossing value named TEJANO",
    defaultMessage: "tejano"
  }
});
export const EMBOSSING_TEJIDO = 'TEJIDO';
export const EMBOSSING_TEJIDO_MESSAGE = defineMessages({
  [EMBOSSING_TEJIDO]: {
    id: "Embossing.tejido",
    description: "Embossing value named TEJIDO",
    defaultMessage: "tejido"
  }
});
export const EMBOSSING_TEJON_SIL = 'TEJON-SIL';
export const EMBOSSING_TEJON_SIL_MESSAGE = defineMessages({
  [EMBOSSING_TEJON_SIL]: {
    id: "Embossing.tejonSil",
    description: "Embossing value named TEJON-SIL",
    defaultMessage: "tejon-sil"
  }
});
export const EMBOSSING_TELA_ALEMANA = 'TELA ALEMANA';
export const EMBOSSING_TELA_ALEMANA_MESSAGE = defineMessages({
  [EMBOSSING_TELA_ALEMANA]: {
    id: "Embossing.telaAlemana",
    description: "Embossing value named TELA ALEMANA",
    defaultMessage: "tela alemana"
  }
});
export const EMBOSSING_TELA_ALEMANA_BRILLANTE = 'TELA ALEMANA BRILLANTE';
export const EMBOSSING_TELA_ALEMANA_BRILLANTE_MESSAGE = defineMessages({
  [EMBOSSING_TELA_ALEMANA_BRILLANTE]: {
    id: "Embossing.telaAlemanaBrillante",
    description: "Embossing value named TELA ALEMANA BRILLANTE",
    defaultMessage: "tela alemana brillante"
  }
});
export const EMBOSSING_TENTACION_G = 'TENTACION (G)';
export const EMBOSSING_TENTACION_G_MESSAGE = defineMessages({
  [EMBOSSING_TENTACION_G]: {
    id: "Embossing.tentacionG",
    description: "Embossing value named TENTACION (G)",
    defaultMessage: "tentacion (g)"
  }
});
export const EMBOSSING_TETRA = 'TETRA';
export const EMBOSSING_TETRA_MESSAGE = defineMessages({
  [EMBOSSING_TETRA]: {
    id: "Embossing.tetra",
    description: "Embossing value named TETRA",
    defaultMessage: "tetra"
  }
});
export const EMBOSSING_TETRA_BENGALA = 'TETRA BENGALA';
export const EMBOSSING_TETRA_BENGALA_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_BENGALA]: {
    id: "Embossing.tetraBengala",
    description: "Embossing value named TETRA BENGALA",
    defaultMessage: "tetra bengala"
  }
});
export const EMBOSSING_TETRA_CARIBE = 'TETRA CARIBE';
export const EMBOSSING_TETRA_CARIBE_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_CARIBE]: {
    id: "Embossing.tetraCaribe",
    description: "Embossing value named TETRA CARIBE",
    defaultMessage: "tetra caribe"
  }
});
export const EMBOSSING_TETRA_CHROMATA = 'TETRA CHROMATA';
export const EMBOSSING_TETRA_CHROMATA_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_CHROMATA]: {
    id: "Embossing.tetraChromata",
    description: "Embossing value named TETRA CHROMATA",
    defaultMessage: "tetra chromata"
  }
});
export const EMBOSSING_TETRA_CUERO_DE_VACA = 'TETRA CUERO DE VACA';
export const EMBOSSING_TETRA_CUERO_DE_VACA_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_CUERO_DE_VACA]: {
    id: "Embossing.tetraCueroDeVaca",
    description: "Embossing value named TETRA CUERO DE VACA",
    defaultMessage: "tetra cuero de vaca"
  }
});
export const EMBOSSING_TETRA_DELFIN = 'TETRA DELFIN';
export const EMBOSSING_TETRA_DELFIN_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_DELFIN]: {
    id: "Embossing.tetraDelfin",
    description: "Embossing value named TETRA DELFIN",
    defaultMessage: "tetra delfin"
  }
});
export const EMBOSSING_TETRA_DIABLO = 'TETRA DIABLO';
export const EMBOSSING_TETRA_DIABLO_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_DIABLO]: {
    id: "Embossing.tetraDiablo",
    description: "Embossing value named TETRA DIABLO",
    defaultMessage: "tetra diablo"
  }
});
export const EMBOSSING_TETRA_GOAT = 'TETRA GOAT';
export const EMBOSSING_TETRA_GOAT_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_GOAT]: {
    id: "Embossing.tetraGoat",
    description: "Embossing value named TETRA GOAT",
    defaultMessage: "tetra goat"
  }
});
export const EMBOSSING_TETRA_GRANE = 'TETRA GRANE';
export const EMBOSSING_TETRA_GRANE_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_GRANE]: {
    id: "Embossing.tetraGrane",
    description: "Embossing value named TETRA GRANE",
    defaultMessage: "tetra grane"
  }
});
export const EMBOSSING_TETRA_JACKSON = 'TETRA JACKSON';
export const EMBOSSING_TETRA_JACKSON_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_JACKSON]: {
    id: "Embossing.tetraJackson",
    description: "Embossing value named TETRA JACKSON",
    defaultMessage: "tetra jackson"
  }
});
export const EMBOSSING_TETRA_M_2 = 'TETRA M 2';
export const EMBOSSING_TETRA_M_2_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_M_2]: {
    id: "Embossing.tetraM2",
    description: "Embossing value named TETRA M 2",
    defaultMessage: "tetra m 2"
  }
});
export const EMBOSSING_TETRA_SKYLINE = 'TETRA SKYLINE';
export const EMBOSSING_TETRA_SKYLINE_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_SKYLINE]: {
    id: "Embossing.tetraSkyline",
    description: "Embossing value named TETRA SKYLINE",
    defaultMessage: "tetra skyline"
  }
});
export const EMBOSSING_TETRA_TAIRONA = 'TETRA TAIRONA';
export const EMBOSSING_TETRA_TAIRONA_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_TAIRONA]: {
    id: "Embossing.tetraTairona",
    description: "Embossing value named TETRA TAIRONA",
    defaultMessage: "tetra tairona"
  }
});
export const EMBOSSING_TETRA_THOMAS_EDO = 'TETRA THOMAS EDO';
export const EMBOSSING_TETRA_THOMAS_EDO_MESSAGE = defineMessages({
  [EMBOSSING_TETRA_THOMAS_EDO]: {
    id: "Embossing.tetraThomasEdo",
    description: "Embossing value named TETRA THOMAS EDO",
    defaultMessage: "tetra thomas edo"
  }
});
export const EMBOSSING_TETRALINE = 'TETRALINE';
export const EMBOSSING_TETRALINE_MESSAGE = defineMessages({
  [EMBOSSING_TETRALINE]: {
    id: "Embossing.tetraline",
    description: "Embossing value named TETRALINE",
    defaultMessage: "tetraline"
  }
});
export const EMBOSSING_TEXTURED_GRID = 'TEXTURED GRID';
export const EMBOSSING_TEXTURED_GRID_MESSAGE = defineMessages({
  [EMBOSSING_TEXTURED_GRID]: {
    id: "Embossing.texturedGrid",
    description: "Embossing value named TEXTURED GRID",
    defaultMessage: "textured grid"
  }
});
export const EMBOSSING_THOMAS_EDO = 'THOMAS EDO';
export const EMBOSSING_THOMAS_EDO_MESSAGE = defineMessages({
  [EMBOSSING_THOMAS_EDO]: {
    id: "Embossing.thomasEdo",
    description: "Embossing value named THOMAS EDO",
    defaultMessage: "thomas edo"
  }
});
export const EMBOSSING_THOMAS_EDO_2 = 'THOMAS EDO #2';
export const EMBOSSING_THOMAS_EDO_2_MESSAGE = defineMessages({
  [EMBOSSING_THOMAS_EDO_2]: {
    id: "Embossing.thomasEdo_2",
    description: "Embossing value named THOMAS EDO #2",
    defaultMessage: "thomas edo #2"
  }
});
export const EMBOSSING_THOMAS_EDO_MATE_360 = 'THOMAS EDO MATE-360';
export const EMBOSSING_THOMAS_EDO_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_THOMAS_EDO_MATE_360]: {
    id: "Embossing.thomasEdoMate360",
    description: "Embossing value named THOMAS EDO MATE-360",
    defaultMessage: "thomas edo mate-360"
  }
});
export const EMBOSSING_TORINO = 'TORINO';
export const EMBOSSING_TORINO_MESSAGE = defineMessages({
  [EMBOSSING_TORINO]: {
    id: "Embossing.torino",
    description: "Embossing value named TORINO",
    defaultMessage: "torino"
  }
});
export const EMBOSSING_TORINO_MATE_360 = 'TORINO MATE-360';
export const EMBOSSING_TORINO_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_TORINO_MATE_360]: {
    id: "Embossing.torinoMate360",
    description: "Embossing value named TORINO MATE-360",
    defaultMessage: "torino mate-360"
  }
});
export const EMBOSSING_TORONADO = 'TORONADO';
export const EMBOSSING_TORONADO_MESSAGE = defineMessages({
  [EMBOSSING_TORONADO]: {
    id: "Embossing.toronado",
    description: "Embossing value named TORONADO",
    defaultMessage: "toronado"
  }
});
export const EMBOSSING_TREBOL = 'TREBOL';
export const EMBOSSING_TREBOL_MESSAGE = defineMessages({
  [EMBOSSING_TREBOL]: {
    id: "Embossing.trebol",
    description: "Embossing value named TREBOL",
    defaultMessage: "trebol"
  }
});
export const EMBOSSING_TRENZADO = 'TRENZADO';
export const EMBOSSING_TRENZADO_MESSAGE = defineMessages({
  [EMBOSSING_TRENZADO]: {
    id: "Embossing.trenzado",
    description: "Embossing value named TRENZADO",
    defaultMessage: "trenzado"
  }
});
export const EMBOSSING_TRENZADO_2 = 'TRENZADO #2';
export const EMBOSSING_TRENZADO_2_MESSAGE = defineMessages({
  [EMBOSSING_TRENZADO_2]: {
    id: "Embossing.trenzado_2",
    description: "Embossing value named TRENZADO #2",
    defaultMessage: "trenzado #2"
  }
});
export const EMBOSSING_TWIGABELLA = 'TWIGABELLA';
export const EMBOSSING_TWIGABELLA_MESSAGE = defineMessages({
  [EMBOSSING_TWIGABELLA]: {
    id: "Embossing.twigabella",
    description: "Embossing value named TWIGABELLA",
    defaultMessage: "twigabella"
  }
});
export const EMBOSSING_VELO_FILM_SIL = 'VELO FILM-SIL';
export const EMBOSSING_VELO_FILM_SIL_MESSAGE = defineMessages({
  [EMBOSSING_VELO_FILM_SIL]: {
    id: "Embossing.veloFilmSil",
    description: "Embossing value named VELO FILM-SIL",
    defaultMessage: "velo film-sil"
  }
});
export const EMBOSSING_VENADO = 'VENADO';
export const EMBOSSING_VENADO_MESSAGE = defineMessages({
  [EMBOSSING_VENADO]: {
    id: "Embossing.venado",
    description: "Embossing value named VENADO",
    defaultMessage: "venado"
  }
});
export const EMBOSSING_VENTURA = 'VENTURA';
export const EMBOSSING_VENTURA_MESSAGE = defineMessages({
  [EMBOSSING_VENTURA]: {
    id: "Embossing.ventura",
    description: "Embossing value named VENTURA",
    defaultMessage: "ventura"
  }
});
export const EMBOSSING_VER_OBSERVACIONES = 'VER OBSERVACIONES';
export const EMBOSSING_VER_OBSERVACIONES_MESSAGE = defineMessages({
  [EMBOSSING_VER_OBSERVACIONES]: {
    id: "Embossing.verObservaciones",
    description: "Embossing value named VER OBSERVACIONES",
    defaultMessage: "ver observaciones"
  }
});
export const EMBOSSING_VINILEXPORT = 'VINILEXPORT';
export const EMBOSSING_VINILEXPORT_MESSAGE = defineMessages({
  [EMBOSSING_VINILEXPORT]: {
    id: "Embossing.vinilexport",
    description: "Embossing value named VINILEXPORT",
    defaultMessage: "vinilexport"
  }
});
export const EMBOSSING_VOLKSWAGEN = 'VOLKSWAGEN';
export const EMBOSSING_VOLKSWAGEN_MESSAGE = defineMessages({
  [EMBOSSING_VOLKSWAGEN]: {
    id: "Embossing.volkswagen",
    description: "Embossing value named VOLKSWAGEN",
    defaultMessage: "volkswagen"
  }
});
export const EMBOSSING_WALLABY = 'WALLABY';
export const EMBOSSING_WALLABY_MESSAGE = defineMessages({
  [EMBOSSING_WALLABY]: {
    id: "Embossing.wallaby",
    description: "Embossing value named WALLABY",
    defaultMessage: "wallaby"
  }
});
export const EMBOSSING_WALLABY_3 = 'WALLABY #3';
export const EMBOSSING_WALLABY_3_MESSAGE = defineMessages({
  [EMBOSSING_WALLABY_3]: {
    id: "Embossing.wallaby_3",
    description: "Embossing value named WALLABY #3",
    defaultMessage: "wallaby #3"
  }
});
export const EMBOSSING_WALLABY_CONTINENTAL_MATE = 'WALLABY CONTINENTAL MATE';
export const EMBOSSING_WALLABY_CONTINENTAL_MATE_MESSAGE = defineMessages({
  [EMBOSSING_WALLABY_CONTINENTAL_MATE]: {
    id: "Embossing.wallabyContinentalMate",
    description: "Embossing value named WALLABY CONTINENTAL MATE",
    defaultMessage: "wallaby continental mate"
  }
});
export const EMBOSSING_WALLABY_MATE = 'WALLABY MATE';
export const EMBOSSING_WALLABY_MATE_MESSAGE = defineMessages({
  [EMBOSSING_WALLABY_MATE]: {
    id: "Embossing.wallabyMate",
    description: "Embossing value named WALLABY MATE",
    defaultMessage: "wallaby mate"
  }
});
export const EMBOSSING_WALLABY_MATE_MOQUETA = 'WALLABY MATE MOQUETA';
export const EMBOSSING_WALLABY_MATE_MOQUETA_MESSAGE = defineMessages({
  [EMBOSSING_WALLABY_MATE_MOQUETA]: {
    id: "Embossing.wallabyMateMoqueta",
    description: "Embossing value named WALLABY MATE MOQUETA",
    defaultMessage: "wallaby mate moqueta"
  }
});
export const EMBOSSING_WALLABY_MATE_360 = 'WALLABY MATE-360';
export const EMBOSSING_WALLABY_MATE_360_MESSAGE = defineMessages({
  [EMBOSSING_WALLABY_MATE_360]: {
    id: "Embossing.wallabyMate360",
    description: "Embossing value named WALLABY MATE-360",
    defaultMessage: "wallaby mate-360"
  }
});
export const EMBOSSING_WALLABY_MATE_360_BEMA = 'WALLABY MATE-360 BEMA';
export const EMBOSSING_WALLABY_MATE_360_BEMA_MESSAGE = defineMessages({
  [EMBOSSING_WALLABY_MATE_360_BEMA]: {
    id: "Embossing.wallabyMate360Bema",
    description: "Embossing value named WALLABY MATE-360 BEMA",
    defaultMessage: "wallaby mate-360 bema"
  }
});
export const EMBOSSING_WARD = 'WARD';
export const EMBOSSING_WARD_MESSAGE = defineMessages({
  [EMBOSSING_WARD]: {
    id: "Embossing.ward",
    description: "Embossing value named WARD",
    defaultMessage: "ward"
  }
});
export const EMBOSSING_WARD_180 = 'WARD-180';
export const EMBOSSING_WARD_180_MESSAGE = defineMessages({
  [EMBOSSING_WARD_180]: {
    id: "Embossing.ward180",
    description: "Embossing value named WARD-180",
    defaultMessage: "ward-180"
  }
});
export const EMBOSSING_WEAVE = 'WEAVE';
export const EMBOSSING_WEAVE_MESSAGE = defineMessages({
  [EMBOSSING_WEAVE]: {
    id: "Embossing.weave",
    description: "Embossing value named WEAVE",
    defaultMessage: "weave"
  }
});
export const EMBOSSING_WEAVEREALEAF = 'WEAVEREALEAF';
export const EMBOSSING_WEAVEREALEAF_MESSAGE = defineMessages({
  [EMBOSSING_WEAVEREALEAF]: {
    id: "Embossing.weaverealeaf",
    description: "Embossing value named WEAVEREALEAF",
    defaultMessage: "weaverealeaf"
  }
});
export const EMBOSSING_WIRE_CROSS = 'WIRE CROSS';
export const EMBOSSING_WIRE_CROSS_MESSAGE = defineMessages({
  [EMBOSSING_WIRE_CROSS]: {
    id: "Embossing.wireCross",
    description: "Embossing value named WIRE CROSS",
    defaultMessage: "wire cross"
  }
});
export const EMBOSSING_WIRE_PLAID = 'WIRE PLAID';
export const EMBOSSING_WIRE_PLAID_MESSAGE = defineMessages({
  [EMBOSSING_WIRE_PLAID]: {
    id: "Embossing.wirePlaid",
    description: "Embossing value named WIRE PLAID",
    defaultMessage: "wire plaid"
  }
});
export const EMBOSSING_W_JUXY = 'W-JUXY';
export const EMBOSSING_W_JUXY_MESSAGE = defineMessages({
  [EMBOSSING_W_JUXY]: {
    id: "Embossing.wJuxy",
    description: "Embossing value named W-JUXY",
    defaultMessage: "w-juxy"
  }
});
export const EMBOSSING_WUMAG = 'WUMAG';
export const EMBOSSING_WUMAG_MESSAGE = defineMessages({
  [EMBOSSING_WUMAG]: {
    id: "Embossing.wumag",
    description: "Embossing value named WUMAG",
    defaultMessage: "wumag"
  }
});
export const EMBOSSING_YAK = 'YAK';
export const EMBOSSING_YAK_MESSAGE = defineMessages({
  [EMBOSSING_YAK]: {
    id: "Embossing.yak",
    description: "Embossing value named YAK",
    defaultMessage: "yak"
  }
});
export const EMBOSSING_YUTE = 'YUTE';
export const EMBOSSING_YUTE_MESSAGE = defineMessages({
  [EMBOSSING_YUTE]: {
    id: "Embossing.yute",
    description: "Embossing value named YUTE",
    defaultMessage: "yute"
  }
});
export const EMBOSSING_ZEBRA = 'ZEBRA';
export const EMBOSSING_ZEBRA_MESSAGE = defineMessages({
  [EMBOSSING_ZEBRA]: {
    id: "Embossing.zebra",
    description: "Embossing value named ZEBRA",
    defaultMessage: "zebra"
  }
});
export const EMBOSSING_ZIRCON = 'ZIRCON';
export const EMBOSSING_ZIRCON_MESSAGE = defineMessages({
  [EMBOSSING_ZIRCON]: {
    id: "Embossing.zircon",
    description: "Embossing value named ZIRCON",
    defaultMessage: "zircon"
  }
});

// IMPORTANT: Some embossings here are temporarily commented due to a client request.
// Please keep them commented and DON'T DELETE ANY OF THEM. Will be enabled soon.
export const EMBOSSINGS = [
  // {...EMBOSSING_3518TH_Q_MESSAGE[EMBOSSING_3518TH_Q], value: EMBOSSING_3518TH_Q},
  // {...EMBOSSING_74_MESSAGE[EMBOSSING_74], value: EMBOSSING_74},
  // {...EMBOSSING_A6_MESSAGE[EMBOSSING_A6], value: EMBOSSING_A6},
  // {...EMBOSSING_ABERDEEN_MESSAGE[EMBOSSING_ABERDEEN], value: EMBOSSING_ABERDEEN},
  {...EMBOSSING_ALCE_MESSAGE[EMBOSSING_ALCE], value: EMBOSSING_ALCE},
  // {...EMBOSSING_ALFAJOR_2_MESSAGE[EMBOSSING_ALFAJOR_2], value: EMBOSSING_ALFAJOR_2},
  // {...EMBOSSING_ALFAJOR_2_100CM_MESSAGE[EMBOSSING_ALFAJOR_2_100CM], value: EMBOSSING_ALFAJOR_2_100CM},
  // {...EMBOSSING_ALFAJOR_3_MESSAGE[EMBOSSING_ALFAJOR_3], value: EMBOSSING_ALFAJOR_3},
  // {...EMBOSSING_ALFAJOR_3_MATE_MESSAGE[EMBOSSING_ALFAJOR_3_MATE], value: EMBOSSING_ALFAJOR_3_MATE},
  // {...EMBOSSING_ALICATES_MESSAGE[EMBOSSING_ALICATES], value: EMBOSSING_ALICATES},
  // {...EMBOSSING_ANGELITOS_MESSAGE[EMBOSSING_ANGELITOS], value: EMBOSSING_ANGELITOS},
  // {...EMBOSSING_ANTILOPE_MESSAGE[EMBOSSING_ANTILOPE], value: EMBOSSING_ANTILOPE},
  // {...EMBOSSING_ARBUSHALONE_MESSAGE[EMBOSSING_ARBUSHALONE], value: EMBOSSING_ARBUSHALONE},
  // {...EMBOSSING_ARENA_MESSAGE[EMBOSSING_ARENA], value: EMBOSSING_ARENA},
  // {...EMBOSSING_ARENA_MATE_360_BEMA_MESSAGE[EMBOSSING_ARENA_MATE_360_BEMA], value: EMBOSSING_ARENA_MATE_360_BEMA},
  // {...EMBOSSING_ARIZONA_MESSAGE[EMBOSSING_ARIZONA], value: EMBOSSING_ARIZONA},
  // {...EMBOSSING_ARIZONA_MATE_MESSAGE[EMBOSSING_ARIZONA_MATE], value: EMBOSSING_ARIZONA_MATE},
  // {...EMBOSSING_ARIZONA_180_MESSAGE[EMBOSSING_ARIZONA_180], value: EMBOSSING_ARIZONA_180},
  {...EMBOSSING_ARO_MESSAGE[EMBOSSING_ARO], value: EMBOSSING_ARO},
  {...EMBOSSING_AVANTE_MESSAGE[EMBOSSING_AVANTE], value: EMBOSSING_AVANTE},
  // {...EMBOSSING_AVANTGARDE_MESSAGE[EMBOSSING_AVANTGARDE], value: EMBOSSING_AVANTGARDE},
  // {...EMBOSSING_AZTECA_MESSAGE[EMBOSSING_AZTECA], value: EMBOSSING_AZTECA},
  // {...EMBOSSING_AZTECA_MATE_360_MESSAGE[EMBOSSING_AZTECA_MATE_360], value: EMBOSSING_AZTECA_MATE_360},
  {...EMBOSSING_AZTECA_180_MESSAGE[EMBOSSING_AZTECA_180], value: EMBOSSING_AZTECA_180},
  // {...EMBOSSING_BADANA_MESSAGE[EMBOSSING_BADANA], value: EMBOSSING_BADANA},
  // {...EMBOSSING_BALON_MESSAGE[EMBOSSING_BALON], value: EMBOSSING_BALON},
  // {...EMBOSSING_BALON_BRILLANTE_MESSAGE[EMBOSSING_BALON_BRILLANTE], value: EMBOSSING_BALON_BRILLANTE},
  // {...EMBOSSING_BECERRO_MESSAGE[EMBOSSING_BECERRO], value: EMBOSSING_BECERRO},
  {...EMBOSSING_BEETLE_MESSAGE[EMBOSSING_BEETLE], value: EMBOSSING_BEETLE},
  // {...EMBOSSING_BENGALA_MESSAGE[EMBOSSING_BENGALA], value: EMBOSSING_BENGALA},
  // {...EMBOSSING_BENGALA_MATE_MESSAGE[EMBOSSING_BENGALA_MATE], value: EMBOSSING_BENGALA_MATE},
  // {...EMBOSSING_BISONTE_MESSAGE[EMBOSSING_BISONTE], value: EMBOSSING_BISONTE},
  // {...EMBOSSING_BISONTE_GAMSIL_MESSAGE[EMBOSSING_BISONTE_GAMSIL], value: EMBOSSING_BISONTE_GAMSIL},
  // {...EMBOSSING_BISONTE_MATE_MESSAGE[EMBOSSING_BISONTE_MATE], value: EMBOSSING_BISONTE_MATE},
  {...EMBOSSING_BKN_MESSAGE[EMBOSSING_BKN], value: EMBOSSING_BKN},
  // {...EMBOSSING_BKN_GAM_MET_MESSAGE[EMBOSSING_BKN_GAM_MET], value: EMBOSSING_BKN_GAM_MET},
  // {...EMBOSSING_BKN_WUMAG_MESSAGE[EMBOSSING_BKN_WUMAG], value: EMBOSSING_BKN_WUMAG},
  {...EMBOSSING_BOUQUET_MESSAGE[EMBOSSING_BOUQUET], value: EMBOSSING_BOUQUET},
  {...EMBOSSING_BRILLANTE_MESSAGE[EMBOSSING_BRILLANTE], value: EMBOSSING_BRILLANTE},
  // {...EMBOSSING_BRILLANTE_ALFAJOR_2_MATE_360_BEMA_MESSAGE[EMBOSSING_BRILLANTE_ALFAJOR_2_MATE_360_BEMA], value: EMBOSSING_BRILLANTE_ALFAJOR_2_MATE_360_BEMA},
  // {...EMBOSSING_BRILLANTE_ALFAJOR_3_MATE_MESSAGE[EMBOSSING_BRILLANTE_ALFAJOR_3_MATE], value: EMBOSSING_BRILLANTE_ALFAJOR_3_MATE},
  // {...EMBOSSING_BRILLANTE_ESMERILADO_MESSAGE[EMBOSSING_BRILLANTE_ESMERILADO], value: EMBOSSING_BRILLANTE_ESMERILADO},
  // {...EMBOSSING_BRILLANTE_ESTRELLAS_DURATEX_MESSAGE[EMBOSSING_BRILLANTE_ESTRELLAS_DURATEX], value: EMBOSSING_BRILLANTE_ESTRELLAS_DURATEX},
  // {...EMBOSSING_BRILLANTE_LABERINTOS_MESSAGE[EMBOSSING_BRILLANTE_LABERINTOS], value: EMBOSSING_BRILLANTE_LABERINTOS},
  // {...EMBOSSING_BRILLANTE_MATE_360_BEMA_MESSAGE[EMBOSSING_BRILLANTE_MATE_360_BEMA], value: EMBOSSING_BRILLANTE_MATE_360_BEMA},
  // {...EMBOSSING_BRILLANTE_MATE_360_BEMA_SULTAN_MESSAGE[EMBOSSING_BRILLANTE_MATE_360_BEMA_SULTAN], value: EMBOSSING_BRILLANTE_MATE_360_BEMA_SULTAN},
  // {...EMBOSSING_BRILLANTE_MAYA_MESSAGE[EMBOSSING_BRILLANTE_MAYA], value: EMBOSSING_BRILLANTE_MAYA},
  // {...EMBOSSING_BRILLANTE_MOQUETA_MESSAGE[EMBOSSING_BRILLANTE_MOQUETA], value: EMBOSSING_BRILLANTE_MOQUETA},
  // {...EMBOSSING_BRILLANTE_PIEL_MESSAGE[EMBOSSING_BRILLANTE_PIEL], value: EMBOSSING_BRILLANTE_PIEL},
  // {...EMBOSSING_BRILLANTE_PINT_SB_MESSAGE[EMBOSSING_BRILLANTE_PINT_SB], value: EMBOSSING_BRILLANTE_PINT_SB},
  // {...EMBOSSING_BRILLANTE_PITAGORAS_CONSUL_MATE_MESSAGE[EMBOSSING_BRILLANTE_PITAGORAS_CONSUL_MATE], value: EMBOSSING_BRILLANTE_PITAGORAS_CONSUL_MATE},
  // {...EMBOSSING_BRILLANTE_SATIN_MESSAGE[EMBOSSING_BRILLANTE_SATIN], value: EMBOSSING_BRILLANTE_SATIN},
  // {...EMBOSSING_CAIMAN_MESSAGE[EMBOSSING_CAIMAN], value: EMBOSSING_CAIMAN},
  {...EMBOSSING_CALF_MESSAGE[EMBOSSING_CALF], value: EMBOSSING_CALF},
  // {...EMBOSSING_CALF_HORN_MESSAGE[EMBOSSING_CALF_HORN], value: EMBOSSING_CALF_HORN},
  // {...EMBOSSING_CALIMA_MESSAGE[EMBOSSING_CALIMA], value: EMBOSSING_CALIMA},
  // {...EMBOSSING_CAMMO_MESSAGE[EMBOSSING_CAMMO], value: EMBOSSING_CAMMO},
  {...EMBOSSING_CANASTA_MESSAGE[EMBOSSING_CANASTA], value: EMBOSSING_CANASTA},
  // {...EMBOSSING_CANGURO_MESSAGE[EMBOSSING_CANGURO], value: EMBOSSING_CANGURO},
  // {...EMBOSSING_CANGURO_LONETA_50Q_MESSAGE[EMBOSSING_CANGURO_LONETA_50Q], value: EMBOSSING_CANGURO_LONETA_50Q},
  // {...EMBOSSING_CANVAS_MESSAGE[EMBOSSING_CANVAS], value: EMBOSSING_CANVAS},
  // {...EMBOSSING_CANVAS_MATE_360_BEMA_MESSAGE[EMBOSSING_CANVAS_MATE_360_BEMA], value: EMBOSSING_CANVAS_MATE_360_BEMA},
  // {...EMBOSSING_CANVAS_PINT_SB_MESSAGE[EMBOSSING_CANVAS_PINT_SB], value: EMBOSSING_CANVAS_PINT_SB},
  {...EMBOSSING_CAP_MESSAGE[EMBOSSING_CAP], value: EMBOSSING_CAP},
  // {...EMBOSSING_CAP_LIJA_A100_MESSAGE[EMBOSSING_CAP_LIJA_A100], value: EMBOSSING_CAP_LIJA_A100},
  // {...EMBOSSING_CAPRI_MESSAGE[EMBOSSING_CAPRI], value: EMBOSSING_CAPRI},
  // {...EMBOSSING_CAPRI_BRILLANTE_MESSAGE[EMBOSSING_CAPRI_BRILLANTE], value: EMBOSSING_CAPRI_BRILLANTE},
  // {...EMBOSSING_CAPRI_LONETA_50Q_MESSAGE[EMBOSSING_CAPRI_LONETA_50Q], value: EMBOSSING_CAPRI_LONETA_50Q},
  // {...EMBOSSING_CAPRICE_MESSAGE[EMBOSSING_CAPRICE], value: EMBOSSING_CAPRICE},
  // {...EMBOSSING_CARACOL_BRILLANTE_MESSAGE[EMBOSSING_CARACOL_BRILLANTE], value: EMBOSSING_CARACOL_BRILLANTE},
  {...EMBOSSING_CARBON_FIBER_MESSAGE[EMBOSSING_CARBON_FIBER], value: EMBOSSING_CARBON_FIBER},
  // {...EMBOSSING_CARIBE_MESSAGE[EMBOSSING_CARIBE], value: EMBOSSING_CARIBE},
  // {...EMBOSSING_CARIBU_MESSAGE[EMBOSSING_CARIBU], value: EMBOSSING_CARIBU},
  // {...EMBOSSING_CEBRA_MESSAGE[EMBOSSING_CEBRA], value: EMBOSSING_CEBRA},
  // {...EMBOSSING_CEBU_MESSAGE[EMBOSSING_CEBU], value: EMBOSSING_CEBU},
  // {...EMBOSSING_CENERY_MESSAGE[EMBOSSING_CENERY], value: EMBOSSING_CENERY},
  // {...EMBOSSING_CERVATO_SIL_MESSAGE[EMBOSSING_CERVATO_SIL], value: EMBOSSING_CERVATO_SIL},
  // {...EMBOSSING_CHACAL_MESSAGE[EMBOSSING_CHACAL], value: EMBOSSING_CHACAL},
  {...EMBOSSING_CHAN_TUNG_MESSAGE[EMBOSSING_CHAN_TUNG], value: EMBOSSING_CHAN_TUNG},
  // {...EMBOSSING_CHECKER_MESSAGE[EMBOSSING_CHECKER], value: EMBOSSING_CHECKER},
  // {...EMBOSSING_CHIGUIRO_MESSAGE[EMBOSSING_CHIGUIRO], value: EMBOSSING_CHIGUIRO},
  // {...EMBOSSING_CHN_MESSAGE[EMBOSSING_CHN], value: EMBOSSING_CHN},
  {...EMBOSSING_CHROMATA_MESSAGE[EMBOSSING_CHROMATA], value: EMBOSSING_CHROMATA},
  {...EMBOSSING_CIERVO_MESSAGE[EMBOSSING_CIERVO], value: EMBOSSING_CIERVO},
  {...EMBOSSING_CIRCULOS_MESSAGE[EMBOSSING_CIRCULOS], value: EMBOSSING_CIRCULOS},
  // {...EMBOSSING_CIRCULOS_MATE_MESSAGE[EMBOSSING_CIRCULOS_MATE], value: EMBOSSING_CIRCULOS_MATE},
  // {...EMBOSSING_COBRA_MESSAGE[EMBOSSING_COBRA], value: EMBOSSING_COBRA},
  // {...EMBOSSING_COLOMBO_MESSAGE[EMBOSSING_COLOMBO], value: EMBOSSING_COLOMBO},
  {...EMBOSSING_COLONIA_MESSAGE[EMBOSSING_COLONIA], value: EMBOSSING_COLONIA},
  // {...EMBOSSING_COLONIA_BRILLANTE_MESSAGE[EMBOSSING_COLONIA_BRILLANTE], value: EMBOSSING_COLONIA_BRILLANTE},
  // {...EMBOSSING_COLONIA_EPINGLE_MESSAGE[EMBOSSING_COLONIA_EPINGLE], value: EMBOSSING_COLONIA_EPINGLE},
  // {...EMBOSSING_COLONIA_MATE_360_BEMA_MESSAGE[EMBOSSING_COLONIA_MATE_360_BEMA], value: EMBOSSING_COLONIA_MATE_360_BEMA},
  // {...EMBOSSING_COLONIA_PINT_SB_MESSAGE[EMBOSSING_COLONIA_PINT_SB], value: EMBOSSING_COLONIA_PINT_SB},
  // {...EMBOSSING_COLONIA_PUMA_MESSAGE[EMBOSSING_COLONIA_PUMA], value: EMBOSSING_COLONIA_PUMA},
  // {...EMBOSSING_COLONIA_QUETZAL_MESSAGE[EMBOSSING_COLONIA_QUETZAL], value: EMBOSSING_COLONIA_QUETZAL},
  // {...EMBOSSING_CONFETY_MESSAGE[EMBOSSING_CONFETY], value: EMBOSSING_CONFETY},
  {...EMBOSSING_CONSTELACION_MESSAGE[EMBOSSING_CONSTELACION], value: EMBOSSING_CONSTELACION},
  {...EMBOSSING_CONSUL_MESSAGE[EMBOSSING_CONSUL], value: EMBOSSING_CONSUL},
  // {...EMBOSSING_CONSUL_ESTRELLAS_MESSAGE[EMBOSSING_CONSUL_ESTRELLAS], value: EMBOSSING_CONSUL_ESTRELLAS},
  // {...EMBOSSING_CONSUL_MATE_MESSAGE[EMBOSSING_CONSUL_MATE], value: EMBOSSING_CONSUL_MATE},
  // {...EMBOSSING_CONSUL_MATE_360_BEMA_MESSAGE[EMBOSSING_CONSUL_MATE_360_BEMA], value: EMBOSSING_CONSUL_MATE_360_BEMA},
  // {...EMBOSSING_CONTINENTAL_MESSAGE[EMBOSSING_CONTINENTAL], value: EMBOSSING_CONTINENTAL},
  // {...EMBOSSING_CORCHO_MESSAGE[EMBOSSING_CORCHO], value: EMBOSSING_CORCHO},
  // {...EMBOSSING_CORDERO_MESSAGE[EMBOSSING_CORDERO], value: EMBOSSING_CORDERO},
  // {...EMBOSSING_CORDOBA_MESSAGE[EMBOSSING_CORDOBA], value: EMBOSSING_CORDOBA},
  // {...EMBOSSING_CORTEZA_MESSAGE[EMBOSSING_CORTEZA], value: EMBOSSING_CORTEZA},
  // {...EMBOSSING_CORY_MESSAGE[EMBOSSING_CORY], value: EMBOSSING_CORY},
  // {...EMBOSSING_CORZO_MESSAGE[EMBOSSING_CORZO], value: EMBOSSING_CORZO},
  // {...EMBOSSING_COSMOS_MESSAGE[EMBOSSING_COSMOS], value: EMBOSSING_COSMOS},
  // {...EMBOSSING_COSMOS_ESTRELLAS_MESSAGE[EMBOSSING_COSMOS_ESTRELLAS], value: EMBOSSING_COSMOS_ESTRELLAS},
  // {...EMBOSSING_COSTURADO_MESSAGE[EMBOSSING_COSTURADO], value: EMBOSSING_COSTURADO},
  // {...EMBOSSING_CROSTERY_MESSAGE[EMBOSSING_CROSTERY], value: EMBOSSING_CROSTERY},
  // {...EMBOSSING_CROYDON_MESSAGE[EMBOSSING_CROYDON], value: EMBOSSING_CROYDON},
  // {...EMBOSSING_CROYDON_CHROMATA_MESSAGE[EMBOSSING_CROYDON_CHROMATA], value: EMBOSSING_CROYDON_CHROMATA},
  // {...EMBOSSING_CROYDON_GAMUZA_2003_2_MESSAGE[EMBOSSING_CROYDON_GAMUZA_2003_2], value: EMBOSSING_CROYDON_GAMUZA_2003_2},
  // {...EMBOSSING_CUADRICULA_G_MESSAGE[EMBOSSING_CUADRICULA_G], value: EMBOSSING_CUADRICULA_G},
  // {...EMBOSSING_CUADRICULA_G_BRILLANTE_MESSAGE[EMBOSSING_CUADRICULA_G_BRILLANTE], value: EMBOSSING_CUADRICULA_G_BRILLANTE},
  {...EMBOSSING_CUADROS_G_MESSAGE[EMBOSSING_CUADROS_G], value: EMBOSSING_CUADROS_G},
  // {...EMBOSSING_CUADROS_G_MATE_MESSAGE[EMBOSSING_CUADROS_G_MATE], value: EMBOSSING_CUADROS_G_MATE},
  {...EMBOSSING_CUERO_DE_VACA_MESSAGE[EMBOSSING_CUERO_DE_VACA], value: EMBOSSING_CUERO_DE_VACA},
  // {...EMBOSSING_DANTA_MESSAGE[EMBOSSING_DANTA], value: EMBOSSING_DANTA},
  {...EMBOSSING_DANTA_1_MESSAGE[EMBOSSING_DANTA_1], value: EMBOSSING_DANTA_1},
  // {...EMBOSSING_DANTA_GAM_MET_MESSAGE[EMBOSSING_DANTA_GAM_MET], value: EMBOSSING_DANTA_GAM_MET},
  {...EMBOSSING_DELFIN_MESSAGE[EMBOSSING_DELFIN], value: EMBOSSING_DELFIN},
  // {...EMBOSSING_DELFIN_MATE_MESSAGE[EMBOSSING_DELFIN_MATE], value: EMBOSSING_DELFIN_MATE},
  // {...EMBOSSING_DELFIN_MATE_360_MESSAGE[EMBOSSING_DELFIN_MATE_360], value: EMBOSSING_DELFIN_MATE_360},
  // {...EMBOSSING_DEMON_MESSAGE[EMBOSSING_DEMON], value: EMBOSSING_DEMON},
  // {...EMBOSSING_D_GRAIN_MESSAGE[EMBOSSING_D_GRAIN], value: EMBOSSING_D_GRAIN},
  // {...EMBOSSING_DIABLO_MESSAGE[EMBOSSING_DIABLO], value: EMBOSSING_DIABLO},
  {...EMBOSSING_DIAMANTE_MESSAGE[EMBOSSING_DIAMANTE], value: EMBOSSING_DIAMANTE},
  {...EMBOSSING_DIVA_MESSAGE[EMBOSSING_DIVA], value: EMBOSSING_DIVA},
  // {...EMBOSSING_DRAPE_MESSAGE[EMBOSSING_DRAPE], value: EMBOSSING_DRAPE},
  {...EMBOSSING_DRILLING_MESSAGE[EMBOSSING_DRILLING], value: EMBOSSING_DRILLING},
  {...EMBOSSING_DURATEX_MESSAGE[EMBOSSING_DURATEX], value: EMBOSSING_DURATEX},
  {...EMBOSSING_DURAZNO_MESSAGE[EMBOSSING_DURAZNO], value: EMBOSSING_DURAZNO},
  // {...EMBOSSING_DURAZNO_MATE_360_BEMA_MESSAGE[EMBOSSING_DURAZNO_MATE_360_BEMA], value: EMBOSSING_DURAZNO_MATE_360_BEMA},
  // {...EMBOSSING_DURAZNO_PINT_SB_MESSAGE[EMBOSSING_DURAZNO_PINT_SB], value: EMBOSSING_DURAZNO_PINT_SB},
  // {...EMBOSSING_DURAZNO_PINT_SB_MATE_MESSAGE[EMBOSSING_DURAZNO_PINT_SB_MATE], value: EMBOSSING_DURAZNO_PINT_SB_MATE},
  // {...EMBOSSING_DURAZNO_SATIN_MESSAGE[EMBOSSING_DURAZNO_SATIN], value: EMBOSSING_DURAZNO_SATIN},
  // {...EMBOSSING_EGIPCIO_MESSAGE[EMBOSSING_EGIPCIO], value: EMBOSSING_EGIPCIO},
  // {...EMBOSSING_ELEFANTE_MESSAGE[EMBOSSING_ELEFANTE], value: EMBOSSING_ELEFANTE},
  // {...EMBOSSING_ELITE_MESSAGE[EMBOSSING_ELITE], value: EMBOSSING_ELITE},
  // {...EMBOSSING_EPINGLE_MESSAGE[EMBOSSING_EPINGLE], value: EMBOSSING_EPINGLE},
  {...EMBOSSING_EPINGLE_2_MESSAGE[EMBOSSING_EPINGLE_2], value: EMBOSSING_EPINGLE_2},
  // {...EMBOSSING_EPINGLE_2_GAMUZA_2007_1_MESSAGE[EMBOSSING_EPINGLE_2_GAMUZA_2007_1], value: EMBOSSING_EPINGLE_2_GAMUZA_2007_1},
  // {...EMBOSSING_EPINGLE_2_MATE_360_BEMA_MESSAGE[EMBOSSING_EPINGLE_2_MATE_360_BEMA], value: EMBOSSING_EPINGLE_2_MATE_360_BEMA},
  // {...EMBOSSING_EPINGLE_MATE_MESSAGE[EMBOSSING_EPINGLE_MATE], value: EMBOSSING_EPINGLE_MATE},
  // {...EMBOSSING_ESMERILADO_MESSAGE[EMBOSSING_ESMERILADO], value: EMBOSSING_ESMERILADO},
  // {...EMBOSSING_ESPINA_DE_PESCADO_MESSAGE[EMBOSSING_ESPINA_DE_PESCADO], value: EMBOSSING_ESPINA_DE_PESCADO},
  // {...EMBOSSING_ESTERILLA_G_MESSAGE[EMBOSSING_ESTERILLA_G], value: EMBOSSING_ESTERILLA_G},
  {...EMBOSSING_ESTRELLAS_MESSAGE[EMBOSSING_ESTRELLAS], value: EMBOSSING_ESTRELLAS},
  // {...EMBOSSING_ESTRELLAS_DURATEX_MESSAGE[EMBOSSING_ESTRELLAS_DURATEX], value: EMBOSSING_ESTRELLAS_DURATEX},
  // {...EMBOSSING_ESTRELLAS_ESMERILADO_MESSAGE[EMBOSSING_ESTRELLAS_ESMERILADO], value: EMBOSSING_ESTRELLAS_ESMERILADO},
  // {...EMBOSSING_ESTRELLAS_PINT_SB_CARB_MESSAGE[EMBOSSING_ESTRELLAS_PINT_SB_CARB], value: EMBOSSING_ESTRELLAS_PINT_SB_CARB},
  // {...EMBOSSING_EUROPA_MESSAGE[EMBOSSING_EUROPA], value: EMBOSSING_EUROPA},
  // {...EMBOSSING_EUROPA_RAYAS_MESSAGE[EMBOSSING_EUROPA_RAYAS], value: EMBOSSING_EUROPA_RAYAS},
  {...EMBOSSING_FAILLE_MESSAGE[EMBOSSING_FAILLE], value: EMBOSSING_FAILLE},
  // {...EMBOSSING_FARGO_MESSAGE[EMBOSSING_FARGO], value: EMBOSSING_FARGO},
  {...EMBOSSING_FIBRA_CARBONO_MESSAGE[EMBOSSING_FIBRA_CARBONO], value: EMBOSSING_FIBRA_CARBONO},
  // {...EMBOSSING_FILIGREE_MESSAGE[EMBOSSING_FILIGREE], value: EMBOSSING_FILIGREE},
  // {...EMBOSSING_FLAMENCO_MESSAGE[EMBOSSING_FLAMENCO], value: EMBOSSING_FLAMENCO},
  // {...EMBOSSING_FLAMENCO_180_MESSAGE[EMBOSSING_FLAMENCO_180], value: EMBOSSING_FLAMENCO_180},
  // {...EMBOSSING_FLANNEL_1_G_MESSAGE[EMBOSSING_FLANNEL_1_G], value: EMBOSSING_FLANNEL_1_G},
  // {...EMBOSSING_FLANNEL_2_G_MESSAGE[EMBOSSING_FLANNEL_2_G], value: EMBOSSING_FLANNEL_2_G},
  // {...EMBOSSING_FLANNEL_2_G_MATE_360_BEMA_MESSAGE[EMBOSSING_FLANNEL_2_G_MATE_360_BEMA], value: EMBOSSING_FLANNEL_2_G_MATE_360_BEMA},
  {...EMBOSSING_FLORIDIAN_MESSAGE[EMBOSSING_FLORIDIAN], value: EMBOSSING_FLORIDIAN},
  // {...EMBOSSING_FRANCESA_MESSAGE[EMBOSSING_FRANCESA], value: EMBOSSING_FRANCESA},
  // {...EMBOSSING_GACELA_MESSAGE[EMBOSSING_GACELA], value: EMBOSSING_GACELA},
  // {...EMBOSSING_GACELA_MATE_360_MESSAGE[EMBOSSING_GACELA_MATE_360], value: EMBOSSING_GACELA_MATE_360},
  // {...EMBOSSING_GACELA_MATE_360_BEMA_MESSAGE[EMBOSSING_GACELA_MATE_360_BEMA], value: EMBOSSING_GACELA_MATE_360_BEMA},
  // {...EMBOSSING_GALAXIA_MESSAGE[EMBOSSING_GALAXIA], value: EMBOSSING_GALAXIA},
  // {...EMBOSSING_GAM_MET_MATE_MESSAGE[EMBOSSING_GAM_MET_MATE], value: EMBOSSING_GAM_MET_MATE},
  // {...EMBOSSING_GAM_MET_PINT_SB_MESSAGE[EMBOSSING_GAM_MET_PINT_SB], value: EMBOSSING_GAM_MET_PINT_SB},
  // {...EMBOSSING_GAM_MET_VENADO_MESSAGE[EMBOSSING_GAM_MET_VENADO], value: EMBOSSING_GAM_MET_VENADO},
  // {...EMBOSSING_GAMSIL_MESSAGE[EMBOSSING_GAMSIL], value: EMBOSSING_GAMSIL},
  // {...EMBOSSING_GAMSIL_159_2_MATE_MESSAGE[EMBOSSING_GAMSIL_159_2_MATE], value: EMBOSSING_GAMSIL_159_2_MATE},
  // {...EMBOSSING_GAMSIL4_MESSAGE[EMBOSSING_GAMSIL4], value: EMBOSSING_GAMSIL4},
  // {...EMBOSSING_GAMUZA_2003_2_MESSAGE[EMBOSSING_GAMUZA_2003_2], value: EMBOSSING_GAMUZA_2003_2},
  // {...EMBOSSING_GAMUZA_2003_2_MATE_MESSAGE[EMBOSSING_GAMUZA_2003_2_MATE], value: EMBOSSING_GAMUZA_2003_2_MATE},
  // {...EMBOSSING_GAMUZA_2003_2_MATE_360_BEMA_MESSAGE[EMBOSSING_GAMUZA_2003_2_MATE_360_BEMA], value: EMBOSSING_GAMUZA_2003_2_MATE_360_BEMA},
  // {...EMBOSSING_GAMUZA_2004_1_MESSAGE[EMBOSSING_GAMUZA_2004_1], value: EMBOSSING_GAMUZA_2004_1},
  {...EMBOSSING_GAMUZA_2007_1_MESSAGE[EMBOSSING_GAMUZA_2007_1], value: EMBOSSING_GAMUZA_2007_1},
  // {...EMBOSSING_GAMUZA_2007_1_CROYDON_MESSAGE[EMBOSSING_GAMUZA_2007_1_CROYDON], value: EMBOSSING_GAMUZA_2007_1_CROYDON},
  // {...EMBOSSING_GARDENIA_MESSAGE[EMBOSSING_GARDENIA], value: EMBOSSING_GARDENIA},
  // {...EMBOSSING_GAVIAL_MESSAGE[EMBOSSING_GAVIAL], value: EMBOSSING_GAVIAL},
  // {...EMBOSSING_GEOMETRIC_MESSAGE[EMBOSSING_GEOMETRIC], value: EMBOSSING_GEOMETRIC},
  {...EMBOSSING_GOAT_MESSAGE[EMBOSSING_GOAT], value: EMBOSSING_GOAT},
  // {...EMBOSSING_GOLD_COAST_MESSAGE[EMBOSSING_GOLD_COAST], value: EMBOSSING_GOLD_COAST},
  {...EMBOSSING_GOLETA_MESSAGE[EMBOSSING_GOLETA], value: EMBOSSING_GOLETA},
  {...EMBOSSING_GRANE_MESSAGE[EMBOSSING_GRANE], value: EMBOSSING_GRANE},
  // {...EMBOSSING_GRANE_2_MESSAGE[EMBOSSING_GRANE_2], value: EMBOSSING_GRANE_2},
  // {...EMBOSSING_GRANE_ESTRELLAS_MESSAGE[EMBOSSING_GRANE_ESTRELLAS], value: EMBOSSING_GRANE_ESTRELLAS},
  // {...EMBOSSING_GRANE_MATE_MESSAGE[EMBOSSING_GRANE_MATE], value: EMBOSSING_GRANE_MATE},
  // {...EMBOSSING_GRANE_MATE_360_BEMA_MESSAGE[EMBOSSING_GRANE_MATE_360_BEMA], value: EMBOSSING_GRANE_MATE_360_BEMA},
  // {...EMBOSSING_GRAVILLA_SIL_MESSAGE[EMBOSSING_GRAVILLA_SIL], value: EMBOSSING_GRAVILLA_SIL},
  // {...EMBOSSING_GRAVILLA_SIL_MATE_360_BEMA_MESSAGE[EMBOSSING_GRAVILLA_SIL_MATE_360_BEMA], value: EMBOSSING_GRAVILLA_SIL_MATE_360_BEMA},
  // {...EMBOSSING_GRID_MESSAGE[EMBOSSING_GRID], value: EMBOSSING_GRID},
  // {...EMBOSSING_GV_M2_MESSAGE[EMBOSSING_GV_M2], value: EMBOSSING_GV_M2},
  // {...EMBOSSING_GV_REN_MESSAGE[EMBOSSING_GV_REN], value: EMBOSSING_GV_REN},
  // {...EMBOSSING_GV_REN_MATE_360_MESSAGE[EMBOSSING_GV_REN_MATE_360], value: EMBOSSING_GV_REN_MATE_360},
  // {...EMBOSSING_HALCON_MESSAGE[EMBOSSING_HALCON], value: EMBOSSING_HALCON},
  // {...EMBOSSING_HAMMERED_P_2887_MESSAGE[EMBOSSING_HAMMERED_P_2887], value: EMBOSSING_HAMMERED_P_2887},
  // {...EMBOSSING_HATCH_RICE_PLAIN_MESSAGE[EMBOSSING_HATCH_RICE_PLAIN], value: EMBOSSING_HATCH_RICE_PLAIN},
  {...EMBOSSING_HATCHBALIBALL_MESSAGE[EMBOSSING_HATCHBALIBALL], value: EMBOSSING_HATCHBALIBALL},
  {...EMBOSSING_HATCHBETSY_MESSAGE[EMBOSSING_HATCHBETSY], value: EMBOSSING_HATCHBETSY},
  {...EMBOSSING_HATCHEAK_MESSAGE[EMBOSSING_HATCHEAK], value: EMBOSSING_HATCHEAK},
  // {...EMBOSSING_HATCHEMLEAF_MESSAGE[EMBOSSING_HATCHEMLEAF], value: EMBOSSING_HATCHEMLEAF},
  // {...EMBOSSING_HATCHFOSLEAF_MESSAGE[EMBOSSING_HATCHFOSLEAF], value: EMBOSSING_HATCHFOSLEAF},
  // {...EMBOSSING_HAWAY_BAMBU_MESSAGE[EMBOSSING_HAWAY_BAMBU], value: EMBOSSING_HAWAY_BAMBU},
  // {...EMBOSSING_HEXAGONOS_MESSAGE[EMBOSSING_HEXAGONOS], value: EMBOSSING_HEXAGONOS},
  {...EMBOSSING_HEXX_MESSAGE[EMBOSSING_HEXX], value: EMBOSSING_HEXX},
  // {...EMBOSSING_HINDU_MESSAGE[EMBOSSING_HINDU], value: EMBOSSING_HINDU},
  {...EMBOSSING_HITCH_MESSAGE[EMBOSSING_HITCH], value: EMBOSSING_HITCH},
  // {...EMBOSSING_H_NEEDLE_MESSAGE[EMBOSSING_H_NEEDLE], value: EMBOSSING_H_NEEDLE},
  // {...EMBOSSING_IGUANA_G_MESSAGE[EMBOSSING_IGUANA_G], value: EMBOSSING_IGUANA_G},
  // {...EMBOSSING_IMPALA_MESSAGE[EMBOSSING_IMPALA], value: EMBOSSING_IMPALA},
  // {...EMBOSSING_IMPALA_180_MESSAGE[EMBOSSING_IMPALA_180], value: EMBOSSING_IMPALA_180},
  {...EMBOSSING_INCA_MESSAGE[EMBOSSING_INCA], value: EMBOSSING_INCA},
  // {...EMBOSSING_INCA_MATE_MESSAGE[EMBOSSING_INCA_MATE], value: EMBOSSING_INCA_MATE},
  // {...EMBOSSING_INCA_MATE_360_BEMA_MESSAGE[EMBOSSING_INCA_MATE_360_BEMA], value: EMBOSSING_INCA_MATE_360_BEMA},
  // {...EMBOSSING_INFUSION_MESSAGE[EMBOSSING_INFUSION], value: EMBOSSING_INFUSION},
  // {...EMBOSSING_IRREGULAR_GRID_MESSAGE[EMBOSSING_IRREGULAR_GRID], value: EMBOSSING_IRREGULAR_GRID},
  {...EMBOSSING_IZMIR_MESSAGE[EMBOSSING_IZMIR], value: EMBOSSING_IZMIR},
  {...EMBOSSING_JACKSON_MESSAGE[EMBOSSING_JACKSON], value: EMBOSSING_JACKSON},
  {...EMBOSSING_JUMBO_MESSAGE[EMBOSSING_JUMBO], value: EMBOSSING_JUMBO},
  // {...EMBOSSING_KOALA_MESSAGE[EMBOSSING_KOALA], value: EMBOSSING_KOALA},
  // {...EMBOSSING_LABERINTOS_MESSAGE[EMBOSSING_LABERINTOS], value: EMBOSSING_LABERINTOS},
  // {...EMBOSSING_LAYOUT_MESSAGE[EMBOSSING_LAYOUT], value: EMBOSSING_LAYOUT},
  {...EMBOSSING_LEVANT_MESSAGE[EMBOSSING_LEVANT], value: EMBOSSING_LEVANT},
  {...EMBOSSING_LIENZO_MESSAGE[EMBOSSING_LIENZO], value: EMBOSSING_LIENZO},
  // {...EMBOSSING_LIENZO_DURAZNO_MESSAGE[EMBOSSING_LIENZO_DURAZNO], value: EMBOSSING_LIENZO_DURAZNO},
  // {...EMBOSSING_LIENZO_MATE_MESSAGE[EMBOSSING_LIENZO_MATE], value: EMBOSSING_LIENZO_MATE},
  // {...EMBOSSING_LIENZO_MATE_360_BEMA_MESSAGE[EMBOSSING_LIENZO_MATE_360_BEMA], value: EMBOSSING_LIENZO_MATE_360_BEMA},
  // {...EMBOSSING_LIJA_A_60_MESSAGE[EMBOSSING_LIJA_A_60], value: EMBOSSING_LIJA_A_60},
  // {...EMBOSSING_LIJA_A100_MESSAGE[EMBOSSING_LIJA_A100], value: EMBOSSING_LIJA_A100},
  // {...EMBOSSING_LINEN_MESSAGE[EMBOSSING_LINEN], value: EMBOSSING_LINEN},
  {...EMBOSSING_LISBOA_MESSAGE[EMBOSSING_LISBOA], value: EMBOSSING_LISBOA},
  // {...EMBOSSING_LISBOA_MATE_MESSAGE[EMBOSSING_LISBOA_MATE], value: EMBOSSING_LISBOA_MATE},
  {...EMBOSSING_L_LOGANA_MESSAGE[EMBOSSING_L_LOGANA], value: EMBOSSING_L_LOGANA},
  // {...EMBOSSING_LONA_FILM_SIL_MESSAGE[EMBOSSING_LONA_FILM_SIL], value: EMBOSSING_LONA_FILM_SIL},
  // {...EMBOSSING_LONETA_50Q_MESSAGE[EMBOSSING_LONETA_50Q], value: EMBOSSING_LONETA_50Q},
  // {...EMBOSSING_LONETA_50Q_2_MESSAGE[EMBOSSING_LONETA_50Q_2], value: EMBOSSING_LONETA_50Q_2},
  // {...EMBOSSING_LONETA_50Q_ALFAJOR_2_MESSAGE[EMBOSSING_LONETA_50Q_ALFAJOR_2], value: EMBOSSING_LONETA_50Q_ALFAJOR_2},
  // {...EMBOSSING_LONETA_50Q_ALFAJOR_3_MESSAGE[EMBOSSING_LONETA_50Q_ALFAJOR_3], value: EMBOSSING_LONETA_50Q_ALFAJOR_3},
  // {...EMBOSSING_LONETA_50Q_BOOMERANG_BRILLANTE_MESSAGE[EMBOSSING_LONETA_50Q_BOOMERANG_BRILLANTE], value: EMBOSSING_LONETA_50Q_BOOMERANG_BRILLANTE},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE], value: EMBOSSING_LONETA_50Q_BRILLANTE},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_2_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_2], value: EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_2},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3], value: EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3_MATE_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3_MATE], value: EMBOSSING_LONETA_50Q_BRILLANTE_ALFAJOR_3_MATE},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_CHECKER_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_CHECKER], value: EMBOSSING_LONETA_50Q_BRILLANTE_CHECKER},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS], value: EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS_MATE_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS_MATE], value: EMBOSSING_LONETA_50Q_BRILLANTE_CIRCULOS_MATE},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_CUADROS_G_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_CUADROS_G], value: EMBOSSING_LONETA_50Q_BRILLANTE_CUADROS_G},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_EUROPA_RAYAS_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_EUROPA_RAYAS], value: EMBOSSING_LONETA_50Q_BRILLANTE_EUROPA_RAYAS},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_MATE_CAPSULA_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_MATE_CAPSULA], value: EMBOSSING_LONETA_50Q_BRILLANTE_MATE_CAPSULA},
  // {...EMBOSSING_LONETA_50Q_BRILLANTE_PITAGORAS_MESSAGE[EMBOSSING_LONETA_50Q_BRILLANTE_PITAGORAS], value: EMBOSSING_LONETA_50Q_BRILLANTE_PITAGORAS},
  // {...EMBOSSING_LONETA_50Q_CIRCULOS_MESSAGE[EMBOSSING_LONETA_50Q_CIRCULOS], value: EMBOSSING_LONETA_50Q_CIRCULOS},
  // {...EMBOSSING_LONETA_50Q_CIRCULOS_DEGRADE_BRILLANTE_MESSAGE[EMBOSSING_LONETA_50Q_CIRCULOS_DEGRADE_BRILLANTE], value: EMBOSSING_LONETA_50Q_CIRCULOS_DEGRADE_BRILLANTE},
  // {...EMBOSSING_LONETA_50Q_DIABLO_MATE_360_BEMA_MESSAGE[EMBOSSING_LONETA_50Q_DIABLO_MATE_360_BEMA], value: EMBOSSING_LONETA_50Q_DIABLO_MATE_360_BEMA},
  // {...EMBOSSING_LONETA_50Q_DURAZNO_MESSAGE[EMBOSSING_LONETA_50Q_DURAZNO], value: EMBOSSING_LONETA_50Q_DURAZNO},
  // {...EMBOSSING_LONETA_50Q_DURAZNO_BRILLANTE_LIJA_A100_MESSAGE[EMBOSSING_LONETA_50Q_DURAZNO_BRILLANTE_LIJA_A100], value: EMBOSSING_LONETA_50Q_DURAZNO_BRILLANTE_LIJA_A100},
  // {...EMBOSSING_LONETA_50Q_EUROPA_MESSAGE[EMBOSSING_LONETA_50Q_EUROPA], value: EMBOSSING_LONETA_50Q_EUROPA},
  // {...EMBOSSING_LONETA_50Q_EUROPA_BRILLANTE_MESSAGE[EMBOSSING_LONETA_50Q_EUROPA_BRILLANTE], value: EMBOSSING_LONETA_50Q_EUROPA_BRILLANTE},
  // {...EMBOSSING_LONETA_50Q_EUROPA_MOLETTE_18L_BRILLANTE_MESSAGE[EMBOSSING_LONETA_50Q_EUROPA_MOLETTE_18L_BRILLANTE], value: EMBOSSING_LONETA_50Q_EUROPA_MOLETTE_18L_BRILLANTE},
  // {...EMBOSSING_LONETA_50Q_LEVANT_MESSAGE[EMBOSSING_LONETA_50Q_LEVANT], value: EMBOSSING_LONETA_50Q_LEVANT},
  // {...EMBOSSING_LONETA_50Q_MATE_MESSAGE[EMBOSSING_LONETA_50Q_MATE], value: EMBOSSING_LONETA_50Q_MATE},
  // {...EMBOSSING_LONETA_50Q_MATE_360_BEMA_MESSAGE[EMBOSSING_LONETA_50Q_MATE_360_BEMA], value: EMBOSSING_LONETA_50Q_MATE_360_BEMA},
  // {...EMBOSSING_LONETA_50Q_PRISMA_BRILLANTE_MESSAGE[EMBOSSING_LONETA_50Q_PRISMA_BRILLANTE], value: EMBOSSING_LONETA_50Q_PRISMA_BRILLANTE},
  // {...EMBOSSING_LONETA_50Q_ROMBOS_MESSAGE[EMBOSSING_LONETA_50Q_ROMBOS], value: EMBOSSING_LONETA_50Q_ROMBOS},
  // {...EMBOSSING_LONETA_50Q_STATIC_FLOOR_MESSAGE[EMBOSSING_LONETA_50Q_STATIC_FLOOR], value: EMBOSSING_LONETA_50Q_STATIC_FLOOR},
  // {...EMBOSSING_LONETA_50Q_STATIC_FLOOR_BRILLANTE_MESSAGE[EMBOSSING_LONETA_50Q_STATIC_FLOOR_BRILLANTE], value: EMBOSSING_LONETA_50Q_STATIC_FLOOR_BRILLANTE},
  // {...EMBOSSING_LONETA_50Q_SULTAN_MESSAGE[EMBOSSING_LONETA_50Q_SULTAN], value: EMBOSSING_LONETA_50Q_SULTAN},
  // {...EMBOSSING_LOON_SIL_MESSAGE[EMBOSSING_LOON_SIL], value: EMBOSSING_LOON_SIL},
  // {...EMBOSSING_LSR_2_MESSAGE[EMBOSSING_LSR_2], value: EMBOSSING_LSR_2},
  {...EMBOSSING_M_10_MESSAGE[EMBOSSING_M_10], value: EMBOSSING_M_10},
  // {...EMBOSSING_M_11_MESSAGE[EMBOSSING_M_11], value: EMBOSSING_M_11},
  {...EMBOSSING_M_13_MESSAGE[EMBOSSING_M_13], value: EMBOSSING_M_13},
  // {...EMBOSSING_M_14_MESSAGE[EMBOSSING_M_14], value: EMBOSSING_M_14},
  // {...EMBOSSING_M_15_MESSAGE[EMBOSSING_M_15], value: EMBOSSING_M_15},
  // {...EMBOSSING_M_16_MESSAGE[EMBOSSING_M_16], value: EMBOSSING_M_16},
  // {...EMBOSSING_M_17_MESSAGE[EMBOSSING_M_17], value: EMBOSSING_M_17},
  {...EMBOSSING_M_18_MESSAGE[EMBOSSING_M_18], value: EMBOSSING_M_18},
  {...EMBOSSING_M_2_MESSAGE[EMBOSSING_M_2], value: EMBOSSING_M_2},
  // {...EMBOSSING_M_2_CROYDON_MESSAGE[EMBOSSING_M_2_CROYDON], value: EMBOSSING_M_2_CROYDON},
  // {...EMBOSSING_M_2_LIJA_A100_MESSAGE[EMBOSSING_M_2_LIJA_A100], value: EMBOSSING_M_2_LIJA_A100},
  // {...EMBOSSING_M_20_MESSAGE[EMBOSSING_M_20], value: EMBOSSING_M_20},
  // {...EMBOSSING_M_21_MESSAGE[EMBOSSING_M_21], value: EMBOSSING_M_21},
  // {...EMBOSSING_M_22_MESSAGE[EMBOSSING_M_22], value: EMBOSSING_M_22},
  // {...EMBOSSING_M_23_MESSAGE[EMBOSSING_M_23], value: EMBOSSING_M_23},
  // {...EMBOSSING_M_25_MESSAGE[EMBOSSING_M_25], value: EMBOSSING_M_25},
  {...EMBOSSING_M_26_MESSAGE[EMBOSSING_M_26], value: EMBOSSING_M_26},
  // {...EMBOSSING_M_28_MESSAGE[EMBOSSING_M_28], value: EMBOSSING_M_28},
  {...EMBOSSING_M_3_MESSAGE[EMBOSSING_M_3], value: EMBOSSING_M_3},
  // {...EMBOSSING_M_30_MESSAGE[EMBOSSING_M_30], value: EMBOSSING_M_30},
  // {...EMBOSSING_M_38_MESSAGE[EMBOSSING_M_38], value: EMBOSSING_M_38},
  // {...EMBOSSING_M_4_MESSAGE[EMBOSSING_M_4], value: EMBOSSING_M_4},
  // {...EMBOSSING_M_40_MESSAGE[EMBOSSING_M_40], value: EMBOSSING_M_40},
  // {...EMBOSSING_M_41_MESSAGE[EMBOSSING_M_41], value: EMBOSSING_M_41},
  // {...EMBOSSING_M_42_MESSAGE[EMBOSSING_M_42], value: EMBOSSING_M_42},
  // {...EMBOSSING_M_43_MESSAGE[EMBOSSING_M_43], value: EMBOSSING_M_43},
  // {...EMBOSSING_M_44_MESSAGE[EMBOSSING_M_44], value: EMBOSSING_M_44},
  // {...EMBOSSING_M_45_MESSAGE[EMBOSSING_M_45], value: EMBOSSING_M_45},
  // {...EMBOSSING_M_46_MESSAGE[EMBOSSING_M_46], value: EMBOSSING_M_46},
  // {...EMBOSSING_M_48_MESSAGE[EMBOSSING_M_48], value: EMBOSSING_M_48},
  // {...EMBOSSING_M_5_MESSAGE[EMBOSSING_M_5], value: EMBOSSING_M_5},
  // {...EMBOSSING_M_50_MESSAGE[EMBOSSING_M_50], value: EMBOSSING_M_50},
  {...EMBOSSING_M_53_MESSAGE[EMBOSSING_M_53], value: EMBOSSING_M_53},
  {...EMBOSSING_M_54_MESSAGE[EMBOSSING_M_54], value: EMBOSSING_M_54},
  // {...EMBOSSING_M_55_MESSAGE[EMBOSSING_M_55], value: EMBOSSING_M_55},
  // {...EMBOSSING_M_56_MESSAGE[EMBOSSING_M_56], value: EMBOSSING_M_56},
  {...EMBOSSING_M_6_MESSAGE[EMBOSSING_M_6], value: EMBOSSING_M_6},
  // {...EMBOSSING_M_7_MESSAGE[EMBOSSING_M_7], value: EMBOSSING_M_7},
  {...EMBOSSING_M_8_MESSAGE[EMBOSSING_M_8], value: EMBOSSING_M_8},
  // {...EMBOSSING_M_9_MESSAGE[EMBOSSING_M_9], value: EMBOSSING_M_9},
  // {...EMBOSSING_M114_FP_MESSAGE[EMBOSSING_M114_FP], value: EMBOSSING_M114_FP},
  // {...EMBOSSING_M_51_MESSAGE[EMBOSSING_M_51], value: EMBOSSING_M_51},
  // {...EMBOSSING_M_52_MESSAGE[EMBOSSING_M_52], value: EMBOSSING_M_52},
  // {...EMBOSSING_M_59_MESSAGE[EMBOSSING_M_59], value: EMBOSSING_M_59},
  // {...EMBOSSING_M_60_MESSAGE[EMBOSSING_M_60], value: EMBOSSING_M_60},
  // {...EMBOSSING_M87_ELH_MESSAGE[EMBOSSING_M87_ELH], value: EMBOSSING_M87_ELH},
  // {...EMBOSSING_M92_REN_MESSAGE[EMBOSSING_M92_REN], value: EMBOSSING_M92_REN},
  // {...EMBOSSING_MACARENA_MESSAGE[EMBOSSING_MACARENA], value: EMBOSSING_MACARENA},
  {...EMBOSSING_MADRAS_MESSAGE[EMBOSSING_MADRAS], value: EMBOSSING_MADRAS},
  // {...EMBOSSING_MADRAS_MATE_360_MESSAGE[EMBOSSING_MADRAS_MATE_360], value: EMBOSSING_MADRAS_MATE_360},
  // {...EMBOSSING_MADRID_MESSAGE[EMBOSSING_MADRID], value: EMBOSSING_MADRID},
  // {...EMBOSSING_MADRID_II_MESSAGE[EMBOSSING_MADRID_II], value: EMBOSSING_MADRID_II},
  // {...EMBOSSING_MADRID_MATE_360_BEMA_MESSAGE[EMBOSSING_MADRID_MATE_360_BEMA], value: EMBOSSING_MADRID_MATE_360_BEMA},
  // {...EMBOSSING_MADRID_VIEJO_MESSAGE[EMBOSSING_MADRID_VIEJO], value: EMBOSSING_MADRID_VIEJO},
  // {...EMBOSSING_MANGO_MESSAGE[EMBOSSING_MANGO], value: EMBOSSING_MANGO},
  // {...EMBOSSING_MARILYN_MESSAGE[EMBOSSING_MARILYN], value: EMBOSSING_MARILYN},
  // {...EMBOSSING_MARILYN_ROMBOS_MESSAGE[EMBOSSING_MARILYN_ROMBOS], value: EMBOSSING_MARILYN_ROMBOS},
  {...EMBOSSING_MARROCO_MESSAGE[EMBOSSING_MARROCO], value: EMBOSSING_MARROCO},
  // {...EMBOSSING_MARROCO_GAMUZA_2003_2_MESSAGE[EMBOSSING_MARROCO_GAMUZA_2003_2], value: EMBOSSING_MARROCO_GAMUZA_2003_2},
  // {...EMBOSSING_MARROCO_LIJA_A100_MESSAGE[EMBOSSING_MARROCO_LIJA_A100], value: EMBOSSING_MARROCO_LIJA_A100},
  // {...EMBOSSING_MATE_MESSAGE[EMBOSSING_MATE], value: EMBOSSING_MATE},
  // {...EMBOSSING_MATE_CAPSULA_MESSAGE[EMBOSSING_MATE_CAPSULA], value: EMBOSSING_MATE_CAPSULA},
  // {...EMBOSSING_MATE_SAMBL_GF_MATE_250_MESSAGE[EMBOSSING_MATE_SAMBL_GF_MATE_250], value: EMBOSSING_MATE_SAMBL_GF_MATE_250},
  // {...EMBOSSING_MATE_250_MESSAGE[EMBOSSING_MATE_250], value: EMBOSSING_MATE_250},
  {...EMBOSSING_MATE_360_MESSAGE[EMBOSSING_MATE_360], value: EMBOSSING_MATE_360},
  // {...EMBOSSING_MATE_360_BEMA_MESSAGE[EMBOSSING_MATE_360_BEMA], value: EMBOSSING_MATE_360_BEMA},
  // {...EMBOSSING_MATE_360_BEMA_DELFIN_MESSAGE[EMBOSSING_MATE_360_BEMA_DELFIN], value: EMBOSSING_MATE_360_BEMA_DELFIN},
  // {...EMBOSSING_MATE_360_BEMA_ESTRELLAS_MESSAGE[EMBOSSING_MATE_360_BEMA_ESTRELLAS], value: EMBOSSING_MATE_360_BEMA_ESTRELLAS},
  // {...EMBOSSING_MATE_360_BEMA_ESTRELLAS_DURATEX_MESSAGE[EMBOSSING_MATE_360_BEMA_ESTRELLAS_DURATEX], value: EMBOSSING_MATE_360_BEMA_ESTRELLAS_DURATEX},
  // {...EMBOSSING_MATE_360_BEMA_GOAT_MESSAGE[EMBOSSING_MATE_360_BEMA_GOAT], value: EMBOSSING_MATE_360_BEMA_GOAT},
  // {...EMBOSSING_MATE_360_BEMA_IMPALA_MESSAGE[EMBOSSING_MATE_360_BEMA_IMPALA], value: EMBOSSING_MATE_360_BEMA_IMPALA},
  // {...EMBOSSING_MATE_360_BEMA_LEVANT_MESSAGE[EMBOSSING_MATE_360_BEMA_LEVANT], value: EMBOSSING_MATE_360_BEMA_LEVANT},
  // {...EMBOSSING_MATE_360_BEMA_LISBOA_MESSAGE[EMBOSSING_MATE_360_BEMA_LISBOA], value: EMBOSSING_MATE_360_BEMA_LISBOA},
  // {...EMBOSSING_MATE_360_BEMA_MATE_360_MESSAGE[EMBOSSING_MATE_360_BEMA_MATE_360], value: EMBOSSING_MATE_360_BEMA_MATE_360},
  // {...EMBOSSING_MATE_360_BEMA_QUETZAL_LINE_MESSAGE[EMBOSSING_MATE_360_BEMA_QUETZAL_LINE], value: EMBOSSING_MATE_360_BEMA_QUETZAL_LINE},
  // {...EMBOSSING_MATE_360_BEMA_SULTAN_MESSAGE[EMBOSSING_MATE_360_BEMA_SULTAN], value: EMBOSSING_MATE_360_BEMA_SULTAN},
  // {...EMBOSSING_MATE_360_BEMA_TENTACION_G_MESSAGE[EMBOSSING_MATE_360_BEMA_TENTACION_G], value: EMBOSSING_MATE_360_BEMA_TENTACION_G},
  // {...EMBOSSING_MATE_360_BEMA_WARD_MESSAGE[EMBOSSING_MATE_360_BEMA_WARD], value: EMBOSSING_MATE_360_BEMA_WARD},
  // {...EMBOSSING_MATE_360_BEMA_WUMAG_MESSAGE[EMBOSSING_MATE_360_BEMA_WUMAG], value: EMBOSSING_MATE_360_BEMA_WUMAG},
  // {...EMBOSSING_MATE_360_IMPALA_MESSAGE[EMBOSSING_MATE_360_IMPALA], value: EMBOSSING_MATE_360_IMPALA},
  // {...EMBOSSING_MATE_360_LEVANT_MESSAGE[EMBOSSING_MATE_360_LEVANT], value: EMBOSSING_MATE_360_LEVANT},
  // {...EMBOSSING_MATE_360_MOQUETA_MESSAGE[EMBOSSING_MATE_360_MOQUETA], value: EMBOSSING_MATE_360_MOQUETA},
  // {...EMBOSSING_MATE_360_PINT_SB_CARB_MESSAGE[EMBOSSING_MATE_360_PINT_SB_CARB], value: EMBOSSING_MATE_360_PINT_SB_CARB},
  // {...EMBOSSING_MAYA_MESSAGE[EMBOSSING_MAYA], value: EMBOSSING_MAYA},
  // {...EMBOSSING_MAYA_MATE_360_BEMA_MESSAGE[EMBOSSING_MAYA_MATE_360_BEMA], value: EMBOSSING_MAYA_MATE_360_BEMA},
  {...EMBOSSING_MICHAEL_MESSAGE[EMBOSSING_MICHAEL], value: EMBOSSING_MICHAEL},
  // {...EMBOSSING_MICHAEL_ESTRELLAS_MESSAGE[EMBOSSING_MICHAEL_ESTRELLAS], value: EMBOSSING_MICHAEL_ESTRELLAS},
  {...EMBOSSING_MILLED_PEBBLE_MESSAGE[EMBOSSING_MILLED_PEBBLE], value: EMBOSSING_MILLED_PEBBLE},
  {...EMBOSSING_MIMBRE_MESSAGE[EMBOSSING_MIMBRE], value: EMBOSSING_MIMBRE},
  {...EMBOSSING_MINICHECK_MESSAGE[EMBOSSING_MINICHECK], value: EMBOSSING_MINICHECK},
  // {...EMBOSSING_MINICHECK_BRILLANTE_MESSAGE[EMBOSSING_MINICHECK_BRILLANTE], value: EMBOSSING_MINICHECK_BRILLANTE},
  // {...EMBOSSING_MINK_MESSAGE[EMBOSSING_MINK], value: EMBOSSING_MINK},
  // {...EMBOSSING_MOCASIN_MESSAGE[EMBOSSING_MOCASIN], value: EMBOSSING_MOCASIN},
  // {...EMBOSSING_MOCASIN_IMPALA_MESSAGE[EMBOSSING_MOCASIN_IMPALA], value: EMBOSSING_MOCASIN_IMPALA},
  // {...EMBOSSING_MOJAVE_MESSAGE[EMBOSSING_MOJAVE], value: EMBOSSING_MOJAVE},
  // {...EMBOSSING_MOLETTE_MESSAGE[EMBOSSING_MOLETTE], value: EMBOSSING_MOLETTE},
  // {...EMBOSSING_MONACO_MESSAGE[EMBOSSING_MONACO], value: EMBOSSING_MONACO},
  // {...EMBOSSING_MONTANA_MESSAGE[EMBOSSING_MONTANA], value: EMBOSSING_MONTANA},
  // {...EMBOSSING_MONTANA_180_MESSAGE[EMBOSSING_MONTANA_180], value: EMBOSSING_MONTANA_180},
  // {...EMBOSSING_MONTEREY_MESSAGE[EMBOSSING_MONTEREY], value: EMBOSSING_MONTEREY},
  {...EMBOSSING_MONTICELLO_MESSAGE[EMBOSSING_MONTICELLO], value: EMBOSSING_MONTICELLO},
  // {...EMBOSSING_MOQUETA_MESSAGE[EMBOSSING_MOQUETA], value: EMBOSSING_MOQUETA},
  // {...EMBOSSING_MOSAICO_MESSAGE[EMBOSSING_MOSAICO], value: EMBOSSING_MOSAICO},
  // {...EMBOSSING_NAPOLES_MESSAGE[EMBOSSING_NAPOLES], value: EMBOSSING_NAPOLES},
  // {...EMBOSSING_NEPTUNO_MESSAGE[EMBOSSING_NEPTUNO], value: EMBOSSING_NEPTUNO},
  {...EMBOSSING_NEW_MITCH_MESSAGE[EMBOSSING_NEW_MITCH], value: EMBOSSING_NEW_MITCH},
  // {...EMBOSSING_NO_APLICA_MESSAGE[EMBOSSING_NO_APLICA], value: EMBOSSING_NO_APLICA},
  // {...EMBOSSING_NUTRIA_MESSAGE[EMBOSSING_NUTRIA], value: EMBOSSING_NUTRIA},
  {...EMBOSSING_OCAPI_MESSAGE[EMBOSSING_OCAPI], value: EMBOSSING_OCAPI},
  // {...EMBOSSING_OLD_WARD_MESSAGE[EMBOSSING_OLD_WARD], value: EMBOSSING_OLD_WARD},
  // {...EMBOSSING_PAMPERO_MESSAGE[EMBOSSING_PAMPERO], value: EMBOSSING_PAMPERO},
  // {...EMBOSSING_PAMPERO_G_MESSAGE[EMBOSSING_PAMPERO_G], value: EMBOSSING_PAMPERO_G},
  // {...EMBOSSING_PAMPERO_G_MATE_MESSAGE[EMBOSSING_PAMPERO_G_MATE], value: EMBOSSING_PAMPERO_G_MATE},
  // {...EMBOSSING_PANA_MESSAGE[EMBOSSING_PANA], value: EMBOSSING_PANA},
  {...EMBOSSING_PANAMA_MESSAGE[EMBOSSING_PANAMA], value: EMBOSSING_PANAMA},
  // {...EMBOSSING_PANNA_SILK_MESSAGE[EMBOSSING_PANNA_SILK], value: EMBOSSING_PANNA_SILK},
  // {...EMBOSSING_PANORAMA_MESSAGE[EMBOSSING_PANORAMA], value: EMBOSSING_PANORAMA},
  {...EMBOSSING_PAPIRO_MESSAGE[EMBOSSING_PAPIRO], value: EMBOSSING_PAPIRO},
  // {...EMBOSSING_PEBBLE_GRAIN_MESSAGE[EMBOSSING_PEBBLE_GRAIN], value: EMBOSSING_PEBBLE_GRAIN},
  {...EMBOSSING_PECARI_MESSAGE[EMBOSSING_PECARI], value: EMBOSSING_PECARI},
  // {...EMBOSSING_PECARI_M_7_MESSAGE[EMBOSSING_PECARI_M_7], value: EMBOSSING_PECARI_M_7},
  // {...EMBOSSING_PECARI_MATE_360_BEMA_MESSAGE[EMBOSSING_PECARI_MATE_360_BEMA], value: EMBOSSING_PECARI_MATE_360_BEMA},
  {...EMBOSSING_PECARI_180_MESSAGE[EMBOSSING_PECARI_180], value: EMBOSSING_PECARI_180},
  // {...EMBOSSING_PIEL_MESSAGE[EMBOSSING_PIEL], value: EMBOSSING_PIEL},
  // {...EMBOSSING_PIEL_DE_SAPA_MESSAGE[EMBOSSING_PIEL_DE_SAPA], value: EMBOSSING_PIEL_DE_SAPA},
  {...EMBOSSING_PINT_SB_MESSAGE[EMBOSSING_PINT_SB], value: EMBOSSING_PINT_SB},
  // {...EMBOSSING_PINT_SB_CARB_MESSAGE[EMBOSSING_PINT_SB_CARB], value: EMBOSSING_PINT_SB_CARB},
  // {...EMBOSSING_PINT_SB_ESP_MESSAGE[EMBOSSING_PINT_SB_ESP], value: EMBOSSING_PINT_SB_ESP},
  // {...EMBOSSING_PINT_SB_ESP_ESTRELLAS_MESSAGE[EMBOSSING_PINT_SB_ESP_ESTRELLAS], value: EMBOSSING_PINT_SB_ESP_ESTRELLAS},
  // {...EMBOSSING_PINT_SB_ESP_MATE_MESSAGE[EMBOSSING_PINT_SB_ESP_MATE], value: EMBOSSING_PINT_SB_ESP_MATE},
  // {...EMBOSSING_PINT_SB_ESP_MATE_360_MESSAGE[EMBOSSING_PINT_SB_ESP_MATE_360], value: EMBOSSING_PINT_SB_ESP_MATE_360},
  // {...EMBOSSING_PINT_SB_ESP_MATE_360_BEMA_MESSAGE[EMBOSSING_PINT_SB_ESP_MATE_360_BEMA], value: EMBOSSING_PINT_SB_ESP_MATE_360_BEMA},
  // {...EMBOSSING_PINT_SB_ESTRELLAS_MESSAGE[EMBOSSING_PINT_SB_ESTRELLAS], value: EMBOSSING_PINT_SB_ESTRELLAS},
  // {...EMBOSSING_PINT_SB_MATE_360_MESSAGE[EMBOSSING_PINT_SB_MATE_360], value: EMBOSSING_PINT_SB_MATE_360},
  // {...EMBOSSING_PINT_SB_MATE_360_BEMA_MESSAGE[EMBOSSING_PINT_SB_MATE_360_BEMA], value: EMBOSSING_PINT_SB_MATE_360_BEMA},
  {...EMBOSSING_PIQUE_MESSAGE[EMBOSSING_PIQUE], value: EMBOSSING_PIQUE},
  // {...EMBOSSING_PIQUE_MATE_360_BEMA_MESSAGE[EMBOSSING_PIQUE_MATE_360_BEMA], value: EMBOSSING_PIQUE_MATE_360_BEMA},
  {...EMBOSSING_PITAGORAS_MESSAGE[EMBOSSING_PITAGORAS], value: EMBOSSING_PITAGORAS},
  // {...EMBOSSING_PORSCHE_MESSAGE[EMBOSSING_PORSCHE], value: EMBOSSING_PORSCHE},
  // {...EMBOSSING_PREMIER_MESSAGE[EMBOSSING_PREMIER], value: EMBOSSING_PREMIER},
  // {...EMBOSSING_PREMIER_CUERO_DE_VACA_MATE_360_BEMA_MESSAGE[EMBOSSING_PREMIER_CUERO_DE_VACA_MATE_360_BEMA], value: EMBOSSING_PREMIER_CUERO_DE_VACA_MATE_360_BEMA},
  // {...EMBOSSING_PREMIER_LIJA_A100_MESSAGE[EMBOSSING_PREMIER_LIJA_A100], value: EMBOSSING_PREMIER_LIJA_A100},
  {...EMBOSSING_PRISMA_MESSAGE[EMBOSSING_PRISMA], value: EMBOSSING_PRISMA},
  // {...EMBOSSING_PRISMA_MATE_MESSAGE[EMBOSSING_PRISMA_MATE], value: EMBOSSING_PRISMA_MATE},
  {...EMBOSSING_PUMA_MESSAGE[EMBOSSING_PUMA], value: EMBOSSING_PUMA},
  // {...EMBOSSING_PUMA_CIERVO_MESSAGE[EMBOSSING_PUMA_CIERVO], value: EMBOSSING_PUMA_CIERVO},
  // {...EMBOSSING_PUMA_GAMUZA_2003_2_MESSAGE[EMBOSSING_PUMA_GAMUZA_2003_2], value: EMBOSSING_PUMA_GAMUZA_2003_2},
  {...EMBOSSING_QUETZAL_MESSAGE[EMBOSSING_QUETZAL], value: EMBOSSING_QUETZAL},
  // {...EMBOSSING_QUETZAL_GAM_MET_MESSAGE[EMBOSSING_QUETZAL_GAM_MET], value: EMBOSSING_QUETZAL_GAM_MET},
  // {...EMBOSSING_QUETZAL_II_MESSAGE[EMBOSSING_QUETZAL_II], value: EMBOSSING_QUETZAL_II},
  {...EMBOSSING_QUETZAL_LINE_MESSAGE[EMBOSSING_QUETZAL_LINE], value: EMBOSSING_QUETZAL_LINE},
  // {...EMBOSSING_QUETZAL_MATE_MESSAGE[EMBOSSING_QUETZAL_MATE], value: EMBOSSING_QUETZAL_MATE},
  // {...EMBOSSING_QUETZAL_MATE_360_BEMA_MESSAGE[EMBOSSING_QUETZAL_MATE_360_BEMA], value: EMBOSSING_QUETZAL_MATE_360_BEMA},
  // {...EMBOSSING_RAYO_MESSAGE[EMBOSSING_RAYO], value: EMBOSSING_RAYO},
  // {...EMBOSSING_REBECO_SIL_MESSAGE[EMBOSSING_REBECO_SIL], value: EMBOSSING_REBECO_SIL},
  // {...EMBOSSING_RECANAL_MESSAGE[EMBOSSING_RECANAL], value: EMBOSSING_RECANAL},
  // {...EMBOSSING_RED_MESSAGE[EMBOSSING_RED], value: EMBOSSING_RED},
  // {...EMBOSSING_RENO_MESSAGE[EMBOSSING_RENO], value: EMBOSSING_RENO},
  // {...EMBOSSING_RENO_MATE_360_BEMA_MESSAGE[EMBOSSING_RENO_MATE_360_BEMA], value: EMBOSSING_RENO_MATE_360_BEMA},
  // {...EMBOSSING_RENO_TETRA_MESSAGE[EMBOSSING_RENO_TETRA], value: EMBOSSING_RENO_TETRA},
  {...EMBOSSING_RICE_PLAIN_LINEN_MESSAGE[EMBOSSING_RICE_PLAIN_LINEN], value: EMBOSSING_RICE_PLAIN_LINEN},
  // {...EMBOSSING_RINO_MESSAGE[EMBOSSING_RINO], value: EMBOSSING_RINO},
  // {...EMBOSSING_ROLAND_MESSAGE[EMBOSSING_ROLAND], value: EMBOSSING_ROLAND},
  // {...EMBOSSING_ROMBO_MESSAGE[EMBOSSING_ROMBO], value: EMBOSSING_ROMBO},
  // {...EMBOSSING_ROMBO_MATE_360_BEMA_MESSAGE[EMBOSSING_ROMBO_MATE_360_BEMA], value: EMBOSSING_ROMBO_MATE_360_BEMA},
  // {...EMBOSSING_ROMBOS_MESSAGE[EMBOSSING_ROMBOS], value: EMBOSSING_ROMBOS},
  // {...EMBOSSING_ROMBOTEX_MESSAGE[EMBOSSING_ROMBOTEX], value: EMBOSSING_ROMBOTEX},
  // {...EMBOSSING_ROSAS_MESSAGE[EMBOSSING_ROSAS], value: EMBOSSING_ROSAS},
  // {...EMBOSSING_ROSSETE_MESSAGE[EMBOSSING_ROSSETE], value: EMBOSSING_ROSSETE},
  // {...EMBOSSING_RR_MESSAGE[EMBOSSING_RR], value: EMBOSSING_RR},
  // {...EMBOSSING_RR_MATE_360_BEMA_MESSAGE[EMBOSSING_RR_MATE_360_BEMA], value: EMBOSSING_RR_MATE_360_BEMA},
  // {...EMBOSSING_RUFFINO_MESSAGE[EMBOSSING_RUFFINO], value: EMBOSSING_RUFFINO},
  // {...EMBOSSING_RUSTIC_MESSAGE[EMBOSSING_RUSTIC], value: EMBOSSING_RUSTIC},
  // {...EMBOSSING_RY_MESSAGE[EMBOSSING_RY], value: EMBOSSING_RY},
  // {...EMBOSSING_RY_LIJA_A100_MESSAGE[EMBOSSING_RY_LIJA_A100], value: EMBOSSING_RY_LIJA_A100},
  // {...EMBOSSING_RY_MATE_360_BEMA_MESSAGE[EMBOSSING_RY_MATE_360_BEMA], value: EMBOSSING_RY_MATE_360_BEMA},
  // {...EMBOSSING_S_1_G_MESSAGE[EMBOSSING_S_1_G], value: EMBOSSING_S_1_G},
  // {...EMBOSSING_SAFARI_MESSAGE[EMBOSSING_SAFARI], value: EMBOSSING_SAFARI},
  {...EMBOSSING_SATIN_MESSAGE[EMBOSSING_SATIN], value: EMBOSSING_SATIN},
  // {...EMBOSSING_SATIN_BRILLANTE_MESSAGE[EMBOSSING_SATIN_BRILLANTE], value: EMBOSSING_SATIN_BRILLANTE},
  // {...EMBOSSING_SATIN_CUADROS_G_MATE_MESSAGE[EMBOSSING_SATIN_CUADROS_G_MATE], value: EMBOSSING_SATIN_CUADROS_G_MATE},
  {...EMBOSSING_SATIN_II_MESSAGE[EMBOSSING_SATIN_II], value: EMBOSSING_SATIN_II},
  // {...EMBOSSING_SATIN_II_MATE_360_MESSAGE[EMBOSSING_SATIN_II_MATE_360], value: EMBOSSING_SATIN_II_MATE_360},
  // {...EMBOSSING_SATIN_II_MATE_360_BEMA_MESSAGE[EMBOSSING_SATIN_II_MATE_360_BEMA], value: EMBOSSING_SATIN_II_MATE_360_BEMA},
  // {...EMBOSSING_SATIN_II_PINT_SB_MESSAGE[EMBOSSING_SATIN_II_PINT_SB], value: EMBOSSING_SATIN_II_PINT_SB},
  // {...EMBOSSING_SATIN_MATE_MESSAGE[EMBOSSING_SATIN_MATE], value: EMBOSSING_SATIN_MATE},
  // {...EMBOSSING_SATIN_MATE_360_BEMA_MESSAGE[EMBOSSING_SATIN_MATE_360_BEMA], value: EMBOSSING_SATIN_MATE_360_BEMA},
  // {...EMBOSSING_SATIN_SATIN_II_MATE_MESSAGE[EMBOSSING_SATIN_SATIN_II_MATE], value: EMBOSSING_SATIN_SATIN_II_MATE},
  // {...EMBOSSING_SAURIO_MESSAGE[EMBOSSING_SAURIO], value: EMBOSSING_SAURIO},
  // {...EMBOSSING_SCRIBBLE_MESSAGE[EMBOSSING_SCRIBBLE], value: EMBOSSING_SCRIBBLE},
  // {...EMBOSSING_SEDA_FILM_SIL_MESSAGE[EMBOSSING_SEDA_FILM_SIL], value: EMBOSSING_SEDA_FILM_SIL},
  // {...EMBOSSING_SEDAMET_MESSAGE[EMBOSSING_SEDAMET], value: EMBOSSING_SEDAMET},
  {...EMBOSSING_SEQUINS_MESSAGE[EMBOSSING_SEQUINS], value: EMBOSSING_SEQUINS},
  {...EMBOSSING_SEVILLA_MESSAGE[EMBOSSING_SEVILLA], value: EMBOSSING_SEVILLA},
  // {...EMBOSSING_SIL_429_MESSAGE[EMBOSSING_SIL_429], value: EMBOSSING_SIL_429},
  // {...EMBOSSING_SILVER_MESSAGE[EMBOSSING_SILVER], value: EMBOSSING_SILVER},
  // {...EMBOSSING_SINU_MESSAGE[EMBOSSING_SINU], value: EMBOSSING_SINU},
  // {...EMBOSSING_SINU_MATE_360_BEMA_MESSAGE[EMBOSSING_SINU_MATE_360_BEMA], value: EMBOSSING_SINU_MATE_360_BEMA},
  // {...EMBOSSING_SKYLINE_MESSAGE[EMBOSSING_SKYLINE], value: EMBOSSING_SKYLINE},
  // {...EMBOSSING_SN_361_MESSAGE[EMBOSSING_SN_361], value: EMBOSSING_SN_361},
  // {...EMBOSSING_SOFT_TORINO_MESSAGE[EMBOSSING_SOFT_TORINO], value: EMBOSSING_SOFT_TORINO},
  // {...EMBOSSING_SOHO_MESSAGE[EMBOSSING_SOHO], value: EMBOSSING_SOHO},
  // {...EMBOSSING_SORRENTO_MESSAGE[EMBOSSING_SORRENTO], value: EMBOSSING_SORRENTO},
  // {...EMBOSSING_SORRENTO_ESTRELLAS_MESSAGE[EMBOSSING_SORRENTO_ESTRELLAS], value: EMBOSSING_SORRENTO_ESTRELLAS},
  // {...EMBOSSING_SORRENTO_MATE_360_BEMA_ESTRELLAS_MESSAGE[EMBOSSING_SORRENTO_MATE_360_BEMA_ESTRELLAS], value: EMBOSSING_SORRENTO_MATE_360_BEMA_ESTRELLAS},
  // {...EMBOSSING_STITCH_MESSAGE[EMBOSSING_STITCH], value: EMBOSSING_STITCH},
  // {...EMBOSSING_SULTAN_MESSAGE[EMBOSSING_SULTAN], value: EMBOSSING_SULTAN},
  // {...EMBOSSING_SURIKEN_MESSAGE[EMBOSSING_SURIKEN], value: EMBOSSING_SURIKEN},
  // {...EMBOSSING_SUTTON_MESSAGE[EMBOSSING_SUTTON], value: EMBOSSING_SUTTON},
  {...EMBOSSING_TAIRONA_MESSAGE[EMBOSSING_TAIRONA], value: EMBOSSING_TAIRONA},
  // {...EMBOSSING_TAPIN_MESSAGE[EMBOSSING_TAPIN], value: EMBOSSING_TAPIN},
  // {...EMBOSSING_TAURO_MESSAGE[EMBOSSING_TAURO], value: EMBOSSING_TAURO},
  // {...EMBOSSING_TED_LAPIDUS_MESSAGE[EMBOSSING_TED_LAPIDUS], value: EMBOSSING_TED_LAPIDUS},
  // {...EMBOSSING_TEJANO_MESSAGE[EMBOSSING_TEJANO], value: EMBOSSING_TEJANO},
  // {...EMBOSSING_TEJIDO_MESSAGE[EMBOSSING_TEJIDO], value: EMBOSSING_TEJIDO},
  // {...EMBOSSING_TEJON_SIL_MESSAGE[EMBOSSING_TEJON_SIL], value: EMBOSSING_TEJON_SIL},
  // {...EMBOSSING_TELA_ALEMANA_MESSAGE[EMBOSSING_TELA_ALEMANA], value: EMBOSSING_TELA_ALEMANA},
  // {...EMBOSSING_TELA_ALEMANA_BRILLANTE_MESSAGE[EMBOSSING_TELA_ALEMANA_BRILLANTE], value: EMBOSSING_TELA_ALEMANA_BRILLANTE},
  {...EMBOSSING_TENTACION_G_MESSAGE[EMBOSSING_TENTACION_G], value: EMBOSSING_TENTACION_G},
  {...EMBOSSING_TETRA_MESSAGE[EMBOSSING_TETRA], value: EMBOSSING_TETRA},
  // {...EMBOSSING_TETRA_BENGALA_MESSAGE[EMBOSSING_TETRA_BENGALA], value: EMBOSSING_TETRA_BENGALA},
  // {...EMBOSSING_TETRA_CARIBE_MESSAGE[EMBOSSING_TETRA_CARIBE], value: EMBOSSING_TETRA_CARIBE},
  // {...EMBOSSING_TETRA_CHROMATA_MESSAGE[EMBOSSING_TETRA_CHROMATA], value: EMBOSSING_TETRA_CHROMATA},
  // {...EMBOSSING_TETRA_CUERO_DE_VACA_MESSAGE[EMBOSSING_TETRA_CUERO_DE_VACA], value: EMBOSSING_TETRA_CUERO_DE_VACA},
  // {...EMBOSSING_TETRA_DELFIN_MESSAGE[EMBOSSING_TETRA_DELFIN], value: EMBOSSING_TETRA_DELFIN},
  // {...EMBOSSING_TETRA_DIABLO_MESSAGE[EMBOSSING_TETRA_DIABLO], value: EMBOSSING_TETRA_DIABLO},
  // {...EMBOSSING_TETRA_GOAT_MESSAGE[EMBOSSING_TETRA_GOAT], value: EMBOSSING_TETRA_GOAT},
  // {...EMBOSSING_TETRA_GRANE_MESSAGE[EMBOSSING_TETRA_GRANE], value: EMBOSSING_TETRA_GRANE},
  // {...EMBOSSING_TETRA_JACKSON_MESSAGE[EMBOSSING_TETRA_JACKSON], value: EMBOSSING_TETRA_JACKSON},
  // {...EMBOSSING_TETRA_M_2_MESSAGE[EMBOSSING_TETRA_M_2], value: EMBOSSING_TETRA_M_2},
  // {...EMBOSSING_TETRA_SKYLINE_MESSAGE[EMBOSSING_TETRA_SKYLINE], value: EMBOSSING_TETRA_SKYLINE},
  // {...EMBOSSING_TETRA_TAIRONA_MESSAGE[EMBOSSING_TETRA_TAIRONA], value: EMBOSSING_TETRA_TAIRONA},
  // {...EMBOSSING_TETRA_THOMAS_EDO_MESSAGE[EMBOSSING_TETRA_THOMAS_EDO], value: EMBOSSING_TETRA_THOMAS_EDO},
  // {...EMBOSSING_TETRALINE_MESSAGE[EMBOSSING_TETRALINE], value: EMBOSSING_TETRALINE},
  // {...EMBOSSING_TEXTURED_GRID_MESSAGE[EMBOSSING_TEXTURED_GRID], value: EMBOSSING_TEXTURED_GRID},
  {...EMBOSSING_THOMAS_EDO_MESSAGE[EMBOSSING_THOMAS_EDO], value: EMBOSSING_THOMAS_EDO},
  // {...EMBOSSING_THOMAS_EDO_2_MESSAGE[EMBOSSING_THOMAS_EDO_2], value: EMBOSSING_THOMAS_EDO_2},
  // {...EMBOSSING_THOMAS_EDO_MATE_360_MESSAGE[EMBOSSING_THOMAS_EDO_MATE_360], value: EMBOSSING_THOMAS_EDO_MATE_360},
  // {...EMBOSSING_TORINO_MESSAGE[EMBOSSING_TORINO], value: EMBOSSING_TORINO},
  // {...EMBOSSING_TORINO_MATE_360_MESSAGE[EMBOSSING_TORINO_MATE_360], value: EMBOSSING_TORINO_MATE_360},
  // {...EMBOSSING_TORONADO_MESSAGE[EMBOSSING_TORONADO], value: EMBOSSING_TORONADO},
  // {...EMBOSSING_TREBOL_MESSAGE[EMBOSSING_TREBOL], value: EMBOSSING_TREBOL},
  {...EMBOSSING_TRENZADO_MESSAGE[EMBOSSING_TRENZADO], value: EMBOSSING_TRENZADO},
  // {...EMBOSSING_TRENZADO_2_MESSAGE[EMBOSSING_TRENZADO_2], value: EMBOSSING_TRENZADO_2},
  // {...EMBOSSING_TWIGABELLA_MESSAGE[EMBOSSING_TWIGABELLA], value: EMBOSSING_TWIGABELLA},
  // {...EMBOSSING_VELO_FILM_SIL_MESSAGE[EMBOSSING_VELO_FILM_SIL], value: EMBOSSING_VELO_FILM_SIL},
  // {...EMBOSSING_VENADO_MESSAGE[EMBOSSING_VENADO], value: EMBOSSING_VENADO},
  // {...EMBOSSING_VENTURA_MESSAGE[EMBOSSING_VENTURA], value: EMBOSSING_VENTURA},
  // {...EMBOSSING_VER_OBSERVACIONES_MESSAGE[EMBOSSING_VER_OBSERVACIONES], value: EMBOSSING_VER_OBSERVACIONES},
  // {...EMBOSSING_VINILEXPORT_MESSAGE[EMBOSSING_VINILEXPORT], value: EMBOSSING_VINILEXPORT},
  // {...EMBOSSING_VOLKSWAGEN_MESSAGE[EMBOSSING_VOLKSWAGEN], value: EMBOSSING_VOLKSWAGEN},
  {...EMBOSSING_WALLABY_MESSAGE[EMBOSSING_WALLABY], value: EMBOSSING_WALLABY},
  // {...EMBOSSING_WALLABY_3_MESSAGE[EMBOSSING_WALLABY_3], value: EMBOSSING_WALLABY_3},
  // {...EMBOSSING_WALLABY_CONTINENTAL_MATE_MESSAGE[EMBOSSING_WALLABY_CONTINENTAL_MATE], value: EMBOSSING_WALLABY_CONTINENTAL_MATE},
  // {...EMBOSSING_WALLABY_MATE_MESSAGE[EMBOSSING_WALLABY_MATE], value: EMBOSSING_WALLABY_MATE},
  // {...EMBOSSING_WALLABY_MATE_MOQUETA_MESSAGE[EMBOSSING_WALLABY_MATE_MOQUETA], value: EMBOSSING_WALLABY_MATE_MOQUETA},
  // {...EMBOSSING_WALLABY_MATE_360_MESSAGE[EMBOSSING_WALLABY_MATE_360], value: EMBOSSING_WALLABY_MATE_360},
  // {...EMBOSSING_WALLABY_MATE_360_BEMA_MESSAGE[EMBOSSING_WALLABY_MATE_360_BEMA], value: EMBOSSING_WALLABY_MATE_360_BEMA},
  // {...EMBOSSING_WARD_MESSAGE[EMBOSSING_WARD], value: EMBOSSING_WARD},
  {...EMBOSSING_WARD_180_MESSAGE[EMBOSSING_WARD_180], value: EMBOSSING_WARD_180},
  {...EMBOSSING_WEAVE_MESSAGE[EMBOSSING_WEAVE], value: EMBOSSING_WEAVE},
  {...EMBOSSING_WEAVEREALEAF_MESSAGE[EMBOSSING_WEAVEREALEAF], value: EMBOSSING_WEAVEREALEAF},
  // {...EMBOSSING_WIRE_CROSS_MESSAGE[EMBOSSING_WIRE_CROSS], value: EMBOSSING_WIRE_CROSS},
  // {...EMBOSSING_WIRE_PLAID_MESSAGE[EMBOSSING_WIRE_PLAID], value: EMBOSSING_WIRE_PLAID},
  {...EMBOSSING_W_JUXY_MESSAGE[EMBOSSING_W_JUXY], value: EMBOSSING_W_JUXY},
  // {...EMBOSSING_WUMAG_MESSAGE[EMBOSSING_WUMAG], value: EMBOSSING_WUMAG},
  // {...EMBOSSING_YAK_MESSAGE[EMBOSSING_YAK], value: EMBOSSING_YAK},
  // {...EMBOSSING_YUTE_MESSAGE[EMBOSSING_YUTE], value: EMBOSSING_YUTE},
  // {...EMBOSSING_ZEBRA_MESSAGE[EMBOSSING_ZEBRA], value: EMBOSSING_ZEBRA},
  {...EMBOSSING_ZIRCON_MESSAGE[EMBOSSING_ZIRCON], value: EMBOSSING_ZIRCON},
];

