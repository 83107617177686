import {connect} from "react-redux";
import MainMenuLocales from "../index";
import {changeLanguage} from "intl/actions";

const mapStateToProps = (state) => {
  return {
    localesReducer: state.localesReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: language => dispatch(changeLanguage(language))
  };
};

const MainMenuLocalesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainMenuLocales);

export default MainMenuLocalesContainer;