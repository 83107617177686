import React from "react";
import FiltersBarContainer from "scenes/ProductsPage/components/FiltersBar/container";
import {FormattedMessage} from "react-intl";
import "./styles/ProductsList.scss";
import ProductsList from "scenes/ProductsPage/components/ProductsList";

const ProductsPage = (props) => (
    <div className="products-page">
      <div className="container">
        <div className="row no-gutters regular-gutters-md">
          <div className="col-12">
            <h1 className="products-page-title mt-gutter-small">
              <FormattedMessage
                  id="ProductsList.title"
                  description="Products list page title"
                  defaultMessage="Custom Search"/>
            </h1>
          </div>
          <div className="col-12 col-md-4 col-xl-3">
            <FiltersBarContainer/>
          </div>
          <div className="col-12 col-md-8 col-xl-9">
            <ProductsList/>
          </div>
        </div>
      </div>
    </div>
);

export default ProductsPage;