import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import {getParsedQueryParams, updateQueryParams} from "services/browser-history";
import {QUERY_PARAM_SEARCH} from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
import ProductList from "scenes/ProductsPage/components/ProductsList/index";
import {withRouter} from "react-router";
import "./styles/SearchInput.scss";
import {FormattedMessage} from "react-intl";

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: getParsedQueryParams()[QUERY_PARAM_SEARCH] || ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props;
    if (location.search !== prevProps.location.search) {
      this.setState({
        searchValue: getParsedQueryParams()[QUERY_PARAM_SEARCH] || ""
      })
    }
  }

  /**
   * Update filters query params with current searchValue and reset the paginations filters
   */
  updateSearchParam() {
    const {searchValue} = this.state;
    updateQueryParams({
      [QUERY_PARAM_SEARCH]: searchValue,
      ...ProductList.resettled_pagination_query_params()
    })
  }

  /**
   * Debounce updateSearchParam calls
   * @type {Function}
   */
  debounceSearchParam = debounce(this.updateSearchParam, 500);

  /**
   * Handles input text changes and set value to searchValue state
   * @param e
   */
  handleInputChange(e) {
    this.setState({
      searchValue: e.target.value
    }, () => {
      this.debounceSearchParam()
    })
  }


  render() {
    const {searchValue} = this.state;
    return (
        <div className="products-search-input">
          <div className="form-group mb-0">
            <FormattedMessage
                id="SearchInput.inputPlaceholder"
                description="Placeholder for products list search box"
                defaultMessage="Search by name...">
              {
                placeholder =>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={placeholder}
                        onChange={this.handleInputChange}
                        value={searchValue}/>
              }
            </FormattedMessage>
            <i className="fa fa-search"/>
          </div>
        </div>
    )
  }
}

SearchInput.propTypes = {};

export default withRouter(SearchInput);