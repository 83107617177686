/**
 * Checks if a given type node is a product categorization node.
 *
 * @param {string} typeNode - The type node to check.
 * @returns {boolean} - Returns true if the type node includes "ProductCategorization", otherwise returns false.
 */
export function isProductCategorizationNode(typeNode) {
  if (typeNode.includes("ProductCategorization")) return true;
}

/**
 * Converts the text to uppercase, with the first letter capitalized and the rest of the text in lowercase.
 * @param {string} text - The text to convert.
 * @returns {string} - The converted text.
 */
function capitalizedText(text) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

/**
 * Replaces old attributes with new attributes for product categorization.
 * @param {Object} data - The data object containing the attributes to be migrated.
 * @returns {Object} - The updated data object with migrated attributes.
 */
export function productCategorizationReplaceOldAttributes(data) {
  const keysToMigrate = ["newApplication", "application", "newMarketSegment", "marketSegment", "newNiche", "niche"];
  const attributesToMigrate = {};

  for (const [key, value] of Object.entries(data)) {
    if (keysToMigrate.includes(key)) {
      attributesToMigrate[key] = capitalizedText(value);
      delete data[key];
    }
  }

  for (const [key, value] of Object.entries(attributesToMigrate)) {
    if (key.includes("new")) {
      if (value) {
        const newKey = key.replace("new", "");
        const convertTheKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
        attributesToMigrate[convertTheKey] = capitalizedText(value);
      }
      delete attributesToMigrate[key];
    }
  }
 
  return {...attributesToMigrate, ...data};
}