import {defineMessages} from "react-intl";

/**
 *
 * @param intl
 * @return Object
 */
const translatedDisplayData = intl => {
  return {
    weight: intl.formatMessage(
        defineMessages({
          weight: {
            id: "ProductSpecAccordionBody.weight",
            description: "'weight' word in single product spec accordion",
            defaultMessage: "Weight"
          }
        }).weight
    ),
    width: intl.formatMessage(
        defineMessages({
          width: {
            id: "ProductSpecAccordionBody.width",
            description: "'width' word in single product spec accordion",
            defaultMessage: "Width"
          }
        }).width
    ),
    gauge: intl.formatMessage(
        defineMessages({
          gauge: {
            id: "ProductSpecAccordionBody.gauge",
            description: "'gauge' word in single product spec accordion",
            defaultMessage: "Gauge"
          }
        }).gauge
    ),
    embossing: intl.formatMessage(
        defineMessages({
          embossing: {
            id: "ProductSpecAccordionBody.embossing",
            description: "'embossing' word in single product spec accordion",
            defaultMessage: "Embossing"
          }
        }).embossing
    ),
    topcoat: intl.formatMessage(
        defineMessages({
          topcoat: {
            id: "ProductSpecAccordionBody.topcoat",
            description: "'topcoat' word in single product spec accordion",
            defaultMessage: "Topcoat"
          }
        }).topcoat
    ),
    pinholes: intl.formatMessage(
        defineMessages({
          pinholes: {
            id: "ProductSpecAccordionBody.pinholes",
            description: "'pinholes' word in single product spec accordion",
            defaultMessage: "Pinholes / Square inch"
          }
        }).pinholes
    ),
    rollLength: intl.formatMessage(
        defineMessages({
          rollLength: {
            id: "ProductSpecAccordionBody.rollLength",
            description: "'rollLength' word in single product spec accordion",
            defaultMessage: "Roll Length"
          }
        }).rollLength
    ),
    constructionType: intl.formatMessage(
        defineMessages({
          constructionType: {
            id: "ProductSpecAccordionBody.constructionType",
            description: "'constructionType' word in single product spec accordion",
            defaultMessage: "Construction Type"
          }
        }).constructionType
    ),
    printType: intl.formatMessage(
        defineMessages({
          printType: {
            id: "ProductSpecAccordionBody.printType",
            description: "'printType' word in single product spec accordion",
            defaultMessage: "Print Type"
          }
        }).printType
    ),
    gloss: intl.formatMessage(
        defineMessages({
          gloss: {
            id: "ProductSpecAccordionBody.gloss",
            description: "'gloss' word in single product spec accordion",
            defaultMessage: "Gloss"
          }
        }).gloss
    ),
    piecesNumber: intl.formatMessage(
        defineMessages({
          piecesNumber: {
            id: "ProductSpecAccordionBody.piecesNumber",
            description: "'piecesNumber' word in single product spec accordion",
            defaultMessage: "Number of pieces"
          }
        }).piecesNumber
    ),
    minPieceNumber: intl.formatMessage(
        defineMessages({
          minPieceNumber: {
            id: "ProductSpecAccordionBody.minPieceNumber",
            description: "'minPieceNumber' word in single product spec accordion",
            defaultMessage: "Min. number of pieces"
          }
        }).minPieceNumber
    ),
    package: intl.formatMessage(
        defineMessages({
          package: {
            id: "ProductSpecAccordionBody.package",
            description: "'package' word in single product spec accordion",
            defaultMessage: "Package"
          }
        }).package
    ),
    storage: intl.formatMessage(
        defineMessages({
          storage: {
            id: "ProductSpecAccordionBody.storage",
            description: "'storage' word in single product spec accordion",
            defaultMessage: "Storage"
          }
        }).storage
    ),
    storageRollPosition: intl.formatMessage(
        defineMessages({
          storageRollPosition: {
            id: "ProductSpecAccordionBody.storageRollPosition",
            description: "'storageRollPosition' word in single product spec accordion",
            defaultMessage: "Storage roll position"
          }
        }).storageRollPosition
    ),
    polymerCompositionPercentage: intl.formatMessage(
        defineMessages({
          polymerCompositionPercentage: {
            id: "ProductSpecAccordionBody.polymerCompositionPercentage",
            description: "'polymerCompositionPercentage' word in single product spec accordion",
            defaultMessage: "Polymer composition %"
          }
        }).polymerCompositionPercentage
    ),
    backingCompositionPercentage: intl.formatMessage(
        defineMessages({
          backingCompositionPercentage: {
            id: "ProductSpecAccordionBody.backingCompositionPercentage",
            description: "'backingCompositionPercentage' word in single product spec accordion",
            defaultMessage: "Backing composition %"
          }
        }).backingCompositionPercentage
    ),
    price: intl.formatMessage(
        defineMessages({
          price: {
            id: "ProductSpecAccordionBody.price",
            description: "'price' word in single product spec accordion",
            defaultMessage: "Price"
          }
        }).price
    ),
    unitPrice: intl.formatMessage(
        defineMessages({
          unitPrice: {
            id: "ProductSpecAccordionBody.unitPrice",
            description: "'unitPrice' word in single product spec accordion",
            defaultMessage: "Unit price"
          }
        }).unitPrice
    ),
    type: intl.formatMessage(
        defineMessages({
          type: {
            id: "ProductSpecAccordionBody.type",
            description: "'type' word in single product spec accordion",
            defaultMessage: "Type"
          }
        }).type
    ),
    description: intl.formatMessage(
        defineMessages({
          description: {
            id: "ProductSpecAccordionBody.description",
            description: "'description' word in single product spec accordion",
            defaultMessage: "Description"
          }
        }).description
    ),
    antiStatic: intl.formatMessage(
        defineMessages({
          antiStatic: {
            id: "ProductSpecAccordionBody.antiStatic",
            description: "'Anti-Static' word in single product spec accordion",
            defaultMessage: "Anti-Static"
          }
        }).antiStatic
    ),
    antiStaticConductive: intl.formatMessage(
        defineMessages({
          antiStaticConductive: {
            id: "ProductSpecAccordionBody.antiStaticConductive",
            description: "'Anti-Static - Conductive' word in single product spec accordion",
            defaultMessage: "Anti-Static - Conductive"
          }
        }).antiStaticConductive
    ),
    antiBacterial: intl.formatMessage(
        defineMessages({
          antiBacterial: {
            id: "ProductSpecAccordionBody.antiBacterial",
            description: "'Anti-Bacterial' word in single product spec accordion",
            defaultMessage: "Anti-Bacterial"
          }
        }).antiBacterial
    ),
    bacterialResistance: intl.formatMessage(
        defineMessages({
          bacterialResistance: {
            id: "ProductSpecAccordionBody.bacterialResistance",
            description: "'Bacterial Resistant' word in single product spec accordion",
            defaultMessage: "Bacterial Resistant"
          }
        }).bacterialResistance
    ),
    mildewResistance: intl.formatMessage(
        defineMessages({
          mildewResistance: {
            id: "ProductSpecAccordionBody.mildewResistance",
            description: "'Mildew Resistant' word in single product spec accordion",
            defaultMessage: "Mildew Resistant"
          }
        }).mildewResistance
    ),
    mildewResistanceMethod: intl.formatMessage(
        defineMessages({
          mildewResistanceMethod: {
            id: "ProductSpecAccordionBody.mildewResistanceMethod",
            description: "'Mildew Resistant Method' word in single product spec accordion",
            defaultMessage: "Mildew Resistant Method"
          }
        }).mildewResistanceMethod
    ),
    pinkStainResistance: intl.formatMessage(
        defineMessages({
          pinkStainResistance: {
            id: "ProductSpecAccordionBody.pinkStainResistance",
            description: "'Pink Stain Resistant' word in single product spec accordion",
            defaultMessage: "Pink Stain Resistant"
          }
        }).pinkStainResistance
    ),
    cytotoxicity: intl.formatMessage(
        defineMessages({
          cytotoxicity: {
            id: "ProductSpecAccordionBody.cytotoxicity",
            description: "'Cytotoxicity' word in single product spec accordion",
            defaultMessage: "Cytotoxicity"
          }
        }).cytotoxicity
    ),
    irritationAndSkinSensitization: intl.formatMessage(
        defineMessages({
          irritationAndSkinSensitization: {
            id: "ProductSpecAccordionBody.irritationAndSkinSensitization",
            description: "'Irritation and skin Sensitization' word in single product spec accordion",
            defaultMessage: "Irritation and skin Sensitization"
          }
        }).irritationAndSkinSensitization
    ),
    cleanability: intl.formatMessage(
        defineMessages({
          cleanability: {
            id: "ProductSpecAccordionBody.cleanability",
            description: "'Cleanability' word in single product spec accordion",
            defaultMessage: "Cleanability"
          }
        }).cleanability
    ),
    cleanibilityMethod: intl.formatMessage(
        defineMessages({
          cleanibilityMethod: {
            id: "ProductSpecAccordionBody.cleanibilityMethod",
            description: "'Cleanability Method' word in single product spec accordion",
            defaultMessage: "Cleanability Method"
          }
        }).cleanibilityMethod
    ),
    hydrocarbonResistance: intl.formatMessage(
        defineMessages({
          hydrocarbonResistance: {
            id: "ProductSpecAccordionBody.hydrocarbonResistance",
            description: "'Hydrocarbon Resistant' word in single product spec accordion",
            defaultMessage: "Hydrocarbon Resistant"
          }
        }).hydrocarbonResistance
    ),
    hydrocarbonResistanceMethod: intl.formatMessage(
        defineMessages({
          hydrocarbonResistanceMethod: {
            id: "ProductSpecAccordionBody.hydrocarbonResistanceMethod",
            description: "'Hydrocarbon Resistant Method' word in single product spec accordion",
            defaultMessage: "Hydrocarbon Resistant Method"
          }
        }).hydrocarbonResistanceMethod
    ),
    oilResistance: intl.formatMessage(
        defineMessages({
          oilResistance: {
            id: "ProductSpecAccordionBody.oilResistance",
            description: "'Oil Resistant' word in single product spec accordion",
            defaultMessage: "Oil Resistant"
          }
        }).oilResistance
    ),
    sulfideStaining: intl.formatMessage(
        defineMessages({
          sulfideStaining: {
            id: "ProductSpecAccordionBody.sulfideStaining",
            description: "'Sulfide Stain Resistant' word in single product spec accordion",
            defaultMessage: "Sulfide Stain Resistant"
          }
        }).sulfideStaining
    ),
    heavyMetals: intl.formatMessage(
        defineMessages({
          heavyMetals: {
            id: "ProductSpecAccordionBody.heavyMetals",
            description: "'No Heavy Metals' word in single product spec accordion",
            defaultMessage: "No Heavy Metals"
          }
        }).heavyMetals
    ),
    phthalateFreeProduct: intl.formatMessage(
        defineMessages({
          phthalateFreeProduct: {
            id: "ProductSpecAccordionBody.phthalateFreeProduct",
            description: "'Phthalate Free Product' word in single product spec accordion",
            defaultMessage: "Phthalate Free Product"
          }
        }).phthalateFreeProduct
    ),
    hydrolisisResistance: intl.formatMessage(
        defineMessages({
          hydrolisisResistance: {
            id: "ProductSpecAccordionBody.hydrolisisResistance",
            description: "'Hydrolisis  Resistance' word in single product spec accordion",
            defaultMessage: "Hydrolisis  Resistance"
          }
        }).hydrolisisResistance
    ),
    hydrolisisResistanceMethod: intl.formatMessage(
        defineMessages({
          hydrolisisResistanceMethod: {
            id: "ProductSpecAccordionBody.hydrolisisResistanceMethod",
            description: "'Hydrolisis Resistance Method' word in single product spec accordion",
            defaultMessage: "Hydrolisis Resistance Method"
          }
        }).hydrolisisResistanceMethod
    ),
    heatBuildup: intl.formatMessage(
        defineMessages({
          heatBuildup: {
            id: "ProductSpecAccordionBody.heatBuildup",
            description: "'Heat Buildup' word in single product spec accordion",
            defaultMessage: "Heat Buildup"
          }
        }).heatBuildup
    ),
    heatBuildupMethod: intl.formatMessage(
        defineMessages({
          heatBuildupMethod: {
            id: "ProductSpecAccordionBody.heatBuildupMethod",
            description: "'Heat Buildup Method' word in single product spec accordion",
            defaultMessage: "Heat Buildup Method"
          }
        }).heatBuildupMethod
    ),
    accousticalAttenuation: intl.formatMessage(
        defineMessages({
          accousticalAttenuation: {
            id: "ProductSpecAccordionBody.accousticalAttenuation",
            description: "'Accoustical Attenuation' word in single product spec accordion",
            defaultMessage: "Accoustical Attenuation"
          }
        }).accousticalAttenuation
    ),
    accousticalAttenuationMethod: intl.formatMessage(
        defineMessages({
          accousticalAttenuationMethod: {
            id: "ProductSpecAccordionBody.accousticalAttenuationMethod",
            description: "'Accoustical Attenuation Method' word in single product spec accordion",
            defaultMessage: "Accoustical Attenuation Method"
          }
        }).accousticalAttenuationMethod
    ),
    soundAbsorption: intl.formatMessage(
        defineMessages({
          soundAbsorption: {
            id: "ProductSpecAccordionBody.soundAbsorption",
            description: "'Sound Absorption' word in single product spec accordion",
            defaultMessage: "Sound Absorption"
          }
        }).soundAbsorption
    ),
    soundAbsorptionMethod: intl.formatMessage(
        defineMessages({
          soundAbsorptionMethod: {
            id: "ProductSpecAccordionBody.soundAbsorptionMethod",
            description: "'Sound Absorption Method' word in single product spec accordion",
            defaultMessage: "Sound Absorption Method"
          }
        }).soundAbsorptionMethod
    ),
    foamLamination: intl.formatMessage(
        defineMessages({
          foamLamination: {
            id: "ProductSpecAccordionBody.foamLamination",
            description: "'Sound Foam Lamination' word in single product spec accordion",
            defaultMessage: "Sound Foam Lamination"
          }
        }).foamLamination
    ),
    thermoformed: intl.formatMessage(
        defineMessages({
          thermoformed: {
            id: "ProductSpecAccordionBody.thermoformed",
            description: "'Thermoformed' word in single product spec accordion",
            defaultMessage: "Thermoformed"
          }
        }).thermoformed
    ),
    heatSealable: intl.formatMessage(
        defineMessages({
          heatSealable: {
            id: "ProductSpecAccordionBody.heatSealable",
            description: "'Heat Sealable' word in single product spec accordion",
            defaultMessage: "Heat Sealable"
          }
        }).heatSealable
    ),
    electricSealable: intl.formatMessage(
        defineMessages({
          electricSealable: {
            id: "ProductSpecAccordionBody.electricSealable",
            description: "'Electric Sealable' word in single product spec accordion",
            defaultMessage: "Electric Sealable"
          }
        }).electricSealable
    ),
    impermeable: intl.formatMessage(
        defineMessages({
          impermeable: {
            id: "ProductSpecAccordionBody.impermeable",
            description: "'Impermeable' word in single product spec accordion",
            defaultMessage: "Impermeable"
          }
        }).impermeable
    ),
    business: intl.formatMessage(
        defineMessages({
          business: {
            id: "ProductSpecAccordionBody.business",
            description: "'Business' word in single product spec accordion",
            defaultMessage: "Business"
          }
        }).business
    ),
    marketSegment: intl.formatMessage(
        defineMessages({
          markets: {
            id: "ProductSpecAccordionBody.markets",
            description: "'Markets' word in single product spec accordion",
            defaultMessage: "Markets"
          }
        }).markets
    ),
    application: intl.formatMessage(
        defineMessages({
          application: {
            id: "ProductSpecAccordionBody.application",
            description: "'Application' word in single product spec accordion",
            defaultMessage: "Application"
          }
        }).application
    ),
    niche: intl.formatMessage(
        defineMessages({
          niche: {
            id: "ProductSpecAccordionBody.niche",
            description: "'Niche' word in single product spec accordion",
            defaultMessage: "Niche"
          }
        }).niche
    ),
    appearance: intl.formatMessage(
        defineMessages({
          appearance: {
            id: "ProductSpecAccordionBody.appearance",
            description: "'Appearance' word in single product spec accordion",
            defaultMessage: "Appearance"
          }
        }).appearance
    ),
    warranty: intl.formatMessage(
        defineMessages({
          warranty: {
            id: "ProductSpecAccordionBody.warranty",
            description: "'Warranty' word in single product spec accordion",
            defaultMessage: "Warranty"
          }
        }).warranty
    ),
    polymer: intl.formatMessage(
        defineMessages({
          polymer: {
            id: "ProductSpecAccordionBody.polymer",
            description: "'Polymer' word in single product spec accordion",
            defaultMessage: "Polymer"
          }
        }).polymer
    ),
    status: intl.formatMessage(
        defineMessages({
          status: {
            id: "ProductSpecAccordionBody.status",
            description: "'Status' word in single product spec accordion",
            defaultMessage: "Status"
          }
        }).status
    ),
    definition: intl.formatMessage(
        defineMessages({
          definition: {
            id: "ProductSpecAccordionBody.definition",
            description: "'Definition' word in single product spec accordion",
            defaultMessage: "Definition"
          }
        }).definition
    ),
    outdoor: intl.formatMessage(
        defineMessages({
          outdoor: {
            id: "ProductSpecAccordionBody.outdoor",
            description: "'Outdoor' word in single product spec accordion",
            defaultMessage: "Outdoor"
          }
        }).outdoor
    ),
  }
};

export default translatedDisplayData;