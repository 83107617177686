import * as types from "../actions/types";

export const initialState = {
  termsModalIsOpen: false
};

export const footerReducer = (state=initialState, action) => {
  switch(action.type){
    case types.TERMS_MODAL_OPEN:
      return {
        ...state,
        termsModalIsOpen: true
      };
    case types.TERMS_MODAL_CLOSE:
      return {
        ...state,
        termsModalIsOpen: false
      };
    default:
      return state;
  }
};