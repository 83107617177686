import * as types from '../actions/types';

export const initialState = {
  searchBoxIsOpen: false,
  mainMenuIsOpen: false
};

export const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_BOX_OPEN:
      return {
        ...state,
        searchBoxIsOpen: true
      };
    case types.SEARCH_BOX_CLOSE:
      return {
        ...state,
        searchBoxIsOpen: false
      };
    case types.MAIN_MENU_OPEN:
      return {
        ...state,
        mainMenuIsOpen: true
      };
    case types.MAIN_MENU_CLOSE:
      return {
        ...state,
        mainMenuIsOpen: false
      };
    default:
      return state;
  }
};