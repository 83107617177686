import {defineMessages} from "react-intl";

export const TOPCOAT_ACRYLIC = 'Acrylic';
export const TOPCOAT_ACRYLIC_MESSAGE = defineMessages({
  [TOPCOAT_ACRYLIC]: {
    id: "Topcoat.acrylic",
    description: "Topcoat value named Acrylic",
    defaultMessage: "Acrylic"
  }
});

export const TOPCOAT_ANTI_SCRATCH = 'Anti Scratch';
export const TOPCOAT_ANTI_SCRATCH_MESSAGE = defineMessages({
  [TOPCOAT_ANTI_SCRATCH]: {
    id: "Topcoat.antiScratch",
    description: "Topcoat value named Anti Scratch",
    defaultMessage: "Anti Scratch"
  }
});

export const TOPCOAT_NAPA = 'Napa';
export const TOPCOAT_NAPA_MESSAGE = defineMessages({
  [TOPCOAT_NAPA]: {
    id: "Topcoat.napa",
    description: "Topcoat value named Napa",
    defaultMessage: "Napa"
  }
});

export const TOPCOAT_NOBUCK = 'Nobuck';
export const TOPCOAT_NOBUCK_MESSAGE = defineMessages({
  [TOPCOAT_NOBUCK]: {
    id: "Topcoat.noBuck",
    description: "Topcoat value named Nobuck",
    defaultMessage: "Nobuck"
  }
});

export const TOPCOAT_PERMABLOK = 'Permablok';
export const TOPCOAT_PERMABLOK_MESSAGE = defineMessages({
  [TOPCOAT_PERMABLOK]: {
    id: "Topcoat.permablok",
    description: "Topcoat value named Permablok",
    defaultMessage: "Permablok"
  }
});

export const TOPCOAT_PERMABLOK_PLUS = 'Permablok Plus';
export const TOPCOAT_PERMABLOK_PLUS_MESSAGE = defineMessages({
  [TOPCOAT_PERMABLOK_PLUS]: {
    id: "Topcoat.permablokPlus",
    description: "Topcoat value named Permablok Plus",
    defaultMessage: "Permablok Plus"
  }
});

export const TOPCOAT_PERMAGUARD = 'Permaguard';
export const TOPCOAT_PERMAGUARD_MESSAGE = defineMessages({
  [TOPCOAT_PERMAGUARD]: {
    id: "Topcoat.permaguard",
    description: "Topcoat value named Permaguard",
    defaultMessage: "Permaguard"
  }
});

export const TOPCOAT_SILVERGUARD = 'SILVERGUARD (LOGO)';
export const TOPCOAT_SILVERGUARD_MESSAGE = defineMessages({
  [TOPCOAT_SILVERGUARD]: {
    id: "Topcoat.silverguard",
    description: "Topcoat value named Silverguard (LOGO)",
    defaultMessage: "Silverguard"
  }
});

export const TOPCOAT_SRT_PLUS = 'SRT PLUS';
export const TOPCOAT_SRT_PLUS_MESSAGE = defineMessages({
  [TOPCOAT_SRT_PLUS]: {
    id: "Topcoat.srtPlus",
    description: "Topcoat value named SRT Plus",
    defaultMessage: "SRT Plus"
  }
});

export const TOPCOAT_SRT_PLUS_IMP = 'SRT PLUS IMP';
export const TOPCOAT_SRT_PLUS_IMP_MESSAGE = defineMessages({
  [TOPCOAT_SRT_PLUS_IMP]: {
    id: "Topcoat.srtPlusImp",
    description: "Topcoat value named SRT PLUS IMP",
    defaultMessage: "SRT PLUS IMP"
  }
});

export const TOPCOAT_SUREGUARD = 'Sureguard';
export const TOPCOAT_SUREGUARD_MESSAGE = defineMessages({
  [TOPCOAT_SUREGUARD]: {
    id: "Topcoat.sureguard",
    description: "Topcoat value named Sureguard",
    defaultMessage: "Sureguard"
  }
});

export const TOPCOAT_TRAXION = 'Traxion';
export const TOPCOAT_TRAXION_MESSAGE = defineMessages({
  [TOPCOAT_TRAXION]: {
    id: "Topcoat.traxion",
    description: "Topcoat value named Traxion",
    defaultMessage: "Traxion"
  }
});

export const TOPCOAT_ULTRA_GLOSS = 'Ultra Gloss';
export const TOPCOAT_ULTRA_GLOSS_MESSAGE = defineMessages({
  [TOPCOAT_ULTRA_GLOSS]: {
    id: "Topcoat.ultraGloss",
    description: "Topcoat value named Ultra Gloss",
    defaultMessage: "Ultra Gloss"
  }
});

export const TOPCOAT_WAXY = 'Waxy';
export const TOPCOAT_WAXY_MESSAGE = defineMessages({
  [TOPCOAT_WAXY]: {
    id: "Topcoat.waxy",
    description: "Topcoat value named Waxy",
    defaultMessage: "Waxy"
  }
});

export const TOPCOAT_PBG = 'PBG';
export const TOPCOAT_PBG_MESSAGE = defineMessages({
  [TOPCOAT_PBG]: {
    id: "Topcoat.pbg",
    description: "Topcoat value named PBG",
    defaultMessage: "PBG"
  }
});

// IMPORTANT: Some topcoats here are temporarily commented due to a client request.
// Please keep them commented and DON'T DELETE ANY OF THEM. Will be enabled soon.
export const TOPCOATS = [
  {...TOPCOAT_ACRYLIC_MESSAGE[TOPCOAT_ACRYLIC], value: TOPCOAT_ACRYLIC},
  {...TOPCOAT_ANTI_SCRATCH_MESSAGE[TOPCOAT_ANTI_SCRATCH], value: TOPCOAT_ANTI_SCRATCH},
  {...TOPCOAT_NAPA_MESSAGE[TOPCOAT_NAPA], value: TOPCOAT_NAPA},
  // {...TOPCOAT_NOBUCK_MESSAGE[TOPCOAT_NOBUCK], value: TOPCOAT_NOBUCK},
  {...TOPCOAT_PERMABLOK_MESSAGE[TOPCOAT_PERMABLOK], value: TOPCOAT_PERMABLOK},
  {...TOPCOAT_PERMABLOK_PLUS_MESSAGE[TOPCOAT_PERMABLOK_PLUS], value: TOPCOAT_PERMABLOK_PLUS},
  {...TOPCOAT_PERMAGUARD_MESSAGE[TOPCOAT_PERMAGUARD], value: TOPCOAT_PERMAGUARD},
  // {...TOPCOAT_SILVERGUARD_MESSAGE[TOPCOAT_SILVERGUARD], value: TOPCOAT_SILVERGUARD},
  {...TOPCOAT_SRT_PLUS_MESSAGE[TOPCOAT_SRT_PLUS], value: TOPCOAT_SRT_PLUS},
  {...TOPCOAT_SRT_PLUS_IMP_MESSAGE[TOPCOAT_SRT_PLUS_IMP], value: TOPCOAT_SRT_PLUS_IMP},
  {...TOPCOAT_SUREGUARD_MESSAGE[TOPCOAT_SUREGUARD], value: TOPCOAT_SUREGUARD},
  // {...TOPCOAT_TRAXION_MESSAGE[TOPCOAT_TRAXION], value: TOPCOAT_TRAXION},
  {...TOPCOAT_ULTRA_GLOSS_MESSAGE[TOPCOAT_ULTRA_GLOSS], value: TOPCOAT_ULTRA_GLOSS},
  // {...TOPCOAT_WAXY_MESSAGE[TOPCOAT_WAXY], value: TOPCOAT_WAXY},
  {...TOPCOAT_PBG_MESSAGE[TOPCOAT_PBG], value: TOPCOAT_PBG},
];