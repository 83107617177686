import {defineMessages} from "react-intl";

export const EXCLUSIVE = 'EX';
export const PATTERN_CLASS_EXCLUSIVE = defineMessages({
  [EXCLUSIVE]: {
    id: "FiltersBar.productDefinitionFilterOptionExclusive",
    description: "Exclusive value filter",
    defaultMessage: 'Exclusive'
  }
});

export const AGAINST_REQUEST = 'CP';
export const PATTERN_CLASS_AGAINST_REQUEST = defineMessages({
  [AGAINST_REQUEST]: {
    id: "FiltersBar.productDefinitionFilterOptionAgainst",
    description: "Against value filter",
    defaultMessage: 'Against Request'
  }
});

export const LOW_TURNOVER = 'BR';
export const PATTERN_CLASS_LOW_TURNOVER = defineMessages({
  [LOW_TURNOVER]: {
    id: "FiltersBar.productDefinitionFilterOptionLowTurnover",
    description: "Low turnover value filter",
    defaultMessage: 'Low Turnover'
  }
});

export const LINE_PRODUCT = 'PL';
export const PATTERN_CLASS_LINE_PRODUCT = defineMessages({
  [LINE_PRODUCT]: {
    id: "FiltersBar.productDefinitionFilterOptionLineProduct",
    description: "Line product value filter",
    defaultMessage: 'Line Product'
  }
});

export const NEW_PRODUCT = 'PN';
export const PATTERN_CLASS_NEW_PRODUCT = defineMessages({
  [NEW_PRODUCT]: {
    id: "FiltersBar.productDefinitionFilterOptionNewProduct",
    description: "New product value filter",
    defaultMessage: 'New Product'
  }
});

export const DISCONTINUED = 'DS';
export const PATTERN_CLASS_DISCONTINUED = defineMessages({
  [DISCONTINUED]: {
    id: "FiltersBar.productDefinitionFilterOptionDiscontinued",
    description: "Discontinued value filter",
    defaultMessage: 'Discontinued'
  }
});


export const PATTERN_CLASSES = [
  {...PATTERN_CLASS_EXCLUSIVE[EXCLUSIVE], value: EXCLUSIVE},
  {...PATTERN_CLASS_AGAINST_REQUEST[AGAINST_REQUEST], value: AGAINST_REQUEST},
  {...PATTERN_CLASS_LOW_TURNOVER[LOW_TURNOVER], value: LOW_TURNOVER},
  {...PATTERN_CLASS_LINE_PRODUCT[LINE_PRODUCT], value: LINE_PRODUCT},
  {...PATTERN_CLASS_NEW_PRODUCT[NEW_PRODUCT], value: NEW_PRODUCT},
  {...PATTERN_CLASS_DISCONTINUED[DISCONTINUED], value: DISCONTINUED}
];