import React from 'react';
import "./styles/HeaderBurgerButton.scss";

const HeaderBurgerButton = ({headerReducer, openMainMenu, closeMainMenu}) => (
    <button
        type="button"
        className="header-burger-button"
        onClick={headerReducer.mainMenuIsOpen ? closeMainMenu : openMainMenu}>
      <span className="header-burger-button__line"/>
      <span className="header-burger-button__line"/>
      <span className="header-burger-button__line"/>
    </button>
);

export default HeaderBurgerButton;