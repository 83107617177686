import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import SendByEmailModal from "scenes/ProductDetails/components/SendByEmailButton/components/SendByEmailModal";

class SendByEmailButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };

    this.toggleModalIsOpen = this.toggleModalIsOpen.bind(this)
  }

  toggleModalIsOpen() {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  render () {
    const {modalIsOpen} = this.state;
    const {patternId} = this.props;

    return (
        <React.Fragment>
          <button
              onClick={this.toggleModalIsOpen}
              className="btn btn-outline-secondary btn-lg btn-block">
            <FormattedMessage
                id="SendPDFByEmail.label"
                description="Product details send by email label"
                defaultMessage="Send by Email"/>
            &nbsp;
            <i className="fa fa-envelope-o"/>
          </button>
          <SendByEmailModal
              patternId={patternId}
              isOpen={modalIsOpen}
              toggle={this.toggleModalIsOpen}/>
        </React.Fragment>
    )
  }
}

SendByEmailButton.propTypes = {
  patternId: PropTypes.string.isRequired
};

export default SendByEmailButton