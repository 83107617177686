import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { FLAME_RETARDANCY_NO_VALUE } from "scenes/ProductDetails/components/ProductSpecs/contants";
import PdfResultRow from "./components/PdfResultRow";

const FlameRetardancyAccordion = ({ data }) =>
  data.map(({ test, pdf, result }) => (
    <tr
      key={`key_${test}`}
      className={classNames({
        "flame-retardancy--pdf": pdf !== FLAME_RETARDANCY_NO_VALUE,
      })}
    >
      <td colSpan={pdf === FLAME_RETARDANCY_NO_VALUE && !result ? 2 : 0}>
        {pdf !== FLAME_RETARDANCY_NO_VALUE ? (
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            {test}
          </a>
        ) : (
          test
        )}
      </td>
      <PdfResultRow pdf={pdf} result={result} />
    </tr>
  ));

FlameRetardancyAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      test: PropTypes.string,
      pdf: PropTypes.string,
      result: PropTypes.string,
    })
  ).isRequired,
};

export default FlameRetardancyAccordion;
