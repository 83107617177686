import React from 'react';
import {FormattedMessage} from "react-intl";

export const renderInput = ({input, type, placeholder, autoFocus, className, meta: {touched, error, warning}}) => (
    <div className="form-field">
      <input
          autoComplete={input.name}
          {...input}
          autoFocus={autoFocus}
          type={type}
          placeholder={placeholder}
          className={className}/>
      {
        touched && (
            (error && <small className="form-text invalid-feedback"><FormattedMessage {...error}/></small>)
            ||
            (warning && <small className="form-text">{warning}</small>)
        )
      }
    </div>
);

export const renderCheckbox = ({input, disabled, readOnly, className, defaultChecked, meta: {touched, error, warning}}) => (
    <React.Fragment>
      <input disabled={disabled} readOnly={readOnly} {...input} type="checkbox" defaultChecked={defaultChecked}
             className={className}/>
      {
        touched && (
            (error && <small className="form-text invalid-feedback"><FormattedMessage {...error}/></small>)
            ||
            (warning && <small className="form-text">{warning}</small>)
        )
      }
    </React.Fragment>
);