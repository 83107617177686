import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {FormattedMessage} from "react-intl";

class TermsModal extends Component {
  render() {
    const {footerReducer, closeTermsModal} = this.props;

    return (
        <Modal
            size="lg"
            isOpen={footerReducer.termsModalIsOpen}
            toggle={closeTermsModal}>
          <ModalHeader toggle={closeTermsModal}>
            <FormattedMessage
                id="TermsModal.title"
                description="Title of terms and conditions modal"
                defaultMessage="Terms and conditions"/>
          </ModalHeader>
          <ModalBody>
            <p>
              <FormattedMessage
                  id="TermsModal.paragraph1"
                  description="Paragraph 1 of terms and conditions modal"
                  defaultMessage="If you are going to enter this website is because you are part of Spradling Group, as a member and part of this team you have the responsibility to protect and manage properly the information in it. Please read carefully the general use conditions below, which you should accept to sign up and use the tool."/>
            </p>
            <p>
              <FormattedMessage
                  id="TermsModal.paragraph2"
                  description="Paragraph 2 of terms and conditions modal"
                  defaultMessage="These general conditions regulate the access and use of library.spradling.group, a property of Spradling Group, from now on, The Company. The user must read and accept the conditions to use all the services and information in this site. The access means full acceptance of this general conditions."/>
            </p>
            <p>
              <FormattedMessage
                  id="TermsModal.paragraph3"
                  description="Paragraph 3 of terms and conditions modal"
                  defaultMessage="The Company reserves the right to modify anytime the general conditions. We recommend consulting periodically the terms because they could have been modified."/>
            </p>
            <p>
              <FormattedMessage
                  id="TermsModal.paragraph4"
                  description="Paragraph 4 of terms and conditions modal"
                  defaultMessage="The user must respect the general conditions at all times. The user expressly states that will use the website diligently and assuming any responsibility that may arise from the infringement of the rules."/>

            </p>
            <p>
              <FormattedMessage
                  id="TermsModal.paragraph5"
                  description="Paragraph 5 of terms and conditions modal"
                  defaultMessage="To guarantee the right use of the tool the user commits to fulfill the following obligations:"/>
            </p>
            <ul>
              <li>
                <FormattedMessage
                    id="TermsModal.paragraph6"
                    description="Paragraph 6 of terms and conditions modal"
                    defaultMessage="Take responsibility for any activity that takes place under their login."/>
              </li>
              <li>
                <FormattedMessage
                    id="TermsModal.paragraph7"
                    description="Paragraph 7 of terms and conditions modal"
                    defaultMessage="Take responsibility of the password security, is not shareable and not transferable."/>
              </li>
              <li>
                <FormattedMessage
                    id="TermsModal.paragraph8"
                    description="Paragraph 8 of terms and conditions modal"
                    defaultMessage="Refrain to send spam email to other users, as well as virus transfer or any destructive code."/>
              </li>
              <li>
                <FormattedMessage
                    id="TermsModal.paragraph9"
                    description="Paragraph 9 of terms and conditions modal"
                    defaultMessage="Do not show the site content in public."/>
              </li>
              <li>
                <FormattedMessage
                    id="TermsModal.paragraph10"
                    description="Paragraph 10 of terms and conditions modal"
                    defaultMessage="Reproduce, duplicate, copy or explode the content in any way without previous authorization of Corporate Marketing Area of the company."/>
              </li>
              <li>
                <FormattedMessage
                    id="TermsModal.paragraph11"
                    description="Paragraph 11 of terms and conditions modal"
                    defaultMessage="Edit or modify the content of this website in any way."/>
              </li>
              <li>
                <FormattedMessage
                    id="TermsModal.paragraph12"
                    description="Paragraph 12 of terms and conditions modal"
                    defaultMessage="Do not sign up with data credentials of another user."/>
              </li>
            </ul>
            <p>
              <FormattedMessage
                  id="TermsModal.paragraph13"
                  description="Paragraph 13 of terms and conditions modal"
                  defaultMessage="All the contents, labels, logos, drawings, documentation or any other element susceptible to protection for the legislation of intellectual or industrial property, that are accessible on the website correspond exclusively to The Company or its legitimate owners and are expressly reserved all rights over them."/>
            </p>
            <p>
              <FormattedMessage
                  id="TermsModal.paragraph14"
                  description="Paragraph 14 of terms and conditions modal"
                  defaultMessage="In any case, the website reserves all the rights over contents, information, and services that hold on them."/>
            </p>
            <p>
              <FormattedMessage
                  id="TermsModal.paragraph15"
                  description="Paragraph 15 of terms and conditions modal"
                  defaultMessage="If the user of library.spradling.group disagrees with the website use conditions or any other provision, please refrain to use this site."/>
            </p>
          </ModalBody>
        </Modal>
    )
  }
}

export default TermsModal;