import React, {Component} from "react";
import "./styles/CompareBox.scss";
import {FormattedMessage} from "react-intl";
import CompareCard from "scenes/ProductsPage/components/ProductsList/components/CompareBox/components/CompareCard";
import {getParsedQueryParams, updateQueryParams} from "services/browser-history";
import {QUERY_PARAM_COMPARE_ITEMS} from "scenes/ProductsPage/components/ProductsList/components/CompareBox/constants";
import DummyCard from "scenes/ProductsPage/components/ProductsList/components/CompareBox/components/DummyCard";
import withCompareItems from "utils/hoc/withCompareItems";
import CompareButton from "scenes/ProductsPage/components/ProductsList/components/CompareButton";
import RemoveAllButton from "utils/components/RemoveAllButton";

export class CompareBox extends Component {

  /**
   * Get all selected patterns to compare from the queryParams
   */
  static getCurrentCompareParams() {
    let selectedPatterns = getParsedQueryParams()[QUERY_PARAM_COMPARE_ITEMS] || [];
    return Array.isArray(selectedPatterns) ? selectedPatterns : [selectedPatterns];
  }

  /**
   * Remove given patternId from queryParams
   * @param patternId
   */
  static removePattern(patternId) {
    const selectedPatterns = CompareBox.getCurrentCompareParams();

    const filteredPatterns = selectedPatterns.filter(itemId => itemId !== patternId);
    updateQueryParams({
      [QUERY_PARAM_COMPARE_ITEMS]: filteredPatterns
    })
  }

  /**
   * Remove all patternIds from queryParams
   */
  static removeAllPatterns() {
    updateQueryParams({
      [QUERY_PARAM_COMPARE_ITEMS]: []
    });
  }

  /**
   * Add new pattern to queryParams
   * If the given pattern exists, there are no changes in the queryParams
   * If the queryParams have less than 3 patterns, push the new pattern
   * If the queryParams have a 3 patterns, set new pattern in the las position
   * @param patternId
   */
  static addPattern(patternId) {
    const itemsToCompareAmount = CompareBox.itemsToCompareAmount();

    const currentQueryParam = CompareBox.getCurrentCompareParams();
    let newQueryParam = [];

    if (!!currentQueryParam.find(itemId => itemId === patternId))
      newQueryParam = [...currentQueryParam];
    else if (currentQueryParam.length !== itemsToCompareAmount)
      newQueryParam = [...currentQueryParam, patternId];
    else
      newQueryParam = [...currentQueryParam.slice(0, itemsToCompareAmount - 1), patternId];

    updateQueryParams({
      [QUERY_PARAM_COMPARE_ITEMS]: newQueryParam
    });
  }

  /**
   * If the device have a more 960px width, it's a large device and must be return 4
   * If the device have a less 959px width, it's a medium/small device and must be return 3
   *
   * @see https://getbootstrap.com/docs/4.1/layout/grid/#grid-options
   * @return {number}
   */
  static itemsToCompareAmount() {
    return window.innerWidth > 960 ? 4 : 3;
  }

  render() {
    const {patternsToCompare} = this.props;
    // TODO: If the itemsToCompareAmount it's minor of patternsToCompare.length, one error has occurs
    const dummyPatterns = [...Array(CompareBox.itemsToCompareAmount() - patternsToCompare.length).keys()];

    if (patternsToCompare.length === 0)
      return null;

    return (
        <div className="compare-box">
          <div className="row no-gutters small-gutters-lg">
            <div className="col-12 col-lg-4">
              <h3 className="compare-box-title">
                <FormattedMessage
                    id="CompareBox.title"
                    description="Compare box title"
                    defaultMessage="Compare products"/>
                <br/>
                <FormattedMessage
                  id="CompareBox.subtitle"
                  description="Compare box disclaimer"
                  defaultMessage="(You can add up to {amount})"
                  values={{
                    amount: CompareBox.itemsToCompareAmount()
                  }}/>
              </h3>
            </div>
            <div className="col-12 col-lg-8">
              <div className="compare-box-cards">
                <div className="row no-gutters small-gutters-lg">
                  {
                    patternsToCompare.map(pattern => (
                        <div
                            key={pattern.id}
                            className="col-4 col-md-3">
                          <CompareCard
                              pattern={pattern}/>
                        </div>
                    ))
                  }
                  {
                    dummyPatterns.map((dummy, index) => (
                        <div
                            key={`dummyPattern${index}`}
                            className="col-4 col-md-3">
                          <DummyCard/>
                        </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="compare-box-actions">
                <div>
                  <RemoveAllButton/>
                </div>
                <div>
                  <CompareButton/>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default withCompareItems(CompareBox);