import gql from 'graphql-tag';

const singlePatternBackingFragment = gql`
    fragment SinglePatternBackingFragment on BackingNode {
        id
        type
        description
        pinholes
    }
`;

export default singlePatternBackingFragment;