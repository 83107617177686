import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.css";
import 'font-awesome/css/font-awesome.css';
import 'rc-slider/assets/index.css';
import 'assets/fonts/spradling/style.css';
import 'assets/styles/ProquinalLibraryApp.scss';
import ProquinalLibraryAppContainer from "app/container";
import {Provider as ReduxProvider} from 'react-redux';
import {reduxStore} from "redux/store";
import { ConnectedRouter } from 'connected-react-router'
import {ApolloProvider} from "react-apollo";
import {apolloClient} from "apollo/client";
import proquinalHistory from "services/browser-history";
import ConnectedIntlProvider from "intl/ConnectedIntlProvider";

ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <ReduxProvider store={reduxStore}>
        <ConnectedIntlProvider>
          <ConnectedRouter history={proquinalHistory}>
            <ProquinalLibraryAppContainer/>
          </ConnectedRouter>
        </ConnectedIntlProvider>
      </ReduxProvider>
    </ApolloProvider>, document.getElementById('root'));
