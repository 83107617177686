import { connect } from 'react-redux';
import HeaderSearchBox from "../index";
import {withRouter} from "react-router";
import {closeSearchBox} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    headerReducer: state.headerReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeSearchBox: () => dispatch(closeSearchBox())
  };
};

const HeaderSearchBoxContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderSearchBox);

export default withRouter(HeaderSearchBoxContainer);