import React from "react";
import PropTypes from "prop-types";
import "./styles/ProductCardImage.scss";
import {Link} from "react-router-dom";

const defaultImage = "https://s3-us-west-2.amazonaws.com/24mm-emails/spradling-temp/temp-texture-for-backgrounds.jpg";
const ProductCardImage = ({slug, image}) => (
    <Link to={`/products/${slug}`}>
      <div
          className="product-card-image"
          style={{
            backgroundImage: `url("${image !== "" ? image : defaultImage}")`
          }}/>
    </Link>
);

ProductCardImage.defaultProps = {
  image: ""
};

ProductCardImage.propTypes = {
  slug: PropTypes.string.isRequired,
  image: PropTypes.string
};

export default ProductCardImage;