import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import AuthPageLayout from "scenes/Auth/components/AuthPageLayout";
import {graphql} from "react-apollo";
import tokenAuth from "scenes/Auth/scenes/LoginPage/mutation/index";
import {authLogin} from "services/auth/index";
import {Redirect} from "react-router";
import {injectIntl, defineMessages} from "react-intl";
import LoginReduxFormContainer from "scenes/Auth/scenes/LoginPage/components/LoginForm/container";

export class LoginPage extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      errors: []
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Handled the LoginForm submit to calls tokenAuth mutation.
   * In the mutation response, calls authLogin function
   * @param data
   * @param data.termsAndConditions
   * @param data.email
   * @param data.password
   */
  handleFormSubmit(data) {
    this.setState({isLoading: true});

    if (data.termsAndConditions)
      this.props.tokenAuth({
        variables: {
          input: {
            email: data.email,
            password: data.password
          }
        }
      }).then(response => {
        const {tokenAuthForLibrary} = response.data;
        authLogin(tokenAuthForLibrary.token).then();
      }).catch(err => {
        const {graphQLErrors} = err;
        this.setState({
          errors: graphQLErrors.map(error => error.message)
        })
      });
    else {
      const {intl} = this.props;
      this.setState({
        errors: [
          intl.formatMessage(
              defineMessages({
                termsAndConditions: {
                  id: "LoginPage.termsAndConditions",
                  description: "Error that occurs when the user does not accept the terms and conditions on the login page",
                  defaultMessage: "Before logging in, you must accept terms and conditions below by clicking the checkbox."
                }
              }).termsAndConditions
          )
        ]
      })
    }
  }

  render() {
    const {isLoading, errors} = this.state;
    const {isAuthenticated} = this.props.authReducer;

    if (isAuthenticated)
      return <Redirect to=""/>;

    return (
        <AuthPageLayout
            heading={
              <FormattedMessage
                  id="LoginPage.title"
                  description="Login page's title"
                  defaultMessage="Welcome to our products library!"/>
            }
            subheading={
              <FormattedMessage
                  id="LoginPage.subTitle"
                  description="Login page's subtitle"
                  defaultMessage="You must enter your corporate email address and your password."/>
            }
            form={
              <LoginReduxFormContainer
                  isLoading={isLoading}
                  errors={errors}
                  onSubmit={this.handleFormSubmit}/>
            }/>
    )
  }
}

export default injectIntl(graphql(tokenAuth, {name: 'tokenAuth'})(LoginPage));