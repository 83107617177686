import gql from "graphql-tag";
import singlePatternFragment from "utils/fragments/SinglePatternFragment";
import pageInfoFragment from "utils/fragments/PageInfoFragment";

// TODO: hotfix solve filter general_embossing_in using embossing_select_in
const patternsQuery = gql`
  query PatternsQuery(
    $first: Int
    $last: Int
    $afterCursor: String
    $beforeCursor: String
    $orderBy: String
    $instanceIds: [ID]
    $search: String
    $productFirst: Int
    $marketSegment: String
    $appearance: String
    $application: String
    $polymer: String
    $backingDescription: String
    $embossingSelect: String
    $topcoat: String
    $outdoor: Boolean
    $flameRetardancyTests: [ID]
    $gaugeRange: String
    $widthRange: String
    $weightRange: String
    $isVisibleLibrary: Boolean
    $patternClassContains: String
    $sustainabilityProp65: Boolean,
    $sustainabilityReach: Boolean,
    $sustainabilityRohs: Boolean,
    $sustainabilityFrFreeIn: String,
    $sustainabilityMpFreeIn: String,
    $sustainabilityCytotoxicityIn: String,
    $sustainabilitySkinSafeSensitizationIn: String,
    $sustainabilityPhthalateFreeProductIn: String,
    $sustainabilitySkinSafeIrritationIn: String,
    $sustainabilityBackingProfile: Boolean,
    # $sustainabilityLowVoc: Boolean,
    # $sustainabilityRecycledPet: Boolean,
    $sustainabilityBiobasedPvcBioAttributed: Boolean,
    # $sustainabilitySolventFree: Boolean,
    # $sustainabilityBiodegradable: Boolean,
    $sustainabilityGreenguard: Boolean,
    $sustainabilityIndoorAirQuality: Boolean,
    $sustainabilityGlobalRecycledStandard: Boolean,
    $sustainabilityAPlusEmissions: Boolean,
    $sustainabilityAntimicrobialIn: String,
    $sustainabilityEpd: Boolean,
    $sustainabilityLeed: Boolean,
    $sustainabilityWell: Boolean,
  ) {
    patterns(
      first: $first
      last: $last
      after: $afterCursor
      before: $beforeCursor
      orderBy: $orderBy
      products_Instances: $instanceIds
      name_Icontains: $search
      productcategorization_MarketSegment_In: $marketSegment
      productcategorization_Appearance_In: $appearance
      productcategorization_Application_In: $application
      productcategorization_Polymer_In: $polymer
      productcategorization_Outdoor: $outdoor
      backing_Description_Title: $backingDescription
      #embossing_Select_In: $embossing
      # general_Composition_In: $embossingSelect
      general_EmbossingSelect_Name_In: $embossingSelect
      general_Topcoat_Title: $topcoat
      successFlameRetardancyTests: $flameRetardancyTests
      gauge_Between: $gaugeRange
      width_Between: $widthRange
      weight_Between: $weightRange
      isVisibleLibrary: $isVisibleLibrary
      patternClass_Contains: $patternClassContains
      sustainability_Prop65: $sustainabilityProp65,
      sustainability_Reach: $sustainabilityReach,
      sustainability_Rohs: $sustainabilityRohs,
      sustainability_FrFree_In: $sustainabilityFrFreeIn,
      sustainability_MpFree_In: $sustainabilityMpFreeIn,
      sustainability_Cytotoxicity_In: $sustainabilityCytotoxicityIn,
      sustainability_SkinSafeSensitization_In: $sustainabilitySkinSafeSensitizationIn,
      sustainability_SkinSafeIrritation_In: $sustainabilitySkinSafeIrritationIn,
      sustainability_BackingProfile: $sustainabilityBackingProfile,
      sustainability_PhthalateFreeProduct_In: $sustainabilityPhthalateFreeProductIn,
      # sustainability_LowVoc: $sustainabilityLowVoc,
      # sustainability_RecycledPet: $sustainabilityRecycledPet,
      sustainability_BiobasedPvcBioAttributed: $sustainabilityBiobasedPvcBioAttributed,
      # sustainability_SolventFree: $sustainabilitySolventFree,
      # sustainability_Biodegradable: $sustainabilityBiodegradable,
      sustainability_Greenguard: $sustainabilityGreenguard,
      sustainability_IndoorAirQuality: $sustainabilityIndoorAirQuality,
      sustainability_GlobalRecycledStandard: $sustainabilityGlobalRecycledStandard,
      sustainability_APlusEmissions: $sustainabilityAPlusEmissions,
      sustainability_Epd: $sustainabilityEpd,
      sustainability_Leed: $sustainabilityLeed,
      sustainability_Well: $sustainabilityWell,
      sustainability_Antimicrobial_In: $sustainabilityAntimicrobialIn
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...SinglePatternFragment
          imageThumbSmall
          products(first: $productFirst, instances: $instanceIds) {
            totalCount
            edges {
              node {
                id
                code
                color
              }
            }
          }
        }
      }
    }
  }
  ${singlePatternFragment}
  ${pageInfoFragment}
`;

export default patternsQuery;
