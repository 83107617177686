import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {
  QUERY_PARAM_AFTER_CURSOR,
  QUERY_PARAM_BEFORE_CURSOR,
  QUERY_PARAM_FIRST,
  QUERY_PARAM_LAST
} from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
import {PRODUCT_LIST_PAGE} from "scenes/ProductsPage/components/ProductsList/constants";
import {updateQueryParams} from "services/browser-history";
import ReactPaginate from 'react-paginate';
import './styles/ProductsPagination.scss';
import {scroller} from "react-scroll";

class ProductsPagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paginationInfo: {}
    };

    this.goToPage = this.goToPage.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.resolvePaginationInfo();
  }

  componentDidUpdate(prevProps) {
    const {pageInfo} = this.props;

    if (JSON.stringify(pageInfo) !== JSON.stringify(prevProps.pageInfo))
      this.resolvePaginationInfo();
  }

  /**
   * Scroll to begin of masonry gallery
   */
  static scrollToBegin() {
    scroller.scrollTo("productListElement",
        {
          duration: 750,
          smooth: true,
          offset: -120
        }
    );
  }

  /**
   * Resolve the pagination data based on totalCount and pageInfo
   */
  resolvePaginationInfo() {
    const {pageInfo, totalCount} = this.props;
    if (pageInfo.startCursor && pageInfo.endCursor) {
      const startCursor = ProductsPagination.resolveCursor(pageInfo.startCursor);
      const endCursor = ProductsPagination.resolveCursor(pageInfo.endCursor);
      const pageRange = {
        start: 0,
        end: PRODUCT_LIST_PAGE - 1
      };
      let currentPage = 1,
          pageFound = false;
      let pages = Math.floor(totalCount / PRODUCT_LIST_PAGE);

      if (totalCount % PRODUCT_LIST_PAGE !== 0)
        pages += 1;

      while (pageRange.start < totalCount && !pageFound) {
        if (pageRange.start <= startCursor && pageRange.end >= endCursor) {
          pageFound = true
        } else {
          pageRange.start += PRODUCT_LIST_PAGE;
          pageRange.end += PRODUCT_LIST_PAGE;
          currentPage += 1
        }
      }

      this.setState({
        paginationInfo: {
          pages,
          currentPage
        }
      })
    }
  }

  /**
   * Receive a relay cursor and resolve the
   *
   * @param cursor (String)
   * @return {number}
   */
  static resolveCursor(cursor) {
    const decodedCursor = window.atob(cursor).split(":");
    if (decodedCursor.length === 2 && decodedCursor[0] === "arrayconnection")
      return Number(decodedCursor[1]);
    else // TODO: Throws an error here
      return 1;
  }

  /**
   * Handle the number page click, search for the "afterCursor" corresponding to the page received and update
   * the queryParams
   *
   * @param page (Number)
   */
  goToPage(page) {
    const {paginationInfo} = this.state;
    const {totalCount} = this.props;

    const reference = Math.floor((totalCount / paginationInfo.pages) * page) - 1;
    const pageRange = {
      start: 0,
      end: PRODUCT_LIST_PAGE - 1
    };
    let pageStart = 0,
        pageFound = false;

    while (pageRange.start <= totalCount && !pageFound) {
      if (reference >= pageRange.start && reference <= pageRange.end) {
        pageFound = true;
        pageStart = pageRange.start
      } else {
        pageRange.start += PRODUCT_LIST_PAGE;
        pageRange.end += PRODUCT_LIST_PAGE;
      }
    }

    const newQueryParams = {
      [QUERY_PARAM_BEFORE_CURSOR]: null,
      [QUERY_PARAM_LAST]: null,
      [QUERY_PARAM_AFTER_CURSOR]: ProductsPagination.convertCursor(pageStart - 1),
      [QUERY_PARAM_FIRST]: PRODUCT_LIST_PAGE,
    };

    updateQueryParams(newQueryParams);
    ProductsPagination.scrollToBegin();
  }

  /**
   * Receive a number and convert to relay cursor
   *
   * @param number (Number)
   * @return {string}
   */
  static convertCursor(number) {
    return window.btoa(`arrayconnection:${number}`)
  }

  /**
   * Handler of ReactPaginate component onPageChange prop. This method calls goToPage method
   * @param selected
   */
  handlePageClick({selected}) {
    this.goToPage(selected + 1)
  }

  render() {
    const {paginationInfo} = this.state;

    return (
        <ReactPaginate
            pageCount={paginationInfo.pages}
            forcePage={paginationInfo.currentPage - 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.handlePageClick}
            previousLabel={
              <span>
                  <i className="sp-arrow-left"/>
                &nbsp;
                <FormattedMessage
                    id="ProductsListPagination.previousPage"
                    description="Label for pagination button to the previous page"
                    defaultMessage="Prev"/>
              </span>
            }
            nextLabel={
              <span>
                  <FormattedMessage
                      id="ProductsListPagination.nextPage"
                      description="Label for pagination button to the next page"
                      defaultMessage="Next"/>
                &nbsp;
                <i className="sp-arrow-right"/>
              </span>
            }
            breakLabel={"..."}
            breakClassName={"product-list-pagination__break"}
            containerClassName={"product-list__pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"product-list-pagination__page-number--active"}
            previousClassName={"product-list-pagination__previous-page"}
            nextClassName={"product-list-pagination__next-page"}/>
    )
  }
}

ProductsPagination.propTypes = {
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
    startCursor: PropTypes.string,
    endCursor: PropTypes.string
  }).isRequired,
  totalCount: PropTypes.number.isRequired
};

export default ProductsPagination