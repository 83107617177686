import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {reduxForm, Field} from "redux-form";
import {renderInput} from "utils/form/renderers";
import {required} from "utils/form/validators";
import GoBackToLoginLink from "scenes/Auth/scenes/ForgotPasswordPage/components/GoBackToLoginLink";

export const ForgotPasswordForm = ({isLoading, handleSubmit, errors}) => (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email" className="sr-only">
          <FormattedMessage
              id="ForgotPasswordForm.emailLabel"
              description="Label for email input at forgot password form"
              defaultMessage="Email"/>
        </label>
        <FormattedMessage
            id="ForgotPasswordForm.emailPlaceholder"
            description="Placeholder for email input at forgot password form"
            defaultMessage="Corporate email address">
          {
            placeholder => (
                <Field
                    name="email"
                    id="email"
                    component={renderInput}
                    type="email"
                    placeholder={placeholder}
                    validate={[required]}
                    className="form-control"/>
            )
          }
        </FormattedMessage>
      </div>
      {
        errors.map((error, index) => (
            <p key={`formError${index}`}>
              <strong>
                {error}
              </strong>
            </p>
        ))
      }
      <button
          type="submit"
          className="btn btn-outline-primary btn-lg mb-3"
          disabled={isLoading}>
        {
          !isLoading ?
              <FormattedMessage
                  id="ForgotPasswordForm.submitButton"
                  description="Submit button label at forgot password form"
                  defaultMessage="Submit"/>
              :
              <i className="fa fa-spinner fa-spin"/>
        }
      </button>
      <GoBackToLoginLink/>
    </form>
);

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

const ForgotPasswordReduxForm = reduxForm({
  form: 'ForgotPasswordForm'
})(ForgotPasswordForm);

export default ForgotPasswordReduxForm;