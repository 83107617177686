import gql from "graphql-tag";

const resetPassword = gql`
    mutation ResetPassword($input: ResetPasswordInput!){
        resetPassword(input:$input){
            passwordChanged
            validationErrors
        }
    }
`;

export default resetPassword;