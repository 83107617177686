import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloLink} from "apollo-link";
import {TokenRefreshLink} from 'apollo-link-token-refresh';
import {refreshToken} from "services/auth/mutations/index";
import {authLogin, authLogout} from "services/auth/index";
import {getToken, getTokenExpirationDate} from "services/auth/localStorage";
import {reduxStore} from "redux/store";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql/`
});

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: getToken() ? `JWT ${getToken()}` : null,
      'Accept-Language': reduxStore.getState().localesReducer.language
    }
  });
  return forward(operation);
});

const link = new ApolloLink.from([
  new TokenRefreshLink({
    isTokenValidOrUndefined: () => {
      // Indicates the current state of access token expiration.
      // If token not yet expired or user doesn't have a token (guest)
      // true should be returned
      if (!getToken()) return true;
      if (!getTokenExpirationDate()) return false;  // Just in case

      const tokenExpirationDate = new Date(getTokenExpirationDate());
      const now = new Date();
      const differenceInSeconds = (tokenExpirationDate.getTime() - now.getTime()) / 1000;
      return differenceInSeconds >= 1000;
    },
    fetchAccessToken: () => {
      const token = getToken();
      const body = {
        operationName: "RefreshToken",
        query: refreshToken,
        variables: {
          input: {
            token
          }
        }
      };
      return fetch(`${process.env.REACT_APP_SERVER_URL}/graphql/`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      });
    },
    accessTokenField: 'refreshToken',
    handleFetch: refreshToken => {
      authLogin(refreshToken.token).then();
    },
    handleError: err => {
      // full control over handling token fetch Error
      console.error(err);
      authLogout();
      // TODO: Must be redirect to "/" ?
    }
  }),
  middlewareLink,
  httpLink
]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'none',
  },
  mutate: {
    errorPolicy: 'none'
  }
};

export const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    dataIdFromObject: object => object.id
  }),
  defaultOptions
});