import gql from "graphql-tag";

const sendDataSheetByEmail = gql`
    mutation SendDataSheetByEmail($input: SendDataSheetByEmailInput!) {
        sendDataSheetByEmail(input: $input) {
            emailSent
        }
    }
`;

export default sendDataSheetByEmail;