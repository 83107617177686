import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/ProductCardName.scss";
import {Link} from "react-router-dom";
import {
  CARD_NAME_IN_COMPARE,
  CARD_NAME_IN_LISTING
} from "scenes/ProductsPage/components/ProductsList/components/ProductCard/components/ProductCardName/constants/contexts";

const ProductCardName = ({name, slug, context}) => (
    <div className={classNames({
      "product-card-name": true,
      "product-card-name--in-compare": context === CARD_NAME_IN_COMPARE,
      "product-card-name--in-listing": context === CARD_NAME_IN_LISTING
    })}>
      <h2 className="text-uppercase">
        <Link to={`/products/${slug}`}>
          {name}
        </Link>
      </h2>
    </div>
);

ProductCardName.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  context: function (props, propName, componentName) {
    if ([CARD_NAME_IN_LISTING, CARD_NAME_IN_COMPARE].indexOf(props[propName]) === -1) {
      return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. Please send one of: ${CARD_NAME_IN_LISTING} and ${CARD_NAME_IN_COMPARE}. Validation failed.`
      );
    }
  },
};

export default ProductCardName;