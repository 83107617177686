import {defineMessages} from "react-intl";

export const APPLICATION_ADVERTISING_PACKAGING = 'ADVERTISING PACKAGING';
export const APPLICATION_ADVERTISING_PACKAGING_MESSAGE = defineMessages({
  [APPLICATION_ADVERTISING_PACKAGING]: {
    id: `Application.advertisingPackaging`,
    description: "Application value named Advertising Packaging",
    defaultMessage: 'advertising packaging'
  }
});
export const APPLICATION_AGRICULTURAL_PE = 'AGRICULTURAL PE';
export const APPLICATION_AGRICULTURAL_PE_MESSAGE = defineMessages({
  [APPLICATION_AGRICULTURAL_PE]: {
    id: `Application.agriculturalPe`,
    description: "Application value named Agricultural PE",
    defaultMessage: 'agricultural pe'
  }
});
export const APPLICATION_APPAREL = 'APPAREL';
export const APPLICATION_APPAREL_MESSAGE = defineMessages({
  [APPLICATION_APPAREL]: {
    id: `Application.apparel`,
    description: "Application value named Apparel",
    defaultMessage: 'apparel'
  }
});
export const APPLICATION_BALLS = 'BALLS';
export const APPLICATION_BALLS_MESSAGE = defineMessages({
  [APPLICATION_BALLS]: {
    id: `Application.balls`,
    description: "Application value name Balls ",
    defaultMessage: 'balls'
  }
});
export const APPLICATION_BINDINGS = 'BINDINGS';
export const APPLICATION_BINDINGS_MESSAGE = defineMessages({
  [APPLICATION_BINDINGS]: {
    id: `Application.bindings`,
    description: "Application value named Bindings",
    defaultMessage: 'bindings'
  }
});
export const APPLICATION_BLANKETS = 'BLANKETS';
export const APPLICATION_BLANKETS_MESSAGE = defineMessages({
  [APPLICATION_BLANKETS]: {
    id: `Application.blankets`,
    description: "Application value named Blankets",
    defaultMessage: 'blankets'
  }
});
export const APPLICATION_CANVAS_AWNING_AND_CANOPIES = 'CANVAS AWNING AND CANOPIES';
export const APPLICATION_CANVAS_AWNING_AND_CANOPIES_MESSAGE = defineMessages({
  [APPLICATION_CANVAS_AWNING_AND_CANOPIES]: {
    id: `Application.canvasAwningAndCanopies`,
    description: "Application value named Canvas Awning and Canopies",
    defaultMessage: 'canvas awning and canopies'
  }
});
export const APPLICATION_CAPELLADAS = 'CAPELLADAS';
export const APPLICATION_CAPELLADAS_MESSAGE = defineMessages({
  [APPLICATION_CAPELLADAS]: {
    id: `Application.capelladas`,
    description: "Application value named Capelladas",
    defaultMessage: 'capelladas'
  }
});
export const APPLICATION_CARPETS_AND_MATS = 'CARPETS & MATS';
export const APPLICATION_CARPETS_AND_MATS_MESSAGE = defineMessages({
  [APPLICATION_CARPETS_AND_MATS]: {
    id: `Application.carpetsAndMats`,
    description: "Application value named Carpets & Mats",
    defaultMessage: 'carpets & mats'
  }
});
export const APPLICATION_CHEMICAL_PROTECTION = 'CHEMICAL PROTECTION';
export const APPLICATION_CHEMICAL_PROTECTION_MESSAGE = defineMessages({
  [APPLICATION_CHEMICAL_PROTECTION]: {
    id: `Application.chemicalProtection`,
    description: "Application value named Chemical Protection",
    defaultMessage: 'chemical protection'
  }
});
export const APPLICATION_CHROME_PLATED = 'CHROME PLATED';
export const APPLICATION_CHROME_PLATED_MESSAGE = defineMessages({
  [APPLICATION_CHROME_PLATED]: {
    id: `Application.chromePlated`,
    description: "Application value named Chrome Plated",
    defaultMessage: 'chrome plated'
  }
});
export const APPLICATION_COMPLEMENTARY_NM = 'COMPLEMENTARY NM';
export const APPLICATION_COMPLEMENTARY_NM_MESSAGE = defineMessages({
  [APPLICATION_COMPLEMENTARY_NM]: {
    id: `Application.complementaryNm`,
    description: "Application value named Complementary NM",
    defaultMessage: 'complementary nm'
  }
});
export const APPLICATION_CONFECTION_SERVICES = 'CONFECTION SERVICES';
export const APPLICATION_CONFECTION_SERVICES_MESSAGE = defineMessages({
  [APPLICATION_CONFECTION_SERVICES]: {
    id: `Application.confectionServices`,
    description: "Application value named Confection Services",
    defaultMessage: 'confection services'
  }
});
export const APPLICATION_CONSTRUCTION_PE = 'CONSTRUCTION PE';
export const APPLICATION_CONSTRUCTION_PE_MESSAGE = defineMessages({
  [APPLICATION_CONSTRUCTION_PE]: {
    id: `Application.constructionPe`,
    description: "Application value named Construction PE",
    defaultMessage: 'construction pe'
  }
});
export const APPLICATION_CONSULTANTS = 'CONSULTANTS';
export const APPLICATION_CONSULTANTS_MESSAGE = defineMessages({
  [APPLICATION_CONSULTANTS]: {
    id: `Application.consultants`,
    description: "Application value named Consultants",
    defaultMessage: 'consultants'
  }
});
export const APPLICATION_CORSETRY = 'CORSETRY';
export const APPLICATION_CORSETRY_MESSAGE = defineMessages({
  [APPLICATION_CORSETRY]: {
    id: `Application.corsetry`,
    description: "Application value named Corsetry",
    defaultMessage: 'corsetry'
  }
});
export const APPLICATION_COVERS = 'COVERS';
export const APPLICATION_COVERS_MESSAGE = defineMessages({
  [APPLICATION_COVERS]: {
    id: `Application.covers`,
    description: "Application value named Covers",
    defaultMessage: 'covers'
  }
});
export const APPLICATION_FIXED = 'FIXED';
export const APPLICATION_FIXED_MESSAGE = defineMessages({
  [APPLICATION_FIXED]: {
    id: `Application.fixed`,
    description: "Application value name Fixed ",
    defaultMessage: 'fixed'
  }
});
export const APPLICATION_FLOORING = 'FLOORING';
export const APPLICATION_FLOORING_MESSAGE = defineMessages({
  [APPLICATION_FLOORING]: {
    id: `Application.flooring`,
    description: "Application value named Flooring",
    defaultMessage: 'flooring'
  }
});
export const APPLICATION_GEOSYNTHETICS = 'GEOSYNTHETICS';
export const APPLICATION_GEOSYNTHETICS_MESSAGE = defineMessages({
  [APPLICATION_GEOSYNTHETICS]: {
    id: `Application.geosynthetics`,
    description: "Application value named Geosynthetics",
    defaultMessage: 'geosynthetics'
  }
});
export const APPLICATION_HANDBAGS = 'HANDBAGS';
export const APPLICATION_HANDBAGS_MESSAGE = defineMessages({
  [APPLICATION_HANDBAGS]: {
    id: `Application.handbags`,
    description: "Application value named Handbags",
    defaultMessage: 'handbags'
  }
});
export const APPLICATION_HEADLINER = 'HEADLINER';
export const APPLICATION_HEADLINER_MESSAGE = defineMessages({
  [APPLICATION_HEADLINER]: {
    id: `Application.headliner`,
    description: "Application value named Headliner",
    defaultMessage: 'headliner'
  }
});
export const APPLICATION_HEAVY_DUTY = 'HEAVY DUTY';
export const APPLICATION_HEAVY_DUTY_MESSAGE = defineMessages({
  [APPLICATION_HEAVY_DUTY]: {
    id: `Application.heavyDuty`,
    description: "Application value named Heavy Duty",
    defaultMessage: 'heavy duty'
  }
});
export const APPLICATION_INSOLES = 'INSOLES';
export const APPLICATION_INSOLES_MESSAGE = defineMessages({
  [APPLICATION_INSOLES]: {
    id: `Application.insoles`,
    description: "Application value named Insoles",
    defaultMessage: 'insoles'
  }
});
export const APPLICATION_INSTALLED_FLOORS = 'INSTALLED FLOORS';
export const APPLICATION_INSTALLED_FLOORS_MESSAGE = defineMessages({
  [APPLICATION_INSTALLED_FLOORS]: {
    id: `Application.installedFloors`,
    description: "Application value named Installed Floors",
    defaultMessage: 'installed floors'
  }
});
export const APPLICATION_LIGHT_PROTECTION = 'LIGHT PROTECTION';
export const APPLICATION_LIGHT_PROTECTION_MESSAGE = defineMessages({
  [APPLICATION_LIGHT_PROTECTION]: {
    id: `Application.lightProtection`,
    description: "Application value named Light Protection",
    defaultMessage: 'light protection'
  }
});
export const APPLICATION_MANUFACTURING = 'MANUFACTURING';
export const APPLICATION_MANUFACTURING_MESSAGE = defineMessages({
  [APPLICATION_MANUFACTURING]: {
    id: `Application.manufacturing`,
    description: "Application value named Manufacturing",
    defaultMessage: 'manufacturing'
  }
});
export const APPLICATION_MARKETING_ARTICLES = 'MARKETING ARTICLES';
export const APPLICATION_MARKETING_ARTICLES_MESSAGE = defineMessages({
  [APPLICATION_MARKETING_ARTICLES]: {
    id: `Application.marketingArticles`,
    description: "Application value named Marketing Articles",
    defaultMessage: 'marketing articles'
  }
});
export const APPLICATION_OII = 'OII';
export const APPLICATION_OII_MESSAGE = defineMessages({
  [APPLICATION_OII]: {
    id: `Application.oii`,
    description: "Application value named OII",
    defaultMessage: 'oii'
  }
});
export const APPLICATION_ORNAMET = 'ORNAMET';
export const APPLICATION_ORNAMET_MESSAGE = defineMessages({
  [APPLICATION_ORNAMET]: {
    id: `Application.ornamet`,
    description: "Application value named Ornamet",
    defaultMessage: 'ornamet'
  }
});
export const APPLICATION_OTHER_INSTALLATIONS = 'OTHER INSTALLATIONS';
export const APPLICATION_OTHER_INSTALLATIONS_MESSAGE = defineMessages({
  [APPLICATION_OTHER_INSTALLATIONS]: {
    id: `Application.otherInstallations`,
    description: "Application value named Other Installations",
    defaultMessage: 'other installations'
  }
});
export const APPLICATION_PANELS = 'PANELS';
export const APPLICATION_PANELS_MESSAGE = defineMessages({
  [APPLICATION_PANELS]: {
    id: `Application.panels`,
    description: "Application value named Panels",
    defaultMessage: 'panels'
  }
});
export const APPLICATION_PGFP = 'PGFP';
export const APPLICATION_PGFP_MESSAGE = defineMessages({
  [APPLICATION_PGFP]: {
    id: `Application.pgfp`,
    description: "Application value named PGFP",
    defaultMessage: 'pgfp'
  }
});
export const APPLICATION_PHYSICAL_TESTS = 'PHYSICAL TESTS';
export const APPLICATION_PHYSICAL_TESTS_MESSAGE = defineMessages({
  [APPLICATION_PHYSICAL_TESTS]: {
    id: `Application.physicalTests`,
    description: "Application value named Physical Tests",
    defaultMessage: 'physical tests'
  }
});
export const APPLICATION_PROTECTION_AND_ENCLOSURE = 'PROTECTION AND ENCLOSURE';
export const APPLICATION_PROTECTION_AND_ENCLOSURE_MESSAGE = defineMessages({
  [APPLICATION_PROTECTION_AND_ENCLOSURE]: {
    id: `Application.protectionAndEnclosure`,
    description: "Application value named Protection and Enclosure",
    defaultMessage: 'protection and enclosure'
  }
});
export const APPLICATION_PROTECTIVE_CLOTHING = 'PROTECTIVE CLOTHING';
export const APPLICATION_PROTECTIVE_CLOTHING_MESSAGE = defineMessages({
  [APPLICATION_PROTECTIVE_CLOTHING]: {
    id: `Application.protectiveClothing`,
    description: "Application value named Protective Clothing",
    defaultMessage: 'protective clothing'
  }
});
export const APPLICATION_RECORDING = 'RECORDING';
export const APPLICATION_RECORDING_MESSAGE = defineMessages({
  [APPLICATION_RECORDING]: {
    id: `Application.recording`,
    description: "Application value named Recording",
    defaultMessage: 'recording'
  }
});
export const APPLICATION_RECTIFIED = 'RECTIFIED';
export const APPLICATION_RECTIFIED_MESSAGE = defineMessages({
  [APPLICATION_RECTIFIED]: {
    id: `Application.rectified`,
    description: "Application value named Rectified",
    defaultMessage: 'rectified'
  }
});
export const APPLICATION_REMNANTS = 'REMNANTS';
export const APPLICATION_REMNANTS_MESSAGE = defineMessages({
  [APPLICATION_REMNANTS]: {
    id: `Application.remnants`,
    description: "Application value named Remnants",
    defaultMessage: 'remnants'
  }
});
export const APPLICATION_STRETCH_PE = 'STRETCH PE';
export const APPLICATION_STRETCH_PE_MESSAGE = defineMessages({
  [APPLICATION_STRETCH_PE]: {
    id: `Application.stretchPe`,
    description: "Application value named Stretch PE",
    defaultMessage: 'stretch pe'
  }
});
export const APPLICATION_SYNTHETIC_GRASS = 'SYNTHETIC GRASS';
export const APPLICATION_SYNTHETIC_GRASS_MESSAGE = defineMessages({
  [APPLICATION_SYNTHETIC_GRASS]: {
    id: `Application.syntheticGrass`,
    description: "Application value named Synthetic Grass",
    defaultMessage: 'synthetic grass'
  }
});
export const APPLICATION_SYNTHETIC_GRASS_INSTALLED = 'SYNTHETIC GRASS INSTALLED';
export const APPLICATION_SYNTHETIC_GRASS_INSTALLED_MESSAGE = defineMessages({
  [APPLICATION_SYNTHETIC_GRASS_INSTALLED]: {
    id: `Application.syntheticGrassInstalled`,
    description: "Application value named Synthetic Grass Installed",
    defaultMessage: 'synthetic grass installed'
  }
});
export const APPLICATION_TOPPING = 'TOPPING';
export const APPLICATION_TOPPING_MESSAGE = defineMessages({
  [APPLICATION_TOPPING]: {
    id: `Application.topping`,
    description: "Application value named Topping",
    defaultMessage: 'topping'
  }
});
export const APPLICATION_UPHOLSTERY = 'UPHOLSTERY';
export const APPLICATION_UPHOLSTERY_MESSAGE = defineMessages({
  [APPLICATION_UPHOLSTERY]: {
    id: `Application.upholstery`,
    description: "Application value named Upholstery",
    defaultMessage: 'upholstery'
  }
});
export const APPLICATION_UPHOLSTERY_INSTALLED = 'UPHOLSTERY INSTALLED';
export const APPLICATION_UPHOLSTERY_INSTALLED_MESSAGE = defineMessages({
  [APPLICATION_UPHOLSTERY_INSTALLED]: {
    id: `Application.upholsteryInstalled`,
    description: "Application value named Upholstery Installed",
    defaultMessage: 'upholstery installed'
  }
});
export const APPLICATION_WALLS = 'WALLS';
export const APPLICATION_WALLS_MESSAGE = defineMessages({
  [APPLICATION_WALLS]: {
    id: `Application.walls`,
    description: "Application value name Walls",
    defaultMessage: 'walls'
  }
});
export const APPLICATION_WELT_AND_TRIMS = 'WELT & TRIMS';
export const APPLICATION_WELT_AND_TRIMS_MESSAGE = defineMessages({
  [APPLICATION_WELT_AND_TRIMS]: {
    id: `Application.weltAndTrims`,
    description: "Application value named Welt & Trims",
    defaultMessage: 'welt & trims'
  }
});

// IMPORTANT: Some applications here are temporarily commented due to a client request.
// Please keep them commented and DON'T DELETE ANY OF THEM. Will be enabled soon.
export const APPLICATIONS = [
  // {...APPLICATION_ADVERTISING_PACKAGING_MESSAGE[APPLICATION_ADVERTISING_PACKAGING], value: APPLICATION_ADVERTISING_PACKAGING},
  // {...APPLICATION_AGRICULTURAL_PE_MESSAGE[APPLICATION_AGRICULTURAL_PE], value: APPLICATION_AGRICULTURAL_PE},
  // {...APPLICATION_APPAREL_MESSAGE[APPLICATION_APPAREL], value: APPLICATION_APPAREL},
  // {...APPLICATION_BALLS_MESSAGE[APPLICATION_BALLS], value: APPLICATION_BALLS},
  // {...APPLICATION_BINDINGS_MESSAGE[APPLICATION_BINDINGS], value: APPLICATION_BINDINGS},
  // {...APPLICATION_BLANKETS_MESSAGE[APPLICATION_BLANKETS], value: APPLICATION_BLANKETS},
  {...APPLICATION_CANVAS_AWNING_AND_CANOPIES_MESSAGE[APPLICATION_CANVAS_AWNING_AND_CANOPIES], value: APPLICATION_CANVAS_AWNING_AND_CANOPIES},
  {...APPLICATION_CAPELLADAS_MESSAGE[APPLICATION_CAPELLADAS], value: APPLICATION_CAPELLADAS},
  // {...APPLICATION_CARPETS_AND_MATS_MESSAGE[APPLICATION_CARPETS_AND_MATS], value: APPLICATION_CARPETS_AND_MATS},
  {...APPLICATION_CHEMICAL_PROTECTION_MESSAGE[APPLICATION_CHEMICAL_PROTECTION], value: APPLICATION_CHEMICAL_PROTECTION},
  // {...APPLICATION_CHROME_PLATED_MESSAGE[APPLICATION_CHROME_PLATED], value: APPLICATION_CHROME_PLATED},
  {...APPLICATION_COMPLEMENTARY_NM_MESSAGE[APPLICATION_COMPLEMENTARY_NM], value: APPLICATION_COMPLEMENTARY_NM},
  // {...APPLICATION_CONFECTION_SERVICES_MESSAGE[APPLICATION_CONFECTION_SERVICES], value: APPLICATION_CONFECTION_SERVICES},
  // {...APPLICATION_CONSTRUCTION_PE_MESSAGE[APPLICATION_CONSTRUCTION_PE], value: APPLICATION_CONSTRUCTION_PE},
  // {...APPLICATION_CONSULTANTS_MESSAGE[APPLICATION_CONSULTANTS], value: APPLICATION_CONSULTANTS},
  // {...APPLICATION_CORSETRY_MESSAGE[APPLICATION_CORSETRY], value: APPLICATION_CORSETRY},
  {...APPLICATION_COVERS_MESSAGE[APPLICATION_COVERS], value: APPLICATION_COVERS},
  // {...APPLICATION_FIXED_MESSAGE[APPLICATION_FIXED], value: APPLICATION_FIXED},
  {...APPLICATION_FLOORING_MESSAGE[APPLICATION_FLOORING], value: APPLICATION_FLOORING},
  // {...APPLICATION_GEOSYNTHETICS_MESSAGE[APPLICATION_GEOSYNTHETICS], value: APPLICATION_GEOSYNTHETICS},
  {...APPLICATION_HANDBAGS_MESSAGE[APPLICATION_HANDBAGS], value: APPLICATION_HANDBAGS},
  // {...APPLICATION_HEADLINER_MESSAGE[APPLICATION_HEADLINER], value: APPLICATION_HEADLINER},
  {...APPLICATION_HEAVY_DUTY_MESSAGE[APPLICATION_HEAVY_DUTY], value: APPLICATION_HEAVY_DUTY},
  // {...APPLICATION_INSOLES_MESSAGE[APPLICATION_INSOLES], value: APPLICATION_INSOLES},
  // {...APPLICATION_INSTALLED_FLOORS_MESSAGE[APPLICATION_INSTALLED_FLOORS], value: APPLICATION_INSTALLED_FLOORS},
  {...APPLICATION_LIGHT_PROTECTION_MESSAGE[APPLICATION_LIGHT_PROTECTION], value: APPLICATION_LIGHT_PROTECTION},
  // {...APPLICATION_MANUFACTURING_MESSAGE[APPLICATION_MANUFACTURING], value: APPLICATION_MANUFACTURING},
  // {...APPLICATION_MARKETING_ARTICLES_MESSAGE[APPLICATION_MARKETING_ARTICLES], value: APPLICATION_MARKETING_ARTICLES},
  // {...APPLICATION_OII_MESSAGE[APPLICATION_OII], value: APPLICATION_OII},
  // {...APPLICATION_ORNAMET_MESSAGE[APPLICATION_ORNAMET], value: APPLICATION_ORNAMET},
  // {...APPLICATION_OTHER_INSTALLATIONS_MESSAGE[APPLICATION_OTHER_INSTALLATIONS], value: APPLICATION_OTHER_INSTALLATIONS},
  {...APPLICATION_PANELS_MESSAGE[APPLICATION_PANELS], value: APPLICATION_PANELS},
  // {...APPLICATION_PGFP_MESSAGE[APPLICATION_PGFP], value: APPLICATION_PGFP},
  // {...APPLICATION_PHYSICAL_TESTS_MESSAGE[APPLICATION_PHYSICAL_TESTS], value: APPLICATION_PHYSICAL_TESTS},
  // {...APPLICATION_PROTECTION_AND_ENCLOSURE_MESSAGE[APPLICATION_PROTECTION_AND_ENCLOSURE], value: APPLICATION_PROTECTION_AND_ENCLOSURE},
  // {...APPLICATION_PROTECTIVE_CLOTHING_MESSAGE[APPLICATION_PROTECTIVE_CLOTHING], value: APPLICATION_PROTECTIVE_CLOTHING},
  // {...APPLICATION_RECORDING_MESSAGE[APPLICATION_RECORDING], value: APPLICATION_RECORDING},
  // {...APPLICATION_RECTIFIED_MESSAGE[APPLICATION_RECTIFIED], value: APPLICATION_RECTIFIED},
  // {...APPLICATION_REMNANTS_MESSAGE[APPLICATION_REMNANTS], value: APPLICATION_REMNANTS},
  // {...APPLICATION_STRETCH_PE_MESSAGE[APPLICATION_STRETCH_PE], value: APPLICATION_STRETCH_PE},
  // {...APPLICATION_SYNTHETIC_GRASS_MESSAGE[APPLICATION_SYNTHETIC_GRASS], value: APPLICATION_SYNTHETIC_GRASS},
  // {...APPLICATION_SYNTHETIC_GRASS_INSTALLED_MESSAGE[APPLICATION_SYNTHETIC_GRASS_INSTALLED], value: APPLICATION_SYNTHETIC_GRASS_INSTALLED},
  {...APPLICATION_TOPPING_MESSAGE[APPLICATION_TOPPING], value: APPLICATION_TOPPING},
  {...APPLICATION_UPHOLSTERY_MESSAGE[APPLICATION_UPHOLSTERY], value: APPLICATION_UPHOLSTERY},
  // {...APPLICATION_UPHOLSTERY_INSTALLED_MESSAGE[APPLICATION_UPHOLSTERY_INSTALLED], value: APPLICATION_UPHOLSTERY_INSTALLED},
  // {...APPLICATION_WALLS_MESSAGE[APPLICATION_WALLS], value: APPLICATION_WALLS},
  // {...APPLICATION_WELT_AND_TRIMS_MESSAGE[APPLICATION_WELT_AND_TRIMS], value: APPLICATION_WELT_AND_TRIMS},
];