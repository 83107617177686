import React, {Component} from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import {apolloClient} from "apollo/client";
import {withRouter} from "react-router";
import CompareBox from "scenes/ProductsPage/components/ProductsList/components/CompareBox";
import {multipleSimplePatternsQuery} from "utils/hoc/withCompareItems/query";

export default function withCompareItems(WrappedComponent) {
  class WithCompareItems extends Component {
    constructor() {
      super();

      this.state = {
        patterns: [],
        loading: true
      };
    }

    componentDidMount() {
      this.getSelectedPatterns();
    }

    componentDidUpdate(prevProps) {
      const {location} = this.props;

      if (prevProps.location.search !== location.search) {
        this.getSelectedPatterns();
      }
    }

    /**
     * Read all patterns id to compare and fetch all
     */
    getSelectedPatterns() {
      const selectedPatterns = CompareBox.getCurrentCompareParams();

      if (selectedPatterns.length > 0) {
        if (selectedPatterns.length <= CompareBox.itemsToCompareAmount())
          this.setState({loading: true}, () => {
            apolloClient.query({
              fetchPolicy: "network-only",
              query: multipleSimplePatternsQuery,
              variables: {
                ids: CompareBox.getCurrentCompareParams()
              }
            }).then(response => {
              const {data: {patterns}} = response;
              this.setState({patterns: patterns.edges.map(item => item.node), loading: false})
            });
          });
        else {
          const lastPattern = selectedPatterns.pop();
          CompareBox.removePattern(lastPattern)
        }
    } else
      this.setState({patterns: [], loading: false})
    }

    render() {
      const {patterns, loading} = this.state;
      return <WrappedComponent
          patternsToCompare={patterns}
          loadingPatterns={loading}
          {...this.props} />;
    }
  }

  hoistNonReactStatic(WithCompareItems, WrappedComponent);

  return withRouter(WithCompareItems)
}