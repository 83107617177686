import React from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

const GoBackToLoginLink = () => (
    <p className="form-text text-muted text-center">
      <Link to="/auth/login">
        <FormattedMessage
            id="ForgotPasswordForm.goBackToLogin"
            description="Go back to login text below submit button at reset password form"
            defaultMessage="Go back to login"/>
      </Link>
    </p>
);

export default GoBackToLoginLink;