import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./styles/SpecsTableColumn.scss";
import ImageField from "./components/ImageField";

export default function SpecsTableColumn({value}) {
  const isLogo = typeof value === "object" && value.logo && value.title

  if (isLogo) {
    return <ImageField value={value} />
  }

  return <span>{value}</span>;
}

SpecsTableColumn.propTypes = {
  value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node
  ]).isRequired
};
