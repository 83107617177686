import gql from "graphql-tag";
import singleUserFragment from "utils/fragments/SingleUserFragment";

const meQuery = gql`
  query MeQuery {
    me {
      ...UserFragment
      profile {
        id
        geographicInstance {
          edges {
            node {
              id
              name
              code
            }
          }
        }
      }
    }
  }
  ${singleUserFragment}
`;

export default meQuery;
