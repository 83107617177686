import React, {Component, createRef} from "react";
import "./styles/ReferencesCarousel.scss";
import PropTypes from "prop-types";
import {updateQueryParams} from "services/browser-history";
import {QUERY_PARAM_REFERENCE} from "scenes/ProductDetails/constants";

class ReferencesCarousel extends Component {
  constructor(props) {
    super(props);

    this.carouselContentRef = createRef();

    this.handleReferenceClick = color => this._handleReferenceClick.bind(this, color);
    this.handleArrowClick = side => this._handleArrowClick.bind(this, side);
  }

  /**
   * Handle reference click to update the query params with the new reference
   * @param color
   * @private
   */
  _handleReferenceClick(color) {
    updateQueryParams({
      [QUERY_PARAM_REFERENCE]: color
    })
  }

  /**
   * Handles clicks on the arrow buttons
   * @param {string} side
   * @private
   */
  _handleArrowClick(side) {
    const carousel = this.carouselContentRef.current;
    const referenceNodes = carousel.querySelectorAll('.reference-card') || null;
    if (referenceNodes) {
      if (side === 'right') {
        carousel.scrollLeft = carousel.scrollLeft += (carousel.clientWidth / 2);

      } else {
        carousel.scrollLeft = carousel.scrollLeft -= (carousel.clientWidth / 2);
      }
    }
  }

  render() {
    const {references} = this.props;

    return (
        <div className="references-carousel-container">
          {
            ["left", "right"].map(side =>
                <button
                    key={side}
                    className={`references-carousel-container__button references-carousel-container__button--${side}`}
                    onClick={this.handleArrowClick(side)}>
                  <i className={`sp-chevron-${side}`}/>
                </button>
            )
          }
          <div className="references-carousel">
            <div
                ref={this.carouselContentRef}
                className="references-carousel__content">
              {
                references.map(reference => (
                    <div
                        key={reference.node.id}
                        onClick={this.handleReferenceClick(reference.node.color)}
                        className="reference-card">
                      <div className="reference-card__image">
                        <div
                            className="product-card-image"
                            style={{
                              backgroundImage: `url("${reference.node.imageThumbSmall}")`
                            }}/>
                      </div>
                      <div className="reference-card__name">
                        <h2 className="text-uppercase">
                          {reference.node.color}
                        </h2>
                      </div>
                    </div>
                ))
              }
            </div>
          </div>
        </div>
    )
  }
}

ReferencesCarousel.propTypes = {
  references: PropTypes.array.isRequired
};

export default ReferencesCarousel;