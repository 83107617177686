import React from 'react'
import { FormattedMessage } from 'react-intl'
import singlePatternZippedImagesQuery from './query';
import meQuery from '../../../../utils/queries/MeQuery';
import { graphql, compose } from "react-apollo";
import "./styles/DownloadZippedImagesButton.scss"
import { createDownloadLog } from './mutations';

const DownloadZippedImagesButton = ({
  singlePatternZippedImagesQuery,
  meQuery,
  logDownload,
  pattern
}) => {

  const { me: { firstName, email } } = meQuery;

  const handleClickInDownloadZippedImageslink = () => {
    logDownload({
      variables: {
        input: {
          firstName: firstName,
          email: email,
          patternId: pattern.id,
          resourceType: "zipped-images",
          resourceUrl: singlePatternZippedImagesQuery?.pattern?.zippedImagesUrl
        }
      }
    })
  }

  return (
    <div>
      <a
        className='link-underline-none'
        href={singlePatternZippedImagesQuery?.pattern?.zippedImagesUrl}
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleClickInDownloadZippedImageslink}
      >
        <button className="btn btn-outline-secondary btn-lg btn-block">
          <FormattedMessage
            id="DownloadZippedImagesButton.label"
            description="Product details download zipped images button label"
            defaultMessage="Download images"
          />
        </button>
      </a>
    </div>
  )
}

export default compose(
  graphql(meQuery, {
    name: "meQuery"
  }),
  graphql(singlePatternZippedImagesQuery, {
    name: "singlePatternZippedImagesQuery",
    options: (props) => ({
      variables: {
        id: props.pattern?.id,
        instanceId: "SW5zdGFuY2VOb2RlOjQ=", // This parameter is irrelevant but must be sent
        hasInstance: false
      },
    }),
  }),
  graphql(createDownloadLog, { name: "logDownload" })
)(DownloadZippedImagesButton);