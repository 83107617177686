import React from "react";
import {FormattedMessage} from "react-intl";

const NoResultsMessage = () => (
    <div className="pt-3 pb-3">
      <p className="text-center">
        <FormattedMessage
            id="NoResultsMessage.label"
            description="Label when not have results in the product list"
            defaultMessage="No results for current search..."/>
      </p>
    </div>
);

export default NoResultsMessage;