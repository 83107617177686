import React, {Component} from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {defineMessages} from "react-intl";
import FiltersBox from "scenes/ProductsPage/components/FiltersBar/components/FiltersBox";
import {updateQueryParams} from "services/browser-history";
import {getParsedQueryParams} from "services/browser-history";
import ProductList from "scenes/ProductsPage/components/ProductsList";

class RadioFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultOption: {
        ...defineMessages({
          radioDefaultOption: {
            id: "RadioFilter.defaultOption",
            description: "Default option in all Radio filter to the word 'all'",
            defaultMessage: 'all'
          }
        }).radioDefaultOption,
        value: undefined
      }
    };
    this.handleOnChange = option => this._handleOnChange.bind(this, option)
  }

  /**
   * Handle onChange checkbox input to set new data into queryParams
   * @param option
   * @private
   */
  _handleOnChange(option) {
    const {queryParam} = this.props;

    updateQueryParams({
      [queryParam]: option.value,
      ...ProductList.resettled_pagination_query_params(),
    })
  }


  render() {
    const {title, options, queryParam, withoutTranslation} = this.props;
    const currentQueryParamData = getParsedQueryParams()[queryParam];
    const {defaultOption} = this.state;

    return (
        <FiltersBox
            title={title}>
          <div>
            <p className="text-capitalize">
              <input
                  type="radio"
                  checked={currentQueryParamData === defaultOption.value}
                  onChange={this.handleOnChange(defaultOption)}/>
              &nbsp;
              {
                withoutTranslation ?
                    <span>{defaultOption.name}</span>
                    :
                    <FormattedMessage
                        {...defaultOption}/>
              }
            </p>
            {
              options.map((option, optionIndex) => (
                  <p
                      key={`filterBoxOption${optionIndex}`}
                      className="text-capitalize">
                    <input
                        type="radio"
                        checked={currentQueryParamData === option.value}
                        onChange={this.handleOnChange(option)}/>
                    &nbsp;
                    {
                      withoutTranslation ?
                          <span>{option.name}</span>
                          :
                          <FormattedMessage
                              {...option}/>
                    }
                  </p>
              ))
            }
          </div>
        </FiltersBox>
    )
  }
}

RadioFilter.defaultProps = {
  withoutTranslation: false
};

RadioFilter.propTypes = {
  title: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  queryParam: PropTypes.string.isRequired,
  withoutTranslation: PropTypes.bool
};

export default RadioFilter;