import {defineMessages} from "react-intl";

export const SPECS_TABLE_PRODUCT_CATEGORIZATION = defineMessages({
  productCategorization: {
    id: "ProductSpecs.productCategorization",
    description: "Product Categorization table title at product specs section",
    defaultMessage: "Product Categorization"
  }
}).productCategorization;

export const SPECS_TABLE_GENERAL = defineMessages({
  generalTableTitle: {
    id: "ProductSpecs.generalTableTitle",
    description: "General table title at product specs section",
    defaultMessage: "General"
  }
}).generalTableTitle;

export const SPECS_TABLE_BACKING = defineMessages({
  backingTableTitle: {
    id: "ProductSpecs.backingTableTitle",
    description: "Backing table title at product specs section",
    defaultMessage: "Backing"
  }
}).backingTableTitle;

export const SPECS_TABLE_OTHER_ATTRIBUTES = defineMessages({
  otherAttributesTableTitle: {
    id: "ProductSpecs.otherAttributesTableTitle",
    description: "Other Attributes table title at product specs section",
    defaultMessage: "Other Attributes"
  }
}).otherAttributesTableTitle;

export const SPECS_TABLE_FLAME_RETARDANCY = defineMessages({
  flameRetardancyTableTitle: {
    id: "ProductSpecs.flameRetardancyTableTitle",
    description: "Flame Retardancy table title at product specs section",
    defaultMessage: "Flame Retardancy"
  }
}).flameRetardancyTableTitle;

export const  SPECS_TABLE_ADDITIONAL_TEST = defineMessages({
  additionalTests: {
    id: "ProductSpecs.additionalTests",
    description: "Additional Tests table title at product specs section",
    defaultMessage: "Additional Tests"
  }
}).additionalTests;
