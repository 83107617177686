import {connect} from "react-redux";
import MainMenuUserInfo from "../index";
import {closeMainMenu} from "components/Header/actions";

const mapStateToProps = (state) => {
  return {
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMainMenu: () => dispatch(closeMainMenu())
  };
};

const MainMenuUserInfoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainMenuUserInfo);

export default MainMenuUserInfoContainer;