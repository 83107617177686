import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {graphql} from "react-apollo";
import sendDataSheetByEmail
  from "scenes/ProductDetails/components/SendByEmailButton/components/SendByEmailModal/mutations";

export class SendByEmailModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      loading: false,
      errors: [],
      emailSent: false
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  /**
   * Handle close modal callback to reset the state
   */
  handleCloseModal() {
    this.setState({
      email: '',
      emailSent: false
    })
  }

  /**
   * Handle email input changes to set data in the state
   * @param event
   */
  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  /**
   * Handle form submit and calls sendDataSheetByEmail mutation
   * @param event
   */
  handleFormSubmit(event) {
    event.preventDefault();
    const {patternId} = this.props;
    const {email} = this.state;

    this.setState({loading: true}, () => {
      this.props.sendDataSheetByEmail({
        variables: {
          input: {
            patternId,
            email
          }
        }
      }).then(response => {
        const {data: {sendDataSheetByEmail}} = response;
        if (sendDataSheetByEmail.emailSent)
          this.setState({
            emailSent: sendDataSheetByEmail.emailSent,
            errors: [],
            loading: false
          });
        else
          this.setState({loading: false});
      }).catch(error => {
        this.setState({
          errors: error.graphQLErrors.map(item => item.message),
          loading: false
        });
      })
    })
  }

  /**
   * Handle close button click, reset the state and calls toggle function.
   */
  handleCloseButtonClick() {
    this.props.toggle();
  }

  render() {
    const {email, loading, errors, emailSent} = this.state;
    const {isOpen} = this.props;

    return (
        <Modal
            onClosed={this.handleCloseModal}
            isOpen={isOpen}
            toggle={this.props.toggle}>
          <form onSubmit={this.handleFormSubmit}>
            <div className="container">
              <div className="row pt-3 pb-3 text-center">
                <div className="col-12">
                  <h2>
                    <FormattedMessage
                        id="SendByEmailModal.title"
                        description="Title of the 'Send by email' modal on product details page"
                        defaultMessage="Share this product Technical Specification."/>
                  </h2>
                </div>
                {
                  emailSent ?
                      <React.Fragment>
                        <div className="col-12">
                          <p className="px-5">
                            <FormattedMessage
                                id="SendByEmailModal.emailSent"
                                description="Confirmation copy of the 'Send by email' modal on product details page"
                                defaultMessage="The product technical specification has been sent"/>
                          </p>
                        </div>
                        <div className="col-12">
                          <button
                              type="button"
                              onClick={this.handleCloseButtonClick}
                              className="btn btn-primary">
                            <FormattedMessage
                                id="SendByEmailModal.closeButton"
                                description="Close button label of the 'Send by email' modal on product details page"
                                defaultMessage="Close"/>
                          </button>
                        </div>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <div className="col-12">
                          <p className="px-5">
                            <FormattedMessage
                                id="SendByEmailModal.body"
                                description="Body of the 'Send by email' modal on product details page"
                                defaultMessage="Enter the email of the person you want to send it to"/>
                          </p>
                        </div>
                        <div className="col-12">
                          <div className="form-group px-5">
                            <input
                                value={email}
                                onChange={this.handleEmailChange}
                                placeholder="Email"
                                className="form-control"
                                type="text"/>
                          </div>
                          {
                            errors.map((error, index) =>
                                <div
                                    key={`SendByEmailModalFormError${index}`}
                                    className="invalid-feedback">
                                  {error}
                                </div>
                            )
                          }
                        </div>
                        <div className="col-12">
                          <button
                              disabled={loading}
                              type="submit"
                              className="btn btn-primary">
                            <FormattedMessage
                                id="SendByEmailModal.sendButton"
                                description="Send button label of the 'Send by email' modal on product details page"
                                defaultMessage="Send"/>
                          </button>
                        </div>
                      </React.Fragment>
                }
              </div>
            </div>
          </form>
        </Modal>
    )
  }
}

SendByEmailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  patternId: PropTypes.string.isRequired
};

export default graphql(sendDataSheetByEmail, {name: 'sendDataSheetByEmail'})(SendByEmailModal);