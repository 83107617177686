import {connect} from "react-redux";
import ProquinalLibraryApp from "../index";
import {withRouter} from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const ProquinalLibraryAppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProquinalLibraryApp);

export default withRouter(ProquinalLibraryAppContainer);