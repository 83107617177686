import React from 'react'
import "./styles/ImageField.scss";

const ImageField = ({ value }) => {
  if (!value.code) return <img src={value?.logo} alt={value?.title} className="specs-table-image"/>
  else return (
    <div>
      <div className='specs-table-image-content'>
        <img src={value?.logo} alt={value?.title} className="specs-table-image"/> <br />
        <span className='specs-table-image-text'>{value.code}</span>
      </div>
    </div>
  )
}

export default ImageField