import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { injectIntl } from "react-intl";
import translatedDisplayData from "scenes/ProductDetails/components/ProductSpecs/components/ProductSpecsAccordion/components/ProductSpecsAccordionBody/data/translations";
import "./styles/ProductSpecsAccordionBody.scss";
export class ProductSpecAccordionBody extends Component {
  constructor(props) {
    super(props);

    const { intl } = props;
    this.state = {
      translatedDisplayData: translatedDisplayData(intl),
    };
  }

  /**
   * Generate translated message based on given key
   * @param key
   * @return {string}
   */
  getMessage(key) {
    const { translatedDisplayData } = this.state;
    const { withoutTranslation } = this.props;
    return withoutTranslation ? key : translatedDisplayData[key];
  }

  render() {
    const { data, withoutTranslation } = this.props;
    const { translatedDisplayData } = this.state;
    const validKeymap = data.findIndex((item) => item !== null);
    let counter = 0; // to store table row indexes

    // If validKeymap is -1, means that no pattern has data to display
    if (validKeymap === -1)
      return (
        <tr className="text-center">
          <th scope="row">No data to display.</th>
        </tr>
      );

    return (
      <React.Fragment>
        {Object.keys(data[validKeymap]).map((key, keyIndex) => {
          if (
            data.every((item) =>
              ["", null].includes(item ? item[key] : null)
            ) ||
            (!translatedDisplayData[key] && !withoutTranslation)
          )
            return null;

          counter++; // update the counter only if the conditional above doesn't returns null
          const isEven = counter % 2 === 0; // Determine if the current row is even (2, 4, 6, 8...)

          return (
            <React.Fragment key={`${key}-${keyIndex}`}>
              {/* This is a mobile specific header, will be hidden on desktop and visible on mobile,
                      should only be rendered for compare page (when more than 1 product) */}
              {data.length > 1 && (
                <tr className="specs-table-row specs-table-row--mobile-header">
                  <th scope="row" colSpan={data.length}>
                    {this.getMessage(key)}
                  </th>
                </tr>
              )}
              {/* This is the row with the data */}
              <tr
                className={classNames({
                  "specs-table-row": true,
                  "specs-table-row--in-product-detail-page": data.length === 1,
                  "specs-table-row--in-compare-page": data.length > 1,
                  // --1-product, --2-products...
                  [`specs-table-row--${data.length}-product${
                    data.length > 1 && "s"
                  }`]: data.length > 1,
                  // I need odd and even class names here for styling purposes (CSS odd &
                  // even selectors won't work due to the mobile header above)
                  "specs-table-row--odd": !isEven,
                  "specs-table-row--even": isEven,
                })}
              >
                {/* This is the header text again, will be hidden on mobile and visible on desktop */}
                <th scope="row">{this.getMessage(key)}</th>
                {data.map((item, itemIndex) => (
                  <td key={`${key}-${keyIndex} - ${itemIndex}`}>
                    {item && <span>{item[key]}</span>}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}

ProductSpecAccordionBody.defaultProps = {
  withoutTranslation: false,
};

ProductSpecAccordionBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  withoutTranslation: PropTypes.bool.isRequired,
};

export default injectIntl(ProductSpecAccordionBody);
