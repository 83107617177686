import {defineMessages} from "react-intl";

export const APPEARANCE_TEXTILE = 'TEXTILE';
export const APPEARANCE_TEXTILE_MESSAGE = defineMessages({
  [APPEARANCE_TEXTILE]: {
    id: "Appearance.textile",
    description: "Appearance value named Administration",
    defaultMessage: 'textile'
  }
});

export const APPEARANCE_LEATHER = 'LEATHER';
export const APPEARANCE_LEATHER_MESSAGE = defineMessages({
  [APPEARANCE_LEATHER]: {
    id: "Appearance.leather",
    description: "Appearance value named Communication",
    defaultMessage: 'leather'
  }
});

export const APPEARANCES = [
  {...APPEARANCE_TEXTILE_MESSAGE[APPEARANCE_TEXTILE], value: APPEARANCE_TEXTILE},
  {...APPEARANCE_LEATHER_MESSAGE[APPEARANCE_LEATHER], value: APPEARANCE_LEATHER}
];