import React from "react";
import SearchInput
  from "scenes/ProductsPage/components/ProductsList/components/ProductsListActions/components/SearchInput";

const ProductsListActions = (props) => (
    <div className="products-list-actions">
      <div className="row small-gutters regular-gutters-md">
        {/*<div className="col-5 order-md-1">
          <FiltersBarButtonContainer/>
        </div>*/}
        <div className="col-7 col-md-12 col-lg-12 order-md-0">
          <SearchInput/>
        </div>
      </div>
    </div>
);

export default ProductsListActions;