import React, {Component} from "react";
import PropTypes from "prop-types";
import ProductCardImage
  from "scenes/ProductsPage/components/ProductsList/components/ProductCard/components/ProductCardImage";
import ProductCardName
  from "scenes/ProductsPage/components/ProductsList/components/ProductCard/components/ProductCardName";
import "./styles/CompareCard.scss";
import CompareBox from "scenes/ProductsPage/components/ProductsList/components/CompareBox/index";
import {CARD_NAME_IN_COMPARE} from "scenes/ProductsPage/components/ProductsList/components/ProductCard/components/ProductCardName/constants/contexts";

class CompareCard extends Component {
  constructor() {
    super();

    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
  }

  /**
   * Handle close button click to remove the pattern id from query params
   */
  handleCloseButtonClick() {
    const {pattern} = this.props;
    CompareBox.removePattern(pattern.id)
  }

  render() {
    const {pattern} = this.props;

    return (
        <div className="compare-card">
          <button
              className="compare-card__close-button"
              onClick={this.handleCloseButtonClick}>
            <i className="sp-times"/>
          </button>
          <div className="compare-card__image">
            <ProductCardImage
                image={pattern.imageThumbSmall}
                slug={pattern.slug}/>
          </div>
          <div className="compare-card__name">
            <ProductCardName
                name={pattern.name}
                slug={pattern.slug}
                context={CARD_NAME_IN_COMPARE}/>
          </div>
        </div>
    )
  }
}

CompareCard.propTypes = {
  pattern: PropTypes.object.isRequired
};

export default CompareCard;