import React, { Component } from "react";
import ProductsListActions from "scenes/ProductsPage/components/ProductsList/components/ProductsListActions";
import ProductCard from "scenes/ProductsPage/components/ProductsList/components/ProductCard";
import "./styles/ProductsList.scss";
import CompareBox from "scenes/ProductsPage/components/ProductsList/components/CompareBox";
import CompareBar from "scenes/ProductsPage/components/ProductsList/components/CompareBar";
import { Query, compose, graphql } from "react-apollo";
import patternsQuery from "scenes/ProductsPage/components/ProductsList/query";
import {
  DEFAULT_ORDER_BY_VALUE,
  PRODUCT_LIST_PAGE,
} from "scenes/ProductsPage/components/ProductsList/constants";
import { getParsedQueryParams } from "services/browser-history";
import { getQueryParamDataAsArray } from "services/browser-history";
import {
  QUERY_PARAM_AFTER_CURSOR,
  QUERY_PARAM_APPEARANCE,
  QUERY_PARAM_APPLICATION,
  QUERY_PARAM_BACKING_DESCRIPTION,
  QUERY_PARAM_BEFORE_CURSOR,
  QUERY_PARAM_EMBOSSING,
  QUERY_PARAM_FIRST,
  QUERY_PARAM_FLAME_RETARDANCY_TEST,
  QUERY_PARAM_GAUGE_RANGE,
  QUERY_PARAM_LAST,
  QUERY_PARAM_OUTDOOR,
  QUERY_PARAM_POLYMER,
  QUERY_PARAM_SEARCH,
  QUERY_PARAM_SEGMENT,
  QUERY_PARAM_TOPCOAT,
  QUERY_PARAM_WEIGHT_RANGE,
  QUERY_PARAM_WIDTH_RANGE,
} from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
import { withRouter } from "react-router";
import LoadingMessage from "utils/components/LoadingMessage";
import NoResultsMessage from "scenes/ProductsPage/components/ProductsList/components/NoResultsMessage";
import ProductsPagination from "scenes/ProductsPage/components/ProductsList/components/ProductsPagination";
import { Element } from "react-scroll";
import { OUTDOOR_YES } from "scenes/ProductsPage/components/FiltersBar/contants/outdoor";
import { QUERY_PARAM_PATTERN_CLASS, QUERY_PARAM_SUSTAINAILITY_SEARCH } from "../FiltersBar/contants/query_params";
import meQuery from "../../../../utils/queries/MeQuery";

export class ProductsList extends Component {
  /**
   * Returns an Object with the pagination initial state
   * @return {object}
   */
  static resettled_pagination_query_params() {
    return {
      [QUERY_PARAM_BEFORE_CURSOR]: null,
      [QUERY_PARAM_LAST]: null,
      [QUERY_PARAM_AFTER_CURSOR]: null,
      [QUERY_PARAM_FIRST]: PRODUCT_LIST_PAGE,
    };
  }

  render() {
    // WIP: work in progress --->
    const { me } = this.props.meQuery;
    const instanceUser = me?.profile?.geographicInstance?.edges ?? [];
    const isEuropeUser = instanceUser.length === 1 ? instanceUser[0]?.node?.code === "eu" : null;

    const instanceEuropeId = isEuropeUser && instanceUser[0]?.node?.id;
    // <--- WIP: work in progress
    const currentQueryParams = getParsedQueryParams();
    const outdoor_param = currentQueryParams[QUERY_PARAM_OUTDOOR] || null;

    // Sustainability Search params
    const sustainability_param = [getQueryParamDataAsArray(QUERY_PARAM_SUSTAINAILITY_SEARCH).join()][0]

    const convertSustainabilityParamToValidArray = "[" + sustainability_param + "]"
    const sustainabilityDesserialized = JSON.parse(convertSustainabilityParamToValidArray);

    const finalSustainabilityAttrs = {}

    sustainabilityDesserialized.map((property) => {
      Object.assign(finalSustainabilityAttrs, property);
    })


    const queryVariables = {
      // Filters
      orderBy: DEFAULT_ORDER_BY_VALUE,
      instanceIds: instanceEuropeId ? instanceEuropeId : null, // If the user have not europe instance, this param will be null
      search: currentQueryParams[QUERY_PARAM_SEARCH],
      marketSegment: getQueryParamDataAsArray(QUERY_PARAM_SEGMENT).join(),
      patternClassContains: getQueryParamDataAsArray(
        QUERY_PARAM_PATTERN_CLASS
      ).join(),
      appearance: getQueryParamDataAsArray(QUERY_PARAM_APPEARANCE).join(),
      application: getQueryParamDataAsArray(QUERY_PARAM_APPLICATION).join(),
      backingDescription: getQueryParamDataAsArray(
        QUERY_PARAM_BACKING_DESCRIPTION
      ).join(),
      topcoat: getQueryParamDataAsArray(QUERY_PARAM_TOPCOAT).join(),
      polymer: getQueryParamDataAsArray(QUERY_PARAM_POLYMER).join(),
      flameRetardancyTests: getQueryParamDataAsArray(
        QUERY_PARAM_FLAME_RETARDANCY_TEST
      ),
      gaugeRange: currentQueryParams[QUERY_PARAM_GAUGE_RANGE],
      widthRange: currentQueryParams[QUERY_PARAM_WIDTH_RANGE],
      weightRange: currentQueryParams[QUERY_PARAM_WEIGHT_RANGE],
      embossingSelect: getQueryParamDataAsArray(QUERY_PARAM_EMBOSSING).join(),
      outdoor: outdoor_param
        ? Array.isArray(outdoor_param)
          ? null
          : outdoor_param === OUTDOOR_YES
        : null,
      isVisibleLibrary: true,
      ...finalSustainabilityAttrs,

      // Pagination
      afterCursor: currentQueryParams[QUERY_PARAM_FIRST]
        ? currentQueryParams[QUERY_PARAM_AFTER_CURSOR]
        : null,
      first:
        currentQueryParams[QUERY_PARAM_FIRST] ||
        !currentQueryParams[QUERY_PARAM_LAST]
          ? PRODUCT_LIST_PAGE
          : null,
      beforeCursor: currentQueryParams[QUERY_PARAM_LAST]
        ? currentQueryParams[QUERY_PARAM_BEFORE_CURSOR]
        : null,
      last: currentQueryParams[QUERY_PARAM_LAST] ? PRODUCT_LIST_PAGE : null,
      productFirst: 1,
    };

    return (
      <React.Fragment>
        <div className="products-list pb-5">
          <Element name="productListElement">
            <div className="products-list__actions">
              <ProductsListActions />
            </div>
            <div className="products-list__compare-box">
              <CompareBox />
            </div>
            <Query
              query={patternsQuery}
              variables={{
                ...queryVariables,
              }}
            >
              {({ loading, error, data }) => {
                if (loading || error) return <LoadingMessage />;

                const { patterns } = data;

                if (patterns.edges.length === 0) return <NoResultsMessage />;

                return (
                  <React.Fragment>
                    <div className="products-list__cards">
                      <div className="row small-gutters regular-gutters-md">
                        {patterns.edges.map((item) => (
                          <div
                            key={`pattern${item.node.id}`}
                            className="col-6 col-xl-4 mb-small-gutter mb-regular-gutter-md"
                          >
                            <ProductCard
                              key={`Product${item.node.id}`}
                              pattern={item.node}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <ProductsPagination
                      pageInfo={patterns.pageInfo}
                      totalCount={patterns.totalCount}
                    />
                  </React.Fragment>
                );
              }}
            </Query>
          </Element>
        </div>
        <CompareBar />
      </React.Fragment>
    );
  }
}

export default compose(graphql(meQuery, { name: "meQuery" }))(
  withRouter(ProductsList)
);
