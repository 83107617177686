import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import { SPEC_TYPE_GENERAL } from "scenes/ProductDetails/components/ProductSpecs/contants";
import ChangeUnitSystemLink from "scenes/ProductDetails/components/ProductSpecs/components/ChangeUnitSystemLink";
import "./styles/SpecsTableAccordion.scss";

class SpecsTableAccordion extends Component {
  render() {
    const { title, isOpen, type } = this.props;

    return (
      <div className="specs-table-accordion">
        <h3
          className="specs-table-accordion__title"
          onClick={this.props.onClickToggle}
        >
          {title}
          {type === SPEC_TYPE_GENERAL && <ChangeUnitSystemLink />}
          {isOpen ? <i className="sp-minus" /> : <i className="sp-plus" />}
        </h3>
        <Collapse className="specs-table-accordion__collapse" isOpen={isOpen}>
          {this.props.children}
        </Collapse>
      </div>
    );
  }
}

SpecsTableAccordion.defaultProps = {
  type: "",
};

SpecsTableAccordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string,
  onClickToggle: PropTypes.func,
};

export default SpecsTableAccordion;
