import gql from "graphql-tag";

const uidAndTokenCheckQuery = gql`
    query uidAndTokenCheckQuery($uid: String!, $token: String!) {
        uidAndTokenCheck(uid: $uid, token: $token)
    }
`;

export const userByUidQuery = gql`
    query UserByUid($uid: String) {
        user(uid: $uid) {
            id
            email
        }
    }
`;

export default uidAndTokenCheckQuery;