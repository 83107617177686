import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import './styles/ChangeUnitSystemLink.scss';
import ChangeUnitSystemModal
  from "scenes/ProductDetails/components/ProductSpecs/components/ChangeUnitSystemLink/components/ChangeUnitSystemModal";

class ChangeUnitSystemLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };

    this.handleUnitSystemClick = this.handleUnitSystemClick.bind(this);
    this.toggleModalIsOpen = this.toggleModalIsOpen.bind(this)
  }

  /**
   * Handle click on Change unit system label to calls toggleModalIsOpen
   * @param event
   */
  handleUnitSystemClick(event) {
    // This line stop the propagation of that event in order to avoid the calls onClickToggle
    // method of SpecTableAccordion component (Parent of this component)
    event.stopPropagation();
    this.toggleModalIsOpen()
  }

  /**
   * Toggle the modalIsOpen data of the state
   */
  toggleModalIsOpen() {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  render() {
    const {modalIsOpen} = this.state;
    return (
        <React.Fragment>
          <small
              onClick={this.handleUnitSystemClick}
              className="unit-system-link">
            &nbsp;
            <FormattedMessage
                id="MainMenuUserInfo.unitLabel"
                description="Unit label in main menu user section"
                defaultMessage="Change unit system"/>
          </small>
          <ChangeUnitSystemModal
              isOpen={modalIsOpen}
              toggle={this.toggleModalIsOpen}/>
        </React.Fragment>
    )
  }
}

ChangeUnitSystemLink.propTypes = {};

export default ChangeUnitSystemLink