import gql from "graphql-tag";

const tokenAuth = gql`
mutation TokenAuth($input: TokenAuthForLibraryInput!) {
  tokenAuthForLibrary(input: $input) {
    token
  }
}
`;

export default tokenAuth;