import {connect} from "react-redux";
import FiltersBar from "../index";
import {closeFiltersBar} from "scenes/ProductsPage/actions/index";
import {withRouter} from "react-router";

const mapStateToProps = (state) => {
  return {
    productsPageReducer: state.productsPageReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeFiltersBar: () => dispatch(closeFiltersBar())
  };
};

const FiltersBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltersBar);

// This whitRouter HOC must be stay here because the FiltersBar component not re-rendering on change in queryParams
export default withRouter(FiltersBarContainer);