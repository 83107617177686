import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/ProductHeader.scss";
import ReferencesCarousel from "scenes/ProductDetails/components/ProductHeader/components/ReferencesCarousel";
import { FormattedMessage } from "react-intl";

export default function ProductHeader({
  productInfo,
  selectedReference,
  references,
  productCategorization,
}) {
  return (
    <header
      className="product-header"
      style={{
        backgroundImage: `url("${
          selectedReference && selectedReference.imageThumbMedium
        }")`,
      }}
    >
      <div className="product-header__info">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 col-md-6 offset-md-6">
              {productInfo?.patternClass && (
                <div className="d-flex justify-content-end">
                  <div className="text-white d-flex justify-content-center bg-danger px-4 py-2 rounded-top">
                    {productInfo.patternClass.substring(
                      3,
                      productInfo.patternClass.length
                    )}
                    <span
                      className="d-inline-block ml-2"
                      tabIndex="0"
                      data-toggle="tooltip"
                      title="Disabled tooltip"
                    >
                      <i className="fa fa-info-circle" />
                    </span>
                  </div>
                </div>
              )}
              <div className="product-info-box">
                <section
                  className={classNames({
                    "product-title": true,
                    "product-title--has-reference":
                      selectedReference !== undefined,
                  })}
                >
                  <h1 className="product-title__name">
                    {productInfo?.name}
                    {selectedReference && (
                      <small>{selectedReference.color}</small>
                    )}
                  </h1>
                </section>
                <section className="product-highlights">
                  {productInfo?.altName && (
                    <div className="highlights-section">
                      <h3 className="highlights-section__title">
                        <FormattedMessage
                          id="ProductHeader.altName"
                          description="Alt name in product header component"
                          defaultMessage="Commercial name"
                        />
                      </h3>
                      <p className="highlights-section__content">
                        {productInfo?.altName}
                      </p>
                    </div>
                  )}
                  {productCategorization && productCategorization.type && (
                    <div className="highlights-section">
                      <h3 className="highlights-section__title">
                        <FormattedMessage
                          id="ProductHeader.type"
                          description="Type title in product header component"
                          defaultMessage="Type"
                        />
                      </h3>
                      <p className="highlights-section__content">
                        {productCategorization.type}
                      </p>
                    </div>
                  )}
                  {productCategorization &&
                    productCategorization.appearance && (
                      <div className="highlights-section">
                        <h3 className="highlights-section__title">
                          <FormattedMessage
                            id="ProductHeader.appearance"
                            description="Appearance title in product header component"
                            defaultMessage="Appearance"
                          />
                        </h3>
                        <p className="highlights-section__content">
                          {productCategorization.appearance}
                        </p>
                      </div>
                    )}
                  {productCategorization && productCategorization.business && (
                    <div className="highlights-section">
                      <h3 className="highlights-section__title">
                        <FormattedMessage
                          id="ProductHeader.business"
                          description="Business title in product header component"
                          defaultMessage="Business"
                        />
                      </h3>
                      <p className="highlights-section__content">
                        {productCategorization.business}
                      </p>
                    </div>
                  )}
                  {productCategorization?.definition && (
                    <div className="highlights-section">
                      <h3 className="highlights-section__title">
                        <FormattedMessage
                          id="ProductSpecAccordionBody.definition"
                          defaultMessage="Definition"
                        />
                      </h3>
                      <p className="product-info-box__exclusive-label">
                        {productCategorization.definition}
                      </p>
                    </div>
                  )}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-header__other-references">
        <div className="container">
          <ReferencesCarousel references={references} />
        </div>
      </div>
    </header>
  );
}

ProductHeader.propTypes = {
  productInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    altName: PropTypes.string,
    patternClass: PropTypes.string.isRequired,
  }),
  selectedReference: PropTypes.object,
  references: PropTypes.array,
  productCategorization: PropTypes.shape({
    id: PropTypes.string,
    appearance: PropTypes.string,
    business: PropTypes.string,
    exclusive: PropTypes.bool,
    type: PropTypes.string,
    warranty: PropTypes.string,
  }).isRequired,
};
