import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import onClickOutside from "react-onclickoutside";
import "./styles/MainMenuDropdownBox.scss";
import {FormattedMessage} from "react-intl";
import proquinalHistory from "services/browser-history";

export class MainMenuDropdownBox extends Component {
  constructor() {
    super();

    this.handleSubSectionClick = subSection => this._handleSubSectionClick.bind(this, subSection)
  }

  /**
   * Handles click outside event
   * @param {Object} event
   */
  handleClickOutside = event => {
    this.props.onClickOutside();
  };


  /**
   * Handle Menu click to redirect to given pathname
   * @param {Object} subSection
   */
  _handleSubSectionClick(subSection) {
    const {pathname, queryParam} = this.props;
    proquinalHistory.push({
      pathname,
      search: queryParam ? `?${queryParam}=${subSection.value}` : null
    });
    this.props.onClickOutside();
  }

  render() {
    const {isOpen, subSections} = this.props;

    return (
        <ul className={classNames({
          "main-menu-dropdown-box": true,
          "main-menu-dropdown-box--visible": isOpen
        })}>
          {
            subSections.map(subSection => (
                <li
                    key={subSection.id}
                    onClick={this.handleSubSectionClick(subSection)}
                    className="dropdown-links-group text-capitalize">
                  <h5 className="dropdown-links-group__title">
                  </h5>
                  <FormattedMessage
                      {...subSection}/>
                  {/*<ul className="dropdown-links">*/}
                    {/*{*/}
                      {/*[1, 2, 3].map(number => (*/}
                          {/*<li*/}
                              {/*key={number}*/}
                              {/*className="dropdown-link">*/}
                            {/*<a href="">Link here</a>*/}
                          {/*</li>*/}
                      {/*))*/}
                    {/*}*/}
                  {/*</ul>*/}
                </li>
            ))
          }
        </ul>
    )
  }
}

MainMenuDropdownBox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  subSections: PropTypes.arrayOf(PropTypes.object).isRequired,
  pathname: PropTypes.string.isRequired,
  queryParam: PropTypes.string
};

export default onClickOutside(MainMenuDropdownBox)