import React from 'react';
import classNames from "classnames/bind";
import "./styles/HeaderSearchButton.scss";

const HeaderSearchButton = ({headerReducer, openSearchBox, closeSearchBox}) => (
    <button
        type="button"
        className={classNames({
          "header-search-button": true,
          "header-search-button--active": headerReducer.searchBoxIsOpen
        })}
        onClick={headerReducer.searchBoxIsOpen ? closeSearchBox : openSearchBox}>
      <div className="header-search-button__circle">
        <i className="fa fa-search"/>
      </div>
    </button>
);

export default HeaderSearchButton;