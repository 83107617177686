import React, {Component} from "react";
import AuthPageLayout from "scenes/Auth/components/AuthPageLayout";
import {FormattedMessage} from "react-intl";
import ForgotPasswordReduxForm from "scenes/Auth/scenes/ForgotPasswordPage/components/ForgotPasswordForm/index";
import {graphql} from "react-apollo";
import forgotPassword from "scenes/Auth/scenes/ForgotPasswordPage/mutation/index";
import GoBackToLoginLink from "scenes/Auth/scenes/ForgotPasswordPage/components/GoBackToLoginLink";
import {getParsedQueryParams} from "services/browser-history";

export class ForgotPasswordPage extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      emailSent: false,
      errors: []
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Handled the ForgotPasswordForm submit to calls forgotPassword mutation.
   * @param data
   */
  handleFormSubmit(data) {
    this.setState({isLoading: true, errors: []});
    this.props.forgotPassword({
      variables: {
        input: {
          email: data.email,
          url: `${window.location.protocol}//${window.location.host}/auth/reset-password/:uid/:token`
        }
      }
    }).then(response => {
      const {forgotPassword} = response.data;
      this.setState({
        isLoading: false,
        emailSent: forgotPassword.emailSent
      });
    }).catch(err => {
      const {graphQLErrors} = err;
      this.setState({
        errors:  graphQLErrors.map(error => error.message),
        isLoading: false
      })
    })
  }

  render() {
    const {isLoading, emailSent, errors} = this.state;
    return (
        <AuthPageLayout
            heading={
              <FormattedMessage
                  id="ForgotPassword.title"
                  description="Forgot password page's title"
                  defaultMessage="Forgot your password?"/>
            }
            subheading={
              !emailSent &&
              <FormattedMessage
                  id="ForgotPassword.subTitle"
                  description="Forgot password page's subtitle"
                  defaultMessage="Please enter your email to receive instructions on how to reset your password."/>
            }
            form={
              !emailSent ?
                  <ForgotPasswordReduxForm
                      initialValues={{
                        email: getParsedQueryParams()['email'] || ""
                      }}
                      isLoading={isLoading}
                      errors={errors}
                      onSubmit={this.handleFormSubmit}/>
                  :
                  <React.Fragment>
                    <FormattedMessage
                        id="ForgotPassword.emailSent"
                        description="Forgot password page's email sent"
                        defaultMessage="The email has been sent"/>
                    <GoBackToLoginLink/>
                  </React.Fragment>
            }/>
    )
  }
}


export default graphql(forgotPassword, {name: 'forgotPassword'})(ForgotPasswordPage);