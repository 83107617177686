import React from "react"
import { FormattedMessage, defineMessages } from "react-intl";

export const HI_LOFT_RPET_YES = {
  sustainabilityBackingProfile: true
}
export const HI_LOFT_RPET_YES_MESSAGE = defineMessages({
  [HI_LOFT_RPET_YES]: {
    id: "FiltersBar.hiloftRpetFilterTitle",
    description: "Hi loft rpet value named yes",
    defaultMessage: "Hi Loft - RPET"
  }
});

export const BACKING_PROFILE_YES = ""
export const BACKING_PROFILE_MESSAGE = defineMessages({
  [BACKING_PROFILE_YES]: {
    id: "FiltersBar.backingProfileFilterTitle",
    description: "Backing profile value named yes",
    defaultMessage: "Perfil del Backing"
  }
});

export const PROP_65_YES = {
  sustainabilityProp65: true
}
export const PROP_65_MESSAGE = defineMessages({
  [PROP_65_YES]: {
    id: "FiltersBar.prop65FilterTitle",
    description: "Prop 65 value named yes",
    defaultMessage: "Prop 65"
  }
});

export const REACH_YES = {
  sustainabilityReach: true
}
export const REACH_MESSAGE = defineMessages({
  [REACH_YES]: {
    id: "FiltersBar.reachFilterTitle",
    description: "Reach value named yes",
    defaultMessage: "Reach"
  }
});

export const ROHS_YES = {
  sustainabilityRohs: true
}
export const ROHS_MESSAGE = defineMessages({
  [ROHS_YES]: {
    id: "FiltersBar.rohsFilterTitle",
    description: "RoHS value named yes",
    defaultMessage: "RoHS"
  }
});

export const FR_FREE_YES = {
  sustainabilityFrFreeIn: "AB-2998"
}
export const FR_FREE_MESSAGE = defineMessages({
  [FR_FREE_YES]: {
    id: "FiltersBar.frFreeFilterTitle",
    description: "FR Free value named yes",
    defaultMessage: "FR Free"
  }
});

export const MP_FREE_YES = {
  sustainabilityMpFreeIn: "EN 71-3"
}
export const MP_FREE_MESSAGE = defineMessages({
  [MP_FREE_YES]: {
    id: "FiltersBar.mpFreeFilterTitle",
    description: "MP Free value named yes",
    defaultMessage: "No heavy metals EN 71-3"
  }
});

export const CITOTOXICITY_YES = {
  sustainabilityCytotoxicityIn: "ISO 10993-5"
}
export const CITOTOXICITY_MESSAGE = defineMessages({
  [CITOTOXICITY_YES]: {
    id: "FiltersBar.cytotoxicityFilterTitle",
    description: "citotoxicity value named yes",
    defaultMessage: "Biocompatibilidad - Citotoxicidad"
  }
});

export const SKIN_SAFE_SENSITIZATION_YES = {
  sustainabilitySkinSafeSensitizationIn: "ISO 10993-10"
}
export const SKIN_SAFE_SENSITIZATION_MESSAGE = defineMessages({
  [SKIN_SAFE_SENSITIZATION_YES]: {
    id: "FiltersBar.skinSafeSensitizationFilterTitle",
    description: "Skin safe sensitization value named yes",
    defaultMessage: "Protecci�n de la piel - Sensibilizaci�n"
  }
});

export const SKIN_SAFE_IRRITATION_YES = {
  sustainabilitySkinSafeIrritationIn: "ISO 10993-23"
}
export const SKIN_SAFE_IRRITATION_MESSAGE = defineMessages({
  [SKIN_SAFE_IRRITATION_YES]: {
    id: "FiltersBar.skinSafeIrritationFilterTitle",
    description: "Skin safe irritation value named yes",
    defaultMessage: "Protecci�n de la piel - Irritaci�n"
  }
});

export const PHTHALATE_FREE_PRODUCT_YES = {
  sustainabilityPhthalateFreeProductIn: "16 CFR PART 1307,DIRECTIVA 2005/84/CE"
}
export const PHTHALATE_FREE_PRODUCT_MESSAGE = defineMessages({
  [PHTHALATE_FREE_PRODUCT_YES]: {
    id: "FiltersBar.phthalateFreeProductFilterTitle",
    description: "PHTalate value named yes",
    defaultMessage: "Phatalate Free"
  }
});

export const BIOBASED_PVC_BIO_ATTRIBUTED_YES = {
  sustainabilityBiobasedPvcBioAttributed: true
}
export const BIOBASED_PVC_BIO_ATTRIBUTED_MESSAGE = defineMessages({
  [BIOBASED_PVC_BIO_ATTRIBUTED_YES]: {
    id: "FiltersBar.biobasedPvcBioAttributedFilterTitle",
    description: "Biobased PVC value named yes",
    defaultMessage: "Bioattributed PVC"
  }
});

export const GREENGUARD_YES = {
  sustainabilityGreenguard: true
}
export const GREENGUARD_MESSAGE = defineMessages({
  [GREENGUARD_YES]: {
    id: "FiltersBar.greenguardFilterTitle",
    description: "Greenguard value named yes",
    defaultMessage: "Greenguard"
  }
});

export const INDOOR_AIR_QUALITY_YES = {
  sustainabilityIndoorAirQuality: true
}
export const INDOOR_AIR_QUALITY_MESSAGE = defineMessages({
  [INDOOR_AIR_QUALITY_YES]: {
    id: "FiltersBar.indoorAirQualityFilterTitle",
    description: "Indoor Air Quality value named yes",
    defaultMessage: "Indoor Air Quality"
  }
});

export const GLOBAL_RECYCLED_STANDARD_YES = {
  sustainabilityGlobalRecycledStandard: true
}
export const GLOBAL_RECYCLED_STANDARD_MESSAGE = defineMessages({
  [GLOBAL_RECYCLED_STANDARD_YES]: {
    id: "FiltersBar.globalRecycledStandardFilterTitle",
    description: "Global Recycled Standard value named yes",
    defaultMessage: "Global Recycled Standard | Backing"
  }
});

export const APLUS_EMISSIONS_YES = {
  sustainabilityAPlusEmissions: true
}
export const APLUS_EMISSIONS_MESSAGE = defineMessages({
  [APLUS_EMISSIONS_YES]: {
    id: "FiltersBar.aPlusEmissionsFilterTitle",
    description: "A + Emissions value named yes",
    defaultMessage: "A + Emissions"
  }
});

export const ANTIMICROBIAL_YES = {
  sustainabilityAntimicrobialIn: "ASTM E2149"
}
export const ANTIMICROBIAL_MESSAGE = defineMessages({
  [ANTIMICROBIAL_YES]: {
    id: "FiltersBar.antimicrobialFilterTitle",
    description: "Antimicrobial value named yes",
    defaultMessage: "Antimicrobial"
  }
});

export const EPD_YES = {
  sustainabilityEpd: true
}
export const EPD_MESSAGE = defineMessages({
  [EPD_YES]: {
    id: "FiltersBar.epdFilterTitle",
    description: "Epd value named yes",
    defaultMessage: "Epd"
  }
});

export const LEED_YES = {
  sustainabilityLeed: true
}
export const LEED_MESSAGE = defineMessages({
  [LEED_YES]: {
    id: "FiltersBar.leedFilterTitle",
    description: "Leed value named yes",
    defaultMessage: "Leed"
  }
});

export const WELL_YES = {
  sustainabilityWell: true
}
export const WELL_MESSAGE = defineMessages({
  [WELL_YES]: {
    id: "FiltersBar.wellFilterTitle",
    description: "Well value named yes",
    defaultMessage: "Well"
  }
});

const convertObjectToString = (object) => {
  return JSON.stringify(object);
}

export const SUSTAINABILITY = [
  {
    title: <FormattedMessage
      id="FiltersBar.attributesFilterTitle"
      description="Title for Attributes filter section at filters bar"
      defaultMessage="Attributes" />,
    options: [
      { ...BIOBASED_PVC_BIO_ATTRIBUTED_MESSAGE[BIOBASED_PVC_BIO_ATTRIBUTED_YES], value: convertObjectToString(BIOBASED_PVC_BIO_ATTRIBUTED_YES) },
      { ...PHTHALATE_FREE_PRODUCT_MESSAGE[PHTHALATE_FREE_PRODUCT_YES], value: convertObjectToString(PHTHALATE_FREE_PRODUCT_YES) },
      { ...FR_FREE_MESSAGE[FR_FREE_YES], value: convertObjectToString(FR_FREE_YES) },
      { ...MP_FREE_MESSAGE[MP_FREE_YES], value: convertObjectToString(MP_FREE_YES) },
      { ...CITOTOXICITY_MESSAGE[CITOTOXICITY_YES], value: convertObjectToString(CITOTOXICITY_YES) },
      { ...SKIN_SAFE_SENSITIZATION_MESSAGE[SKIN_SAFE_SENSITIZATION_YES], value: convertObjectToString(SKIN_SAFE_SENSITIZATION_YES) },
      { ...SKIN_SAFE_IRRITATION_MESSAGE[SKIN_SAFE_IRRITATION_YES], value: convertObjectToString(SKIN_SAFE_IRRITATION_YES) },
      { ...ANTIMICROBIAL_MESSAGE[ANTIMICROBIAL_YES], value: convertObjectToString(ANTIMICROBIAL_YES) },
    ],
  },
  {
    title: <FormattedMessage
      id="FiltersBar.backingProfileFilterTitle"
      description="Backing profile value named yes"
      defaultMessage="Perfil del Backing" />,
    options: [
      { ...HI_LOFT_RPET_YES_MESSAGE[HI_LOFT_RPET_YES], value: convertObjectToString(HI_LOFT_RPET_YES) }
    ]
  },
  {
    title: <FormattedMessage
      id="FiltersBar.compliancesFilterTitle"
      description="Title for Compilances filter section at filters bar"
      defaultMessage="Compliances" />,
    options: [
      { ...PROP_65_MESSAGE[PROP_65_YES], value: convertObjectToString(PROP_65_YES) },
      { ...REACH_MESSAGE[REACH_YES], value: convertObjectToString(REACH_YES) },
      { ...ROHS_MESSAGE[ROHS_YES], value: convertObjectToString(ROHS_YES) }
    ]
  },
  {
    title: <FormattedMessage
      id="FiltersBar.certificationsFilterTitle"
      description="Title for Certifications filter section at filters bar"
      defaultMessage="Certifications" />,
    options: [
      { ...GREENGUARD_MESSAGE[GREENGUARD_YES], value: convertObjectToString(GREENGUARD_YES) },
      { ...INDOOR_AIR_QUALITY_MESSAGE[INDOOR_AIR_QUALITY_YES], value: convertObjectToString(INDOOR_AIR_QUALITY_YES) },
      { ...GLOBAL_RECYCLED_STANDARD_MESSAGE[GLOBAL_RECYCLED_STANDARD_YES], value: convertObjectToString(GLOBAL_RECYCLED_STANDARD_YES) },
      { ...APLUS_EMISSIONS_MESSAGE[APLUS_EMISSIONS_YES], value: convertObjectToString(APLUS_EMISSIONS_YES) },
      { ...EPD_MESSAGE[EPD_YES], value: convertObjectToString(EPD_YES) }
    ]
  },
  {
    title: <FormattedMessage
      id="FiltersBar.sealsWeContributeFilterTitle"
      description="Title for Seals we contribute to filter section at filters bar"
      defaultMessage="Seals we contribute to" />,
    options: [
      { ...LEED_MESSAGE[LEED_YES], value: convertObjectToString(LEED_YES) },
      { ...WELL_MESSAGE[WELL_YES], value: convertObjectToString(WELL_YES) }
    ]
  }
]