import {connect} from "react-redux";
import Footer from "../index";
import {openTermsModal} from "components/Footer/actions";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openTermsModal: () => dispatch(openTermsModal())
  };
};

const FooterContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);

export default FooterContainer;