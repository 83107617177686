import React from 'react';
import CompareBox from "scenes/ProductsPage/components/ProductsList/components/CompareBox";
import {FormattedMessage} from "react-intl";

const RemoveAllButton = () => (
    <button
        className="btn btn-outline-secondary"
        onClick={CompareBox.removeAllPatterns}>
      <FormattedMessage
          id="RemoveAllButton.buttonLabel"
          description="Compare button label at compare box"
          defaultMessage="Remove all"/>
      &nbsp;
      <i className="sp-times-o"/>
    </button>
);

export default RemoveAllButton;