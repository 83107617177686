import React from "react";
import "./styles/FiltersBarActions.scss";
import {FormattedMessage} from "react-intl";
import FiltersBarButtonContainer from "scenes/ProductsPage/components/FiltersBarButton/container";
import {updateQueryParams} from "services/browser-history";
import {FILTER_QUERY_PARAMS} from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
import {QUERY_PARAM_COMPARE_ITEMS} from "scenes/ProductsPage/components/ProductsList/components/CompareBox/constants";
import CompareBox from "scenes/ProductsPage/components/ProductsList/components/CompareBox";

const FiltersBarActions = (props) => {

  /**
   * Handle Clear filters button click to calls updateQueryParams and clean all filters query params
   */
  const handleClearFiltersButtonClick = () => {
    const currentCompareParams = CompareBox.getCurrentCompareParams();
    let cleanedParams = {
      [QUERY_PARAM_COMPARE_ITEMS]: currentCompareParams
    };

    for (const queryParam in FILTER_QUERY_PARAMS) {
      cleanedParams = {
        ...cleanedParams,
        [queryParam]: []
      };
    }

    updateQueryParams(cleanedParams, true)
  };

  return (
      <div className="filters-bar-actions">
        <div className="row no-gutters">
          <div className="col-6 order-md-1">
            <button
                onClick={handleClearFiltersButtonClick}
                className="reset-filters-button btn btn-outline-secondary">
              <FormattedMessage
                  id="FiltersBarActions.clearFiltersButtonLabel"
                  description="Label for the clear filters button at filters bar"
                  defaultMessage="Clear filters"/>
              &nbsp;
              <i className="sp-times-o"/>
            </button>
          </div>
          <div className="col-6 order-md-0">
            <h5 className="filters-bar-title mb-0">
              <FormattedMessage
                  id="FiltersBarActions.filtersBarTitle"
                  description="Title for the filters bar"
                  defaultMessage="Filter by"/>
            </h5>
            <FiltersBarButtonContainer
                closeMode
                className="pull-right"/>
          </div>
        </div>
      </div>
  )
};

export default FiltersBarActions;