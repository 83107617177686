import {defineMessages} from "react-intl";

export const BUSINESS_USA = 'USA';
export const BUSINESS_USA_MESSAGE = defineMessages({
  [BUSINESS_USA]: {
    id: "Business.usa",
    description: "Business value named USA",
    defaultMessage: 'usa'
  }
});

export const BUSINESS_EUROPE = 'EUROPE';
export const BUSINESS_EUROPE_MESSAGE = defineMessages({
  [BUSINESS_EUROPE]: {
    id: "Business.europe",
    description: "Business value named EUROPE",
    defaultMessage: 'europe'
  }
});

export const BUSINESS_LATAM = 'LATAM';
export const BUSINESS_LATAM_MESSAGE = defineMessages({
  [BUSINESS_LATAM]: {
    id: "Business.latam",
    description: "Business value named LATAM",
    defaultMessage: 'latam'
  }
});

export const BUSINESS_MEXICO = 'MEXICO';
export const BUSINESS_MEXICO_MESSAGE = defineMessages({
  [BUSINESS_MEXICO]: {
    id: "Business.mexico",
    description: "Business value named MEXICO",
    defaultMessage: 'mexico'
  }
});

export const BUSINESS_COLOMBIA = 'COLOMBIA';
export const BUSINESS_COLOMBIA_MESSAGE = defineMessages({
  [BUSINESS_COLOMBIA]: {
    id: "Business.colombia",
    description: "Business value named COLOMBIA",
    defaultMessage: 'colombia'
  }
});

export const BUSINESS_CADENA = 'CADENA';
export const BUSINESS_CADENA_MESSAGE = defineMessages({
  [BUSINESS_CADENA]: {
    id: "Business.cadena",
    description: "Business value named CADENA",
    defaultMessage: 'cadena'
  }
});


export const BUSINESSES = [
  {...BUSINESS_USA_MESSAGE[BUSINESS_USA], value: BUSINESS_USA},
  {...BUSINESS_EUROPE_MESSAGE[BUSINESS_EUROPE], value: BUSINESS_EUROPE},
  {...BUSINESS_LATAM_MESSAGE[BUSINESS_LATAM], value: BUSINESS_LATAM},
  {...BUSINESS_MEXICO_MESSAGE[BUSINESS_MEXICO], value: BUSINESS_MEXICO},
  {...BUSINESS_COLOMBIA_MESSAGE[BUSINESS_COLOMBIA], value: BUSINESS_COLOMBIA},
  {...BUSINESS_CADENA_MESSAGE[BUSINESS_CADENA], value: BUSINESS_CADENA},
];