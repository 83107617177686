import React from "react";
import PropTypes from "prop-types";
import logoPortrait from "components/Header/components/HeaderLogo/img/spradling-logo-portrait.svg";
import "./styles/AuthPageLayout.scss";

const AuthPageLayout = ({heading, subheading, form}) => (
    <div className="auth-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4">
            <div className="auth-page-wrapper text-center">
              <div className="auth-page-content">
                <img
                    className="auth-page-content__logo"
                    src={logoPortrait}
                    alt="Spradling"/>
                <h1 className="auth-page-content__heading">
                  {heading}
                </h1>
                <p className="auth-page-content__subheading">
                  {subheading}
                </p>
                {form}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
);

AuthPageLayout.propTypes = {
  heading: PropTypes.node.isRequired,
  subheading: PropTypes.node.isRequired,
  form: PropTypes.node.isRequired
};

export default AuthPageLayout;