import gql from 'graphql-tag';

const singlePatternProductCategorizationFragment = gql`
    fragment SinglePatternProductCategorizationFragment on ProductCategorizationNode {
        id
        newApplication
        newMarketSegment
        newNiche
        application
        marketSegment
        niche
        polymer
        definition
        warranty
    }
`;

export default singlePatternProductCategorizationFragment;