import gql from "graphql-tag";
import singleUserFragment from "utils/fragments/SingleUserFragment";

const updateUserMutation = gql`
    mutation UpdateUser($input: UpdateUserInput!){
        updateUser(input: $input) {
            user {
                ...UserFragment
            }
        }
    }
  ${singleUserFragment}
`;

export default updateUserMutation;