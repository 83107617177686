import React from 'react';
import "./styles/Header.scss";
import HeaderSearchBoxContainer from "components/Header/components/HeaderSearchBox/container";
import HeaderSearchButtonContainer from "components/Header/components/HeaderSearchButton/container";
import HeaderBurgerButtonContainer from "components/Header/components/HeaderBurgerButton/container";
import HeaderMainMenuContainer from "components/Header/components/HeaderMainMenu/container";
import HeaderLogo from "components/Header/components/HeaderLogo";

const Header = (props) => (
    <header className="proquinal-header fixed-top">
      <section className="proquinal-header__content">
        <div className="container">
          <div className="row no-gutters">
            <div className="proquinal-header-col proquinal-header-col--main-menu col col-lg-10 order-lg-2">
              <HeaderBurgerButtonContainer/>
              <HeaderMainMenuContainer/>
            </div>
            <div className="proquinal-header-col proquinal-header-col--logo col-8 col-lg-2 col-xl-auto order-lg-0">
              <HeaderLogo/>
            </div>
            <div className="proquinal-header-col proquinal-header-col--search-button col order-lg-1">
              <HeaderSearchButtonContainer/>
            </div>
          </div>
        </div>
      </section>
      <HeaderSearchBoxContainer/>
    </header>
);

export default Header;