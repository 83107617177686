import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import {FormattedMessage} from "react-intl";
import "./styles/ProductCard.scss";
import ProductCardImage
  from "scenes/ProductsPage/components/ProductsList/components/ProductCard/components/ProductCardImage";
import ProductCardName
  from "scenes/ProductsPage/components/ProductsList/components/ProductCard/components/ProductCardName";
import CompareBox from "scenes/ProductsPage/components/ProductsList/components/CompareBox";
import {CARD_NAME_IN_LISTING} from "scenes/ProductsPage/components/ProductsList/components/ProductCard/components/ProductCardName/constants/contexts";

const ProductCard = ({pattern}) => {

  const handleCompareButtonClick = () => {
    CompareBox.addPattern(pattern.id)
  };

  const patternIsSelected = CompareBox.getCurrentCompareParams().includes(pattern.id);

  return (
      <div
          className={classNames({
            "product-card": true,
            "product-card--selected": patternIsSelected
          })}>
        <div className="product-card__name">
          <ProductCardName
              name={pattern.name}
              slug={pattern.slug}
              context={CARD_NAME_IN_LISTING}/>
        </div>
        <div className="product-card__image">
          <ProductCardImage
              image={pattern.imageThumbSmall}
              slug={pattern.slug}/>
        </div>
        <div className="product-card__info">
          <div className="row no-gutters">
            <div className="col-6">
              <p>
                {pattern.products.totalCount}&nbsp;
                {
                  pattern.products.totalCount > 1 ?
                      <FormattedMessage
                          id="ProductCard.colors"
                          description="Colors plural word in product card (single product in products list)"
                          defaultMessage="colors"/>
                      :
                      <FormattedMessage
                          id="ProductCard.color"
                          description="Colors singular word in product card (single product in products list)"
                          defaultMessage="color"/>
                }
              </p>
            </div>
            <div className="col-6">
              {
                patternIsSelected ?
                    <div className="text-right">
                      <i className="fa fa-check"/>
                    </div>
                    :
                    <button
                        type="button"
                        onClick={handleCompareButtonClick}>
                      <FormattedMessage
                          id="ProductCard.compareButton"
                          description="Compare button label in product card"
                          defaultMessage="compare"/>
                    </button>
              }
            </div>
          </div>
        </div>
      </div>
  )
};

ProductCard.propTypes = {
  pattern: PropTypes.shape({
    name: PropTypes.string.isRequired,
    products: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired
    }),
  })
};

export default ProductCard;