import React from "react";
import HomeCustomSearch from "scenes/Home/components/HomeCustomSearch";
import HomeMarketSegments from "scenes/Home/components/HomeMarketSegments";
import HomeLatestReleases from "scenes/Home/components/HomeLatestReleases";
import "./styles/Home.scss";

const Home = (props) => (
    <div>
      <section className="home-top-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6">
              <HomeCustomSearch/>
            </div>
            <div className="col-12 col-sm-6">
              <HomeMarketSegments/>
            </div>
          </div>
        </div>
      </section>
      <section className="home-bottom-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6">
              <HomeLatestReleases/>
            </div>
          </div>
        </div>
      </section>
    </div>
);

export default Home;