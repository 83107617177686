import gql from 'graphql-tag';

const singleUserFragment = gql`
    fragment UserFragment on UserNode {
        id
        email
        fullName
        firstName
        unitSystem
    }
`;

export default singleUserFragment;