import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { cleanData } from "scenes/ProductDetails/components/ProductSpecs/components/SpecsTableRow/utils";
import SpecsTableColumn from "scenes/ProductDetails/components/ProductSpecs/components/SpecsTableRow/components/SpecsTableColumn";
import SpecLogo from "utils/components/SpecLogo";
import { TEMPERATURE_REDUCTION_TREATMENT_ASTM_D4803 } from "scenes/ProductDetails/components/ProductSpecs/contants";
import { LOGO_PERMACOOL } from "utils/components/SpecLogo/constants/logos";
import "scenes/ProductDetails/components/ProductSpecs/components/GeneralAccordion/styles/general-accordion.scss";

const GeneralAccordion = ({ data }) => {
  const intl = useIntl();
  const {
    temperatureReductionTreatment,
    embossingSelect,
    topcoatData,
    ...restData
  } = data;

  const topcoatValue = topcoatData ?? null
  const embossing = embossingSelect ? embossingSelect.name : null;
  const finalData = { ...restData, topcoat: topcoatValue, embossing };
  const cleanedData = cleanData(finalData, intl);

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handletemperatureReductionTreatment = () => (
    <>
      <td>
        {intl.formatMessage({
          id: "ProductSpecAccordionBody.temperatureReductionTreatment",
        })}
      </td>
      {isJsonString(data.temperatureReductionTreatment) ? (
        <>
          <td className="d-flex align-items-center">
            <span>
              {JSON.parse(data.temperatureReductionTreatment).value ===
              TEMPERATURE_REDUCTION_TREATMENT_ASTM_D4803 ? (
                <SpecLogo spec={LOGO_PERMACOOL} />
              ) : (
                JSON.parse(data.temperatureReductionTreatment).value
              )}
            </span>
            <a
              className="technical_limitations ml-2"
              href={
                JSON.parse(data.temperatureReductionTreatment)
                  .technical_limitations
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-info-circle" />
            </a>
          </td>
        </>
      ) : (
        <td>{data.temperatureReductionTreatment}</td>
      )}
    </>
  );

  return (
    <>
      {cleanedData.map((array, index) => (
        <tr key={index}>
          {array.map((item, index) => (
            <td key={index}>
              <SpecsTableColumn value={item} />
            </td>
          ))}
        </tr>
      ))}
      {data.temperatureReductionTreatment && (
        <tr>{handletemperatureReductionTreatment()}</tr>
      )}
    </>
  );
};

GeneralAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      technical_limitations: PropTypes.string,
    })
  ).isRequired,
};

export default GeneralAccordion;
