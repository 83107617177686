import React from 'react';
import {FormattedMessage} from "react-intl";

const LoadingMessage = () => (
    <span className="d-block text-center">
      <i className="fa fa-spinner fa-spin"/>&nbsp;
      <FormattedMessage
          id="LoadingMessage.label"
          description="Label to default loading Message"
          defaultMessage="Loading..."/>
    </span>
);

export default LoadingMessage;
