import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { cleanData } from "./utils";
import SpecsTableColumn from "scenes/ProductDetails/components/ProductSpecs/components/SpecsTableRow/components/SpecsTableColumn";

export default function SpecsTableRow({ data, labelOnly }) {
  const intl = useIntl();

  let cleanedData = cleanData(data, intl);

  if (labelOnly) cleanedData = cleanedData.map((row) => [row[0]]); // two-dimensional array without the value [[k], [k]]

  return cleanedData.map((array, index) => (
    <tr key={index}>
      {array.map((item, index) => (
        <td key={index}>
          <SpecsTableColumn value={item} />
        </td>
      ))}
    </tr>
  ));
}

SpecsTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  labelOnly: PropTypes.bool,
};

SpecsTableRow.defaultProps = {
  labelOnly: false,
};
