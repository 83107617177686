import {defineMessages} from "react-intl";

const messages = defineMessages({
  required: {
    id: "Validators.required",
    description: "Text to required validator",
    defaultMessage: "This field is required"
  }
});

export const required = value => value ? undefined : messages.required;