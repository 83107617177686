import React from "react";
import PropTypes from "prop-types";
import { FLAME_RETARDANCY_NO_VALUE } from "scenes/ProductDetails/components/ProductSpecs/contants";
import classNames from "classnames";

const PdfResultRow = ({ pdf, result, isIndividualPattern = true }) => (
  <>
    {pdf === null ? (
      <td>
        <span />
      </td>
    ) : pdf !== FLAME_RETARDANCY_NO_VALUE ? (
      <td
        className={classNames({
          "flame-retardancy--pdf": !isIndividualPattern,
        })}
      >
        <a href={pdf} target="_blank" rel="noopener noreferrer">
          <i className="fa fa-file-pdf-o" />
          <span className="ml-2 text-capitalize">{result}</span>
        </a>
      </td>
    ) : result ? (
      <td>
        <span className="text-capitalize">{result}</span>
      </td>
    ) : (
      !isIndividualPattern && (
        <td>
          <span />
        </td>
      )
    )}
  </>
);

PdfResultRow.propTypes = {
  pdf: PropTypes.string,
  result: PropTypes.string,
  isIndividualPattern: PropTypes.bool,
};

export default PdfResultRow;
