import {defineMessages} from "react-intl";

export const MARKET_SEGMENT_ADMINISTRATION = 'ADMINISTRATION';
export const MARKET_SEGMENT_ADMINISTRATION_MESSAGE = defineMessages({
  [MARKET_SEGMENT_ADMINISTRATION]: {
    id: "MarketSegment.administration",
    description: "Market segment value named Administration",
    defaultMessage: 'administration'
  }
});

export const MARKET_SEGMENT_COMMUNICATION = 'COMMUNICATION';
export const MARKET_SEGMENT_COMMUNICATION_MESSAGE = defineMessages({
  [MARKET_SEGMENT_COMMUNICATION]: {
    id: "MarketSegment.communication",
    description: "Market segment value named Communication",
    defaultMessage: 'communication'
  }
});

export const MARKET_SEGMENT_CONSTRUCTION_AND_AGRICULTURE = 'CONSTRUCTION AND AGRICULTURE';
export const MARKET_SEGMENT_CONSTRUCTION_AND_AGRICULTURE_MESSAGE = defineMessages({
  [MARKET_SEGMENT_CONSTRUCTION_AND_AGRICULTURE]: {
    id: "MarketSegment.constructionAndAgriculture",
    description: "Market segment value named Construction and agriculture",
    defaultMessage: 'construction and agriculture'
  }
});

export const MARKET_SEGMENT_CONTRACT_AND_RESIDENTIAL = 'CONTRACT AND RESIDENTIAL';
export const MARKET_SEGMENT_CONTRACT_AND_RESIDENTIAL_MESSAGE = defineMessages({
  [MARKET_SEGMENT_CONTRACT_AND_RESIDENTIAL]: {
    id: "MarketSegment.contractAndResidential",
    description: "Market segment value named Contract and residential",
    defaultMessage: 'contract and residential'
  }
});

export const MARKET_SEGMENT_FOOTWEAR_AND_LEATHER_GOODS = 'FOOTWEAR AND LEATHER GOODS';
export const MARKET_SEGMENT_FOOTWEAR_AND_LEATHER_GOODS_MESSAGE = defineMessages({
  [MARKET_SEGMENT_FOOTWEAR_AND_LEATHER_GOODS]: {
    id: "MarketSegment.FootwearAndLeathersGoods",
    description: "Market segment value named Footwear and leather goods",
    defaultMessage: 'Footwear and leather goods'
  }
});

export const MARKET_SEGMENT_MARINE = 'MARINE';
export const MARKET_SEGMENT_MARINE_MESSAGE = defineMessages({
  [MARKET_SEGMENT_MARINE]: {
    id: "MarketSegment.marine",
    description: "Market segment value named Marine",
    defaultMessage: 'marine'
  }
});

export const MARKET_SEGMENT_PROTECTION_AND_APPAREL = 'PROTECTION AND APPAREL';
export const MARKET_SEGMENT_PROTECTION_AND_APPAREL_MESSAGE = defineMessages({
  [MARKET_SEGMENT_PROTECTION_AND_APPAREL]: {
    id: "MarketSegment.protectionAndApparel",
    description: "Market segment value named Protection and apparel",
    defaultMessage: 'protection and apparel'
  }
});

export const MARKET_SEGMENT_TRANSPORT = 'TRANSPORT';
export const MARKET_SEGMENT_TRANSPORT_MESSAGE = defineMessages({
  [MARKET_SEGMENT_TRANSPORT]: {
    id: "MarketSegment.transport",
    description: "Market segment value named Transport",
    defaultMessage: 'transport'
  }
});

export const MARKET_SEGMENT_WRAPPING_AND_PACKAGING = 'WRAPPING AND PACKAGING';
export const MARKET_SEGMENT_WRAPPING_AND_PACKAGING_MESSAGE = defineMessages({
  [MARKET_SEGMENT_WRAPPING_AND_PACKAGING]: {
    id: "MarketSegment.wrappingAndPackaging",
    description: "Market segment value named Wrapping and packaging",
    defaultMessage: 'wrapping and packaging'
  }
});

// IMPORTANT: Some market segments here are temporarily commented due to a client request.
// Please keep them commented and DON'T DELETE ANY OF THEM. Will be enabled soon.
export const MARKET_SEGMENTS = [
  // {...MARKET_SEGMENT_ADMINISTRATION_MESSAGE[MARKET_SEGMENT_ADMINISTRATION], value: MARKET_SEGMENT_ADMINISTRATION},
  // {...MARKET_SEGMENT_COMMUNICATION_MESSAGE[MARKET_SEGMENT_COMMUNICATION], value: MARKET_SEGMENT_COMMUNICATION},
  // {...MARKET_SEGMENT_CONSTRUCTION_AND_AGRICULTURE_MESSAGE[MARKET_SEGMENT_CONSTRUCTION_AND_AGRICULTURE], value: MARKET_SEGMENT_CONSTRUCTION_AND_AGRICULTURE},
  {...MARKET_SEGMENT_CONTRACT_AND_RESIDENTIAL_MESSAGE[MARKET_SEGMENT_CONTRACT_AND_RESIDENTIAL], value: MARKET_SEGMENT_CONTRACT_AND_RESIDENTIAL},
  {...MARKET_SEGMENT_FOOTWEAR_AND_LEATHER_GOODS_MESSAGE[MARKET_SEGMENT_FOOTWEAR_AND_LEATHER_GOODS], value: MARKET_SEGMENT_FOOTWEAR_AND_LEATHER_GOODS},
  {...MARKET_SEGMENT_MARINE_MESSAGE[MARKET_SEGMENT_MARINE], value: MARKET_SEGMENT_MARINE},
  {...MARKET_SEGMENT_PROTECTION_AND_APPAREL_MESSAGE[MARKET_SEGMENT_PROTECTION_AND_APPAREL], value: MARKET_SEGMENT_PROTECTION_AND_APPAREL},
  {...MARKET_SEGMENT_TRANSPORT_MESSAGE[MARKET_SEGMENT_TRANSPORT], value: MARKET_SEGMENT_TRANSPORT},
  // {...MARKET_SEGMENT_WRAPPING_AND_PACKAGING_MESSAGE[MARKET_SEGMENT_WRAPPING_AND_PACKAGING], value: MARKET_SEGMENT_WRAPPING_AND_PACKAGING},
];
