import React from "react";
import PropTypes from "prop-types";
import permablokLogo from "utils/components/SpecLogo/images/permablok.svg";
import permablokPlusLogo from "utils/components/SpecLogo/images/permablok-plus.svg";
import permaguardLogo from "utils/components/SpecLogo/images/permaguard.svg";
import silveguardLogo from "utils/components/SpecLogo/images/silverguard.svg";
import pbgLogo from "utils/components/SpecLogo/images/pbg.svg";
import hiLoftLogo from "utils/components/SpecLogo/images/hi-loft.svg";
import hiLoftFlexLogo from "utils/components/SpecLogo/images/hi-loft-flex.svg";
import permacoolLogo from "utils/components/SpecLogo/images/permacool.svg";
import {
  LOGO_HI_LOFT,
  LOGO_HI_LOFT_FLEX,
  LOGO_PBG,
  LOGO_PERMABLOK,
  LOGO_PERMABLOK_3_PLUS,
  LOGO_PERMAGUARD,
  LOGO_SILVERGUARD,
  LOGO_PERMACOOL
} from "utils/components/SpecLogo/constants/logos";

const SpecLogo = ({spec}) => {
  switch (spec) {
    case LOGO_PERMABLOK:
      return <img className="img-fluid" src={permablokLogo} alt={LOGO_PERMABLOK}/>;

    case LOGO_PERMABLOK_3_PLUS:
      return <img className="img-fluid" src={permablokPlusLogo} alt={LOGO_PERMABLOK_3_PLUS}/>;

    case LOGO_PERMAGUARD:
      return <img className="img-fluid" src={permaguardLogo} alt={LOGO_PERMAGUARD}/>;

    case LOGO_SILVERGUARD:
      return <img className="img-fluid" src={silveguardLogo} alt={LOGO_SILVERGUARD}/>;

    case LOGO_PBG:
      return <img className="img-fluid" src={pbgLogo} alt={LOGO_PBG}/>;

    case LOGO_HI_LOFT:
      return <img className="img-fluid" src={hiLoftLogo} alt={LOGO_HI_LOFT}/>;

    case LOGO_HI_LOFT_FLEX:
      return <img className="img-fluid" src={hiLoftFlexLogo} alt={LOGO_HI_LOFT_FLEX}/>;

    case LOGO_PERMACOOL:
      return <img className="img-fluid h-30" src={permacoolLogo} alt={LOGO_PERMACOOL}/>;

    default: 
      return spec
  }
};

SpecLogo.propTypes = {
  spec: PropTypes.string.isRequired
};

export default SpecLogo;
