import React, {Component} from "react";
import classNames from "classnames/bind";
import "./styles/MainMenuLocales.scss";

class MainMenuLocales extends Component {
  constructor(props) {
    super(props);

    this.handleChangeLanguage = lang => this._handleChangeLanguage.bind(this, lang);
  }

  /**
   * Handling change language to dispatch changeLanguage action of localeReducer
   *
   * @param lang
   * @private
   */
  _handleChangeLanguage(lang) {
    this.props.changeLanguage(lang)
  }

  render() {
    const {language} = this.props.localesReducer;

    return (
        <div className="main-menu-locales">
          <div className="row no-gutters justify-content-center">
            <div className="col-auto col-lg-12">
              <button
                  onClick={this.handleChangeLanguage('es')}
                  type="button"
                  className={classNames({
                    "main-menu-locale-button main-menu-locale-button--es": true,
                    "main-menu-locale-button--active": language === 'es'
                  })}>
                ESP
              </button>
            </div>
            <div className="col-auto col-lg-12">
              <button
                  onClick={this.handleChangeLanguage('en')}
                  type="button"
                  className={classNames({
                    "main-menu-locale-button main-menu-locale-button--en": true,
                    "main-menu-locale-button--active": language === 'en'
                  })}>
                ENG
              </button>
            </div>
          </div>
        </div>
    )
  }

}

export default MainMenuLocales;