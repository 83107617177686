import {connect} from "react-redux";
import FiltersBarButton from "../index";
import {closeFiltersBar, openFiltersBar} from "scenes/ProductsPage/actions/index";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openFiltersBar: () => dispatch(openFiltersBar()),
    closeFiltersBar: () => dispatch(closeFiltersBar())
  };
};

const FiltersBarButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltersBarButton);

export default FiltersBarButtonContainer;