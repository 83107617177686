import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

const DownloadPDFButton = ({loading, onClick}) => (
    <button
        onClick={onClick}
        className="btn btn-outline-secondary btn-lg btn-block">
      {
        loading ?
            <React.Fragment>
              <FormattedMessage
                  id="DownloadPDFButton.loading"
                  description="Product pyramid downloading pdf button label"
                  defaultMessage="Downloading PDF"/>
              &nbsp;
              <i className="fa fa-spinner fa-spin"/>
            </React.Fragment>
            :
            <React.Fragment>
              <FormattedMessage
                  id="DownloadPDFButton.label"
                  description="Product pyramid download pdf button label"
                  defaultMessage="Download PDF"/>
              &nbsp;
              <i className="fa fa-file-pdf-o"/>
            </React.Fragment>
      }
    </button>
);

DownloadPDFButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default DownloadPDFButton