import React from "react";
import "./styles/MainMenuSections.scss";
import MainMenuDropdown
  from "components/Header/components/HeaderMainMenu/components/MainMenuSections/components/MainMenuDropdown";
import {FormattedMessage, injectIntl, defineMessages} from "react-intl";
import {MARKET_SEGMENTS} from "scenes/ProductsPage/components/FiltersBar/contants/market_segments";
import {
  // QUERY_PARAM_BUSINESS,
  QUERY_PARAM_SEGMENT
} from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
// import {BUSINESSES} from "scenes/ProductsPage/components/FiltersBar/contants/business";
import {Link} from "react-router-dom";

const MainMenuSections = ({intl}) => {

  const menuList = [
    {
      title: intl.formatMessage(
          defineMessages({
            markets: {
              id: "MainMenuSection.markets",
              description: "Header main menu named Markets",
              defaultMessage: "Markets"
            }
          }).markets
      ),
      subSections: MARKET_SEGMENTS,
      pathname: '/products',
      queryParam: QUERY_PARAM_SEGMENT
    },
    // {
    //   title: intl.formatMessage({
    //     id: "MainMenuSection.productPyramid",
    //     description: "Header main menu named Product Pyramid",
    //     defaultMessage: "Product Pyramid"
    //   }),
    //   subSections: BUSINESSES,
    //   pathname: '/product-pyramid',
    //   queryParam: QUERY_PARAM_BUSINESS
    // },
    // {
    //   title: intl.formatMessage({
    //     id: "MainMenuSection.rollers",
    //     description: "Header main menu named Rollers",
    //     defaultMessage: "Rollers"
    //   }),
    //   subSections: [],
    //   pathname: '/',
    //   queryParam: null
    // }
  ];

  return (
      <ul className="main-menu-sections">
        {/* IMPORTANT: This is a temporal hard-coded link for the header not to look "empty",
        remove this as soon as the other links gets enabled*/}
        <li className="main-menu-dropdown">
          <Link to="/products" className="main-menu-dropdown__button">
            <FormattedMessage
                id="MainMenuSections.hardCodedLink"
                description="Products Library hard-coded link in the header nav buttons"
                defaultMessage="Product Library"/>
          </Link>
        </li>

        {
          menuList.map((menu, index) => (
              <MainMenuDropdown
                  menu={menu}
                  key={`menu-${index}`}/>
          ))
        }
      </ul>
  )
};

export default injectIntl(MainMenuSections);