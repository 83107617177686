import React, {Component} from "react";
import AuthPageLayout from "scenes/Auth/components/AuthPageLayout";
import {FormattedMessage} from "react-intl";
import ResetPasswordReduxForm from "scenes/Auth/scenes/ResetPasswordPage/components/ResetPasswordForm/index";
import {compose, graphql} from "react-apollo";
import uidAndTokenCheckQuery, {userByUidQuery} from "scenes/Auth/scenes/ResetPasswordPage/query/index";
import resetPassword from "scenes/Auth/scenes/ResetPasswordPage/mutation/index";
import {Redirect} from "react-router-dom";
import {apolloClient} from "apollo/client";
import proquinalHistory from "services/browser-history";
import queryString from "query-string";


export class ResetPasswordPage extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      validationErrors: [],
      passwordChanged: false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleGetNewLinkClick = this.handleGetNewLinkClick.bind(this);
  }

  /**
   * Handled the LoginForm submit to calls tokenAuth mutation.
   * In the mutation response, calls authLogin function
   * @param data
   */
  handleFormSubmit(data) {
    const {match} = this.props;

    if (data.password === data.passwordConfirmation) {
      this.setState({isLoading: true});
      const input = {
        uid: match.params.uid,
        token: match.params.token,
        newPassword: data.password
      };

      this.props.resetPassword({
        variables: {
          input
        }
      }).then(response => {
        const {resetPassword} = response.data;
        this.setState({
          isLoading: false,
          validationErrors: !resetPassword.passwordChanged ? resetPassword.validationErrors : null,
          passwordChanged: resetPassword.passwordChanged
        });
      });
    }
  }

  /**
   *
   */
  handleGetNewLinkClick() {
    const {match} = this.props;
    apolloClient.query({
      query: userByUidQuery,
      variables: {
        uid: match.params.uid
      },
      fetchPolicy: "network-only"
    }).then(({data}) => {
      const {user} = data;
      proquinalHistory.push({
        pathname: "/auth/forgot-password",
        search: `?${queryString.stringify({email: user.email})}`
      })
    }).catch(err => {
      proquinalHistory.push({
        pathname: "/auth/forgot-password"
      })
    })
  }

  render() {
    const {isLoading, validationErrors, passwordChanged} = this.state;
    const {uidAndTokenCheckQuery} = this.props;
    const error = uidAndTokenCheckQuery.error;

    if (uidAndTokenCheckQuery && uidAndTokenCheckQuery.loading)
      return <div>Loading</div>;

    if (passwordChanged)
      return <Redirect to={'/auth/login'}/>;

    return (
        <AuthPageLayout
            heading={
              <FormattedMessage
                  id="ResetPasswordPage.title"
                  description="Reset password page's title"
                  defaultMessage="Reset password"/>
            }
            subheading={
              !error &&
              <FormattedMessage
                  id="ResetPassword.subTitle"
                  description="Reset password page's subtitle"
                  defaultMessage="Please input your new password twice"/>
            }
            form={
              !error ?
                  <ResetPasswordReduxForm
                      isLoading={isLoading}
                      validationErrors={validationErrors}
                      onSubmit={this.handleFormSubmit}/>
                  :
                  <div>
                    <FormattedMessage
                        id="ResetPassword.linkUsed"
                        description="Reset password page's link used copy"
                        defaultMessage="This link has expired"/>
                    <p className="form-text text-muted">
                      <a
                          href="#/"
                          onClick={this.handleGetNewLinkClick}>
                        <FormattedMessage
                            id="LoginForm.generateNewLink"
                            description="Reset password link in Reset password page when the link has expired"
                            defaultMessage="Click here to get a new link"/>
                      </a>
                    </p>
                  </div>
            }/>
    )
  }
}

export default compose(
    graphql(resetPassword, {name: 'resetPassword'}),
    graphql(
        uidAndTokenCheckQuery,
        {
          name: 'uidAndTokenCheckQuery',
          options: props => ({
            variables: {
              token: props.match.params.token,
              uid: props.match.params.uid
            }
          })
        })
)(ResetPasswordPage);