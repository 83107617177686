import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FiltersBox from "scenes/ProductsPage/components/FiltersBar/components/FiltersBox";
import {Range} from "rc-slider";
import {updateQueryParams} from "services/browser-history";
import {getParsedQueryParams} from "services/browser-history";
import ProductList from "scenes/ProductsPage/components/ProductsList";
import "./styles/RangeFilter.scss";
import {withRouter} from "react-router";
import LoadingMessage from "utils/components/LoadingMessage";

class RangeFilter extends Component {
  constructor(props) {
    super(props);

    const {minValue, maxValue, queryParam} = props;
    const queryParamData = getParsedQueryParams()[queryParam];
    this.state = {
      rangeValue: queryParamData ? queryParamData.split(',').map(item => parseFloat(item)) : [minValue, maxValue],
      step: 0
    };

    this.handleAfterChange = this.handleAfterChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
  }

  componentDidMount() {
    const {minValue, maxValue} = this.props;
    let step = 1;
    if (maxValue - minValue < 1)
      step = 0.01;
    else if (maxValue - minValue < 5)
      step = 0.1;
    this.setState({step: step})
  }

  componentDidUpdate(prevProps) {
    const {queryParam, location, minValue, maxValue} = this.props;

    if (prevProps.location.search !== location.search) {
      const queryParamData = getParsedQueryParams()[queryParam];
      if (!queryParamData) {
        this.setState({
          rangeValue: [minValue, maxValue]
        })
      }
    }

  }

  /**
   * Handle the after change event of Range slider to get data from state and update the query params data
   */
  handleAfterChange() {
    const {queryParam} = this.props;
    const {rangeValue} = this.state;

    let nextParams = getParsedQueryParams()[queryParam];
    if (nextParams !== rangeValue.join(',')) {
      nextParams = rangeValue.join(',')
    }

    updateQueryParams({
      [queryParam]: nextParams,
      ...ProductList.resettled_pagination_query_params(),
    })
  }

  /**
   * Handle Range slider change to set the new values
   * @param rangeValue
   */
  handleSliderChange(rangeValue) {
    this.setState({
      rangeValue,
    });
  }


  render() {
    const {title, minValue, maxValue} = this.props;
    const {rangeValue, step} = this.state;

    return (
        <FiltersBox
            title={title}>
          <div className="range-filter">
            <div className="range-filter__limits">
              <div className="row no-gutters">
                <div className="col-6">
                  <h6>
                    {rangeValue[0]}
                  </h6>
                </div>
                <div className="col-6 text-right">
                  <h6>
                    {rangeValue[1]}
                  </h6>
                </div>
              </div>
            </div>
            <div className="range-filter__slider">
              {
                step !== 0 ?
                    <Range
                        min={minValue}
                        max={maxValue}
                        step={step}
                        value={rangeValue}
                        onChange={this.handleSliderChange}
                        onAfterChange={this.handleAfterChange}/>
                    :
                    <LoadingMessage/>
              }
            </div>
          </div>
        </FiltersBox>
    )
  }
}

RangeFilter.propTypes = {
  title: PropTypes.node.isRequired,
  queryParam: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired
};

export default withRouter(RangeFilter);