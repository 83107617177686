import gql from "graphql-tag";

const forgotPassword = gql`
mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    emailSent
  }
}
`;

export default forgotPassword;