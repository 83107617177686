import React, {Component} from 'react';
import './styles/ProductPyramid.scss';
import classNames from 'classnames';
import {FormattedMessage} from "react-intl";
import {BUSINESSES} from "scenes/ProductsPage/components/FiltersBar/contants/business";
import DownloadPDFButton from "utils/components/DownloadPDFButton";
import {getParsedQueryParams, updateQueryParams} from "services/browser-history";
import {QUERY_PARAM_BUSINESS} from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
import {BUSINESS_USA} from "scenes/ProductsPage/components/FiltersBar/contants/business";
import {BUSINESS_LATAM} from "scenes/ProductsPage/components/FiltersBar/contants/business";

class ProductPyramid extends Component {
  constructor(props) {
    super(props);

    this.handleDownloadPDFButtonClick = this.handleDownloadPDFButtonClick.bind(this);
    this.handleBusinessClick = business => this._handleBusinessClick.bind(this, business);
  }

  /**
   * Handle DownloadPDFButton button click
   */
  handleDownloadPDFButtonClick() {

  }

  /**
   * Handle business click on Sidebar to update query params with the new business
   * @param business
   * @private
   */
  _handleBusinessClick(business) {
    updateQueryParams({
      [QUERY_PARAM_BUSINESS]: business
    })
  }

  render() {
    const currentBusiness = getParsedQueryParams()[QUERY_PARAM_BUSINESS];

    return (
        <div className="product-pyramid">
          <div className="container">
            <div className="row no-gutters regular-gutters-md">
              <div className="col-3">
                <div className="product-pyramid-sidebar">
                  <FormattedMessage
                      id="ProductPyramid.sidebarTitle"
                      description="Product Pyramid sidebar title"
                      defaultMessage="Search by Business"/>
                  <hr/>
                  <div className="product-pyramid-sidebar__businesses">
                    <ul>
                      {
                        BUSINESSES.map((business, index) => (
                            <li
                                key={`business${index}`}
                                onClick={this.handleBusinessClick(business.value)}
                                className={classNames({
                                  "text-capitalize": true,
                                  "product-pyramid-sidebar__business": true,
                                  "product-pyramid-sidebar__business--active": currentBusiness === business.value,
                                })}>
                              <FormattedMessage
                                  {...business}/>
                            </li>
                        ))
                      }
                    </ul>
                  </div>
                  <DownloadPDFButton
                      loading={false}
                      onClick={this.handleDownloadPDFButtonClick}/>
                </div>
              </div>
              <div className="col-9">
                <div className="product-pyramid-graphic">
                  <h2 className="text-capitalize">
                    <FormattedMessage
                        id="ProductPyramid.graphicTitle"
                        description="Product Pyramid graphic title"
                        defaultMessage="Spradling {business} product pyramid"
                        values={{
                          business: [BUSINESS_USA, BUSINESS_LATAM].includes(currentBusiness)
                              ? currentBusiness : currentBusiness.toLowerCase()
                        }}/>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

ProductPyramid.propTypes = {};

export default ProductPyramid