import React from 'react';
import {FormattedMessage} from "react-intl";
import {
  KEY_TABLE_GENERAL,
  KEY_TABLE_OTHER_ATTRIBUTES,
  RULE_MED_VALUE_TRANSLATION,
  TEMPERATURE_REDUCTION_TREATMENT_ASTM_D4803
} from "scenes/ProductDetails/components/ProductSpecs/contants";
import SpecLogo from "utils/components/SpecLogo";
import {LOGO_PERMACOOL} from "utils/components/SpecLogo/constants/logos";

export function processDataTable(data, tableLookuped) {
  let tableData = data;

  switch (tableLookuped) {
    case KEY_TABLE_GENERAL:
      handleTemperatureReductionTreatment(tableData)
      break;

    case KEY_TABLE_OTHER_ATTRIBUTES:
      handleruleMed(data) 
      handleTemperatureReductionTreatment(tableData)
      break;

    default:
  }

  return tableData
}

function handleruleMed(data) {
  if (data) {
    if (data.ruleMed) {
      return data.ruleMed = <FormattedMessage {...RULE_MED_VALUE_TRANSLATION} />;
    } else {
      return data.ruleMed = null
    }

  } 
}

function handleTemperatureReductionTreatment(data) {
  if (data && data.temperatureReductionTreatment === TEMPERATURE_REDUCTION_TREATMENT_ASTM_D4803)
    return  data.temperatureReductionTreatment = <SpecLogo spec={LOGO_PERMACOOL}/>;
}