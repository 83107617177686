import { defineMessages } from "react-intl";

export const SPEC_TYPE_GENERAL = "SPEC_TYPE_GENERAL";
export const SPEC_TYPE_BACKING = "SPEC_TYPE_BACKING";
export const SPEC_TYPE_OTHER_ATTRIBUTES = "SPEC_TYPE_OTHER_ATTRIBUTES";
export const SPEC_TYPE_PRODUCT_CATEGORIZATION =
  "SPEC_TYPE_PRODUCT_CATEGORIZATION";
export const SPEC_TYPE_FLAME_RETARDANCY = "SPEC_TYPE_FLAME_RETARDANCY";
export const SPEC_TYPE_ADDITIONAL_TESTS = "SPEC_TYPE_ADDITIONAL_TESTS";

export const KEY_TABLE_GENERAL = "general";
export const KEY_TABLE_OTHER_ATTRIBUTES = "otherattributes";

export const RULE_MED_VALUE_TRANSLATION = defineMessages({
  id: "OtherAttribute.ruleMed",
  description:
    "value 'ruleMed' in Other Attributes table at product specs section",
  defaultMessage: "DIRECTIVE 96/98/EC Module B and D",
});

// WARNING: constan value for manage UI of temperatureReductionTreatment
export const TEMPERATURE_REDUCTION_TREATMENT_ASTM_D4803 = "ASTM D4803";

export const FLAME_RETARDANCY_NO_VALUE = "NO_PDF";
