import React from 'react';
import {FormattedMessage} from "react-intl";
import proquinalHistory from "services/browser-history";
import {withRouter} from "react-router";

const CompareButton = ({location}) => {

  /**
   * Handle compare button click to redirect to Compare page with current queryParams
   * TODO: Maybe this must be change when the filters are be developed to send only the needed params
   */
  const handleCompareButtonClick = () => {
    proquinalHistory.push({
      pathname: '/products/compare',
      search: location.search
    })
  };

  return (
      <button
          className="btn btn-primary"
          onClick={handleCompareButtonClick}>
        <FormattedMessage
            id="CompareButton.label"
            description="Compare button label "
            defaultMessage="Compare"/>
      </button>
  )
};

export default withRouter(CompareButton);