import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps(state) {
  return {
    /**
     * This key value is to force react to create a new instance an re-rendering everything
     * @see https://github.com/yahoo/react-intl/issues/243#issuecomment-166030664
     */
    key: state.localesReducer.language,

    locale: state.localesReducer.language,
    messages: state.localesReducer.messages[state.localesReducer.language]
  };
}

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const ConnectedIntlProvider = connect(
    mapStateToProps,
    mapDispatchToProps
)(IntlProvider);

export default ConnectedIntlProvider;