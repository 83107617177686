import React from "react";
import { FormattedMessage } from "react-intl";
import logoLandscape from "components/Header/components/HeaderLogo/img/spradling-logo-landscape.svg";
import "./styles/Footer.scss";
import { Link } from "react-router-dom";
import TermsModalContainer from "components/Footer/components/TermsModal/container";

const Footer = ({ openTermsModal }) => (
  <React.Fragment>
    <footer className="proquinal-footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <h5 className="footer-section-title text-uppercase">
              Spradling Group
            </h5>
            <p className="footer-spradling-links text-uppercase">
              <a
                href="https://www.proquinal.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Proquinal
              </a>
              <a
                href="http://www.spradling.eu/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Spradling&reg;&nbsp;
                <FormattedMessage
                  id="Footer.spradlingLinksEurope"
                  description="Europe name in footer links section"
                  defaultMessage="Europe"
                />
              </a>
              <a
                href="https://spradlingvinyl.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Spradling&reg;&nbsp;
                <FormattedMessage
                  id="Footer.spradlingLinksUSA"
                  description="USA name in footer links section"
                  defaultMessage="USA"
                />
              </a>
              <a
                href="https://www.spradling.group/mx/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Spradling&reg;&nbsp;
                <FormattedMessage
                  id="Footer.spradlingLinksMexico"
                  description="Mexico name in footer links section"
                  defaultMessage="Mexico"
                />
              </a>
            </p>
          </div>
          <div className="col-12 col-md-4">
            <p className="footer-links">
              <Link to="/help/faq">
                <FormattedMessage
                  id="Footer.linkFAQ"
                  description="One of the links at footer"
                  defaultMessage="FAQ"
                />
              </Link>
              <button className="btn btn-link" onClick={openTermsModal}>
                <FormattedMessage
                  id="Footer.linkTerms"
                  description="One of the links at footer"
                  defaultMessage="Terms and Conditions"
                />
              </button>
            </p>
          </div>
          <div className="col-12 col-md-4">
            <h5 className="footer-section-title text-uppercase">
              <FormattedMessage
                id="Footer.socialMediaTitle"
                description="Social media links title in footer"
                defaultMessage="Social Networks"
              />
            </h5>
            <p className="footer-social-links">
              <a
                href="https://www.facebook.com/Spradlingroup/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fa fa-facebook" /> Facebook
              </a>
              <a
                href="https://www.instagram.com/spradlinggroup/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fa fa-instagram" /> Instagram
              </a>
              <a
                href="https://www.youtube.com/channel/UCMIzYYZezlbyux8TmZvuQ0A"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fa fa-youtube" /> YouTube
              </a>
              <a
                href="https://www.linkedin.com/company/spradling-group/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fa fa-linkedin" /> LinkedIn
              </a>
            </p>
          </div>
          <div className="col-12">
            <div className="footer-copyright-info">
              <img src={logoLandscape} alt="Spradling" />
              <p className="text-uppercase">
                &copy;{new Date().getFullYear()} Spradling&reg; Group
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <TermsModalContainer />
  </React.Fragment>
);

export default Footer;
