import React, { useContext } from "react";
import SpecsTableAccordion from "utils/components/SpecsTableAccordion";
import PatternContext from "scenes/ProductDetails/components/ProductSpecs/contexts/PatternContext";

export default function AdditionalTestAccordion({
  title,
  isOpen,
  onClickToggle,
}) {
  const pattern = useContext(PatternContext);
  const tests = pattern.specsSheetData.additional_tests;

  return (
    <SpecsTableAccordion
      title={title}
      isOpen={isOpen}
      onClickToggle={onClickToggle}
    >
      {isOpen && (
        <>
          {tests.map(({ data: test }, testIndex) => (
            <table key={testIndex} className="table table-striped mt-0 mb-5">
              <tbody>
                {test.map((row, rowIndex) => {

                  // Specific validation for the "Abrasion resistance field"
                  if (test.length !== 3) {
                    test.map((row, rowIndex) => {
                      if (rowIndex !== 0 && rowIndex !== 1) {
                        row[0] = ""
                      }
                    })
                  }

                  return <tr key={rowIndex}>
                    {row.map((col, colIndex) => {
                      if (rowIndex === 0)
                        return (
                          <th
                            key={colIndex}
                            colSpan={test[1] ? test[1].length : 1}
                          >
                            {col}
                          </th>
                        );
                      else if (colIndex === 0) 
                        if (
                          test[1] !== undefined &&
                          test[2] !== undefined &&
                          test[1][0] === test[2][0] 
                        )
                          if (rowIndex + 1 !== test.length)
                            return (
                              <td width="20%" rowSpan="2">
                                {col}
                              </td>
                            );
                          else return null;
                        else
                          return (
                            <td key={colIndex} width="20%">
                              {col}
                            </td>
                          );
                      else
                        return (
                          <td
                            key={colIndex}
                            width={row.length === 2 ? "80%" : "40%"}
                          >
                            {col}
                          </td>
                        );
                    })}
                  </tr>
                })}
              </tbody>
            </table>
        ))}
        </>
      )}
    </SpecsTableAccordion>
  );
}
