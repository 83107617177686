import gql from "graphql-tag";
import singlePatternFragment from "utils/fragments/SinglePatternFragment";
import singlePatternGeneralFragment from "utils/fragments/SinglePatternGeneralFragment";
import singlePatternBackingFragment from "utils/fragments/SinglePatternBackingFragment";
import singlePatternOtherAttributesFragment from "utils/fragments/SinglePatternOtherAttributesFragment";
import singlePatternProductCategorizationFragment from "utils/fragments/SinglePatternProductCategorizationFragment";
import singlePatternSustainabilityAttributesFragment from "../../../utils/fragments/SinglePatternSustainabilityAttributesFragment";

export const singlePatternQuery = gql`
  query SinglePatternQuery($id: ID, $slug: String) {
    pattern(id: $id, slug: $slug) {
      ...SinglePatternFragment
      maintenanceGuide
      transformationGuide
      specsSheetData(fullVersion: false)
      products {
        totalCount
        edges {
          node {
            id
            code
            color
            imageThumbSmall
            imageThumbMedium
          }
        }
      }
      productcategorization {
        id
        type
        appearance
        business
        warranty
        exclusive
        definition
      }
      sectors {
        edges {
          node {
            id
            name
            market {
              id
              name
            }
          }
        }
      }
    }
  }
  ${singlePatternFragment}
`;

export const singlePatternGeneralQuery = gql`
  query SinglePatternGeneralQuery($id: ID!) {
    pattern(id: $id) {
      id
      general {
        ...SinglePatternGeneralFragment
        rollLength
      }
    }
  }
  ${singlePatternGeneralFragment}
`;

export const singlePatternBackingQuery = gql`
  query SinglePatternBackingQuery($id: ID!) {
    pattern(id: $id) {
      id
      backing {
        ...SinglePatternBackingFragment
        weight
      }
    }
  }
  ${singlePatternBackingFragment}
`;

export const singlePatternOtherAttributesQuery = gql`
  query SinglePatternOtherAttributesQuery($id: ID!) {
    pattern(id: $id) {
      id
      otherattributes {
        ...SinglePatternOtherAttributesFragment
      }
    }
  }
  ${singlePatternOtherAttributesFragment}
`;

export const singlePatternProductCategorizationQuery = gql`
  query SinglePatternProductCategorizationQuery($id: ID!) {
    pattern(id: $id) {
      id
      productcategorization {
        ...SinglePatternProductCategorizationFragment
      }
    }
  }
  ${singlePatternProductCategorizationFragment}
`;

export const singlePatternSustainabilityAttributes = gql`
  query SinglePatternSustainabilityAttributesQuery($id: ID!) {
    pattern(id: $id) {
      id
      slug
      packagingFsc
      sustainability {
        ...SinglePatternSustainabilityAttributesFragment
      }
      manufacturingCertifications {
        id
        name
        logo
        isSustainable
      }
    }
  }
  ${singlePatternSustainabilityAttributesFragment}
`;

export const singlePatternPdfFlameRetardancyQuery = gql`
  query SinglePatternPdfFlameRetardancyQuery($id: ID!) {
    pattern(id: $id) {
      id
      successPdfFlameRetardancyTests
    }
  }
`;

export const singlePatternAdditionalTestsQuery = gql`
  query SinglePatternAdditionalTestsQuery($id: ID!) {
    pattern(id: $id) {
      id
      additionaltests {
        tensileStrengthTest {
          range
          warp
          fill
          unit
          method
        }
        elongationAtBreakTest {
          range
          warp
          fill
          unit
          method
        }
        elongationITest {
          range
          warp
          fill
          unit
          method
          at
        }
        elongationIITest {
          range
          warp
          fill
          unit
          method
          at
        }
        stretchTest {
          range
          warp
          fill
          unit
          method
        }
        setTest {
          range
          warp
          fill
          unit
          method
        }
        tearStrengthTrapezoidMethodTest {
          range
          warp
          fill
          unit
          method
        }
        tearStrengthTongueMethodTest {
          range
          warp
          fill
          unit
          method
        }
        tearStrengthTest {
          range
          warp
          fill
          unit
          method
        }
        stitchingStrengthTest {
          range
          warp
          fill
          unit
          method
        }
        seamStrengthTest {
          range
          warp
          fill
          unit
          method
        }
        seamFatigueTest {
          range
          warp
          fill
          unit
          method
        }
        adhesionTest {
          range
          warp
          fill
          unit
          method
        }
        abrasionResistanceITest {
          range
          result
          type
          method
          conditions
        }
        abrasionResistanceIITest {
          result
          type
          method
          conditions
        }
        abrasionResistanceIIITest {
          result
          type
          method
          conditions
        }
        crockingResistanceITest {
          range
          wet
          dry
          method
        }
        crockingResistanceIITest {
          range
          wet
          dry
          method
        }
        odourTest {
          range
          wet
          dry
          method
        }
        flexResistanceTest {
          range
          warp
          fill
          unit
          method
        }
        acceleratedLightAgingTest {
          xenotestResult
          xenotestMethod
          quvResult
          quvMethod
          meterResult
          meterMethod
        }
        acceleratedLightAgingTearingStrengthTest {
          warp
          fill
          unit
          method
        }
        acceleratedLightAgingBendingTest {
          range
          warp
          fill
          method
        }
        coldCrackTest {
          temperature
          method
        }
        blockingTest {
          result
          method
        }
        dimensionalStabilityHeatTest {
          range
          warp
          fill
          unit
          method
        }
        dimensionalStabilityHumidityTest {
          range
          warp
          fill
          unit
          method
        }
        dimensionalStabilityWaterTest {
          range
          warp
          fill
          unit
          method
        }
        volatilityTest {
          range
          result
          unit
          method
        }
        hardnessTest {
          range
          warp
          fill
          unit
          method
        }
        lossOnHeatingTest {
          range
          result
          unit
          method
        }
        resistanceToChemicalsTest {
          result
          substance
          method
        }
        slipResistanceOfFlooringTest {
          range
          wet
          dry
          method
        }
        thicknessOfWearLayerTest {
          range
          result
          unit
          method
        }
        flooringClassificationTest {
          classification
          method
        }
        thermalConductivityTest {
          range
          result
          unit
          method
        }
        castorChairContinuousUseTest {
          result
          method
        }
        residualIndentationTest {
          range
          result
          unit
          method
        }
      }
    }
  }
`;
