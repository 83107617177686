import gql from "graphql-tag";
import singlePatternFragment from "utils/fragments/SinglePatternFragment";

const simplePatternsQuery = gql`
  query SimplePatternsQuery(
    $orderBy: String
    $first: Int
    $isVisibleLibrary: Boolean
  ) {
    patterns(
      orderBy: $orderBy
      first: $first
      isVisibleLibrary: $isVisibleLibrary
    ) {
      edges {
        node {
          ...SinglePatternFragment
        }
      }
    }
  }
  ${singlePatternFragment}
`;

export default simplePatternsQuery;
