import React, {Component} from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import FiltersBox from "scenes/ProductsPage/components/FiltersBar/components/FiltersBox";
import {updateQueryParams} from "services/browser-history";
import ProductList from "scenes/ProductsPage/components/ProductsList";
import {getQueryParamDataAsArray} from "services/browser-history";

class CheckboxFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSubOptions: new Set()
    }
    this.handleOnChange = option => this._handleOnChange.bind(this, option)
  }

  /**
   * Handle onChange checkbox input to set new data into queryParams
   * @param option
   * @private
   */
  _handleOnChange(option) {
    const {queryParam} = this.props;

    let nextParams = getQueryParamDataAsArray(queryParam);

    if (nextParams.includes(option.value))
      nextParams = nextParams.filter(value => value !== option.value);
    else
      nextParams.push(option.value);

    updateQueryParams({
      [queryParam]: nextParams,
      ...ProductList.resettled_pagination_query_params(),
    })
  }

  _handleChangeSubGroup(option) {
    const newDropdowns = new Set(this.state.showSubOptions);
    // Si el desplegable ya est� abierto, cerrarlo; de lo contrario, abrirlo
    if (newDropdowns.has(option)) {
      newDropdowns.delete(option);
    } else {
      newDropdowns.add(option);
    }

    this.setState({ showSubOptions: newDropdowns });
  }


  render() {
    const {title, options, queryParam, withoutTranslation, containsSubGroups, optGroups} = this.props;
    const currentQueryParamData = getQueryParamDataAsArray(queryParam);

    return (
        <FiltersBox title={title}>
          {/* This is when the options have Subgroups */}
          {containsSubGroups &&
            optGroups.map((group, index) => (
              <div key={index} className="mb-4">
                {group.title && (
                  <>
                    <h6 className="font-weight-bold">{group.title}</h6>
                    <hr />
                  </>
                )}
                {group.options.map((option, optionIndex) => {
                  return (
                    <div
                      key={`filterBoxOption${optionIndex}`}
                      >
                      <p className="text-capitalize p-0 m-2">
                        <input
                          type="checkbox"
                          checked={currentQueryParamData.includes(option.value)}
                          onChange={this.handleOnChange(option)} />
                        &nbsp;
                        {
                          withoutTranslation ?
                            <span>{option.name}</span>
                            :
                            <FormattedMessage
                              {...option} />
                        }
                      </p>
                    </div>
                  );
                })}
              </div>
            ))
          }

          {/* This is the map when the options not have clasificated by groups */}
          {!containsSubGroups && 
            options.map((option, optionIndex) => (
                <div
                    key={`filterBoxOption${optionIndex}`}>
                  <p className="text-capitalize">
                    <input
                        type="checkbox"
                        checked={currentQueryParamData.includes(option.value)}
                        onChange={this.handleOnChange(option)}/>
                    &nbsp;
                    {
                      withoutTranslation ?
                          <span>{option.name}</span>
                          :
                          <FormattedMessage
                              {...option}/>
                    }
                  </p>
                </div>
            ))
          }
        </FiltersBox>
    )
  }
}

CheckboxFilter.defaultProps = {
  withoutTranslation: false
};

CheckboxFilter.propTypes = {
  title: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  queryParam: PropTypes.string.isRequired,
  withoutTranslation: PropTypes.bool
};

export default CheckboxFilter;