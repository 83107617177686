import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/FiltersBar.scss";
import FiltersBarActions from "scenes/ProductsPage/components/FiltersBar/components/FiltersBarActions";
import {FormattedMessage} from "react-intl";
import {MARKET_SEGMENTS} from "scenes/ProductsPage/components/FiltersBar/contants/market_segments";
import {APPEARANCES} from "scenes/ProductsPage/components/FiltersBar/contants/appearances";
import {APPLICATIONS} from "scenes/ProductsPage/components/FiltersBar/contants/applications";
import {TOPCOATS} from "scenes/ProductsPage/components/FiltersBar/contants/topcoat";
import {POLYMERS} from "scenes/ProductsPage/components/FiltersBar/contants/polymers";
import CheckboxFilter from "scenes/ProductsPage/components/FiltersBar/components/CheckboxFilter";
import {Query} from "react-apollo";
import {
  flameRetardancyTestsQuery,
  gaugeRangeLimitsQuery,
  weightRangeLimitsQuery,
  widthRangeLimitsQuery
} from "scenes/ProductsPage/components/FiltersBar/queries";
import LoadingMessage from "utils/components/LoadingMessage";
import FiltersBox from "scenes/ProductsPage/components/FiltersBar/components/FiltersBox";
import RangeFilter from "scenes/ProductsPage/components/FiltersBar/components/RangeFilter";
import {BACKING_DESCRIPTION} from "scenes/ProductsPage/components/FiltersBar/contants/backing_description";
import {EMBOSSINGS} from "scenes/ProductsPage/components/FiltersBar/contants/embossings";
import {
  QUERY_PARAM_APPEARANCE,
  QUERY_PARAM_APPLICATION,
  QUERY_PARAM_BACKING_DESCRIPTION,
  QUERY_PARAM_EMBOSSING,
  QUERY_PARAM_FLAME_RETARDANCY_TEST,
  QUERY_PARAM_GAUGE_RANGE,
  QUERY_PARAM_OUTDOOR,
  QUERY_PARAM_POLYMER,
  QUERY_PARAM_SEGMENT,
  QUERY_PARAM_TOPCOAT,
  QUERY_PARAM_WEIGHT_RANGE,
  QUERY_PARAM_WIDTH_RANGE
} from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
import {OUTDOORS} from "scenes/ProductsPage/components/FiltersBar/contants/outdoor";
import RadioFilter from "scenes/ProductsPage/components/FiltersBar/components/RadioFilter";
import { QUERY_PARAM_PATTERN_CLASS, QUERY_PARAM_SUSTAINAILITY_SEARCH } from "./contants/query_params";
import { PATTERN_CLASSES } from "./contants/patternClasses";
import { SUSTAINABILITY } from "./contants/sustainability";

const FiltersBar = ({productsPageReducer}) => {
  const defaultGaugeRangeLimits = {gauge_min_value: 0, gauge_max_value: 0}
  const defaultGeneralWidthLimits = {width_min_value: 0, width_max_value: 0}
  const defaultGeneralWeightLimits = {weight_min_value: 0, weight_max_value: 0}

  return (
    <div className={classNames({
      "filters-bar": true,
      "filters-bar--visible": productsPageReducer.filtersBarIsOpen
    })}>
      <div className="filters-bar__content">
        <FiltersBarActions/>

        <CheckboxFilter
            options={PATTERN_CLASSES}
            queryParam={QUERY_PARAM_PATTERN_CLASS}
            title={
              <FormattedMessage
                  id="FiltersBar.productDefinitionFilterTitle"
                  description="Title for product definition filter section at filters bar"
                  defaultMessage="Product Definition"/>
          }/>

        <CheckboxFilter
            options={MARKET_SEGMENTS}
            queryParam={QUERY_PARAM_SEGMENT}
            title={
              <FormattedMessage
                  id="FiltersBar.segmentFilterTitle"
                  description="Title for segment filter section at filters bar"
                  defaultMessage="Segment"/>
            }/>

        <CheckboxFilter
            options={APPEARANCES}
            queryParam={QUERY_PARAM_APPEARANCE}
            title={
              <FormattedMessage
                  id="FiltersBar.appearanceFilterTitle"
                  description="Title for appearance filter section at filters bar"
                  defaultMessage="Appearance"/>
            }/>

        <RadioFilter
            options={OUTDOORS}
            queryParam={QUERY_PARAM_OUTDOOR}
            title={
              <FormattedMessage
                  id="FiltersBar.outdoorFilterTitle"
                  description="Title for outdoor filter section at filters bar"
                  defaultMessage="Outdoor"/>
            }/>

        <Query
            query={gaugeRangeLimitsQuery}>
          {({loading, error, data}) => {
            const title = <FormattedMessage
                id="FiltersBar.gaugeFilterTitle"
                description="Title for gauge filter section at filters bar"
                defaultMessage="Gauge"/>;

            if (loading || error)
              return (
                  <FiltersBox
                      title={title}>
                    <LoadingMessage/>
                  </FiltersBox>
              );

            const {
              gaugeRangeLimits: {
                  gauge_min_value,
                  gauge_max_value
              } = defaultGaugeRangeLimits
            } = data;
            return (
                <RangeFilter
                    title={title}
                    queryParam={QUERY_PARAM_GAUGE_RANGE}
                    minValue={gauge_min_value}
                    maxValue={gauge_max_value}/>
            )
          }}
        </Query>

        <Query
            query={widthRangeLimitsQuery}>
          {({loading, error, data}) => {
            const title = <FormattedMessage
                id="FiltersBar.widthFilterTitle"
                description="Title for width filter section at filters bar"
                defaultMessage="Width"/>;

            if (loading || error)
              return (
                  <FiltersBox
                      title={title}>
                    <LoadingMessage/>
                  </FiltersBox>
              );

            const {
              generalWidthLimits: {
                  width_min_value,
                  width_max_value
              } = defaultGeneralWidthLimits
            } = data;
            return (
                <RangeFilter
                    title={title}
                    queryParam={QUERY_PARAM_WIDTH_RANGE}
                    minValue={width_min_value}
                    maxValue={width_max_value}/>
            )
          }}
        </Query>

        <Query
            query={weightRangeLimitsQuery}>
          {({loading, error, data}) => {
            const title = <FormattedMessage
                id="FiltersBar.weightFilterTitle"
                description="Title for weight filter section at filters bar"
                defaultMessage="Weight"/>;

            if (loading || error)
              return (
                  <FiltersBox
                      title={title}>
                    <LoadingMessage/>
                  </FiltersBox>
              );
              
            const {
                generalWeightLimits: {
                    weight_min_value,
                    weight_max_value
                } = defaultGeneralWeightLimits
            } = data;
            return (
                <RangeFilter
                    title={title}
                    queryParam={QUERY_PARAM_WEIGHT_RANGE}
                    minValue={weight_min_value}
                    maxValue={weight_max_value}/>
            )
          }}
        </Query>

        <CheckboxFilter
            options={APPLICATIONS}
            queryParam={QUERY_PARAM_APPLICATION}
            title={
              <FormattedMessage
                  id="FiltersBar.applicationFilterTitle"
                  description="Title for application filter section at filters bar"
                  defaultMessage="Application"/>
            }/>

        <CheckboxFilter
            options={BACKING_DESCRIPTION}
            queryParam={QUERY_PARAM_BACKING_DESCRIPTION}
            title={
              <FormattedMessage
                  id="FiltersBar.backingDescriptionFilterTitle"
                  description="Title for backing filter section at filters bar"
                  defaultMessage="Backing"/>
            }/>

        <CheckboxFilter
            options={TOPCOATS}
            queryParam={QUERY_PARAM_TOPCOAT}
            title={
              <FormattedMessage
                  id="FiltersBar.topcoatFilterTitle"
                  description="Title for topcoat filter section at filters bar"
                  defaultMessage="Topcoat"/>
            }/>

        <CheckboxFilter
            options={POLYMERS}
            queryParam={QUERY_PARAM_POLYMER}
            title={
              <FormattedMessage
                  id="FiltersBar.polymerFilterTitle"
                  description="Title for polymer filter section at filters bar"
                  defaultMessage="Polymer"/>
            }/>

        <Query
            query={flameRetardancyTestsQuery}>
          {({loading, error, data}) => {
            const title = <FormattedMessage
                id="FiltersBar.flameRetardancyFilterTitle"
                description="Title for flame retardancy filter section at filters bar"
                defaultMessage="Flame Retardancy"/>;

            if (loading || error)
              return (
                  <FiltersBox
                      title={title}>
                    <LoadingMessage/>
                  </FiltersBox>
              );

            const {flameRetardancyTests} = data;

            return (
                <CheckboxFilter
                    options={flameRetardancyTests.edges.map(item => ({
                      'value': item.node.id,
                      'name': item.node.name
                    }))}
                    queryParam={QUERY_PARAM_FLAME_RETARDANCY_TEST}
                    title={title}
                    withoutTranslation/>
            )
          }}
        </Query>

        <CheckboxFilter
          options={EMBOSSINGS}
          queryParam={QUERY_PARAM_EMBOSSING}
          title={
            <FormattedMessage
              id="FiltersBar.embossingFilterTitle"
              description="Title for embossing filter section at filters bar"
              defaultMessage="Embossing" />
          } />

        <CheckboxFilter
          containsSubGroups
          optGroups={SUSTAINABILITY}
          queryParam={QUERY_PARAM_SUSTAINAILITY_SEARCH}
          title={
            <FormattedMessage
              id="FiltersBar.sustainabilityFilterTitle"
              description="Title for sustainability filter section at filters bar"
              defaultMessage="Sustainability" />
          } />
      </div>
    </div>
  )
}

FiltersBar.propTypes = {
  productsPageReducer: PropTypes.shape({
    filtersBarIsOpen: PropTypes.bool.isRequired,
    compareBoxItems: PropTypes.array.isRequired,
  })
};

export default FiltersBar;