import * as types from '../actions/types';
import esMessages from "locales/es";
import enMessages from "locales/en";

export const initialState = {
  language: navigator.language.split(/[-_]/)[0],
  messages: {
    es: esMessages,
    en: enMessages
  }
};

export const localesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LANGUAGE_CHANGE:
      return {
        ...state,
        language: action.language
      };

    default:
      return state;
  }
};