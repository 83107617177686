import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import "./styles/MainMenuUserInfo.scss";
import {authLogout} from "services/auth/index";
import {Query} from "react-apollo";
import meQuery from "utils/queries/MeQuery";

class MainMenuUserInfo extends Component {
  constructor(props) {
    super(props);

    this.handleLogoutButtonClick = this.handleLogoutButtonClick.bind(this);
  }

  /**
   * Handles logout button click to calls authLogout function
   */
  handleLogoutButtonClick() {
    authLogout();
    this.props.closeMainMenu();
  }

  render() {
    return (
        <div className="main-menu-user">
          <span className="main-menu-user__welcome">
            <FormattedMessage
                id="MainMenuUserInfo.welcome"
                description="Main menu user section, welcome word"
                defaultMessage="Welcome"/>
          </span>
          <Query
              query={meQuery}>
            {({loading, error, data}) => {
              if (loading || error)
                return null;

              const {me} = data;
              return (
                  <span
                      className="main-menu-user__name">
                    {me.fullName}
                  </span>
              )
            }}
          </Query>
          <span className="main-menu-user__logout">
            <button
                type="button"
                onClick={this.handleLogoutButtonClick}>
              <FormattedMessage
                  id="MainMenuUserInfo.logoutButton"
                  description="Main menu user section, logout button"
                  defaultMessage="Log out"/>
            </button>
          </span>
        </div>
    )
  }
}

export default MainMenuUserInfo;