import React, {Component} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames/bind";
import "./styles/MainMenuDropdown.scss";
import MainMenuDropdownBox
  from "components/Header/components/HeaderMainMenu/components/MainMenuSections/components/MainMenuDropdown/components/MainMenuDropdownBox";

class MainMenuDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleClickOutsideBox = this.handleClickOutsideBox.bind(this);
  }

  /**
   * Handles button click to toggle open status of the dropdown
   * @param {Object} event
   */
  handleButtonClick(event) {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  /**
   * Handles clicks outside dropdown box
   * @param {Object} event
   */
  handleClickOutsideBox(event) {
    this.setState({
      isOpen: false
    });
  }

  render() {
    const {isOpen} = this.state;
    const {title, subSections, pathname, queryParam} = this.props.menu;

    return (
        <li className={classNames({
          "main-menu-dropdown": true,
          "main-menu-dropdown--open": isOpen
        })}>
          <button
              type="button"
              className={classNames({
                "main-menu-dropdown__button": true,
                "ignore-react-onclickoutside": isOpen
              })}
              onClick={this.handleButtonClick}>
            {title}
            <i className={classNames({
              "sp-plus": !isOpen,
              "sp-minus": isOpen
            })}/>
          </button>
          <MainMenuDropdownBox
              isOpen={isOpen}
              subSections={subSections}
              pathname={pathname}
              queryParam={queryParam}
              onClickOutside={this.handleClickOutsideBox}/>
        </li>
    )
  }
}

MainMenuDropdown.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subSections: PropTypes.array.isRequired,
    pathname: PropTypes.string.isRequired,
    queryParam: PropTypes.string
  }).isRequired
};

export default MainMenuDropdown