import {createBrowserHistory} from "history";
import queryString from 'query-string';

const proquinalHistory = createBrowserHistory();

/**
 * Retrieves parsed query params in current location
 * @return {Object} queryParams
 */
export const getParsedQueryParams = () => {
  return queryString.parse(proquinalHistory.location.search);
};

/**
 * Update query params in current path.
 * @param {Object} queryParams
 * @param {Boolean} replaceQueryParams
 */
export const updateQueryParams = (queryParams, replaceQueryParams = false) => {
  const currentParams = getParsedQueryParams();
  let newParams = {};
  if (!replaceQueryParams)
    newParams = Object.assign(currentParams, queryParams);
  else
    newParams = Object.assign(queryParams);
  const cleanedParams = removeEmpty(newParams);
  const paramsAsString = queryString.stringify(cleanedParams);
  proquinalHistory.push({
    pathname:  proquinalHistory.location.pathname,
    search: `?${paramsAsString}`
  });
};

// Taken from https://stackoverflow.com/a/38340730/923323
const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => (obj[key] === null || obj[key] === undefined || obj[key] === '') && delete obj[key]);
  return obj;
};

/**
 * Get received query param in the current path and returns as array of strings
 * @param queryParam
 * @return {array<string>}
 */
export const getQueryParamDataAsArray = queryParam => {
  const queryParamData = getParsedQueryParams()[queryParam] || [];
  return Array.isArray(queryParamData) ? queryParamData : [queryParamData];
};

export default proquinalHistory;