import React from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";

export default function Warranty() {
  const intl = useIntl();

  const messages = defineMessages({
    latam: {
      id: "Warranties.latam",
      defaultMessage: "Latam"
    },
    europe: {
      id: "Warranties.europe",
      defaultMessage: "Europe"
    },
    mexico: {
      id: "Warranties.mexico",
      defaultMessage: "Mexico"
    },
    usaOem: {
      id: "Warranties.usaOem",
      defaultMessage: "USA - OEM School Bus"
    },
    usaContract: {
      id: "Warranties.usaContract",
      defaultMessage: "USA - Contract"
    },
    usaMarine: {
      id: "Warranties.usaMarine",
      defaultMessage: "USA - Marine"
    },
    usaRvPu: {
      id: "Warranties.usaRvPu",
      defaultMessage: "USA - RV PU"
    },
    usaRvVinyl: {
      id: "Warranties.usaRvVinyl",
      defaultMessage: "USA - RV Vinyl"
    }
  });

  const documents = [
    {
      name: intl.formatMessage(messages.latam),
      file: "https://dupyu6pa640bl.cloudfront.net/attachments/warranty-latam.pdf"
    },
    {
      name: intl.formatMessage(messages.europe),
      file: "https://dupyu6pa640bl.cloudfront.net/attachments/Warranty-europe.pdf"
    },
    {
      name: intl.formatMessage(messages.mexico),
      file: "https://dupyu6pa640bl.cloudfront.net/attachments/Warranty-europe.pdf"
    },
    {
      name: intl.formatMessage(messages.usaOem),
      file: "https://dupyu6pa640bl.cloudfront.net/attachments/Warranty-USA-Bus.pdf "
    },
    {
      name: intl.formatMessage(messages.usaContract),
      file: "https://dupyu6pa640bl.cloudfront.net/attachments/Warranty-USA-Contract.pdf "
    },
    {
      name: intl.formatMessage(messages.usaMarine),
      file: "https://dupyu6pa640bl.cloudfront.net/attachments/Warranty-USA-Marine.pdf "
    },
    {
      name: intl.formatMessage(messages.usaRvPu),
      file: "https://dupyu6pa640bl.cloudfront.net/attachments/Warranty-USA-RV-PU.pdf "
    },
    {
      name: intl.formatMessage(messages.usaRvVinyl),
      file: "https://dupyu6pa640bl.cloudfront.net/attachments/Warranty-USA-RV-Vinyl.pdf "
    }
  ];

  return (
      <div className="container pt-5 pb-5">
        <h1>
          <FormattedMessage
              id="Warranties.title"
              description="Warranties page title"
              defaultMessage="Limited warranty"/>
        </h1>
        <hr/>
        {
          documents.map((document, index) =>
              <React.Fragment key={index}>
                <div
                    className="pt-4 mb-5">
                  <h5>
                    <FormattedMessage
                        id="Warranties.limitedGuarantee"
                        description="Limited guarantee title of single warranty section"
                        defaultMessage="Limited warranty"/>
                    &nbsp;
                    {document.name}
                  </h5>
                  <p>
                    <FormattedMessage
                        id="Warranties.warrantyDescription"
                        description="Description in single warranty section"
                        defaultMessage="We guarantee that our products comply with performance in accordance with defined standards as long as the recommendations for proper maintenance are followed."/>
                  </p>
                  <a
                      href={document.file}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn btn-link pl-0">
                    <FormattedMessage
                        id="Warranties.downloadPDFBtnLabel"
                        description="Download PDF button label in warranty section"
                        defaultMessage="Download PDF"/>
                    &nbsp;
                    <i className="fa fa-file-pdf-o"/>
                  </a>
                </div>
                <hr/>
              </React.Fragment>
          )
        }
      </div>
  );
}