import {defineMessages} from "react-intl";

export const POLYMER_PVC = 'PVC';
export const POLYMER_PVC_MESSAGE = defineMessages({
  [POLYMER_PVC]: {
    id: "Polymer.pvc",
    description: "Polymer type value named PVC",
    defaultMessage: "PVC"
  }
});

export const POLYMER_PU = 'PU';
export const POLYMER_PU_MESSAGE = defineMessages({
  [POLYMER_PU]: {
    id: "Polymer.pu",
    description: "Polymer type value PU",
    defaultMessage: "PU"
  }
});

export const POLYMER_TPE = 'TPE';
export const POLYMER_TPE_MESSAGE = defineMessages({
  [POLYMER_TPE]: {
    id: "Polymer.tpe",
    description: "Polymer type value named TPE",
    defaultMessage: "TPE"
  }
});

export const POLYMER_TPO = 'TPO';
export const POLYMER_TPO_MESSAGE = defineMessages({
  [POLYMER_TPO]: {
    id: "Polymer.tpo",
    description: "Polymer type value named TPO",
    defaultMessage: "TPO"
  }
});

export const POLYMER_SILICONE = 'SILICONE';
export const POLYMER_SILICONE_MESSAGE = defineMessages({
  [POLYMER_SILICONE]: {
    id: "Polymer.silicone",
    description: "Polymer type value named SILICONE",
    defaultMessage: "Silicone"
  }
});


export const POLYMERS = [
  {...POLYMER_PVC_MESSAGE[POLYMER_PVC], value: POLYMER_PVC},
  {...POLYMER_PU_MESSAGE[POLYMER_PU], value: POLYMER_PU},
  {...POLYMER_TPE_MESSAGE[POLYMER_TPE], value: POLYMER_TPE},
  {...POLYMER_TPO_MESSAGE[POLYMER_TPO], value: POLYMER_TPO},
  {...POLYMER_SILICONE_MESSAGE[POLYMER_SILICONE], value: POLYMER_SILICONE},
];