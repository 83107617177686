import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {graphql, compose} from "react-apollo";
import meQuery from "utils/queries/MeQuery";
import updateUserMutation from "utils/mutations/UpdateUser";
import {UNIT_SYSTEMS} from "scenes/ProductDetails/components/ProductSpecs/components/ChangeUnitSystemLink/components/ChangeUnitSystemModal/constants/unit_systems";

export class ChangeUnitSystemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUnitSystem: null
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  /**
   *
   * @param event
   */
  handleFormSubmit(event) {
    const {selectedUnitSystem} = this.state;
    const {me} = this.props.meQuery;

    event.preventDefault();
    this.props.updateUserMutation({
      variables: {
        input: {
          userId: me.id,
          unitSystem: selectedUnitSystem
        }
      },
      refetchQueries: ['SinglePatternGeneralQuery', 'SinglePatternBackingQuery', 'MultiplePatternsQuery', 'SinglePatternPDFQuery']
    }).then(response => {
      this.props.toggle()
    })
  }

  /**
   *
   * @param event
   */
  handleSelectChange(event) {
    this.setState({
      selectedUnitSystem: event.target.value
    });
  }

  render() {
    const {selectedUnitSystem} = this.props;
    const {isOpen} = this.props;
    const {me} = this.props.meQuery;

    if (!me)
      return null;

    return (
        <Modal
            isOpen={isOpen}
            toggle={this.props.toggle}>
          <ModalHeader toggle={this.props.toggle}>
            <FormattedMessage
                id="ChangeUnitSystemModal.title"
                description="Title of Change unit system modal"
                defaultMessage="Change Unit System"/>
          </ModalHeader>
          <form onSubmit={this.handleFormSubmit}>
            <ModalBody>
              <div className="form-group">
                <select
                    className="form-control"
                    name="unitSystem"
                    defaultValue={me.unitSystem}
                    onChange={this.handleSelectChange}
                    value={selectedUnitSystem}>
                  {
                    UNIT_SYSTEMS.map((unitSystem, index) => (
                        <option
                            key={`unitSystem${index}`}
                            value={unitSystem.value}>
                          {unitSystem.display}
                        </option>
                    ))
                  }
                </select>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                  type="submit"
                  className="btn btn-primary">
                <FormattedMessage
                    id="ChangeUnitSystemModal.saveButton"
                    description="save button label of Change unit system modal"
                    defaultMessage="Save"/>
              </button>
            </ModalFooter>
          </form>
        </Modal>
    )
  }
}

ChangeUnitSystemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default compose(
    graphql(meQuery, {name: "meQuery"}),
    graphql(updateUserMutation, {name: "updateUserMutation"})
)(ChangeUnitSystemModal);
