import React from "react";
import {FormattedMessage} from "react-intl";
import "./styles/CompareBar.scss";
import {withRouter} from "react-router";
import CompareButton from "scenes/ProductsPage/components/ProductsList/components/CompareButton";
import CompareBox from "scenes/ProductsPage/components/ProductsList/components/CompareBox";

const CompareBar = () => {

  const currentCompareParams = CompareBox.getCurrentCompareParams();

  if (currentCompareParams.length === 0)
    return null;

  return (
      <div className="compare-bar">
        <div className="container">
          <div className="row justify-content-between no-gutters">
            <div className="col-auto d-flex align-items-center">
              <p className="mb-0">
                <strong>{currentCompareParams.length}&nbsp;</strong>
                <FormattedMessage
                    id="CompareBar.text"
                    description="Selected references text at compare bar"
                    defaultMessage="selected references"/>
              </p>
            </div>
            <div className="col-auto text-right">
              <CompareButton/>
            </div>
          </div>
        </div>
      </div>
  )
};

export default withRouter(CompareBar);